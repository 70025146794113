import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import ProductCard from '../../components/productCard'
import ShopProfile from '../../components/shop/shopProfile'
import { useGetShopProductsQuery } from '../../features/products/productsSlice'
import NoData from '../../components/noData'
import '../searchPage/index.css'
import ShopProductsPanel from '../../components/shopProductsPanel'

const ShopPage = () => {
  const { shopID } = useParams()
  const mostRecommendedQuery = 'sort_by=units_sold&sort=desc&limit=6'

  const navigate = useNavigate()

  const {
    data: recommendedProduct,
    error: isRecommendedProductError,
    isLoading: isRecommendedProductLoading,
  } = useGetShopProductsQuery({ id: shopID, query: mostRecommendedQuery })

  return (
    <>
      <Container maxW='6xl' mt={4}>
        <ShopProfile />

        {isRecommendedProductLoading ? (
          <LoadingSpinner />
        ) : isRecommendedProductError ? (
          <ErrorState />
        ) : (
          <Box
            mt={4}
            borderColor={'gray.100'}
            boxShadow='md'
            borderWidth={1}
            padding={2}
            borderRadius={10}
          >
            <Flex justifyContent='space-between'>
              <Text fontSize={['lg', 'xl', 'xl']}>Most purchased products</Text>
              {recommendedProduct.data && (
                <Button
                  onClick={() => {
                    navigate('most-purchased')
                  }}
                >
                  See more
                </Button>
              )}
            </Flex>
            {!recommendedProduct.data ? (
              <Center mt={1}>
                <NoData />
              </Center>
            ) : (
              <HStack
                overflowX={'scroll'}
                flexFlow='row'
                gridColumn={[2, 3, 5]}
                gridAutoFlow='column'
                gridAutoColumns={'auto'}
              >
                {recommendedProduct?.data.map((x: any) => (
                  <ProductCard
                    key={x.id}
                    onClick={() => navigate(`/products/${x.id}`)}
                    data={x}
                  />
                ))}
              </HStack>
            )}
          </Box>
        )}
        <ShopProductsPanel />
      </Container>
    </>
  )
}

export default ShopPage
