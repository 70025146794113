export enum GendersEnum {
  male = 'm',
  female = 'f',
}

export enum ShopVoucherStatusesEnum {
  ONGOING = 'ONGOING',
  INCOMING = 'INCOMING',
  ENDED = 'ENDED',
}

export enum ShopVoucherTypeEnum {
  PERCENTAGE = 'PERCENTAGE',
  NOMINAL = 'NOMINAL',
}
