import {
  Container,
  SimpleGrid,
  Grid,
  GridItem,
  Box,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import ErrorState from '../errorState'
import LoadingSpinner from '../loadingSpinner'
import ProductCard from '../productCard'
import ShopProductCategoryPanel from '../shopProductCategoryPanel'
import ShopProductCategoryTabs from '../tabs/shopProductCategoryTabs'
import SortSellerPage from '../sort/sortSellerPage'
import {
  useLazyGetProductsByShopCategoryQuery,
  useLazyGetShopProductsQuery,
} from '../../features/products/productsSlice'
import { useLazyGetShopProductCategoriesByShopIDQuery } from '../../features/shopProductCategories/shopProductCategoriesSlice'
import Pagination from 'react-responsive-pagination'
import { setSortAndSortBy } from '../../features/sortFilter/sortFilterSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../app/store'
import NoData from '../noData'

const ShopProductsPanel = () => {
  const { shopID } = useParams()

  const [products, setProducts] = useState<Array<any>>([])
  const [categories, setCategories] = useState<Array<any>>([])
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)

  const [querySort, setQuerySort] = useState(
    'limit=10&sort_by=created_at&sort=asc'
  )

  const dispatch = useDispatch()

  const productListRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const [triggerGetShopProductCategories, shopProductCategoriesResult] =
    useLazyGetShopProductCategoriesByShopIDQuery()
  const [triggerGetProducts, productsResult] =
    useLazyGetProductsByShopCategoryQuery()
  const [triggerGetAllProducts, allProductsResult] =
    useLazyGetShopProductsQuery()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const trigger = () => {
    if (selectedIndex === 0) {
      triggerGetAllProducts({ id: shopID, query: querySort })

      return
    }

    triggerGetProducts({
      categoryId: categories[selectedIndex].id,
      query: querySort,
    })
  }
  useEffect(() => {
    const querystring = `{"sort_by":"created_at", "sort":"asc"}`
    dispatch(setSortAndSortBy(querystring))
    triggerGetShopProductCategories({ shopID })
    triggerGetAllProducts({ id: shopID, query: querystring })
  }, [])

  useEffect(() => {
    if (!shopProductCategoriesResult.data) return
    trigger()
  }, [selectedIndex])

  useEffect(() => {
    if (!allProductsResult.data) return

    setProducts(allProductsResult.data.data)
    setTotalPages(allProductsResult.data.total_pages)
  }, [allProductsResult])

  useEffect(() => {
    if (!productsResult.data) return
    setProducts(productsResult.data.data)
    setTotalPages(productsResult.data.total_pages)
  }, [productsResult])

  useEffect(() => {
    if (!shopProductCategoriesResult.data) return

    setCategories([
      { id: '0', name: 'All Products' },
      ...shopProductCategoriesResult.data,
    ])
  }, [shopProductCategoriesResult])

  const { sort, sort_by } = useSelector(
    (state: RootState) => state.sortFilterReducer
  )

  useEffect(() => {
    trigger()
  }, [querySort])

  useEffect(() => {
    queryBuilder()
  }, [sort, sort_by, page])

  const queryBuilder = () => {
    let combinedQuery = 'limit=10'

    if (sort) {
      combinedQuery += `&sort=${sort}`
    }

    if (sort_by) {
      combinedQuery += `&sort_by=${sort_by}`
    }

    if (page) {
      combinedQuery += `&page=${page}`
    }

    setQuerySort(combinedQuery)
  }

  const handlePageClick = (page: number) => {
    productListRef.current.scrollIntoView()
    setPage(page)
  }

  const isLoading =
    shopProductCategoriesResult.isLoading ||
    productsResult.isLoading ||
    allProductsResult.isLoading
  const isError =
    shopProductCategoriesResult.isError ||
    productsResult.isError ||
    allProductsResult.isError
  const isDataExist = productsResult.data || allProductsResult.data

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {isError && <ErrorState />}
      {!isLoading && !isError && isDataExist && (
        <Box
          borderColor={'gray.100'}
          borderWidth={1}
          boxShadow='md'
          padding={2}
          borderRadius={10}
          mt={4}
          ref={productListRef}
        >
          <Text fontSize='xl'>Products</Text>
          <SortSellerPage />
          <Container maxW='6xl'>
            {shopProductCategoriesResult.data && (
              <Box>
                <ShopProductCategoryTabs
                  data={categories}
                  selectedIndex={selectedIndex}
                  onClick={(index: number) => setSelectedIndex(index)}
                />
              </Box>
            )}
            <Grid
              templateAreas={`"nav main"`}
              gridTemplateColumns={'.25fr 1fr'}
              gap='1'
            >
              {shopProductCategoriesResult.data && (
                <GridItem mt='8' area={'nav'}>
                  <ShopProductCategoryPanel
                    data={categories}
                    selectedIndex={selectedIndex}
                    onClick={(index: number) => setSelectedIndex(index)}
                  />
                </GridItem>
              )}
              {products ? (
                <GridItem pl='2' area={'main'}>
                  <SimpleGrid columns={[1, 2, 4]} spacing={0}>
                    {products.map((x: any) => (
                      <ProductCard key={x.id} data={x} />
                    ))}
                  </SimpleGrid>
                </GridItem>
              ) : (
                <Box mt={4}>
                  <NoData />
                </Box>
              )}
            </Grid>
            <Pagination
              total={totalPages ?? 0}
              ariaPreviousLabel={' '}
              ariaNextLabel={' '}
              a11yActiveLabel={' '}
              ariaCurrentAttr={true}
              current={page}
              onPageChange={(page) => handlePageClick(page)}
              className={'navigationButtons'}
            />
          </Container>
        </Box>
      )}
    </>
  )
}

export default ShopProductsPanel
