import { Text } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../buttons/PrimaryButton'
import { ShopProductCategoryCardProps } from './entities'

const ShopProductCategoryCard = (props: ShopProductCategoryCardProps) => {
  const { data, isActive, onClick } = props
  return (
    <PrimaryButton isActive={isActive} onClick={onClick} width={'100%'}>
      <Text align='center'>{data.name}</Text>
    </PrimaryButton>
  )
}

export default ShopProductCategoryCard
