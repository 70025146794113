import {
  Box,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Sealabspay } from '../../../features/sealabspay/entities'
import SealabspayCardDetail from '../../card/SealabspayCardDetail'
import { TopupWalletFormInputs } from '../../form/entities'
import CustomForm from '../../form'
import StandardInput from '../../inputs/StandardInput'
import { toast } from 'react-toastify'
import { useTopupSealabspayMutation } from '../../../features/sealabspay/sealabspaySlice'
import { getStringEnv } from '../../../helpers/env'
import { useGetProfileQuery } from '../../../features/users/usersSlice'
import SecondaryButton from '../../buttons/SecondaryButton'
import { useNavigate } from 'react-router-dom'

const SealabspaySelection = ({
  sealabsPays,
}: {
  sealabsPays: Sealabspay[]
}) => {
  const [selectedSealabsPay, setSelectedSealabsPay] =
    useState<Sealabspay | null>(null)
  const [triggerPayment, resultPayment] = useTopupSealabspayMutation()
  const schema = yup.object().shape({
    amount: yup.number().required('Must be not empty').label('Amount'),
  })
  const { data: userData } = useGetProfileQuery()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TopupWalletFormInputs>({ resolver: yupResolver(schema) })
  const onSubmit = (values: TopupWalletFormInputs) => {
    if (sealabsPays.length === 0) {
      toast.error('Please Create Sealabspay Card First!')
    } else if (selectedSealabsPay === null) {
      toast.error('Please Select Sealabspay Card First!')
    }

    const cardNumber = selectedSealabsPay?.card_number
    const amount = values.amount
    const sealabspayAccountID = selectedSealabsPay?.id
    const redirectURL =
      getStringEnv('REACT_APP_FE_API_URL') + '/payment-result/topup'
    const paymentType = 'topup'

    triggerPayment({
      cardNumber,
      amount,
      redirectURL,
      id: sealabspayAccountID,
      paymentType,
    })
      .unwrap()
      .then((data) => {
        if (data !== '') {
          window.location.href = data
        } else {
          toast.error('Invalid Card')
        }
      })
  }

  const navigate = useNavigate()

  useEffect(() => {
    sealabsPays.forEach((x) => {
      if (x.id === userData?.data.default_sealabspay_account_id) {
        setSelectedSealabsPay(x)
      }
    })
  }, [userData])

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>Sealabspay</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {sealabsPays.length === 0 ? (
              <Center>
                <SecondaryButton
                  onClick={() => navigate('/profile/sealabspay')}
                >
                  Create Sealabspay
                </SecondaryButton>
              </Center>
            ) : (
              <VStack h='300px' overflowY='auto'>
                <Flex flexDirection='column' gap={3} w='full'>
                  {sealabsPays.map((val) => {
                    const isSelected = val.id === selectedSealabsPay?.id
                    return (
                      <Box
                        key={val.id}
                        bgColor={isSelected ? 'primary.400' : 'primary.50'}
                        p={5}
                        h='full'
                        rounded='md'
                        border='1px solid black'
                        borderColor={isSelected ? 'primary.400' : 'primary.200'}
                        cursor='pointer'
                        onClick={() => {
                          setSelectedSealabsPay(val)
                        }}
                      >
                        <Flex flexDirection='column' h='100%'>
                          <SealabspayCardDetail
                            title='Name'
                            value={val.name}
                            colorTitle={
                              isSelected ? 'primary.50' : 'primary.500'
                            }
                            colorValue={
                              isSelected ? 'primary.800' : 'text.normal'
                            }
                          />
                          <SealabspayCardDetail
                            title='Card Number'
                            value={val.card_number}
                            colorTitle={
                              isSelected ? 'primary.50' : 'primary.500'
                            }
                            colorValue={
                              isSelected ? 'primary.800' : 'text.normal'
                            }
                          />
                          <SealabspayCardDetail
                            title='Valid Date'
                            value={moment(val.active_date).format('MM/YY')}
                            colorTitle={
                              isSelected ? 'primary.50' : 'primary.500'
                            }
                            colorValue={
                              isSelected ? 'primary.800' : 'text.normal'
                            }
                          />
                        </Flex>
                      </Box>
                    )
                  })}
                </Flex>
              </VStack>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box
        rounded={'md'}
        border={'1px solid black'}
        borderColor={'primary.200'}
        p={8}
      >
        <CustomForm
          onSubmit={handleSubmit(onSubmit)}
          isLoading={isSubmitting || resultPayment.isLoading}
          submitLabel='Topup'
        >
          <Text color={'text.light'}>Input Amount to Topup</Text>
          <StandardInput
            autoFocus
            {...register('amount')}
            isRequired
            type='number'
            label='Amount'
            isInvalid={!!errors.amount}
            errorMessage={errors.amount?.message}
          />
        </CustomForm>
      </Box>
    </>
  )
}

export default SealabspaySelection
