import React from 'react'
import { ButtonProps } from '@chakra-ui/react'
import CustomButton from '.'

const SecondaryButton = (props: ButtonProps) => {
  const { ...rest } = props
  return <CustomButton variant='outline' border='2px' {...rest} />
}

export default SecondaryButton
