import React from 'react'
import {
  SimpleGrid,
  GridItem,
  RadioGroup,
  Radio,
  HStack,
} from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { GendersEnum } from '../../const'
import { Controller, useForm } from 'react-hook-form'
import StandardInput from '../inputs/StandardInput'
import CustomForm from '.'
import { ProfileFormInputs } from './entities'
import {
  useGetProfileQuery,
  useUpdateProfileDataMutation,
} from '../../features/users/usersSlice'
import moment from 'moment'
import { toast } from 'react-toastify'

const ProfileForm = () => {
  const { data } = useGetProfileQuery()
  const [updateProfileData] = useUpdateProfileDataMutation()
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<ProfileFormInputs>()

  const onSubmit = (values: ProfileFormInputs) => {
    toast.promise(
      updateProfileData({
        birthdate: moment(values.dob).format('DD-MM-YYYY'),
        fullname: values.username,
        gender: values.gender === 'm' ? 'male' : 'female',
      }).unwrap(),
      {
        pending: 'loading...',
        success: 'Profile updated successfully',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      }
    )
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Save'
    >
      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        gap={3}
        mt={5}
        mb={5}
        alignItems='center'
      >
        <GridItem>Full name: </GridItem>
        <GridItem>
          <StandardInput
            {...register('username')}
            isInvalid={!!errors.username}
            defaultValue={data?.data.fullname}
            placeholder='John Doe'
          />
        </GridItem>
        <GridItem>Date of Birth: </GridItem>
        <GridItem>
          <Controller
            name='dob'
            control={control}
            defaultValue={
              data?.data.birthdate
                ? moment(data.data.birthdate.slice(0, 10)).toDate()
                : new Date()
            }
            render={({ field: { onChange, value } }) => {
              return (
                <SingleDatepicker
                  date={value}
                  maxDate={new Date()}
                  onDateChange={onChange}
                  configs={{
                    dateFormat: 'dd-MMM-yyyy',
                  }}
                />
              )
            }}
          />
        </GridItem>
        <GridItem>Gender: </GridItem>
        <GridItem>
          <Controller
            name='gender'
            control={control}
            defaultValue={
              data?.data.gender === 'male'
                ? GendersEnum.male
                : GendersEnum.female
            }
            render={({ field: { value, onChange } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <HStack>
                  {Object.entries(GendersEnum).map((el) => (
                    <Radio key={el[0]} value={el[1]}>
                      {el[0]}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            )}
          />
        </GridItem>
      </SimpleGrid>
    </CustomForm>
  )
}

export default ProfileForm
