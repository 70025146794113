import { Box, Container, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import ProductCard from '../../components/productCard'
import { useGetCategoryByIdQuery } from '../../features/categories/categoriesSlice'
import { useGetProductsByCategoryQuery } from '../../features/products/productsSlice'

const ProductsInCategoryPage = () => {
  const { categoryId } = useParams()
  const { data, error, isLoading } = useGetProductsByCategoryQuery({
    categoryId,
  })
  const { data: categoryData } = useGetCategoryByIdQuery({ categoryId })

  const navigate = useNavigate()

  return (
    <Container maxW='6xl'>
      {isLoading && <LoadingSpinner />}
      {error && <ErrorState />}
      {!isLoading && !error && (
        <Box mt={4}>
          <Text fontSize='xl'>
            Showing results for &rdquo;{categoryData?.name}&rdquo;
          </Text>
          <SimpleGrid columns={[2, null, 5]}>
            {data?.map((val: any) => (
              <ProductCard
                key={val.id}
                data={val}
                onClick={() => navigate(`/products/${val.id}`)}
              />
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Container>
  )
}

export default ProductsInCategoryPage
