import { HStack, IconButton, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaHeart } from 'react-icons/fa'
import { FiHeart } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  useFavoriteProductMutation,
  useLazyGetProductIsFavoriteQuery,
} from '../../features/products/productsSlice'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { ProductFavoriteProps } from './entities'

const ProductFavorite = (props: ProductFavoriteProps) => {
  const [isFavorite, setIsFavorite] = useState(false)
  const [isFavoriteTrigger] = useLazyGetProductIsFavoriteQuery()
  const [triggerFavorite] = useFavoriteProductMutation()
  const [favoriteCount, setFavoriteCount] = useState(props.favoriteCount)
  const navigate = useNavigate()
  const location = useLocation()
  const { data: userData } = useGetProfileQuery()

  useEffect(() => {
    setIsFavorite(false)
    setFavoriteCount(props.favoriteCount)
    if (userData) {
      isFavoriteTrigger(props.id)
        .unwrap()
        .then((data: boolean) => {
          setIsFavorite(data)
        })
    }
  }, [props])

  const handleFavoriteClick = () => {
    if (!userData) {
      navigate('/login', {
        state: {
          prevURI: location.pathname,
        },
      })
    } else {
      if (isFavorite) {
        setFavoriteCount(favoriteCount - 1)
      } else {
        setFavoriteCount(favoriteCount + 1)
      }
      setIsFavorite(!isFavorite)
      triggerFavorite(props.id)
    }
  }
  return (
    <HStack>
      <IconButton
        bg='transparent'
        aria-label='favorite button'
        onClick={handleFavoriteClick}
        icon={
          isFavorite ? (
            <FaHeart color='red' size={35} />
          ) : (
            <FiHeart color='red' size={35} />
          )
        }
      />
      <Text fontSize='lg'>Favorite ({favoriteCount})</Text>
    </HStack>
  )
}

export default ProductFavorite
