import React from 'react'
import CustomModal from '.'
import ShopDetail from '../shop/shopDetail'

const ShopDetailModal = (props: { isOpen: boolean; onClose: () => void }) => {
  const { isOpen, onClose } = props
  return (
    <CustomModal
      size={['sm', 'md', '6xl']}
      title='Shop Detail'
      body={<ShopDetail />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default ShopDetailModal
