import { apiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import { OKResponse } from '../entities'
import { SellerDashboard } from './entities'
import { ErrorResponse } from '@remix-run/router'

export const sellerDashboardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerDashboard: builder.query<SellerDashboard, void>({
      query: () => ({
        url: '/shops/dashboard',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<SellerDashboard>) =>
        response.data,
      transformErrorResponse: (error: ErrorResponse) => error.data.message,
    }),
  }),
})

export const { useLazyGetSellerDashboardQuery } = sellerDashboardSlice
