function calculateDiscountedPrice(
  actualPrice: number,
  discountType: string,
  discountAmount: number
) {
  let price = actualPrice

  if (discountType) {
    switch (discountType) {
      case 'nominal':
        price -= discountAmount
        break
      case 'percentage':
        price -= (discountAmount / 100) * price
        break
    }
  }

  return price
}

export { calculateDiscountedPrice }
