import Cookies from 'js-cookie'
import { ShopVoucherFormInputs } from '../../components/form/entities'
import { apiSlice } from '../api/apiSlice'
import { OKResponse } from '../entities'
import { ShopVoucher } from './entities'
import { ErrorResponse } from '@remix-run/router'

export const shopVouchersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createShopVoucher: builder.mutation<
      OKResponse<ShopVoucher>,
      ShopVoucherFormInputs
    >({
      query: ({ code, endDate, minimumPayment, startDate, ...rest }) => ({
        url: `/shops/vouchers`,
        method: 'POST',
        body: JSON.stringify({
          ...rest,
          code: code,
          start_date: startDate,
          end_date: endDate,
          minimum_payment: minimumPayment,
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['shopVouchers'],
    }),
    editShopVoucher: builder.mutation<
      OKResponse<ShopVoucher>,
      { args: ShopVoucherFormInputs; id: string }
    >({
      query: ({
        args: { endDate, minimumPayment, startDate, ...rest },
        id,
      }) => ({
        url: `/shops/vouchers/${id}`,
        method: 'PUT',
        body: JSON.stringify({
          ...rest,
          start_date: startDate,
          end_date: endDate,
          minimum_payment: minimumPayment,
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['shopVouchers'],
    }),
    deleteShopVoucher: builder.mutation<OKResponse<ShopVoucher>, string>({
      query: (id) => ({
        url: `/shops/vouchers/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['shopVouchers'],
    }),
    getShopVouchers: builder.query<
      OKResponse<ShopVoucher[]>,
      { shopId: string; status?: 'ONGOING' | 'ENDED' | 'INCOMING' }
    >({
      query: ({ shopId, status = '' }) =>
        `/shops/${shopId}/vouchers?status=${status}`,
      providesTags: ['shopVouchers'],
    }),
    getShopVoucher: builder.query<OKResponse<ShopVoucher>, string>({
      query: (id) => `/shops/vouchers/${id}`,
      providesTags: ['shopVouchers'],
    }),
  }),
})

export const {
  useCreateShopVoucherMutation,
  useEditShopVoucherMutation,
  useGetShopVouchersQuery,
  useLazyGetShopVouchersQuery,
  useGetShopVoucherQuery,
  useDeleteShopVoucherMutation,
} = shopVouchersApiSlice
