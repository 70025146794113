import {
  Flex,
  Grid,
  GridItem,
  Hide,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaCartPlus, FaStar } from 'react-icons/fa'
import { FiStar } from 'react-icons/fi'
import {
  useAddItemToCartMutation,
  useUpdateItemInCartMutation,
  useGetCartItemsQuery,
} from '../../features/carts/cartsSlice'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import ProductImage from './ProductImage'
import ProductPrice from './ProductPrice'
import ProductShare from './ProductShare'
import ProductFavorite from './ProductFavorite'
import ProductQuantity from './ProductQuantity'
import { ProductDetailPanelProps } from './entities'
import SwitchButtonInput from '../inputs/SwitchButtonInput'
import convertToRupiah from '../../helpers/currencyFormat'
import { toast } from 'react-toastify'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { useNavigate } from 'react-router-dom'
import {
  addItem,
  clearAll,
} from '../../features/slices/transactionSlice/transactionSlice'
import { useDispatch } from 'react-redux'
import { calculateDiscountedPrice } from '../../helpers/priceCalculation'

const ProductDetailPanel = (props: ProductDetailPanelProps) => {
  const { data, selectionInput, inventoryData } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(1)
  const [addItemToCart] = useAddItemToCartMutation()
  const [updateItemToCart] = useUpdateItemInCartMutation()
  const { data: userData } = useGetProfileQuery()
  const { data: cartData, isLoading: cartIsLoading } =
    useGetCartItemsQuery(null)

  const handleChange = (valueAsString: string, valueAsNumber: number) => {
    if (!valueAsNumber) return
    setValue(valueAsNumber)
  }

  const handleOnClickAddToCart = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    if (!userData) {
      toast.info('Need to login first')
      navigate('/login', {
        state: {
          prevURI: location.pathname,
        },
      })
      return
    }
    if (cartIsLoading) return

    const existedCart = cartData?.items.find(
      (val) => val.inventory.id === selectionInput.inventoryId
    )
    if (existedCart) {
      const inventoryMaxStock = inventoryData?.stock || 0
      if (existedCart.quantity + value > inventoryMaxStock) {
        toast.error(
          `Not enough stock, already has ${existedCart.quantity} in cart`
        )
        return
      }
    }

    addItemToCart({
      inventoryId: selectionInput.inventoryId,
      quantity: value,
    })
      .unwrap()
      .then(() => toast.success('added to cart'))
      .catch(() => toast.error('failed to add to cart'))
  }

  const handleOnClickBuyNow = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    if (!userData) {
      toast.info('Need to login first')
      navigate('/login', {
        state: {
          prevURI: location.pathname,
        },
      })
      return
    }
    if (cartIsLoading) return

    const existedCart = cartData?.items.find(
      (val) => val.inventory.id === selectionInput.inventoryId
    )
    if (existedCart) {
      const promiseBuyNow = updateItemToCart({
        productId: selectionInput.inventoryId,
        quantity: value,
      })
      await toast.promise(promiseBuyNow, {
        pending: 'loading...',
        success: 'Checkout success',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
    } else {
      const promiseBuyNow = addItemToCart({
        inventoryId: selectionInput.inventoryId,
        quantity: value,
      })
      await toast.promise(promiseBuyNow, {
        pending: 'loading...',
        success: 'Checkout success',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
    }

    dispatch(clearAll())
    dispatch(
      addItem({
        shopId: data.shop?.id || '',
        shopName: data.shop?.name || '',
        orderDetail: {
          id: inventoryData?.id || '',
          name: data.title || '',
          quantity: value,
          weight: data.weight,
          variantName:
            inventoryData?.first_variant_type ||
            inventoryData?.second_variant_type
              ? `${inventoryData?.first_variant_type?.name || ''}${
                  inventoryData?.second_variant_type
                    ? `, ${inventoryData?.second_variant_type.name}`
                    : ''
                }`
              : undefined,
          price: calculateDiscountedPrice(
            inventoryData?.price || 0,
            data.discount_type,
            data.discount_amount
          ),
          actualPrice: inventoryData?.price || 0,
          stock: inventoryData?.stock || 0,
        },
      })
    )
    navigate('/checkout')
  }

  // After data loading, sync with get item query
  /* useEffect(() => {
    if (!inventoryIsLoading) return
    // If data is lower than min. order, adjust value
    // If data is higher than max. order/max. stock, adjust value
    if (value < inventoryData.min_stock) {
      setValue(inventoryData.min_stock)
    }
    if (value < inventoryData.max_stock) {
      setValue(inventoryData.min_stock)
    }
    if (value < inventoryData.max_order) {
      setValue(inventoryData.max_order)
    }
  }, [inventoryIsLoading]) */

  return (
    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(3,1fr)']} gap={4} p={4}>
      <GridItem w='300px'>
        <ProductImage data={data} inventory={inventoryData} />
        <Flex
          mt={4}
          gap={4}
          direction={['column', 'column', 'row']}
          justifyContent='space-between'
        >
          <ProductShare />
          <ProductFavorite id={data.id} favoriteCount={data.favorite_count} />
        </Flex>
      </GridItem>
      <GridItem colSpan={[1, 2]}>
        <Text as={'b'} fontSize='2xl' textTransform='uppercase'>
          {data.title}
        </Text>
        <Stack direction='row' minH='30px' gap={2}>
          <HStack>
            <Text as={'u'} color='primary.400' fontSize='lg'>
              {(Math.round(data.average_rating * 100) / 100).toFixed(2)}
            </Text>
            <HStack>
              {Array.from(Array(5).keys()).map((e, index) => {
                if (index + 1 <= Math.round(data.average_rating))
                  return <FaStar key={e} color='#76B7B5' />
                return <FiStar key={e} color='#76B7B5' />
              })}
            </HStack>
          </HStack>
          <Hide below='md'>
            <HStack borderLeft='1px gray solid' pl={4}>
              <Text as='u' fontSize='lg'>
                {data.view_count}
              </Text>
              <Text color='gray'>View(s)</Text>
            </HStack>
          </Hide>
          <HStack borderLeft='1px gray solid' pl={4}>
            <Text as='u' fontSize='lg'>
              {data.units_sold}
            </Text>
            <Text color='gray'>Sold(s)</Text>
          </HStack>
        </Stack>
        <ProductPrice
          price={
            inventoryData
              ? `Rp.\xa0${convertToRupiah(inventoryData.price)}`
              : data.min_price === data.max_price
              ? `Rp.\xa0${convertToRupiah(data.min_price)}`
              : `Rp.\xa0${convertToRupiah(data.min_price)}-${convertToRupiah(
                  data.max_price
                )}`
          }
          discountedPrice={
            inventoryData && data.discount_type
              ? `Rp.\xa0${convertToRupiah(
                  data.discount_type.toLowerCase() === 'percentage'
                    ? inventoryData.price * ((100 - data.discount_amount) / 100)
                    : inventoryData.price - data.discount_amount
                )}`
              : data.discount_type &&
                data.min_discount_price === data.max_discount_price
              ? `Rp.\xa0${convertToRupiah(data.min_discount_price)}`
              : data.discount_type
              ? `Rp.\xa0${convertToRupiah(
                  data.min_discount_price
                )}-${convertToRupiah(data.max_discount_price)}`
              : ''
          }
          discountAmount={
            data.discount_type === 'percentage'
              ? `${data.discount_amount}%`
              : data.discount_type === 'nominal'
              ? `Rp.\xa0${convertToRupiah(data.discount_amount)}`
              : ''
          }
        />
        {data.variant_groups?.[0] && (
          <Flex key={data.variant_groups[0].id} gap={5}>
            <Text>{data.variant_groups[0].name}</Text>
            <Flex gap={2}>
              {data.variant_groups[0].variant_types?.map((typeVal) => (
                <SwitchButtonInput
                  key={typeVal.id}
                  value={typeVal.id}
                  isSelected={selectionInput.firstTypeId === typeVal.id}
                  placeholder={`${typeVal.name}`}
                  onClick={() => {
                    selectionInput.setFirstTypeId(typeVal.id)
                  }}
                />
              ))}
            </Flex>
          </Flex>
        )}
        {data.variant_groups?.[1] && (
          <Flex key={data.variant_groups[1].id} gap={5}>
            <Text>{data.variant_groups[1].name}</Text>
            <Flex gap={2}>
              {data.variant_groups[1].variant_types?.map((typeVal) => (
                <SwitchButtonInput
                  key={typeVal.id}
                  isSelected={selectionInput.secondTypeId === typeVal.id}
                  placeholder={`${typeVal.name}`}
                  onClick={() => {
                    selectionInput.setSecondTypeId(typeVal.id)
                  }}
                />
              ))}
            </Flex>
          </Flex>
        )}
        {inventoryData ? (
          <ProductQuantity
            handleChange={handleChange}
            minValue={1}
            maxValue={inventoryData ? inventoryData.stock : data.total_stock}
            defaultValue={
              inventoryData?.stock ? value : data.total_stock === 0 ? 0 : value
            }
            isDisabled={inventoryData && inventoryData.stock === 0}
            totalStock={inventoryData ? inventoryData.stock : data.total_stock}
          />
        ) : (
          <Text>Please choose available variant</Text>
        )}
        <Flex
          gap='0.5rem'
          marginTop='1rem'
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
        >
          <SecondaryButton
            type='button'
            gap='0.4rem'
            onClick={handleOnClickAddToCart}
            disabled={!selectionInput.inventoryId}
          >
            <FaCartPlus size={'1.25rem'} />
            &nbsp;Add to Cart
          </SecondaryButton>
          <PrimaryButton
            onClick={handleOnClickBuyNow}
            disabled={!selectionInput.inventoryId}
          >
            Buy Now
          </PrimaryButton>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default ProductDetailPanel
