import React from 'react'
import CustomForm from './index'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { RegisterFormInputs } from './entities'
import { yupResolver } from '@hookform/resolvers/yup'
import StandardInput from '../inputs/StandardInput'
import PasswordInput from '../inputs/PasswordInput'
import { useNavigate } from 'react-router-dom'
import { useRegisterMutation } from '../../features/auth/authSlice'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { clearAll } from '../../features/slices/transactionSlice/transactionSlice'

const schema = yup.object().shape({
  username: yup.string().min(4).max(30).required('Must be not empty'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Must be not empty'),
  password: yup
    .string()
    .min(8)
    .required('Must be not empty')
    .test(
      'passwords-contain-username',
      'Password cannot contain username',
      function (value) {
        return !value?.includes(this.parent.username) ?? true
      }
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match'),
})

const RegisterForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [registerUser] = useRegisterMutation()

  const { email } = useSelector((state: RootState) => state.authReduxReducer)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: RegisterFormInputs) => {
    const promiseRegisterUser = registerUser({
      email: values.email,
      password: values.password,
      username: values.username,
    })
      .unwrap()
      .then(() => {
        dispatch(clearAll())
        navigate('/')
      })

    toast.promise(promiseRegisterUser, {
      pending: 'loading...',
      success: 'Register success',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Register'
    >
      <StandardInput
        {...register('username')}
        autoFocus
        label='Username'
        isRequired
        isInvalid={!!errors.username}
        placeholder='JohnDoe'
        errorMessage={errors.username?.message}
      />
      <StandardInput
        {...register('email')}
        isRequired
        label='Email'
        type='email'
        isInvalid={!!errors.email}
        placeholder='john.doe@gmail.com'
        errorMessage={errors.email?.message}
        defaultValue={email ?? ''}
        readOnly={email ? true : false}
      />
      <PasswordInput
        {...register('password')}
        isRequired
        label='Password'
        isInvalid={!!errors.password}
        placeholder='Password'
        errorMessage={errors.password?.message}
      />
      <PasswordInput
        {...register('confirmPassword')}
        isRequired
        label='Confirm Password'
        isInvalid={!!errors.confirmPassword}
        placeholder='Confirm Password'
        errorMessage={errors.confirmPassword?.message}
      />
    </CustomForm>
  )
}

export default RegisterForm
