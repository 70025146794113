import React, { useEffect, useState } from 'react'
// swiper style
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Swiper, SwiperSlide } from 'swiper/react'
import CustomImage from '../images'
import SwiperCore, { Navigation, Thumbs } from 'swiper'
import { AspectRatio, Box, useDisclosure } from '@chakra-ui/react'
import ImageModal from '../modal/ImageModal'
import './productImage.css'
import { ProductImageProps } from './entities'
import { ProductMediaProps } from '../../features/products/entities'
const ProductImage = (props: ProductImageProps) => {
  const { data, inventory } = props

  const [activeThumb, setActiveThumb] = useState<SwiperCore | undefined>(
    undefined
  )
  const [activeSlide, setActiveSlide] = useState<SwiperCore | undefined>(
    undefined
  )

  const [selectedImage, setSelectedImage] = useState<string>('')
  const [slides, setSlides] = useState<Array<ProductMediaProps> | undefined>([])
  const [isNewLoadedProduct, setIsNewLoadedProduct] = useState(true)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleImageClick = (url: string) => {
    setSelectedImage(url)
    onOpen()
  }

  const [initSlideCount, setInitSlideCount] = useState(1)
  useEffect(() => {
    setIsNewLoadedProduct(true)
  }, [data])

  useEffect(() => {
    if (isNewLoadedProduct) return
    if (inventory) {
      if ((slides?.length || 0) > initSlideCount) {
        setSlides(slides?.splice((slides?.length || 1) - 1, 1))
      }
      if (inventory?.product_media?.media_url) {
        const media: ProductMediaProps = {
          id: inventory?.product_media?.id || '',
          media_url: inventory?.product_media?.media_url || '',
          type: inventory?.product_media?.type || '',
        }
        setSlides([...(slides || []), media])
        activeThumb?.slideTo(initSlideCount + 1, 100, false)
        activeSlide?.slideTo(initSlideCount + 1, 100, false)
      }
    }
  }, [props, isNewLoadedProduct])

  useEffect(() => {
    if (!isNewLoadedProduct) return

    const tempSlide = data?.product_media?.filter(
      (item: ProductMediaProps) =>
        item.type === 'image' || item.type === 'video'
    )
    setSlides(tempSlide)
    setInitSlideCount(tempSlide?.length || 0)

    setIsNewLoadedProduct(false)
  }, [isNewLoadedProduct])
  if (slides?.length) {
    return (
      <>
        <ImageModal
          isOpen={isOpen}
          onClose={onClose}
          body={
            <CustomImage
              src={selectedImage}
              alt='product'
              _hover={{ transform: 'scale(1.5)' }}
            />
          }
        />
        <Swiper
          onSwiper={setActiveSlide}
          loop
          spaceBetween={10}
          navigation
          modules={[Navigation, Thumbs]}
          grabCursor
          thumbs={activeThumb ? { swiper: activeThumb } : undefined}
        >
          {slides?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <AspectRatio ratio={4 / 3}>
                  {item.type === 'video' ? (
                    <video
                      src={item.media_url}
                      style={{ width: '100%' }}
                      controls
                    />
                  ) : (
                    <CustomImage
                      src={item.media_url}
                      alt='product'
                      w='100%'
                      onClick={() => {
                        handleImageClick(item.media_url)
                      }}
                    />
                  )}
                </AspectRatio>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {(slides?.length || 1) > 1 && (
          <Swiper
            onSwiper={setActiveThumb}
            loop
            spaceBetween={10}
            slidesPerView={(slides?.length || 1) < 4 ? slides?.length : 4}
            modules={[Navigation, Thumbs]}
          >
            {slides?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Box w='100%' overflow='hidden'>
                    <AspectRatio ratio={4 / 3}>
                      {item.type === 'video' ? (
                        <video src={item.media_url} style={{ width: '100%' }} />
                      ) : (
                        <CustomImage
                          src={item.media_url}
                          alt='thumb'
                          w='100%'
                        />
                      )}
                    </AspectRatio>
                  </Box>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
      </>
    )
  }
  return null
}

export default ProductImage
