import { Box, Select, Tab, TabList, Tabs } from '@chakra-ui/react'
import React from 'react'
import { ShopProductCategory } from '../../shopProductCategoryCard/entities'
import { TabsProps } from '../entities'

const ShopProductCategoryTabs = (props: TabsProps<ShopProductCategory[]>) => {
  const { data, selectedIndex, onClick } = props

  return (
    <Box overflowY='auto'>
      <Tabs index={selectedIndex >= 4 ? 4 : selectedIndex}>
        <TabList>
          {data.slice(0, 4).map((tab: ShopProductCategory, index: number) => (
            <Tab key={index} onClick={() => onClick(index)}>
              {tab.name}
            </Tab>
          ))}
          {data.length > 4 && (
            <Tab>
              <Select
                placeholder='Select option'
                value={selectedIndex - 4}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const index = parseInt(event.target.value)
                  if (index > -1) {
                    onClick(index + 4)
                  }
                }}
              >
                nClick(parseInt(event.target.value) + 4)
                {data
                  .slice(4)
                  .map((tab: ShopProductCategory, index: number) => (
                    <option key={index} value={index}>
                      {tab.name}
                    </option>
                  ))}
              </Select>
            </Tab>
          )}
        </TabList>
      </Tabs>
    </Box>
  )
}

export default ShopProductCategoryTabs
