import { forwardRef, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import StepInput from '../inputs/StepInput'
import { ProductQuantityProps } from './entities'

const ProductQuantity = forwardRef<ProductQuantityProps, 'input'>(
  (props, ref) => {
    const {
      minValue,
      maxValue,
      defaultValue,
      handleChange,
      totalStock,
      ...rest
    } = props

    return (
      <HStack>
        {defaultValue && (
          <StepInput
            ref={ref}
            minValue={minValue}
            maxValue={maxValue}
            defaultValue={defaultValue}
            handleChange={handleChange}
            totalStock={totalStock}
            {...rest}
          />
        )}
        <Text>{maxValue} available</Text>
      </HStack>
    )
  }
)

export default ProductQuantity
