import React from 'react'
import RegisterForm from '../../components/form/RegisterForm'
import { Box, Center } from '@chakra-ui/react'
import CustomImage from '../../components/images'

const RegisterPage = () => {
  return (
    <Center flexDirection='column'>
      <Box my={5}>
        <CustomImage h='200px' src='/assets/svgs/login.svg' />
      </Box>
      <RegisterForm />
    </Center>
  )
}

export default RegisterPage
