import {
  AspectRatio,
  Badge,
  Box,
  Flex,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import convertToRupiah from '../../helpers/currencyFormat'
import CustomImage from '../images'
import { ProductCardProps } from './entities'
import { useNavigate } from 'react-router-dom'

const ProductCard = (props: ProductCardProps) => {
  const {
    data: {
      id,
      name,
      discount,
      discount_type,
      thumbnail_url,
      is_discount,
      location,
      price,
      max_discount_price,
      min_discount_price,
      final_price,
      min_price,
      max_price,
    },
  } = props

  const nav = useNavigate()

  return (
    <Box>
      <Flex
        cursor='pointer'
        py={5}
        px={2}
        h='full'
        alignItems='center'
        justifyContent='center'
        onClick={() => {
          nav('/products/' + id)
        }}
      >
        <Box
          bg={useColorModeValue('white', 'gray.800')}
          w='250px'
          h='full'
          borderWidth='1px'
          rounded='lg'
          shadow='lg'
          position='relative'
          mb='auto'
        >
          <AspectRatio ratio={1}>
            <CustomImage
              src={thumbnail_url}
              alt={`Picture of ${name}`}
              roundedTop='lg'
              fit='cover'
            />
          </AspectRatio>

          <Box p='2'>
            <Text fontSize='md'>{name}</Text>
            <Text
              fontSize='2xl'
              fontWeight='semibold'
              color={useColorModeValue('gray.800', 'white')}
            >
              {!is_discount
                ? min_price !== max_price
                  ? `Rp. ${convertToRupiah(min_price)} - ${convertToRupiah(
                      max_price
                    )}`
                  : `Rp. ${convertToRupiah(min_price)}`
                : min_discount_price !== max_discount_price
                ? `Rp. ${convertToRupiah(
                    min_discount_price
                  )} - ${convertToRupiah(max_discount_price)}`
                : `Rp. ${convertToRupiah(min_discount_price)}`}
            </Text>
            {is_discount && (
              <Box
                display='flex'
                alignItems='baseline'
                gap='4px'
                mb='2'
                flexDirection={['column', 'row']}
              >
                <Badge
                  fontSize='0.8em'
                  colorScheme='gray'
                  color={useColorModeValue('gray.500', 'white')}
                  textTransform='none'
                >
                  {discount_type === 'percentage'
                    ? `${discount}%`
                    : `Rp.\xa0${convertToRupiah(Number(discount))}`}
                </Badge>
                <Text
                  as='s'
                  fontWeight='thin'
                  color={useColorModeValue('gray.500', 'white')}
                >
                  {min_price !== max_price
                    ? `Rp. ${convertToRupiah(min_price)} - ${convertToRupiah(
                        max_price
                      )}`
                    : `Rp.\xa0${convertToRupiah(min_price)}`}
                </Text>
              </Box>
            )}
            <Text fontWeight='thin'>{location}</Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default ProductCard
