import React from 'react'
import CustomModal from '..'
import { MarkeplaceVoucherSelectionModalProps } from '../entities'
import MarketplaceVoucherSelection from '../../checkout/MarketplaceVoucherSelection'

const MarketplaceVoucherSelectionModal = (
  props: MarkeplaceVoucherSelectionModalProps
) => {
  const { isOpen, onClose, ...rest } = props
  return (
    <CustomModal
      size='6xl'
      title='Select Voucher'
      body={<MarketplaceVoucherSelection {...rest} />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default MarketplaceVoucherSelectionModal
