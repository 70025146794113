import React, { useEffect } from 'react'
import { Center, Text, Button, Flex } from '@chakra-ui/react'
import LoginForm from '../../components/form/LoginForm'
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import CustomImage from '../../components/images'
import { GoogleLogin } from '@react-oauth/google'
import jwtDecode from 'jwt-decode'
import { useLoginGoogleMutation } from '../../features/auth/authSlice'
import { GoogleUserResponse } from './entities'
import { useDispatch } from 'react-redux'
import { setEmailAndPicture } from '../../features/auth/authReduxSlice'
import { toast } from 'react-toastify'

const LoginPage = () => {
  const [loginGoogle] = useLoginGoogleMutation()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()

  useEffect(() => {
    if (queryParams.get('status') === '401') {
      toast.warning('Login required')
    }

    navigate('/login')
  }, [])

  const loginWithGoogle = (email: string) => {
    loginGoogle({ email: email })
      .unwrap()
      .then((response) => {
        if (typeof response === 'boolean') {
          navigate('/register')
        } else {
          if (location.state?.prevURI) {
            window.location.replace(location.state.prevURI)
          } else {
            window.location.replace('/')
          }
        }
      })
      .catch((err) => toast.error(err))
  }

  return (
    <Center>
      <Flex maxW='md' flexDirection='column' gap={3}>
        <CustomImage h='200px' src='/assets/svgs/login.svg' />
        <LoginForm />
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const decoded = jwtDecode<GoogleUserResponse>(
              credentialResponse.credential ?? ''
            )
            loginWithGoogle(decoded.email)
            dispatch(
              setEmailAndPicture({
                email: decoded.email,
                picture: decoded.picture,
                name: decoded.name,
              })
            )
          }}
        />
        <Text>
          New user?{' '}
          <Button
            variant='link'
            as={NavLink}
            to='/register'
            minH='44px'
            minW='44px'
            colorScheme='primary'
          >
            Register!
          </Button>
        </Text>
        <Button
          variant='link'
          as={NavLink}
          to='/forgot'
          minH='44px'
          minW='44px'
          colorScheme='primary'
        >
          Forgot password?
        </Button>
      </Flex>
    </Center>
  )
}

export default LoginPage
