import { VStack, Heading } from '@chakra-ui/react'
import React from 'react'
import ShopVoucherForm from '../../components/form/ShopVoucherForm'
import ErrorState from '../../components/errorState/index'
import LoadingSpinner from '../../components/loadingSpinner'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { useCreateShopVoucherMutation } from '../../features/shopVouchers/shopVouchersSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { OKResponse } from '../../features/entities'
import { ShopVoucher } from '../../features/shopVouchers/entities'

const CreateShopVoucherPage = () => {
  const { data, isError } = useGetProfileQuery()
  const [createShopVoucher] = useCreateShopVoucherMutation()
  const navigate = useNavigate()

  const showToast = async (promise: Promise<OKResponse<ShopVoucher>>) =>
    toast
      .promise(promise, {
        pending: 'Creating shop voucher...',
        success: 'Success create shop voucher',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
      .then((data) => navigate(`/seller/vouchers/${data.data.id}`))
      .catch(() => {
        return
      })

  return isError ? (
    <ErrorState />
  ) : (
    <VStack align='start'>
      <Heading>Create Shop Voucher Page</Heading>
      {data ? (
        <ShopVoucherForm
          userName={data.data.username}
          onSubmit={(values) => showToast(createShopVoucher(values).unwrap())}
        />
      ) : (
        <LoadingSpinner />
      )}
    </VStack>
  )
}

export default CreateShopVoucherPage
