import { Text, Box, AspectRatio, Center, Flex, Divider } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleCategoryQuery } from '../../features/sortFilter/sortFilterSlice'
import CustomImage from '../images'
import { Category } from './entities'

const CategoryCard = (props: Category) => {
  const { image_url, name, id } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <Box
      flex='0 0 10%'
      borderWidth='1px'
      borderColor={'primary.200'}
      cursor='pointer'
      minWidth='90px'
      rounded={'md'}
      mb={5}
      onClick={() => {
        dispatch(
          toggleCategoryQuery({
            id,
            name,
          })
        )
        navigate('/search')
      }}
    >
      <Flex direction={'column'}>
        {' '}
        <AspectRatio ratio={3 / 2}>
          <CustomImage
            p={1}
            src={image_url}
            fit='cover'
            alt={`category ${name}`}
          />
        </AspectRatio>
        <Divider />
        <Flex justifyContent={'center'} alignItems={'center'} p={1}>
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            textOverflow='ellipsis'
            align='center'
            alignSelf='end'
            as={'b'}
            color={'primary.400'}
          >
            {name}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default CategoryCard
