import React from 'react'
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  ButtonGroup,
} from '@chakra-ui/react'
import { NavbarProps } from './entities'
import SecondaryButton from '../buttons/SecondaryButton'
import PrimaryButton from '../buttons/PrimaryButton'
import CartPopover from '../popover/CartPopover'
import SearchBar from '../searchBar/index'
import { useNavigate } from 'react-router-dom'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import CustomImage from '../images'

function Navbar(props: NavbarProps) {
  const { userMenus, onLogout } = props
  const { data: userData } = useGetProfileQuery()
  const navigate = useNavigate()

  return (
    <Box bg='gray.100' px={4}>
      <Flex alignItems='center' justifyContent='space-between' gap={3}>
        <CustomImage
          src='/assets/pngs/logo.png'
          h='60px'
          w='60px'
          my={3}
          cursor='pointer'
          onClick={() => navigate('/')}
          alt='SeaMart'
        />

        <SearchBar
          searchHandler={(value) => {
            navigate(`search?query=${value}`)
            navigate(0)
          }}
        />
        <Flex alignItems='center' gap={3}>
          <CartPopover />
          {!userData && (
            <ButtonGroup>
              <SecondaryButton
                type='button'
                display={{ base: 'none', md: 'flex' }}
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/register')
                }}
              >
                Register
              </SecondaryButton>
              <PrimaryButton
                type='button'
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/login')
                }}
              >
                Login
              </PrimaryButton>
            </ButtonGroup>
          )}

          {userData && (
            <Menu>
              <MenuButton
                minW='44px'
                minH='44px'
                as={Button}
                rounded='full'
                variant='link'
                cursor='pointer'
              >
                <Avatar
                  size='md'
                  name={userData.data.fullname}
                  src={userData.data.profile_picture}
                />
              </MenuButton>
              <MenuList zIndex='dropdown'>
                {userMenus.map((menu) => (
                  <MenuItem
                    key={menu.title}
                    minH='44px'
                    minW='44px'
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(menu.ref)
                    }}
                  >
                    {menu.title}
                  </MenuItem>
                ))}
                <MenuDivider />
                <MenuItem color='red' onClick={onLogout}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>

      {/* {isMenuOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as='nav' spacing={4}>
              {links.map((link) => (
                <NavLink key={link.title} href={link.ref}>
                  {link.title}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null} */}
    </Box>
  )
}

export default Navbar
