import React from 'react'
import ResetPasswordForm from '../../components/form/ResetPasswordForm'
import CustomImage from '../../components/images/index'
import { Heading, VStack } from '@chakra-ui/react'

const ResetPasswordPage = () => {
  return (
    <VStack>
      <Heading>Reset Password</Heading>
      <CustomImage
        src='/assets/pngs/changePassword.png'
        boxSize='xs'
        fit='contain'
      />
      <ResetPasswordForm />
    </VStack>
  )
}

export default ResetPasswordPage
