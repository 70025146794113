import React from 'react'
import CustomModal from '..'
import { ShopCourierModalProps } from '../entities'
import ShopCourierSelection from '../../checkout/ShopCourierSelection'

const ShopCourierSelectionModal = (props: ShopCourierModalProps) => {
  const { isOpen, onClose, shopCouriers, selectedCourier, evtOnClick } = props
  return (
    <CustomModal
      size='6xl'
      title='Select Courier'
      body={
        <ShopCourierSelection
          shopCouriers={shopCouriers}
          selectedCourier={selectedCourier}
          evtOnClick={evtOnClick}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default ShopCourierSelectionModal
