import { Box, Spacer, Stack } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer'
import Navbar from '../components/navbar/Navbar'
import { useGetSelfShopInformationQuery } from '../features/shops/shopsSlice'

const Layout = () => {
  const { data } = useGetSelfShopInformationQuery()

  const userMenus = [{ title: 'My Profile', ref: '/profile/user' }]

  userMenus.push(
    { title: 'My Favorite Product', ref: '/favorites' },
    { title: 'My Purchase History', ref: '/transaction' },
    {
      title: `${data?.data.name.toLocaleUpperCase() ?? 'Create Shop'}`,
      ref: `/${data?.data.id ? 'seller' : 'create-shop'}`,
    }
  )

  return (
    <Stack minH='100vh' justifyContent='space-between'>
      <Navbar
        userMenus={userMenus}
        onLogout={function (): void {
          Cookies.remove('tokenaccess')
          Cookies.remove('tokenrefresh')
          window.location.replace('/')
        }}
      />
      <Box maxH='97%' maxW='97%' alignSelf='center' w='full'>
        <Outlet />
      </Box>
      <Spacer />
      <Footer />
    </Stack>
  )
}

export default Layout
