import { ButtonGroup, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { toast } from 'react-toastify'
import CustomModal from '..'
import { useUpdateOrderStatusMutation } from '../../../features/orders/ordersSlice'
import PrimaryButton from '../../buttons/PrimaryButton'
import SecondaryButton from '../../buttons/SecondaryButton'
import { UpdateStatusOrderModalProps } from '../entities'

const UpdateStatusModal = (props: UpdateStatusOrderModalProps) => {
  const { isOpen, onClose, id, status } = props
  const [updateOrderStatus] = useUpdateOrderStatusMutation()
  const handleUpdateOrderStatus = () => {
    const promiseUpdate = updateOrderStatus({ orderID: id, status })
      .unwrap()
      .then(() => onClose())

    toast.promise(promiseUpdate, {
      pending: 'loading...',
      success: 'Order Completed',
      error: {
        render({ data }: any) {
          return `${data}`
        },
      },
    })
  }

  return (
    <CustomModal
      size={{ base: 'sm', md: 'md' }}
      title='Update Order Status'
      body={<Text mt='3'>Are you sure want to complete the order?</Text>}
      footer={
        <ButtonGroup>
          <Flex gap={3}>
            <PrimaryButton flex='1' onClick={handleUpdateOrderStatus}>
              Yes
            </PrimaryButton>
            <SecondaryButton flex='1'>Cancel</SecondaryButton>
          </Flex>
        </ButtonGroup>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default UpdateStatusModal
