import { Flex, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import CustomImage from '../images'
import { CategoryItemProps } from './entities'

const CategoryItem = (props: CategoryItemProps) => {
  const { data, onCategoryClick, ...rest } = props
  return (
    <Stack
      key={data.id}
      direction='row'
      minH='44px'
      _hover={{ background: 'gray.100', cursor: 'pointer' }}
      p={1}
      {...rest}
    >
      <Flex w='full' justifyContent='space-between'>
        <Stack
          direction='row'
          onClick={() => {
            if (onCategoryClick) onCategoryClick(data.id, data.sub_categories)
          }}
          alignItems='center'
          h='full'
          w='full'
        >
          <CustomImage src={data.image_url} h='25px' />
          <Text>{data.name}</Text>
        </Stack>
      </Flex>
    </Stack>
  )
}

export default CategoryItem
