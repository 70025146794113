import React, { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import PrimaryButton from '../buttons/PrimaryButton'
import { Sealabspay } from '../../features/sealabspay/entities'
import SealabspayCardDetail from '../card/SealabspayCardDetail'
import moment from 'moment'
import { Wallet } from '../../features/wallets/entities'
import { useGetOwnWalletQuery } from '../../features/wallets/walletsSlice'
import { useNavigate } from 'react-router-dom'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import SecondaryButton from '../buttons/SecondaryButton'

const PaymentSelection = ({
  sealabsPays,
  onClose,
  evtOnClick,
}: {
  sealabsPays: Sealabspay[]
  onClose: () => void
  evtOnClick: (paymentData: {
    paymentType: 'sealabspay' | 'wallet'
    sealabspay?: Sealabspay
    wallet?: Wallet
  }) => void
}) => {
  const { data: wallet } = useGetOwnWalletQuery(null)
  const [paymentType, setPaymentType] = useState<'sealabspay' | 'wallet'>(
    'sealabspay'
  )
  const { data: userData } = useGetProfileQuery()
  const [buttonPay, setButtonPay] = useState(true)
  const [selectedSealabsPay, setSelectedSealabsPay] =
    useState<Sealabspay | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    sealabsPays.forEach((x) => {
      if (x.id === userData?.data.default_sealabspay_account_id) {
        setSelectedSealabsPay(x)
      }
    })
  }, [userData])

  return (
    <>
      <Tabs>
        <TabList>
          <Tab
            w='full'
            onClick={() => {
              setPaymentType('sealabspay')
              setButtonPay(true)
            }}
          >
            Sealabs Pay
          </Tab>
          <Tab
            w='full'
            onClick={() => {
              setPaymentType('wallet')
              if (wallet?.status === 'blocked') {
                setButtonPay(false)
              }
            }}
          >
            Wallet
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {sealabsPays.length === 0 ? (
              <Center>
                <SecondaryButton
                  onClick={() => navigate('/profile/sealabspay')}
                >
                  Create Sealabspay
                </SecondaryButton>
              </Center>
            ) : (
              <VStack h='300px' overflowY='auto'>
                <Flex flexDirection='column' gap={3} w='full'>
                  {sealabsPays.map((val) => {
                    const isSelected = val.id === selectedSealabsPay?.id
                    return (
                      <Box
                        key={val.id}
                        bgColor={isSelected ? 'primary.400' : 'primary.50'}
                        p={5}
                        h='full'
                        rounded='md'
                        border='1px solid black'
                        borderColor={isSelected ? 'primary.400' : 'primary.200'}
                        cursor='pointer'
                        onClick={() => {
                          setSelectedSealabsPay(val)
                        }}
                      >
                        <Flex flexDirection='column' h='100%'>
                          <SealabspayCardDetail
                            title='Name'
                            value={val.name}
                            colorTitle={
                              isSelected ? 'primary.50' : 'primary.500'
                            }
                            colorValue={
                              isSelected ? 'primary.800' : 'text.normal'
                            }
                          />
                          <SealabspayCardDetail
                            title='Card Number'
                            value={val.card_number}
                            colorTitle={
                              isSelected ? 'primary.50' : 'primary.500'
                            }
                            colorValue={
                              isSelected ? 'primary.800' : 'text.normal'
                            }
                          />
                          <SealabspayCardDetail
                            title='Valid Date'
                            value={moment(val.active_date).format('MM/YY')}
                            colorTitle={
                              isSelected ? 'primary.50' : 'primary.500'
                            }
                            colorValue={
                              isSelected ? 'primary.800' : 'text.normal'
                            }
                          />
                        </Flex>
                      </Box>
                    )
                  })}
                </Flex>
              </VStack>
            )}
          </TabPanel>
          <TabPanel>
            {wallet === undefined ? (
              <Center>
                <SecondaryButton onClick={() => navigate('/profile/wallet')}>
                  Create Wallet
                </SecondaryButton>
              </Center>
            ) : (
              <Box
                bgColor={'primary.400'}
                p={5}
                h='full'
                rounded='md'
                border='1px solid black'
                borderColor={'primary.400'}
              >
                <Flex
                  alignItems='baseline'
                  flexDirection={{ base: 'column', md: 'row' }}
                >
                  <Box
                    flex={2}
                    color={'primary.50'}
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='xs'
                    textTransform='uppercase'
                    ml='2'
                    mr='2'
                  >
                    <Text>STATUS</Text>
                  </Box>
                  <Box
                    flex={7}
                    mt='1'
                    ml='5'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                    color={'primary.800'}
                  >
                    <Text>{wallet?.status.toLocaleUpperCase()}</Text>
                  </Box>
                </Flex>
                <Flex
                  alignItems='baseline'
                  flexDirection={{ base: 'column', md: 'row' }}
                >
                  <Box
                    flex={2}
                    color={'primary.50'}
                    fontWeight='semibold'
                    letterSpacing='wide'
                    fontSize='xs'
                    textTransform='uppercase'
                    ml='2'
                    mr='2'
                  >
                    <Text>BALANCE</Text>
                  </Box>
                  <Box
                    flex={7}
                    mt='1'
                    ml='5'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                    color={'primary.800'}
                  >
                    <Text>Rp. {wallet?.balance.toLocaleString('id-ID')}</Text>
                  </Box>
                </Flex>
              </Box>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box>
        {buttonPay && (
          <PrimaryButton
            w='full'
            onClick={() => {
              switch (paymentType) {
                case 'sealabspay':
                  if (!selectedSealabsPay) return
                  evtOnClick({
                    paymentType,
                    sealabspay: selectedSealabsPay,
                  })
                  break
                case 'wallet':
                  if (!wallet) return
                  evtOnClick({
                    paymentType,
                    wallet: wallet,
                  })
                  break
                default:
                  return
              }
              onClose()
            }}
            disabled={
              (paymentType === 'sealabspay' && !selectedSealabsPay) ||
              wallet === undefined ||
              wallet?.status === 'blocked'
            }
          >
            Pay
          </PrimaryButton>
        )}
      </Box>
    </>
  )
}

export default PaymentSelection
