import React, { useEffect } from 'react'
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import DashboardCard from '../../../components/card/DashboardCard'
import { useLazyGetSellerDashboardQuery } from '../../../features/sellerDashboard/sellerDashboardSlice'
import { useGetProfileQuery } from '../../../features/users/usersSlice'

const SellerDashboardPage = () => {
  const navigate = useNavigate()

  const { data: userData } = useGetProfileQuery()
  const [sellerDashboardTrigger, sellerDashboardResult] =
    useLazyGetSellerDashboardQuery()

  useEffect(() => {
    sellerDashboardTrigger()
  }, [])

  return (
    <Flex flexDir='column' flex='1' p='5' gap={5}>
      <Text fontSize='lg'>Welcome, {userData.data.username}</Text>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        gap='3'
      >
        <DashboardCard
          title='Average Shop Card'
          value={sellerDashboardResult.data?.average_rating}
        />
        <DashboardCard
          title='Product Count'
          value={sellerDashboardResult.data?.product_count}
        />
        <DashboardCard
          title='Active Promotion'
          value={sellerDashboardResult.data?.active_promotion_count}
        />
        <DashboardCard
          title='Active Voucher'
          value={sellerDashboardResult.data?.active_voucher_count}
        />
      </Grid>

      <Box>
        <Heading as='h2' size='lg' mb='2'>
          Orders
        </Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap='3'
        >
          <DashboardCard
            title='Need Approval'
            value={sellerDashboardResult.data?.need_approval_count}
            evtOnClick={() => navigate(`/seller/orders?status=`)}
          />
          <DashboardCard
            title='Sent'
            value={sellerDashboardResult.data?.sent_count}
            evtOnClick={() => navigate(`/seller/orders?status=`)}
          />
          <DashboardCard
            title='Arrived'
            value={sellerDashboardResult.data?.arrived_count}
            evtOnClick={() => navigate(`/seller/orders?status=`)}
          />
        </Grid>
      </Box>

      <Box>
        <Heading as='h2' size='lg' mb='2'>
          Products
        </Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={3}
        >
          <DashboardCard
            title='Most Viewed'
            value={sellerDashboardResult.data?.most_viewed_product.title}
            evtOnClick={() =>
              navigate(
                `/products/${sellerDashboardResult.data?.most_viewed_product.id}`
              )
            }
          />
          <DashboardCard
            title='Most Selled'
            value={sellerDashboardResult.data?.most_purchased_product.title}
            evtOnClick={() =>
              navigate(
                `/products/${sellerDashboardResult.data?.most_viewed_product.id}`
              )
            }
          />
          <DashboardCard
            title='Active Wishlisted'
            value={sellerDashboardResult.data?.most_wishlisted_product.title}
            evtOnClick={() =>
              navigate(
                `/products/${sellerDashboardResult.data?.most_viewed_product.id}`
              )
            }
          />
        </Grid>
      </Box>
    </Flex>
  )
}

export default SellerDashboardPage
