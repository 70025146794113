import React from 'react'
import ProfileForm from '../../components/form/ProfileForm'
import { Box, Center, Flex } from '@chakra-ui/react'
import ProfilePicture from '../../components/profilePicture'

const UserProfilePage = () => {
  return (
    <Center>
      <Flex
        maxW='6xl'
        justify='center'
        flex='1'
        maxH={{ base: '700px', md: '500px' }}
        border={{ md: '1px solid black' }}
        borderColor={{ md: 'primary.300' }}
        flexDirection={['column', 'row', 'row']}
        p={10}
      >
        <Center mr={10}>
          <ProfilePicture />
        </Center>
        <Box p={5} borderLeft={{ md: '1px solid #d2cfcf' }}>
          <ProfileForm />
        </Box>
      </Flex>
    </Center>
  )
}

export default UserProfilePage
