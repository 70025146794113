import { Container } from '@chakra-ui/react'
import React from 'react'
import EditShopProductCategoryForm from '../../components/form/EditShopProductCategoryForm'

const CreateShopCategoryPage = () => {
  return (
    <Container maxW='6xl' overflow='auto'>
      <EditShopProductCategoryForm />
    </Container>
  )
}

export default CreateShopCategoryPage
