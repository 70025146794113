import {
  Box,
  Container,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import moment from 'moment'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import CustomImage from '../../components/images'
import { useGetDetailProductQuery } from '../../features/orders/ordersSlice'

const DetailProductHistoryPage = () => {
  const params = useParams()
  const orderID = params.id
  const { data: order } = useGetDetailProductQuery({ orderID })
  const navigate = useNavigate()

  return (
    <Container
      maxW={'6xl'}
      border={'1px solid black'}
      borderColor={'primary.300'}
      rounded={'md'}
      p={'5'}
      mt={'6'}
    >
      <Box
        rounded={'md'}
        border={'1px solid black'}
        borderColor={'primary.300'}
        mb={'5'}
        p={'4'}
        bgColor={'primary.50'}
      >
        The following is a detailed view of products ordered on{' '}
        <Text as={'b'}>{moment(order?.created_at).format('DD MMM YYYY')}</Text>
      </Box>
      <Flex direction={{ base: 'column', md: 'row' }}>
        <Flex direction={'column'}>
          <CustomImage
            width={{ base: 'auto', sm: '300px' }}
            height={'300px'}
            src={order?.thumbnail_url}
            mb={'5'}
          />
          <PrimaryButton
            onClick={() => navigate('/products/' + order?.product_id)}
          >
            See Product Page
          </PrimaryButton>
        </Flex>
        <Flex
          flex={1}
          direction={'column'}
          mt={{ base: 3, md: 0 }}
          ml={{ base: 0, md: 5 }}
        >
          <Text as={'b'} fontSize={'2xl'}>
            {order?.title}
          </Text>
          <Text as={'b'} fontSize={'4xl'}>
            Rp {order?.price?.toLocaleString('id-ID')}
          </Text>
          <Tabs mt={'3'}>
            <TabList>
              <Tab color={'primary.500'}>DETAIL</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex direction={'row'}>
                  <Text color={'text.light'}>Condition:</Text>
                  <Text as={'b'}>
                    &nbsp;{order?.condition.toLocaleUpperCase()}
                  </Text>
                </Flex>
                <Text color={'text.light'}>Description:</Text>
                <Text color={'text.normal'}>{order?.description}</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </Container>
  )
}

export default DetailProductHistoryPage
