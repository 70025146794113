import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import moment from 'moment'
import { FaClipboardList, FaCopy, FaPencilAlt } from 'react-icons/fa'
import { DataTable } from '../../../components/datatable'
import { createColumnHelper } from '@tanstack/react-table'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useLazyGetShopPromotionsQuery } from '../../../features/shopPromotions/shopPromotionsSlice'
import {
  PromotionActField,
  PromotionDataTable,
  PromotionInformationField,
  PromotionPeriodField,
  PromotionQuotaUsageField,
  PromotionStatusField,
} from './entities'
import convertToRupiah from '../../../helpers/currencyFormat'
import { useNavigate } from 'react-router-dom'

const PromotionPage = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState('all')
  const [promotions, setPromotions] = useState<PromotionDataTable[]>([])
  const [trigger, result] = useLazyGetShopPromotionsQuery({})
  const columnHelper = createColumnHelper<PromotionDataTable>()

  const renderInformationCell = ({
    name,
    type,
    amount,
  }: PromotionInformationField) => (
    <Box>
      <Text fontWeight='bold'>{name}</Text>
      <Text>
        {type === 'percentage'
          ? `Discount ${amount}%`
          : `Discount Rp.\xa0${convertToRupiah(amount)}`}
      </Text>
    </Box>
  )

  const renderQuotaUsageCell = ({ quota }: PromotionQuotaUsageField) => (
    <Box>
      <Text>{quota}</Text>
    </Box>
  )

  const renderStatusCell = ({ status }: PromotionStatusField) => (
    <Badge
      colorScheme={
        status === 'will come'
          ? 'blue'
          : status === 'on going'
          ? 'green'
          : 'whiteAlpha'
      }
      rounded='sm'
    >
      {status}
    </Badge>
  )

  const renderPeriodCell = ({ startDate, endDate }: PromotionPeriodField) => (
    <Box>
      <Text>
        {moment(startDate).format('DD MMMM YYYY HH:mm')} -{' '}
        {moment(endDate).format('DD MMMM YYYY HH:mm')}
      </Text>
    </Box>
  )

  const renderActCell = ({ id, status }: PromotionActField) => (
    <Flex justifyContent='right' gap={1.5}>
      {status === 'will come' ? (
        <Tooltip label='Update promotion'>
          <Button
            type='button'
            onClick={(e) => {
              e.preventDefault()
              navigate(`/seller/promotion/update/${id}`)
            }}
          >
            <FaPencilAlt />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip label='Detail promotion'>
          <Button
            type='button'
            onClick={(e) => {
              e.preventDefault()
              navigate(`/seller/promotion/${id}`)
            }}
          >
            <FaClipboardList />
          </Button>
        </Tooltip>
      )}

      <Tooltip label='Copy promotion'>
        <Button
          type='button'
          onClick={(e) => {
            e.preventDefault()
            navigate(`/seller/promotion/create?copy=${id}`)
          }}
        >
          <FaCopy />
        </Button>
      </Tooltip>
    </Flex>
  )

  const columns = [
    columnHelper.accessor('information', {
      cell: (info) => renderInformationCell(info.getValue()),
      header: 'Information',
      sortingFn: (a, b) =>
        a.original.information.name > b.original.information.name ? 1 : -1,
    }),
    columnHelper.accessor('quotaUsage', {
      cell: (info) => renderQuotaUsageCell(info.getValue()),
      header: 'Quota',
      sortingFn: (a, b) => {
        return a.original.quotaUsage.quota - b.original.quotaUsage.quota
      },
    }),
    columnHelper.accessor('status', {
      cell: (info) => renderStatusCell(info.getValue()),
      header: 'Status',
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('period', {
      cell: (info) => renderPeriodCell(info.getValue()),
      header: 'Period',
      sortingFn: (a, b) =>
        moment(a.original.period.startDate).diff(
          moment(b.original.period.startDate)
        ),
    }),
    columnHelper.accessor('act', {
      cell: (info) => renderActCell(info.getValue()),
      header: '',
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ]

  useEffect(() => {
    trigger({ status: filter }, false)
  }, [filter])

  useEffect(() => {
    const promotionDatas = result.data
    if (!promotionDatas) {
      setPromotions([])
      return
    }

    const promos = promotionDatas.map<PromotionDataTable>((val) => ({
      information: {
        id: val.id,
        name: val.name,
        amount: val.amount,
        type: val.type,
      },
      quotaUsage: {
        quota: val.quota,
      },
      status: {
        status: val.status,
      },
      period: {
        startDate: val.start_date,
        endDate: val.end_date,
      },
      act: {
        id: val.id,
        status: val.status,
      },
    }))

    setPromotions(promos)
  }, [result])

  return (
    <Box px={{ base: '0', md: '50px' }} py='1rem' flex='1' overflow='auto'>
      <Flex
        flexDirection={{
          md: 'row',
          base: 'column',
        }}
        mb={10}
      >
        <Box flex={1}>
          <Heading as='h2' fontWeight='bold' fontSize='2rem'>
            Promotions
          </Heading>
        </Box>
        <Flex alignItems='center'>
          <PrimaryButton
            type='button'
            onClick={(e) => {
              e.preventDefault()
              navigate('/seller/promotion/create')
            }}
          >
            Create Promotion
          </PrimaryButton>
        </Flex>
      </Flex>

      <Box p={2} rounded='md' shadow='md'>
        <Tabs>
          <TabList>
            <Tab onClick={() => setFilter('all')}>All</Tab>
            <Tab onClick={() => setFilter('ongoing')}>On Going</Tab>
            <Tab onClick={() => setFilter('will come')}>Will Come</Tab>
            <Tab onClick={() => setFilter('has ended')}>Has Ended</Tab>
          </TabList>
        </Tabs>

        <Box overflow='auto'>
          <DataTable columns={columns} data={promotions} />
        </Box>
      </Box>
    </Box>
  )
}

export default PromotionPage
