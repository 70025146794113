import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import CustomModal from '.'
import { useCreateWalletMutation } from '../../features/wallets/walletsSlice'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import { WalletCreateModalProps } from './entities'

const WalletModal = (props: WalletCreateModalProps) => {
  const { isOpen, onClose } = props
  const [pin, setPin] = useState('')

  const [triggerCreate] = useCreateWalletMutation()
  const [inputPin, setInputPin] = useState(false)
  const handleChangePin = (value: string) => {
    setPin(value)
    if (value.length === 6) {
      setInputPin(true)
    } else {
      setInputPin(false)
    }
  }
  const handleCreate = () => {
    const promiseCreate = triggerCreate({ pin: pin })
      .unwrap()
      .then(() => {
        onClose()
        setPin('')
      })

    toast.promise(promiseCreate, {
      pending: 'loading...',
      success: 'Success Create Wallet',
      error: {
        render({ data }: any) {
          return `${data}`
        },
      },
    })
  }

  return (
    <CustomModal
      size={{ base: 'sm', md: 'md' }}
      title='Create Wallet'
      body={
        <>
          <Box p={5}>
            <Center mb={5}>
              <Text as={'b'} fontSize={'lg'}>
                CREATE PIN
              </Text>
            </Center>
            <Flex justifyContent={'center'}>
              <HStack>
                <PinInput
                  size={'lg'}
                  onChange={(value) => handleChangePin(value)}
                  mask
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
          </Box>
        </>
      }
      footer={
        <ButtonGroup>
          <Flex gap={3}>
            {!inputPin ? (
              <PrimaryButton onClick={handleCreate} flex='1' disabled>
                Create
              </PrimaryButton>
            ) : (
              <PrimaryButton onClick={handleCreate} flex='1'>
                Create
              </PrimaryButton>
            )}
            <SecondaryButton onClick={onClose} flex='1'>
              Cancel
            </SecondaryButton>
          </Flex>
        </ButtonGroup>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default WalletModal
