import {
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetTransactionQuery } from '../../features/transactions/transactionsSlice'
import { usePaymentSealabspayMutation } from '../../features/sealabspay/sealabspaySlice'
import { getStringEnv } from '../../helpers/env'
import CustomImage from '../../components/images'

const PaymentPage = () => {
  const { id } = useParams()
  const transactionID = id
  const { data, isLoading } = useGetTransactionQuery(transactionID)
  const [triggerPayment] = usePaymentSealabspayMutation()
  const navigate = useNavigate()

  const handlePayment = () => {
    if (data?.sealabspay_account !== null) {
      triggerPayment({
        cardNumber: data?.sealabspay_account?.card_number,
        amount: data?.total_price,
        redirectURL:
          getStringEnv('REACT_APP_FE_API_URL') + '/payment-result/payment',
        id: data?.id,
        paymentType: 'payment',
      })
        .unwrap()
        .then((data) => {
          window.location.href = data
        })
    } else {
      navigate('/wallet-payments/' + transactionID)
    }
  }

  return (
    <>
      {data === undefined || data?.status === 'PAID' ? (
        <Container
          minW={'xs'}
          maxW={'2xl'}
          rounded={'md'}
          border={'1px solid black'}
          borderColor={'primary.300'}
          p={8}
        >
          <Flex direction={'column'}>
            <Flex justifyContent={'center'}>
              <CustomImage
                width={'300px'}
                height={'auto'}
                src={'/assets/svgs/payment-failed.svg'}
              />
            </Flex>
            <Flex direction={'column'} mt={3} gap={3}>
              <Center>
                <Heading>{'Sorry...'}</Heading>
              </Center>
              <Center>
                <Text color={'text.light'}>
                  {
                    'Your payment has been paid or the transaction belongs to other user'
                  }
                </Text>
              </Center>
              <Center>
                <PrimaryButton type='button' fontWeight='medium'>
                  {'Go To My Purchase History'}
                </PrimaryButton>
              </Center>
            </Flex>
          </Flex>
        </Container>
      ) : (
        <Container
          minW={'xs'}
          maxW={'2xl'}
          rounded={'md'}
          border={'1px solid black'}
          borderColor={'primary.300'}
          p={8}
        >
          <Flex direction={'column'}>
            <Flex direction={'column'} mt={3} gap={3}>
              <Heading>Payment</Heading>
              <Grid templateColumns='repeat(2, 1fr)'>
                <GridItem>
                  <Text as='b'>Total Price</Text>
                </GridItem>
                <GridItem>
                  {/* total price */}
                  <Text align='right' color='primary.400' fontWeight='bold'>
                    {!isLoading &&
                      'Rp ' + data?.total_price.toLocaleString('id-ID')}
                  </Text>
                </GridItem>
              </Grid>
              <Divider />
              {/* Payment type */}
              <Text color={'text.light'}>
                {!isLoading && data?.payment_type.toLocaleUpperCase()}
              </Text>
              <Text color={'primary.500'}>
                {!isLoading && data?.sealabspay_account?.card_number}
                {!isLoading &&
                  data?.wallet &&
                  'Rp. ' + data?.wallet?.balance.toLocaleString('id-ID')}
              </Text>
              <Divider />
              {/* list action */}
              {data?.payment_type === 'sealabspay' ? (
                <>
                  <Text as={'b'}>Transfer with Sealabspay</Text>
                  <OrderedList mb={4}>
                    <ListItem>
                      Open <Text as={'b'}>Sealabspay Mobile</Text> with
                      registered Sealabspay Account
                    </ListItem>
                    <ListItem>
                      Input OTP Code from{' '}
                      <Text as={'b'}>Sealabspay Mobile</Text> into{' '}
                      <Text as={'b'}>Sealabspay Payment Page</Text>
                    </ListItem>
                    <ListItem>
                      Click <Text as={'b'}>SUBMIT</Text> to proceed the payment
                    </ListItem>
                    <ListItem>
                      Click <Text as={'b'}>Cancel Payment</Text> to cancel the
                      payment
                    </ListItem>
                    <ListItem>
                      Transaction will be defined after payment either it&apos;s
                      successful or failed
                    </ListItem>
                  </OrderedList>
                </>
              ) : (
                <>
                  <Text as={'b'}>Transfer with Wallet</Text>
                  <OrderedList mb={4}>
                    <ListItem>
                      Input <Text as={'b'}>Wallet Pin</Text>
                    </ListItem>
                    <ListItem>
                      If the pin is incorrect 3 times then the wallet will be{' '}
                      <Text as={'b'}>BLOCKED</Text>
                    </ListItem>
                    <ListItem>
                      If wallet is <Text as={'b'}>BLOCKED</Text> then the user
                      will be notified with pop-up text
                    </ListItem>
                    <ListItem>
                      If the pin is correct then it will redirect to{' '}
                      <Text as={'b'}>Payment Result Page</Text>
                    </ListItem>
                    <ListItem>
                      Transaction will be defined after payment either it&apos;s
                      successful or failed
                    </ListItem>
                  </OrderedList>
                </>
              )}

              <Center>
                <PrimaryButton
                  onClick={handlePayment}
                  type='button'
                  fontWeight='medium'
                >
                  Proceed Payment
                </PrimaryButton>
              </Center>
            </Flex>
          </Flex>
        </Container>
      )}
    </>
  )
}

export default PaymentPage
