import React from 'react'
import moment from 'moment'
import { Card, Text, Grid, CardBody } from '@chakra-ui/react'
import convertToRupiah from '../../helpers/currencyFormat'
import { ShopVoucherCardProps } from './entities'

const CheckoutShopVoucherCard = (props: ShopVoucherCardProps) => {
  const {
    shopVoucher: { code, minimum_payment, end_date, type, amount },
    onClick,
    ...rest
  } = props

  return (
    <Card
      boxShadow='xl'
      borderColor='primary.200'
      backgroundColor='primary.50'
      borderWidth={1}
      onClick={onClick}
      cursor='pointer'
      {...rest}
    >
      <CardBody>
        <Grid gap='1'>
          <Text fontWeight='extrabold'>{code}</Text>
          <Text>
            {type === 'NOMINAL' ? 'Rp' + convertToRupiah(amount) : amount + '%'}{' '}
            off
          </Text>
          <Text>Min. Payment Rp.&nbsp;{convertToRupiah(minimum_payment)}</Text>
          <Text>Valid until {moment(end_date).format('DD MMMM YYYY')}</Text>
        </Grid>
      </CardBody>
    </Card>
  )
}

export default CheckoutShopVoucherCard
