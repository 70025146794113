import { Flex } from '@chakra-ui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { MdLocalShipping } from 'react-icons/md'
import { FaBoxes, FaClipboardList, FaTag } from 'react-icons/fa'
import Sidebar from '../components/sidebar'
import { SidebarProps } from '../components/sidebar/entities'

const SellerLayout = () => {
  const sidebarItems: SidebarProps = {
    items: [
      {
        title: 'Delivery',
        icon: (
          <MdLocalShipping
            style={{ marginRight: '0.25rem', display: 'inline' }}
          />
        ),
        items: [{ ref: '/seller/deliveries', title: 'Deliveries' }],
      },
      {
        icon: (
          <FaClipboardList
            style={{ marginRight: '0.25rem', display: 'inline' }}
          />
        ),
        title: 'Order',
        items: [
          { ref: '/seller/orders', title: 'Orders' },
          { ref: '/seller/orders?filter=canceled', title: 'Cancelled Orders' },
          { ref: '/seller/orders?filter=canceled', title: 'Refund Orders' },
        ],
      },
      {
        icon: <FaBoxes style={{ marginRight: '0.25rem', display: 'inline' }} />,
        title: 'Product',
        items: [
          { ref: '/seller/products', title: 'Products' },
          { ref: '/seller/categories', title: 'Categories' },
          { ref: '/seller/categories/create', title: 'New Category' },
          { ref: '/seller/products/create', title: 'New Product' },
        ],
      },
      {
        icon: <FaTag style={{ marginRight: '0.25rem', display: 'inline' }} />,
        title: 'Promotion',
        items: [
          { ref: 'promotion', title: 'Promotions' },
          { ref: 'vouchers', title: 'Vouchers' },
        ],
      },
    ],
  }

  return (
    <Flex w='full'>
      <Sidebar items={sidebarItems.items} />
      <Outlet />
    </Flex>
  )
}

export default SellerLayout
