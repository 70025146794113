import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react'
import React from 'react'

const CustomTextarea = forwardRef<TextareaProps, 'input'>((props, ref) => {
  const { ...rest } = props
  return (
    <Textarea
      focusBorderColor='primary.400'
      errorBorderColor='danger.400'
      ref={ref}
      {...rest}
    />
  )
})

export default CustomTextarea
