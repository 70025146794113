import { SearchIcon } from '@chakra-ui/icons'
import { HStack, IconButton } from '@chakra-ui/react'
import React, { KeyboardEvent, useState } from 'react'
import StandardInput from '../inputs/StandardInput'

const SearchBar = (props: { searchHandler: (data: string) => void }) => {
  const { searchHandler } = props
  const [search, setSearch] = useState('')
  const keyboardSearchHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchHandler(search)
    }
  }
  return (
    <HStack alignContent='center' w='full'>
      <StandardInput
        placeholder='Search...'
        backgroundColor='white'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={keyboardSearchHandler}
      />
      <IconButton
        aria-label={'search'}
        onClick={() => searchHandler(search)}
        colorScheme='primary'
      >
        <SearchIcon />
      </IconButton>
    </HStack>
  )
}

export default SearchBar
