import React, { useEffect, useState } from 'react'
import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { createColumnHelper } from '@tanstack/react-table'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'
import CustomForm from '.'
import { useLazyGetShopProductsQuery } from '../../features/products/productsSlice'
import { useGetOwnShopQuery } from '../../features/users/usersSlice'
import convertToRupiah from '../../helpers/currencyFormat'
import { DataTable } from '../datatable'
import IndeterminateCheckbox from '../inputs/IndeterminateCheckbox'
import LeftElementInput from '../inputs/LeftElementInput'
import SelectInput from '../inputs/SelectInput'
import StandardInput from '../inputs/StandardInput'
import {
  PromotionUpdateFormInputs,
  PromotionProductDataTable,
  PromotionUpdateFormProps,
  PromotionProductInformationField,
  PromotionProductPriceField,
  PromotionProductStockField,
} from './entities'
import DateTimeInput from '../inputs/DateTimeInput'
import NumberInput from '../inputs/NumberInput'
import CustomImage from '../images'
import AdditionalElementsInput from '../inputs/AdditionalElementsInput'

const schema = yup.object().shape({
  name: yup.string().required('Must be not empty'),
  quota: yup.number().required('Must be not empty'),
  maxOrder: yup.number().required('Must be not empty'),
  startDate: yup.string().required('Must be not empty'),
  endDate: yup.string().required('Must be not empty'),
  type: yup
    .string()
    .oneOf(['nominal', 'percentage'])
    .required('Must be not empty'),
  amount: yup.number().required('Must be not empty'),
  minimumPayment: yup.number().required('Must be not empty'),
  newAddPromotionProductIds: yup.array().of(yup.string()),
  willDeletePromotionProductIds: yup.array().of(yup.string()),
})

const renderInformationCell = ({
  imageUrl,
  name,
  sku,
}: PromotionProductInformationField) => (
  <Flex gap={2.5}>
    <CustomImage
      w='50px'
      h='50px'
      borderRadius='2px'
      objectFit='cover'
      src={imageUrl}
    />
    <Box>
      <Text fontWeight='bold' color='text.normal'>
        {name}
      </Text>
      <Text fontSize='14px' color='text.light'>
        SKU: {sku}
      </Text>
    </Box>
  </Flex>
)

const renderPriceCell = ({ maxPrice, minPrice }: PromotionProductPriceField) =>
  minPrice === maxPrice
    ? `Rp.\xa0${convertToRupiah(minPrice)}`
    : `Rp.\xa0${convertToRupiah(minPrice)} - Rp.\xa0${convertToRupiah(
        maxPrice
      )}`

const renderStockCell = ({ stock }: PromotionProductStockField) => stock

const PromotionUpdateForm = (props: PromotionUpdateFormProps) => {
  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<PromotionUpdateFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      promotionProductsIds: [],
      newAddPromotionProductIds: [],
      willDeletePromotionProductIds: [],
    },
  })
  const { data: shopData } = useGetOwnShopQuery(null)
  const [shopProductTrigger] = useLazyGetShopProductsQuery()
  const [amountType, setAmountType] = useState<string>('nominal')
  const [shopProducts, setShopProducts] = useState<PromotionProductDataTable[]>(
    []
  )
  const [minStartDatetime] = useState<string>(
    moment().add(30, 'minutes').format('YYYY-MM-DDTHH:mm')
  )
  const [minEndDatetime, setMinEndDatetime] = useState<string>(minStartDatetime)
  const [isShopProductInitialized, setIsShopProductInitialized] =
    useState<boolean>(false)
  const columnHelper = createColumnHelper<PromotionProductDataTable>()
  const columns = [
    columnHelper.accessor('select', {
      header: ({ table }) => {
        return (
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={(e) => {
              table.getToggleAllRowsSelectedHandler()(e)
              resetField('willDeletePromotionProductIds')
              resetField('newAddPromotionProductIds')
              if (e.target.checked)
                setValue(
                  'willDeletePromotionProductIds',
                  shopProducts.map((val) => val.information.id)
                )
            }}
          />
        )
      },
      cell: ({ row }) => {
        const { onChange, ...rest } = register('promotionProductsIds')

        return (
          <div className='px-1'>
            <IndeterminateCheckbox
              {...rest}
              checked={
                props.data
                  ? props.data.promotionProductsIds.includes(
                      row.original.information.id
                    )
                  : row.getIsSelected()
              }
              isIndeterminate={row.getIsSomeSelected()}
              onChange={(e) => {
                row.getToggleSelectedHandler()(e)
                onChange(e)

                switch (row.original.information.defaultState) {
                  case true:
                    if (!e.target.checked) {
                      setValue('willDeletePromotionProductIds', [
                        ...getValues().willDeletePromotionProductIds,
                        row.original.information.id,
                      ])
                    } else {
                      setValue(
                        'willDeletePromotionProductIds',
                        getValues().willDeletePromotionProductIds.filter(
                          (val) => val !== e.target.value
                        )
                      )
                    }
                    break
                  case false:
                    if (e.target.checked) {
                      setValue('newAddPromotionProductIds', [
                        ...getValues().newAddPromotionProductIds,
                        row.original.information.id,
                      ])
                    } else {
                      setValue(
                        'newAddPromotionProductIds',
                        getValues().newAddPromotionProductIds.filter(
                          (val) => val !== e.target.value
                        )
                      )
                    }
                    break
                  default:
                    break
                }
              }}
              value={row.original.information.id}
            />
          </div>
        )
      },
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('information', {
      cell: (info) => renderInformationCell(info.getValue()),
      header: 'Information',
      sortingFn: (a, b) =>
        a.original.information.name > b.original.information.name ? 1 : -1,
    }),
    columnHelper.accessor('price', {
      cell: (info) => renderPriceCell(info.getValue()),
      header: 'Price',
    }),
    columnHelper.accessor('stock', {
      cell: (info) => renderStockCell(info.getValue()),
      header: 'Stock',
    }),
  ]

  const onSubmit = async (values: PromotionUpdateFormInputs) => {
    await props.evtFormOnSubmit(values)
  }

  useEffect(() => {
    const preValue = props.data
    if (!preValue) return

    setValue('name', preValue.name)
    setValue('amount', preValue.amount)
    setValue('maxOrder', preValue.maxOrder)
    setValue('minimumPayment', preValue.minimumPayment)
    setValue('quota', preValue.quota)
    setValue('startDate', preValue.startDate)
    setValue('endDate', preValue.endDate)
    setValue('type', preValue.type)
    setValue('promotionProductsIds', preValue.promotionProductsIds)

    setAmountType(preValue.type)
  }, [])

  useEffect(() => {
    if (!shopData) return
    shopProductTrigger(
      {
        id: shopData.id,
        query: '',
      },
      false
    )
      .unwrap()
      .then((data: any) => {
        setShopProducts(
          data.data.map((val: any) => {
            const total_stock = val?.inventories?.reduce(
              (acc: number, curr: any) => acc + curr.stock,
              0
            )
            return {
              select: null,
              information: {
                id: val.id,
                imageUrl: val.thumbnail_url,
                name: val.name,
                sku: val.sku,
                defaultState: props.data?.promotionProductsIds.includes(val.id),
              },
              price: {
                minPrice: val.min_price,
                maxPrice: val.max_price,
              },
              stock: {
                stock: total_stock,
              },
            }
          })
        )
      })
  }, [shopData])

  useEffect(() => {
    if (!isShopProductInitialized) setIsShopProductInitialized(true)
  }, [shopProducts])

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel={props.submitButtonTitle}
    >
      <Text as='h2' fontWeight='bold' fontSize='1.5rem' mb='1rem'>
        Promotion Details
      </Text>
      <Box p={2} rounded='md' shadow='md' mb={10} px={7}>
        <StandardInput
          {...register('name')}
          isRequired
          label='Promotion Name'
          type='text'
          isInvalid={!!errors.name}
          placeholder='Name'
          errorMessage={errors.name?.message}
        />
        <NumberInput
          {...register('quota')}
          isRequired
          isInvalid={!!errors.quota}
          placeholder='Quota'
          label='Quota'
          errorMessage={errors.quota?.message}
        />
        <NumberInput
          {...register('maxOrder')}
          isRequired
          isInvalid={!!errors.maxOrder}
          placeholder='Maximum Order'
          label='Maximum Order'
          errorMessage={errors.maxOrder?.message}
        />

        <Box my={4}>
          <FormControl isRequired my={4}>
            <FormLabel>Period</FormLabel>
          </FormControl>
          <Flex
            gap='1rem'
            width='100%'
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
            alignItems={{
              base: 'flex-start',
              md: 'center',
            }}
          >
            <DateTimeInput
              {...register('startDate')}
              placeholder='Start Period'
              min={minStartDatetime}
              isInvalid={!!errors.startDate}
              errorMessage={errors.startDate?.message}
              onChange={(e) => {
                if (moment(e.currentTarget.value).diff(minStartDatetime) < 0) {
                  setValue('startDate', minStartDatetime)
                  return
                }
                register('startDate').onChange(e)
                setMinEndDatetime(
                  getValues().startDate?.toString() || minStartDatetime
                )

                const periodEnd = getValues().endDate
                if (!periodEnd) return
                if (moment(e.target.value).diff(periodEnd) > 0) {
                  resetField('endDate')
                }
              }}
            />
            <DateTimeInput
              {...register('endDate')}
              placeholder='End Period'
              min={minEndDatetime}
              isInvalid={!!errors.startDate}
              errorMessage={errors.startDate?.message}
              onChange={(e) => {
                if (moment(e.currentTarget.value).diff(minStartDatetime) < 0) {
                  setValue('endDate', minStartDatetime)
                  return
                }
                register('endDate').onChange(e)
              }}
            />
          </Flex>
        </Box>

        <AdditionalElementsInput
          {...register('amount')}
          isRequired
          type='number'
          leftElement={
            <Flex justifyContent='center' alignItems='center'>
              <SelectInput
                {...register('type')}
                borderRight='1px solid'
                borderRightColor='gray.500'
                borderTopEndRadius='none'
                borderBottomEndRadius='none'
                isRequired
                isInvalid={!!errors.type}
                errorMessage={errors.type?.message}
                onChange={(e) => {
                  setAmountType(e.target.value)
                  register('type').onChange(e)
                }}
              >
                <option value='nominal'>Nominal</option>
                <option value='percentage'>Percentage</option>
              </SelectInput>

              {amountType === 'nominal' && (
                <Box px={3}>
                  <Text>Rp</Text>
                </Box>
              )}
            </Flex>
          }
          rightAddon={
            amountType === 'percentage' && (
              <Flex justifyContent='center' alignItems='center' h='full'>
                <Box px={3}>
                  <Text>%</Text>
                </Box>
              </Flex>
            )
          }
          isLabelTop
          isInvalid={!!errors.amount}
          label='Amount'
          placeholder='Amount'
          errorMessage={errors.amount?.message}
        />

        <LeftElementInput
          {...register('minimumPayment')}
          isRequired
          type='number'
          isLabelTop
          label='Minimum Payment'
          leftElement={
            <Flex
              justifyContent='center'
              alignItems='center'
              height='100%'
              px={3}
            >
              <Text>Rp</Text>
            </Flex>
          }
          isInvalid={!!errors.minimumPayment}
          placeholder='Minimum Payment'
          errorMessage={errors.minimumPayment?.message}
        />
      </Box>

      <Text as='h2' fontWeight='bold' fontSize='1.5rem' mb='1.5rem'>
        Promotion Products
      </Text>
      <Box rounded='md' shadow='md' mb={10} overflow='auto'>
        {isShopProductInitialized ? (
          <DataTable
            columns={columns}
            data={shopProducts}
            enableMultiRowSelection
          />
        ) : (
          <Text textAlign='center'>Loading product</Text>
        )}
      </Box>
    </CustomForm>
  )
}

export default PromotionUpdateForm
