import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { reviewPagesProp } from './entities'

const initialState: reviewPagesProp = {
  page: 1,
}

const reviewPagesSlice = createSlice({
  name: 'reviewPages',
  initialState: initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    resetPage: () => initialState,
  },
})

export const { setPage, resetPage } = reviewPagesSlice.actions
export default reviewPagesSlice.reducer
