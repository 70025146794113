import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../buttons/PrimaryButton'

const ConfirmDeleteModal = (props: any) => {
  const { isOpen, onClose, deleteAction, title, body, buttonText } = props
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <PrimaryButton colorScheme='red' mr={3} onClick={deleteAction}>
            {buttonText}
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteModal
