import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import convertToRupiah from '../../helpers/currencyFormat'
import CustomImage from '../images'
import { CartItemProps } from './entitites'

const CartItem = ({ data }: CartItemProps) => {
  return (
    <Flex
      gap='8px'
      fontSize='sm'
      paddingY='0.25rem'
      borderBottom='1px solid #737A79'
    >
      <CustomImage
        w='50px'
        h='50px'
        borderRadius='2px'
        objectFit='cover'
        src={data.imageUrl}
      />
      <Box w='full'>
        <Text>{data.name}</Text>
        <Text>{data.quantity}&nbsp;item(s)</Text>
      </Box>
      <Flex alignItems='center'>
        <Text fontWeight='bold'>
          Rp&nbsp;{convertToRupiah(data.totalPrice)}
        </Text>
      </Flex>
    </Flex>
  )
}

export default CartItem
