import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { minuteToDay } from '../../helpers/durationFormat'
import { getNumberEnv, getStringEnv } from '../../helpers/env'

const baseQuery = fetchBaseQuery({
  baseUrl: getStringEnv('REACT_APP_BE_API_URL'),
})

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  const tokenRefresh = Cookies.get('tokenrefresh')

  if (result.error?.status === 401 && tokenRefresh) {
    const refetchTokenResult = await baseQuery(
      {
        url: '/token-access',
        headers: {
          Authorization: 'Bearer ' + tokenRefresh,
        },
      },
      api,
      extraOptions
    )

    if (refetchTokenResult.data) {
      const { data }: any = refetchTokenResult.data
      const tokenAccessLifetime = getNumberEnv('REACT_APP_TOKEN_ACCESS_EXPIRED')

      Cookies.set('tokenaccess', data['token_access'], {
        expires: minuteToDay(tokenAccessLifetime),
      })

      result = await baseQuery(
        {
          ...args,
          headers: {
            ...args.headers,
            Authorization: `Bearer ${data['token_access']}`,
          },
        },
        api,
        extraOptions
      )

      const tokenRefreshData: any = jwtDecode(tokenRefresh)
      const tokenAccessData: any = jwtDecode(data['token_access'])
      if (
        tokenRefreshData.data['password_updated_at'] !==
        tokenAccessData.data['password_updated_at']
      ) {
        Cookies.remove('tokenaccess')
        Cookies.remove('tokenrefresh')
        window.location.replace('/login?status=401')
      }
    } else {
      Cookies.remove('tokenaccess')
      Cookies.remove('tokenrefresh')
    }
  }

  return result
}

export const apiSlice = createApi({
  reducerPath: 'api', // optional
  baseQuery: customFetchBase,
  tagTypes: [
    'users',
    'categories',
    'auth',
    'products',
    'carts',
    'shops',
    'shopProductCategories',
    'couriers',
    'shopPromotions',
    'shopVouchers',
    'shopCouriers',
    'address',
    'reviews',
    'sealabspayAccounts',
    'locations',
    'wallet',
    'walletHistories',
    'isFavorite',
    'transactions',
    'vouchers',
    'orders',
    'detailOrder',
    'detailTransaction',
  ],
  endpoints: () => ({}),
})
