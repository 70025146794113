import {
  Box,
  Container,
  SimpleGrid,
  HStack,
  Text,
  Center,
  Stack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import NoData from '../../components/noData'
import ProductDescriptionPanel from '../../components/product/ProductDescriptionPanel'
import ProductDetailPanel from '../../components/product/ProductDetailPanel'
import ProductSeller from '../../components/product/ProductSeller'
import ProductCard from '../../components/productCard'
import ReviewPanel from '../../components/review/ReviewPanel'
import {
  useGetProductByIdQuery,
  useLazyGetShopProductsQuery,
  useLazyGetSearchProductQuery,
} from '../../features/products/productsSlice'
import { resetPage } from '../../features/reviewPages/reviewPagesSlice'
import { InventoryProps } from './entities'

const ProductPage = () => {
  const { productId: id } = useParams()
  const { data, error, isLoading } = useGetProductByIdQuery(id)
  const [triggerShopProduct, resultShop] = useLazyGetShopProductsQuery({})
  const [triggerSimilarProduct, resultSimilarProduct] =
    useLazyGetSearchProductQuery()
  const [firstTypeId, setFirstTypeId] = useState<string>('')
  const [secondTypeId, setSecondTypeId] = useState<string>('')
  const [selectedInventoryData, setSelectedInventoryData] =
    useState<InventoryProps>()
  const [inventoryId, setInventoryId] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    const productData = data
    setFirstTypeId('')
    setSecondTypeId('')
    setSelectedInventoryData(undefined)
    setInventoryId('')

    if (!productData) return
    if (productData.variant_groups.length === 0) {
      setInventoryId(productData.inventories[0].id)
      setSelectedInventoryData(productData.inventories[0])
    }
  }, [data])

  useEffect(() => {
    if (data) {
      triggerShopProduct({
        id: data.shop_id,
        query: `limit=12&ignore_product_id=${data.id}`,
      })
    }
  }, [data])

  useEffect(() => {
    if (data) {
      triggerSimilarProduct(
        `limit=24&category_ids=${data.category_id}&ignore_product_id=${data.id}`
      )
    }
  }, [data])

  useEffect(() => {
    const productData = data

    if (!productData) return
    switch (productData.variant_groups.length) {
      case 2:
        if (!(firstTypeId && secondTypeId)) return
        ;(productData.inventories as any[]).forEach((val) => {
          if (
            val.first_variant_type.id === firstTypeId &&
            val.second_variant_type.id === secondTypeId
          ) {
            setInventoryId(val.id)
            setSelectedInventoryData(val)
          }
        })
        break
      case 1:
        if (!firstTypeId) return
        ;(productData.inventories as any[]).forEach((val) => {
          if (val.first_variant_type.id === firstTypeId) {
            setInventoryId(val.id)
            setSelectedInventoryData(val)
          }
        })
        break
    }
  }, [firstTypeId, secondTypeId])

  return (
    <Container maxW='6xl'>
      {isLoading && <LoadingSpinner />}
      {error && <ErrorState />}
      {!isLoading && !error && (
        <>
          {data === null ? (
            <ErrorState />
          ) : (
            <>
              <Box boxShadow='lg'>
                <ProductDetailPanel
                  data={data}
                  selectionInput={{
                    firstTypeId,
                    secondTypeId,
                    inventoryId,
                    setInventoryId,
                    setFirstTypeId,
                    setSecondTypeId,
                  }}
                  inventoryData={selectedInventoryData}
                />
              </Box>
              <Box boxShadow='lg' mt={4}>
                <ProductSeller data={data.shop} />
              </Box>
              <Box boxShadow='lg' mt={4}>
                <ProductDescriptionPanel description={data.description} />
              </Box>
              <Box boxShadow='lg' mt={4}>
                <ReviewPanel
                  averageRating={data.average_rating}
                  displayStatus='block'
                />
              </Box>
            </>
          )}
        </>
      )}
      <Box mt={4}>
        <Text fontSize='xl'>FROM THE SAME SHOP</Text>
        <>
          {!resultShop.data ? (
            <LoadingSpinner />
          ) : resultShop.isError ? (
            <ErrorState />
          ) : (
            <>
              {resultShop.data.length === 0 ? (
                <Center>
                  <NoData />
                </Center>
              ) : (
                <Stack direction={'row'} overflowX='auto'>
                  {resultShop.data.data.map((x: any) => (
                    <ProductCard
                      key={x.id}
                      data={x}
                      onClick={() => {
                        navigate(`/products/${x.id}`)
                        dispatch(resetPage())
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                      }}
                    />
                  ))}
                </Stack>
              )}
            </>
          )}
        </>
      </Box>
      <Box mt={4}>
        <Text fontSize='xl'>YOU MAY ALSO LIKE</Text>
        <>
          {!resultSimilarProduct.data ? (
            <LoadingSpinner />
          ) : resultSimilarProduct.isError ? (
            <ErrorState />
          ) : (
            <>
              {resultSimilarProduct.data.length === 0 ? (
                <Center>
                  <NoData />
                </Center>
              ) : (
                <SimpleGrid columns={[2, null, 4, 5]}>
                  {resultSimilarProduct.data.data.map((x: any) => (
                    <ProductCard
                      key={x.id}
                      data={x}
                      onClick={() => {
                        navigate(`/products/${x.id}`)
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                      }}
                    />
                  ))}
                </SimpleGrid>
              )}
            </>
          )}
        </>
      </Box>
    </Container>
  )
}

export default ProductPage
