import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  GridItem,
  Text,
  Grid,
} from '@chakra-ui/react'
import convertToRupiah from '../../helpers/currencyFormat'
import { ShopVoucherCardProps } from './entities'
import { Badge } from '@chakra-ui/react'

const ShopVoucherCard = (props: ShopVoucherCardProps) => {
  const {
    shopVoucher: { code, status, type, amount },
    onClick,
    ...rest
  } = props

  return (
    <Card
      boxShadow='xl'
      borderColor='primary.200'
      backgroundColor='primary.50'
      borderWidth={1}
      onClick={onClick}
      cursor='pointer'
      {...rest}
    >
      <CardHeader>
        <Text fontWeight='extrabold'>{code}</Text>
      </CardHeader>
      <CardBody>
        <Grid templateColumns='3fr 7fr' gap={3} width='full'>
          <GridItem>Status</GridItem>
          <GridItem>
            <Badge
              colorScheme={
                status === 'ONGOING'
                  ? 'green'
                  : status === 'INCOMING'
                  ? 'blue'
                  : 'blackAlpha'
              }
            >
              {status}
            </Badge>
          </GridItem>
          <GridItem>Discount</GridItem>
          <GridItem>
            {type === 'NOMINAL' ? 'Rp' + convertToRupiah(amount) : amount + '%'}
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  )
}

export default ShopVoucherCard
