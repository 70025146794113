import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
} from '@chakra-ui/react'
import React from 'react'
import CustomTextarea from './CustomTextarea'
import { CustomTextareaProps } from './entities'

const StandardTextarea = forwardRef<CustomTextareaProps, 'input'>(
  (props, ref) => {
    const { placeholder, isRequired, isInvalid, errorMessage, label, ...rest } =
      props
    return (
      <FormControl isRequired={isRequired} isInvalid={isInvalid} my={4}>
        {/* Empty placeholder is required for behavior of the label */}
        <FormLabel>{label}</FormLabel>
        <CustomTextarea
          {...rest}
          ref={ref}
          type='text'
          placeholder={placeholder}
        />

        {/* Error message will be displayed if isInvalid is true */}
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    )
  }
)

export default StandardTextarea
