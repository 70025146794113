import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { FaDollarSign, FaMapMarked, FaUserAlt } from 'react-icons/fa'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/sidebar'
import { SidebarProps } from '../components/sidebar/entities'

const ProfileLayout = () => {
  const sidebarItems: SidebarProps = {
    items: [
      {
        title: 'Profile',
        icon: <FaUserAlt style={{ marginRight: '1rem', display: 'inline' }} />,
        items: [
          { ref: '/profile/user', title: 'Profile' },
          { ref: '/profile/password', title: 'Change Password' },
        ],
      },
      {
        title: 'Payment',
        icon: (
          <FaDollarSign style={{ marginRight: '1rem', display: 'inline' }} />
        ),
        items: [
          { ref: '/profile/sealabspay', title: 'Sealabspay Account' },
          { ref: '/profile/wallet', title: 'Wallet' },
        ],
      },
      {
        title: 'Address',
        icon: (
          <FaMapMarked style={{ marginRight: '1rem', display: 'inline' }} />
        ),
        items: [{ ref: '/profile/addresses', title: 'Address' }],
      },
    ],
  }

  return (
    <Flex h='full'>
      <Sidebar items={sidebarItems.items} />
      <Box w='full'>
        <Outlet />
      </Box>
    </Flex>
  )
}

export default ProfileLayout
