import React from 'react'
import CustomModal from '..'
import { ShopVoucherModalProps } from '../entities'
import ShopVoucherSelection from '../../checkout/ShopVoucherSelection'

const ShopVoucherSelectionModal = (props: ShopVoucherModalProps) => {
  const {
    isOpen,
    onClose,
    shopVouchers,
    selectedShopVoucher,
    totalItemPrice,
    evtOnClick,
  } = props
  return (
    <CustomModal
      size='6xl'
      title='Select Shop Voucher'
      body={
        <ShopVoucherSelection
          totalItemPrice={totalItemPrice}
          shopVouchers={shopVouchers}
          selectedShopVoucher={selectedShopVoucher}
          evtOnClick={evtOnClick}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default ShopVoucherSelectionModal
