import React from 'react'
import { HStack, Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import CustomButton from '../../components/buttons'
import { useNavigate } from 'react-router-dom'
import { useGetShopVouchersQuery } from '../../features/shopVouchers/shopVouchersSlice'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { TokenMeta } from '../../features/auth/entities'
import ShopVoucherCard from '../../components/card/ShopVoucherCard'

const ShopVoucherPage = () => {
  const token = Cookies.get('tokenaccess') ?? ''
  const tokenData = jwtDecode<TokenMeta>(token)
  const { data, isError } = useGetShopVouchersQuery({
    shopId: tokenData.data.shop_id,
  })
  const navigate = useNavigate()

  return (
    <Stack w='full' m={3}>
      <HStack justify='space-between'>
        <Heading>Vouchers</Heading>

        <CustomButton onClick={() => navigate('create')}>
          Create New Voucher
        </CustomButton>
      </HStack>

      <SimpleGrid
        minChildWidth={{
          base: 'full',
          sm: '250px',
          md: '500px',
        }}
        gap={5}
        w='full'
      >
        {isError ? (
          <ErrorState />
        ) : !data ? (
          <LoadingSpinner />
        ) : (
          data.data.map((el) => (
            <ShopVoucherCard
              key={el.id}
              shopVoucher={el}
              onClick={() => navigate(el.id)}
            />
          ))
        )}
      </SimpleGrid>
    </Stack>
  )
}

export default ShopVoucherPage
