import { AspectRatio } from '@chakra-ui/react'
import React from 'react'
import { Navigation, Thumbs, Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useGetVouchersQuery } from '../../features/vouchers/vouchersSlice'
import ErrorState from '../errorState'
import CustomImage from '../images'
import LoadingSpinner from '../loadingSpinner'
// swiper style
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const PromotionBanner = () => {
  const { data, error, isLoading } = useGetVouchersQuery({
    status: 'ONGOING',
  })
  return isLoading ? (
    <LoadingSpinner />
  ) : error ? (
    <ErrorState />
  ) : data ? (
    <Swiper
      loop
      spaceBetween={10}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation
      modules={[Navigation, Thumbs, Autoplay, Pagination]}
      grabCursor
      style={{
        zIndex: 0,
        marginTop: '20px',
      }}
    >
      {data?.map((item, index) => (
        <SwiperSlide key={index}>
          <AspectRatio ratio={3 / 1}>
            <CustomImage src={item.banner_url} alt='promotions' w='100%' />
          </AspectRatio>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : null
}

export default PromotionBanner
