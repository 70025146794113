import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { ProductDescriptionPanelProps } from './entities'

const ProductDescriptionPanel = (props: ProductDescriptionPanelProps) => {
  return (
    <Stack bg='white' p={4}>
      <Box w='full' bg='gray.100' p={2}>
        <Text as='b'>Description</Text>
      </Box>
      <Text p={2}>{props.description}</Text>
    </Stack>
  )
}

export default ProductDescriptionPanel
