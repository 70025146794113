import React from 'react'
import {
  SimpleGrid,
  GridItem,
  RadioGroup,
  Radio,
  HStack,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { Controller, useForm } from 'react-hook-form'
import StandardInput from '../inputs/StandardInput'
import CustomForm from '.'
import { ShopVoucherFormInputs } from './entities'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ShopVoucherStatusesEnum, ShopVoucherTypeEnum } from '../../const/index'

const schema = yup.object().shape({
  code: yup.string().min(0).max(5).required('Must be not empty'),
  quota: yup
    .number()
    .typeError('input must be a number')
    .moreThan(0, 'Quota should be greater than 0')
    .required('Must be not empty'),
  amount: yup
    .number()
    .typeError('input must be a number')
    .min(1, 'Amount should be greater than 0')
    .required('Must be not empty'),
  minimumPayment: yup
    .number()
    .notRequired()
    .nullable(true)
    .transform((_, val) => (val ? Number(val) : null)),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), `end date can't be before start date`),
})

const ShopVoucherForm = (props: {
  inputs?: ShopVoucherFormInputs
  userName: string
  onSubmit: (value: ShopVoucherFormInputs) => void
  onReset?: () => void
}) => {
  const { userName, inputs, onSubmit, onReset } = props
  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm<ShopVoucherFormInputs>({ resolver: yupResolver(schema) })
  const today = new Date()
  const onSubmitHandler = (values: ShopVoucherFormInputs) => {
    if (values.startDate > today)
      values.status = ShopVoucherStatusesEnum.INCOMING
    else if (values.endDate < today)
      values.status = ShopVoucherStatusesEnum.ENDED
    else values.status = ShopVoucherStatusesEnum.ONGOING
    values.code = userName + values.code
    onSubmit(values)
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmitHandler)}
      isLoading={isSubmitting}
      submitLabel='Save'
      onReset={onReset}
    >
      <SimpleGrid columns={{ sm: 1, md: 2 }} gap={3} mt={5} alignItems='center'>
        <GridItem>Code: </GridItem>
        <GridItem>
          <InputGroup>
            <InputLeftAddon>{userName.toUpperCase()}</InputLeftAddon>
            <StandardInput
              {...register('code')}
              isReadOnly={!!inputs?.code}
              isInvalid={!!errors.code}
              errorMessage={errors.code?.message}
              placeholder='Code'
              defaultValue={inputs?.code.replace(
                new RegExp(userName, 'gi'),
                ''
              )}
            />
          </InputGroup>
        </GridItem>
        <GridItem>Type: </GridItem>
        <GridItem>
          <Controller
            name='type'
            control={control}
            defaultValue={inputs?.type ?? ShopVoucherTypeEnum.NOMINAL}
            render={({ field: { value, onChange } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <HStack>
                  {Object.entries(ShopVoucherTypeEnum).map((el) => (
                    <Radio key={el[0]} value={el[1]}>
                      {el[0]}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            )}
          />
        </GridItem>
        <GridItem>Quota: </GridItem>
        <GridItem>
          <StandardInput
            {...register('quota')}
            isInvalid={!!errors.quota}
            errorMessage={errors.quota?.message}
            defaultValue={inputs?.quota}
            type='number'
            placeholder='Quota'
            isRequired
          />
        </GridItem>
        <GridItem>Amount: </GridItem>
        <GridItem>
          <StandardInput
            {...register('amount')}
            isInvalid={!!errors.amount}
            errorMessage={errors.amount?.message}
            defaultValue={inputs?.amount}
            type='number'
            placeholder='Amount'
            isRequired
          />
        </GridItem>
        <GridItem>Minimum Payment: </GridItem>
        <GridItem>
          <StandardInput
            {...register('minimumPayment')}
            isInvalid={!!errors.minimumPayment}
            errorMessage={errors.minimumPayment?.message}
            defaultValue={inputs?.minimumPayment}
            type='number'
            placeholder='Minimum Payment'
          />
        </GridItem>
        <GridItem>Start date: </GridItem>
        <GridItem>
          <Controller
            name='startDate'
            control={control}
            defaultValue={inputs?.startDate ?? today}
            render={({ field: { onChange, value } }) => {
              return (
                <SingleDatepicker
                  date={value}
                  onDateChange={onChange}
                  configs={{
                    dateFormat: 'dd-MMM-yyyy',
                  }}
                />
              )
            }}
          />
        </GridItem>
        <GridItem>End date: </GridItem>
        <GridItem>
          <Controller
            name='endDate'
            control={control}
            defaultValue={inputs?.endDate ?? today}
            render={({ field: { onChange, value } }) => {
              return (
                <SingleDatepicker
                  date={value}
                  onDateChange={onChange}
                  configs={{
                    dateFormat: 'dd-MMM-yyyy',
                  }}
                />
              )
            }}
          />
        </GridItem>
      </SimpleGrid>
    </CustomForm>
  )
}

export default ShopVoucherForm
