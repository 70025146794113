import { CloseIcon, AddIcon } from '@chakra-ui/icons'
import * as yup from 'yup'
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PrimaryButton from '../buttons/PrimaryButton'
import FileInput from '../inputs/FileInput'
import RightAddonInput from '../inputs/RightAddonInput'
import StandardInput from '../inputs/StandardInput'
import StandardTextarea from '../inputs/StandardTextArea'
import StepInput from '../inputs/StepInput'
import { ApplyToAllInputs, CreateProductInputs } from './entities'
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from '../../features/products/productsSlice'
import CategoryModal from '../modal/CategoryModal'
import SecondaryButton from '../buttons/SecondaryButton'
import { useGetShopProductCategoriesQuery } from '../../features/shopProductCategories/shopProductCategoriesSlice'
import SearchSelect from '../select/SearchSelect'
import LoadingSpinner from '../loadingSpinner'
import ErrorState from '../errorState'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { useGetSelfShopInformationQuery } from '../../features/shops/shopsSlice'
import CustomImage from '../images'
import CustomForm from '.'

const EditProductForm = (props: { data?: any }) => {
  const { data } = props
  const navigate = useNavigate()
  // States
  const [imageUrls, setImageUrls] = useState<any>([])
  const [images, setImages] = useState<any>([])
  const [imagesErrorMessage, setImagesErrorMessage] = useState<string | null>(
    null
  )
  const [inventoryImagesError, setInventoryImagesError] = useState<
    string | null
  >(null)
  const [inventoryImages, setInventoryImages] = useState<any>([])
  const [inventoryImageIndexEdit, setInventoryImageIndexEdit] = useState(-1)
  const [videoUrl, setVideoUrl] = useState<any>('')
  const [video, setVideo] = useState<any>('')
  const [isPreorder, setIsPreorder] = useState(false)

  // Get user id
  const { data: userData } = useGetProfileQuery()
  const userID = userData?.data.id

  // Get shop id
  const { data: shopData } = useGetSelfShopInformationQuery()
  const shopID = shopData?.data.id

  // Queries and mutations
  const [createProduct] = useCreateProductMutation()
  const [updateProduct] = useUpdateProductMutation()
  const {
    data: shopCategories,
    isLoading: shopCategoriesIsLoading,
    error: shopCategoriesError,
  } = useGetShopProductCategoriesQuery({ userID: userID })

  const schema = yup.object().shape({
    sku: yup.string(),
    title: yup.string().min(4).max(30).required('Must be not empty'),
    description: yup.string().min(4).max(30).required('Must be not empty'),
    is_hazardous: yup.bool().required('Must be not empty'),
    condition: yup
      .string()
      .oneOf(['new', 'used'])
      .required('Must be not empty'),
    status: yup
      .string()
      .oneOf(['active', 'inactive'])
      .required('Must be not empty'),
    weight: yup.number().min(1).max(30).required('Must be not empty'),
    length: yup.number().min(1).max(30).required('Must be not empty'),
    width: yup.number().min(1).max(30).required('Must be not empty'),
    height: yup.number().min(1).max(30).required('Must be not empty'),
    category_id: yup
      .string()
      .uuid('Please input a valid uuid')
      .required('Must be not empty'),
    shop_product_category_id: yup.string(),
    preorder_days: yup.number(),
    variant_groups: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        variant_types: yup.array().of(
          yup.object().shape({
            name: yup.string(),
          })
        ),
      })
    ),
    inventories: yup.array().of(
      yup.object().shape({
        price: yup.number(),
        stock: yup.number(),
        code: yup.string(),
      })
    ),
  })

  // Validators
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateProductInputs>({
    resolver: yupResolver(schema),
    defaultValues: data ?? null,
  })

  const urlToObject = async (url: string) => {
    const response = await fetch(url)
    const re = /(?:\.([^.]+))?$/
    const arrExt = re.exec(url)
    const blob = await response.blob()
    if (arrExt) {
      const file = new File([blob], 'image' + arrExt[0], {
        type: 'image/' + arrExt[1],
      })
      return file
    }
  }

  useEffect(() => {
    setValue('variant_groups', [])

    if (!data) return
    setValue('variant_groups', data.variant_groups)

    // Set inventory images
    const inventoryImages: any[] = []
    data.inventories.forEach((element: any) =>
      inventoryImages.push({
        url: element.product_media?.media_url,
        file: urlToObject(element.product_media?.media_url),
      })
    )

    setInventoryImages(inventoryImages)

    // Set images and video
    const imageUrls: string[] = []
    const images: File[] = []
    data.product_media.forEach((element: any) => {
      if (element.type === 'image') {
        urlToObject(element.media_url).then((file: any) => images.push(file))
        imageUrls.push(element.media_url)
      }
      if (element.type === 'video') {
        urlToObject(element.media_url).then((file: any) => setVideo(file))
        setVideoUrl(element.media_url)
      }
    })

    // Set hazardous material
    setValue('is_hazardous', data.is_hazardous ? 'true' : 'false')

    // Set preorder
    const isPreorder = data.preorder_days >= 7
    setIsPreorder(isPreorder)

    // Set category path
    setCategoryPath(data.category.name)

    // Set status
    setStatus({
      label: data.status[0].toUpperCase() + data?.status.substring(1),
      value: data.status,
    })

    setShopCategoryPath({
      label: data.shop_product_category.name,
      value: data.shop_product_category.id,
    })

    // Set condition
    setCondition({
      label: data.condition[0].toUpperCase() + data.condition.substring(1),
      value: data.condition,
    })

    setValue('inventories', data.inventories)
    setImageUrls(imageUrls)
    setImages(images)
  }, [])

  const { register: registerApplyToAll, getValues: getValuesApplyToAll } =
    useForm<ApplyToAllInputs>()

  // Refs
  const imageInput = useRef<HTMLInputElement>(null)
  const inventoryImageInput = useRef<HTMLInputElement>(null)
  const videoInput = useRef<HTMLInputElement>(null)
  const imagesRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const inventoriesRef = useRef() as React.MutableRefObject<HTMLInputElement>

  // Variations
  const handleActivateVariation = () => {
    setValue('variant_groups', [{ variant_types: [{ name: '' }] }])
    updateInventories()
  }

  const handleActivateSecondVariation = () => {
    setValue('variant_groups', [
      ...getValues('variant_groups'),
      { name: '', variant_types: [{ name: '' }] },
    ])
    updateInventories()
  }

  const handleAddImage = async (e: any) => {
    setImageUrls([...imageUrls, URL.createObjectURL(e.target.files[0])])
    setImages([...images, e.target.files[0]])

    e.target.files = null
  }

  const handleAddInventoryImage = async (e: any) => {
    const imgs = inventoryImages.slice()

    imgs[inventoryImageIndexEdit] = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    }
    e.target.files = null
    setInventoryImages(imgs)
  }

  const handleAddVideo = async (e: any) => {
    setVideoUrl(URL.createObjectURL(e.target.files[0]))
    setVideo(e.target.files[0])

    e.target.value = null
  }

  const handleDeleteImage = (index: number) => {
    const urls = imageUrls.slice()
    const imgs = images.slice()

    urls.splice(index, 1)
    imgs.splice(index, 1)

    setImageUrls(urls)
    setImages(imgs)
  }

  const onSubmit = async (values: CreateProductInputs) => {
    const formData = new FormData()

    // Validate and add images
    if (images.length === 0) {
      setImagesErrorMessage('Please upload at least one image.')
      imagesRef.current.scrollIntoView()
      return
    } else {
      setImagesErrorMessage(null)
      images.forEach((image: File) => formData.append('images', image))
    }

    // Validate inventory images
    const haveNull = inventoryImages.reduce((total: any, current: any) => {
      return total || !current.url
    }, false)
    const isAllNull = inventoryImages.reduce((total: any, current: any) => {
      return total && !current.url
    }, true)

    // images = [{null} {null}] valid
    //            false false
    // images = [{null} {url}] invalid
    //            false true
    // images = [{url} {url}] valid
    //            true true

    if (!isAllNull && haveNull) {
      setInventoryImagesError(
        'Please select a photo for each inventory or none at all.'
      )
      inventoriesRef.current.scrollIntoView()
      return
    } else {
      // Add inventory images
      setInventoryImagesError(null)
      inventoryImages.forEach((element: any) => {
        formData.append('inventoryImages', element.file)
      })
    }

    // Add video
    if (videoUrl) {
      formData.append('video', video)
    }

    Object.keys(values as object).forEach((key) => {
      if (
        typeof (values as any)[key] === 'object' ||
        Array.isArray((values as any)[key])
      ) {
        formData.append(key, JSON.stringify((values as any)[key]))
      } else {
        formData.append(key, (values as any)[key])
      }
    })

    formData.append('shop_id', shopID || '')

    if (data) {
      await updateProduct({ id: data.id, data: formData })
        .unwrap()
        .then(() => {
          toast.success('Success updating product', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
          navigate('/seller/products')
        })
        .catch((err: any) => {
          toast.error(err.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        })
    } else {
      await createProduct({ data: formData })
        .unwrap()
        .then(() => {
          toast.success('Success creating product', {
            position: toast.POSITION.TOP_RIGHT,
          })
          navigate('/seller/products')
        })
        .catch((err: string) =>
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          })
        )
    }
  }

  const handleAddFirstVariationChoice = () => {
    setValue('variant_groups.0.variant_types', [
      ...getValues('variant_groups.0.variant_types'),
      { name: '' },
    ])
    updateInventories()
  }
  const handleAddSecondVariationChoice = () => {
    setValue('variant_groups.1.variant_types', [
      ...getValues('variant_groups.1.variant_types'),
      { name: '' },
    ])
    updateInventories()
  }

  const handleDeleteInventoryImage = (index: number) => {
    const imgs = inventoryImages.slice()

    imgs[index] = { file: null, url: null }

    setInventoryImages(imgs)
  }

  const handleDeactivateFirstVariation = () => {
    setValue('variant_groups', getValues('variant_groups').slice(1))
    updateInventories()
  }

  const handleDeactivateSecondVariation = () => {
    setValue('variant_groups', getValues('variant_groups').slice(0, 1))
    updateInventories()
  }

  const removeFirstVariantChoice = (index: number) => {
    const variantTypes = getValues('variant_groups.0.variant_types').slice()
    variantTypes.splice(index, 1)
    setValue('variant_groups.0.variant_types', variantTypes)
    updateInventories()
  }

  const removeSecondVariantChoice = (index: number) => {
    const variantTypes = getValues('variant_groups.1.variant_types').slice()
    variantTypes.splice(index, 1)
    setValue('variant_groups.1.variant_types', variantTypes)
    updateInventories()
  }

  const handleApplyToAll = () => {
    let length: number

    switch (getValues('variant_groups').length) {
      case 2:
        length =
          getValues('variant_groups.0.variant_types').length *
          getValues('variant_groups.1.variant_types').length
        break

      case 1:
        length = getValues('variant_groups.0.variant_types').length
        break
      case 0:
        length = 1
        break

      default:
        length = 1
        break
    }

    const inventories = Array.from(
      {
        length: length,
      },
      () => ({
        price: getValuesApplyToAll('price'),
        stock: getValuesApplyToAll('stock'),
        code: getValuesApplyToAll('code'),
      })
    ) as any
    setValue('inventories', inventories)
  }

  const updateInventories = () => {
    let inventories: Array<any>

    switch (watch('variant_groups').length) {
      case 2: {
        let firstVariantTypesLength = watch(
          'variant_groups.0.variant_types'
        ).length
        let secondVariantTypesLength = watch(
          'variant_groups.1.variant_types'
        ).length

        if (firstVariantTypesLength === 0) {
          firstVariantTypesLength = 1
        }
        if (secondVariantTypesLength === 0) {
          secondVariantTypesLength = 1
        }
        inventories = Array.from(
          {
            length: firstVariantTypesLength * secondVariantTypesLength,
          },
          () => ({ code: null, price: null, stock: null })
        )
        break
      }

      case 1: {
        let firstVariantTypesLength = watch(
          'variant_groups.0.variant_types'
        ).length
        if (firstVariantTypesLength === 0) {
          firstVariantTypesLength = 1
        }
        inventories = Array.from(
          {
            length: firstVariantTypesLength,
          },
          () => ({ code: null, price: null, stock: null })
        )
        break
      }

      case 0: {
        inventories = Array.from(
          {
            length: 1,
          },
          () => ({ code: null, price: null, stock: null })
        )
        break
      }
      default:
        inventories = []
        break
    }

    const inventoryImages = Array.from(
      {
        length: inventories.length,
      },
      () => ({ file: null, url: null })
    )
    setValue('inventories', inventories)
    setInventoryImages(inventoryImages)
  }

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()

  const [status, setStatus] = useState<any>(null)
  const [categoryPath, setCategoryPath] = useState<any>('')
  const [shopCategoryPath, setShopCategoryPath] = useState<any>(null)
  const [condition, setCondition] = useState<any>(null)
  const setCategory = (id: string) => {
    setValue('category_id', id)
    onModalClose()
  }

  const handleOnClickInventoryImage = (index: number) => {
    setInventoryImageIndexEdit(index)
    inventoryImageInput?.current?.click()
  }

  const handleChangeStatus = (e: any) => {
    setStatus(e)
    setValue('status', e.value)
  }

  const handleChangePreorderDays = (valueAsString: string) => {
    setValue('preorder_days', valueAsString)
  }

  const handleChangeIsHazardous = (e: any) => {
    setValue('is_hazardous', e)
  }

  const handleChangeShopCategory = (e: any) => {
    setShopCategoryPath(e)
    setValue('shop_product_category_id', e.value)
  }

  const handleChangeCondition = (e: any) => {
    setCondition(e)
    setValue('condition', e.value)
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      submitLabel={data ? 'Update Product' : 'Create Product'}
      isLoading={isSubmitting}
    >
      <CategoryModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        setCategory={setCategory}
        setPathString={(path: string) => setCategoryPath(path)}
      />
      <Box boxShadow='base' rounded='md' p='8'>
        <Heading as='h1' size='lg'>
          Product Information
        </Heading>
        <FormControl isRequired isInvalid={imagesErrorMessage != null} mt='8px'>
          <FormLabel>Product Images</FormLabel>
          <HStack overflow={'scroll'}>
            {imageUrls.map((element: any, index: number) => (
              <Box key={index} minW='100px' minH='100px' position='relative'>
                <IconButton
                  position='absolute'
                  zIndex={1}
                  bottom={0}
                  isDisabled={images.length === 1}
                  aria-label='Delete image'
                  onClick={() => handleDeleteImage(index)}
                  size='sm'
                  icon={<CloseIcon />}
                />
                <CustomImage
                  boxSize='100px'
                  objectFit='cover'
                  borderRadius={'8px'}
                  src={element}
                  alt='product image'
                />
              </Box>
            ))}
            {imageUrls.length < 9 && (
              <IconButton
                minW={'100px'}
                minH={'100px'}
                onClick={() => imageInput?.current?.click()}
                aria-label='Add Image'
                colorScheme='primary'
                icon={<AddIcon />}
              />
            )}
          </HStack>
          <FileInput
            display='none'
            ref={imageInput}
            accept='.png,.jpg,.webp'
            onChange={handleAddImage}
          />
          <FormErrorMessage>{imagesErrorMessage}</FormErrorMessage>
        </FormControl>
        <FormControl mt='8px'>
          <FormLabel>Product Video</FormLabel>
          {videoUrl ? (
            <Box position={'relative'}>
              <IconButton
                position='absolute'
                zIndex={1}
                top={0}
                aria-label='Delete image'
                onClick={() => {
                  setVideo(null)
                  setVideoUrl(null)
                }}
                size='sm'
                icon={<CloseIcon />}
              />
              <Box
                as='video'
                borderRadius={8}
                controls
                src={videoUrl}
                objectFit='contain'
                sx={{
                  aspectRatio: '16/9',
                }}
              />
            </Box>
          ) : (
            <IconButton
              minW={'100px'}
              minH={'100px'}
              onClick={() => videoInput.current?.click()}
              aria-label='Add Video'
              colorScheme='primary'
              icon={<AddIcon />}
            />
          )}
          <FileInput
            display='none'
            ref={videoInput}
            accept='.mp4'
            onChange={handleAddVideo}
          />
        </FormControl>
        <Box mt={8}>
          <StandardInput
            {...register('title')}
            isInvalid={!!errors.title}
            errorMessage={errors.title?.message}
            isRequired
            label='Product Name'
          />
        </Box>
        <Box mt={8}>
          <SearchSelect
            control={control}
            name='status'
            value={status}
            label='Product Status'
            isInvalid={!!errors.status}
            isRequired
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
            placeholder='Product Status'
            onChange={handleChangeStatus}
          />
        </Box>
        <StandardInput
          value={categoryPath}
          isInvalid={!!errors.category_id}
          errorMessage={errors.category_id?.message}
          isRequired
          isReadOnly={true}
          onClick={onModalOpen}
          label='Product Category'
        />
        {shopCategoriesIsLoading && <LoadingSpinner />}
        {shopCategoriesError && <ErrorState />}
        {!shopCategoriesIsLoading && !shopCategoriesError && (
          <SearchSelect
            control={control}
            value={shopCategoryPath}
            name='shop_product_category_id'
            label='Shop Product Category'
            isInvalid={!!errors.shop_product_category_id}
            options={shopCategories.map((element: any) => ({
              label: element.name,
              value: element.id,
            }))}
            placeholder='Product Category'
            onChange={handleChangeShopCategory}
          />
        )}
        <StandardTextarea
          {...register('description')}
          isInvalid={!!errors.description}
          errorMessage={errors.description?.message}
          isRequired
          label='Product Description'
          placeholder='Short description about your product...'
        />
      </Box>
      <Box mt='16px' boxShadow='base' rounded='md' p='8'>
        <Heading as='h1' size='lg'>
          Inventory Information
        </Heading>
        {watch('variant_groups', []).length === 0 && (
          <FormControl isRequired mt={4}>
            <FormLabel>Variation</FormLabel>
            <PrimaryButton onClick={() => handleActivateVariation()}>
              Activate Variation
            </PrimaryButton>
          </FormControl>
        )}

        {watch('variant_groups', []).length > 0 ? (
          <Box maxW='6xl'>
            {watch('variant_groups', []).length >= 1 && (
              <Container maxW='6xl'>
                <FormControl isRequired mt={4}>
                  <Flex>
                    <Heading as={'h3'} size='md'>
                      First Variation
                    </Heading>
                    <Spacer />
                    <IconButton
                      onClick={handleDeactivateFirstVariation}
                      aria-label='Close'
                      icon={<CloseIcon />}
                    ></IconButton>
                  </Flex>
                  <StandardInput
                    {...register('variant_groups.0.name')}
                    label='First Variant Name'
                    isRequired
                  />
                  <FormLabel>Variation Type Names</FormLabel>
                  {watch('variant_groups.0.variant_types', []).map(
                    (element: any, index: number) => (
                      <HStack key={index}>
                        <StandardInput
                          type='text'
                          label='Variant Type Name'
                          isRequired
                          {...register(
                            `variant_groups.0.variant_types.${index}.name`
                          )}
                        />
                        {watch('variant_groups.0.variant_types', []).length >
                          1 && (
                          <PrimaryButton
                            onClick={() => removeFirstVariantChoice(index)}
                          >
                            Delete
                          </PrimaryButton>
                        )}
                      </HStack>
                    )
                  )}
                  <PrimaryButton onClick={handleAddFirstVariationChoice}>
                    Add Choice
                  </PrimaryButton>
                </FormControl>
                {watch('variant_groups', []).length < 2 ? (
                  <FormControl maxW='6xl'>
                    <SecondaryButton
                      mt='8px'
                      onClick={handleActivateSecondVariation}
                    >
                      Add Second Variation
                    </SecondaryButton>
                  </FormControl>
                ) : (
                  <FormControl isRequired mt={4}>
                    <Flex>
                      <Heading as={'h3'} size='md'>
                        Second Variation
                      </Heading>
                      <Spacer />
                      <IconButton
                        onClick={handleDeactivateSecondVariation}
                        aria-label='Close'
                        icon={<CloseIcon />}
                      ></IconButton>
                    </Flex>
                    <StandardInput
                      type='text'
                      label='Variation Name'
                      isRequired
                      {...register(`variant_groups.1.name`)}
                    />
                    <FormLabel>Variant Type Names</FormLabel>
                    {watch('variant_groups.1.variant_types', []).map(
                      (element: any, index: number) => (
                        <HStack key={index}>
                          <StandardInput
                            type='text'
                            label='Variant Type Name'
                            isRequired
                            {...register(
                              `variant_groups.1.variant_types.${index}.name`
                            )}
                          />
                          {watch('variant_groups.1.variant_types', []).length >
                            1 && (
                            <PrimaryButton
                              onClick={() => removeSecondVariantChoice(index)}
                            >
                              Delete
                            </PrimaryButton>
                          )}
                        </HStack>
                      )
                    )}
                    <PrimaryButton onClick={handleAddSecondVariationChoice}>
                      Add Choice
                    </PrimaryButton>
                  </FormControl>
                )}
              </Container>
            )}
            <Box p='16px'>
              <Heading as={'h3'} size='sm'>
                Variation List
              </Heading>
              <Grid templateColumns='repeat(3, 1fr)' gap={16} mt='16px'>
                <GridItem w='100%'>
                  <StandardInput
                    {...registerApplyToAll('price')}
                    label='Price'
                    type='number'
                  />
                </GridItem>
                <GridItem w='100%'>
                  <StandardInput
                    {...registerApplyToAll('stock')}
                    label='Stock'
                    type='number'
                    min={0}
                  />
                </GridItem>
                <GridItem w='100%'>
                  <StandardInput
                    {...registerApplyToAll('code')}
                    label='Code'
                    type='text'
                  />
                </GridItem>
              </Grid>
              <Button onClick={() => handleApplyToAll()}>Apply to all</Button>
            </Box>
            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    {getValues('variant_groups').length >= 1 && (
                      <Th>
                        {getValues('variant_groups.0.name') || 'Variation 1'}
                      </Th>
                    )}
                    {getValues('variant_groups').length >= 2 && (
                      <Th>
                        {getValues('variant_groups.1.name') || 'Variation 2'}
                      </Th>
                    )}
                    <Th>Price</Th>
                    <Th>Stock</Th>
                    <Th>Code</Th>
                    <Th>Image</Th>
                  </Tr>
                </Thead>

                {getValues('variant_groups').length === 2 && (
                  <Tbody>
                    {getValues('variant_groups.0.variant_types').map(
                      (firstVariation: any, firstIndex: number) =>
                        getValues('variant_groups.1.variant_types').map(
                          (secondVariation: any, secondIndex: number) => (
                            <Tr
                              key={
                                firstIndex *
                                  getValues('variant_groups.1.variant_types')
                                    .length +
                                secondIndex
                              }
                            >
                              <Td>{firstVariation.name}</Td>
                              <Td>{secondVariation.name}</Td>
                              <Td>
                                <StandardInput
                                  type='number'
                                  {...register(
                                    `inventories.${
                                      firstIndex *
                                        getValues(
                                          'variant_groups.1.variant_types'
                                        ).length +
                                      secondIndex
                                    }.price`,
                                    {
                                      valueAsNumber: true,
                                    }
                                  )}
                                  isRequired
                                  label='Price'
                                />
                              </Td>
                              <Td>
                                <StandardInput
                                  type='number'
                                  {...register(
                                    `inventories.${
                                      firstIndex *
                                        getValues(
                                          'variant_groups.1.variant_types'
                                        ).length +
                                      secondIndex
                                    }.stock`,
                                    {
                                      valueAsNumber: true,
                                    }
                                  )}
                                  isRequired
                                  label='Stock'
                                />
                              </Td>
                              <Td>
                                <StandardInput
                                  type='text'
                                  {...register(
                                    `inventories.${
                                      firstIndex *
                                        getValues(
                                          'variant_groups.1.variant_types'
                                        ).length +
                                      secondIndex
                                    }.code`
                                  )}
                                  isRequired
                                  label='Code'
                                />
                              </Td>
                              <Td>
                                {inventoryImages[
                                  firstIndex *
                                    getValues('variant_groups.1.variant_types')
                                      .length +
                                    secondIndex
                                ]?.url ? (
                                  <Box position={'relative'} minW='100px'>
                                    <IconButton
                                      position='absolute'
                                      zIndex={1}
                                      top={0}
                                      aria-label='Delete image'
                                      onClick={() => {
                                        handleDeleteInventoryImage(
                                          firstIndex *
                                            getValues(
                                              'variant_groups.1.variant_types'
                                            ).length +
                                            secondIndex
                                        )
                                      }}
                                      size='sm'
                                      icon={<CloseIcon />}
                                    />
                                    <Image
                                      boxSize='100px'
                                      objectFit='cover'
                                      src={
                                        inventoryImages[
                                          firstIndex *
                                            watch(
                                              'variant_groups.1.variant_types'
                                            ).length +
                                            secondIndex
                                        ]?.url
                                      }
                                      alt='product image'
                                    />
                                  </Box>
                                ) : (
                                  <Box>
                                    <IconButton
                                      minW={'100px'}
                                      minH={'100px'}
                                      onClick={() =>
                                        handleOnClickInventoryImage(
                                          firstIndex *
                                            getValues(
                                              'variant_groups.1.variant_types'
                                            ).length +
                                            secondIndex
                                        )
                                      }
                                      aria-label='Add Image'
                                      colorScheme='primary'
                                      icon={<AddIcon />}
                                    />
                                  </Box>
                                )}
                              </Td>
                            </Tr>
                          )
                        )
                    )}
                  </Tbody>
                )}

                {getValues('variant_groups').length === 1 && (
                  <Tbody>
                    {getValues('variant_groups.0.variant_types').map(
                      (variation: any, index: number) => (
                        <Tr key={index}>
                          <Td>{variation.name}</Td>
                          <Td>
                            <StandardInput
                              type='number'
                              isRequired
                              {...register(`inventories.${index}.price`, {
                                valueAsNumber: true,
                              })}
                              label='Price'
                            />
                          </Td>
                          <Td>
                            <StandardInput
                              type='number'
                              isRequired
                              {...register(`inventories.${index}.stock`, {
                                valueAsNumber: true,
                              })}
                              label='Stock'
                            />
                          </Td>
                          <Td>
                            <StandardInput
                              type='text'
                              isRequired
                              {...register(`inventories.${index}.code`)}
                              label='Code'
                            />
                          </Td>
                          <Td>
                            {inventoryImages[index]?.url ? (
                              <Box position={'relative'} minW='100px'>
                                <IconButton
                                  position='absolute'
                                  zIndex={1}
                                  top={0}
                                  aria-label='Delete image'
                                  onClick={() => {
                                    handleDeleteInventoryImage(index)
                                  }}
                                  size='sm'
                                  icon={<CloseIcon />}
                                />
                                <Image
                                  boxSize='100px'
                                  objectFit='cover'
                                  src={inventoryImages[index]?.url}
                                  alt='product image'
                                />
                              </Box>
                            ) : (
                              <Box>
                                <IconButton
                                  minW={'100px'}
                                  minH={'100px'}
                                  onClick={() =>
                                    handleOnClickInventoryImage(index)
                                  }
                                  aria-label='Add Image'
                                  colorScheme='primary'
                                  icon={<AddIcon />}
                                />
                              </Box>
                            )}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
            <FormControl isInvalid={inventoryImagesError !== ''}>
              <FormErrorMessage>{inventoryImagesError}</FormErrorMessage>
            </FormControl>
            <FileInput
              display='none'
              ref={inventoryImageInput}
              accept='.png,.jpg,.webp'
              onChange={handleAddInventoryImage}
            />
          </Box>
        ) : (
          <Box>
            <StandardInput
              {...register('inventories.0.price', {
                valueAsNumber: true,
              })}
              label='Price'
              type='number'
              isRequired
            />
            <StandardInput
              {...register('inventories.0.stock', {
                valueAsNumber: true,
              })}
              label='Stock'
              type='number'
              isRequired
            />
            <StandardInput
              {...register('inventories.0.code')}
              label='Code'
              type='text'
              isRequired
            />
          </Box>
        )}
      </Box>

      <Box mt='16px' boxShadow='base' rounded='md' p='8'>
        <Heading as='h1' size='lg'>
          Shipping
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap={3}>
          <RightAddonInput
            {...register('weight')}
            isInvalid={!!errors.weight}
            errorMessage={errors.weight?.message}
            label='Weight'
            type='number'
            isRequired
            rightAddon={'gr'}
          />
          <RightAddonInput
            {...register('length')}
            isInvalid={!!errors.length}
            errorMessage={errors.length?.message}
            label='Length'
            type='number'
            isRequired
            rightAddon={'cm'}
          />
          <RightAddonInput
            {...register('width')}
            isInvalid={!!errors.width}
            errorMessage={errors.width?.message}
            label='Width'
            type='number'
            isRequired
            rightAddon={'cm'}
          />
          <RightAddonInput
            {...register('height')}
            isInvalid={!!errors.height}
            errorMessage={errors.height?.message}
            label='Height'
            type='number'
            isRequired
            rightAddon={'cm'}
          />
        </SimpleGrid>

        <FormControl isRequired isInvalid={!!errors.is_hazardous}>
          <FormLabel>Hazardous Material</FormLabel>
          <RadioGroup
            onChange={handleChangeIsHazardous}
            value={watch('is_hazardous')}
          >
            <Stack direction='row'>
              <Radio value='false' defaultChecked>
                No
              </Radio>
              <Radio value='true'>
                Contains battery / magnet / liquid / flammables
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage>{errors.is_hazardous?.message}</FormErrorMessage>
        </FormControl>
      </Box>
      <Box mt='16px' boxShadow='base' rounded='md' p='8'>
        <Heading as='h1' size='lg'>
          Others
        </Heading>
        <FormControl>
          <FormLabel>Preorder</FormLabel>
          <VStack align={'start'}>
            <RadioGroup
              value={isPreorder ? 'true' : 'false'}
              onChange={(e) => setIsPreorder(e === 'true')}
            >
              <Stack direction='row'>
                <Radio value='false'>No</Radio>
                <Radio value='true'>Yes</Radio>
              </Stack>
            </RadioGroup>
            {isPreorder && (
              <StepInput
                handleChange={handleChangePreorderDays}
                {...register('preorder_days')}
                minValue={7}
                maxValue={30}
                defaultValue={data?.preorder_days}
              />
            )}
          </VStack>
        </FormControl>
        <SearchSelect
          control={control}
          name='condition'
          value={condition}
          label='Condition'
          isInvalid={!!errors.condition}
          isRequired
          options={[
            {
              label: 'New',
              value: 'new',
            },
            {
              label: 'Used',
              value: 'used',
            },
          ]}
          placeholder='Condition'
          onChange={handleChangeCondition}
        />
        <StandardInput
          {...register('sku')}
          isInvalid={!!errors.sku}
          errorMessage={errors.sku?.message}
          label='SKU'
        />
      </Box>
    </CustomForm>
  )
}

export default EditProductForm
