import { Box, Container, Flex, SimpleGrid } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../app/store'
import ErrorState from '../../components/errorState'
import FilterSearhPage from '../../components/filter/filterSearhPage'
import LoadingSpinner from '../../components/loadingSpinner'
import NoData from '../../components/noData'
import ProductCard from '../../components/productCard'
import { ProductCardData as ProductCardEntities } from '../../components/productCard/entities'
import SortSearchPage from '../../components/sort/sortSearchPage'
import { useLazyGetSearchProductQuery } from '../../features/products/productsSlice'
import Pagination from 'react-responsive-pagination'
import './index.css'
import { setSortAndSortBy } from '../../features/sortFilter/sortFilterSlice'

function SearchPage() {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('query')
  const {
    sort,
    sort_by,
    location_query,
    category_query,
    min_price,
    max_price,
    review,
  } = useSelector((state: RootState) => state.sortFilterReducer)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const [fullQuery, setFullQuery] = useState('')
  const [trigger, { data, isError, isLoading }] =
    useLazyGetSearchProductQuery(fullQuery)

  useEffect(() => {
    dispatch(setSortAndSortBy('{"sort_by":"favorite_count", "sort":"desc"}'))
    trigger()
  }, [])

  useEffect(() => {
    trigger(fullQuery)
  }, [fullQuery])

  useEffect(() => {
    queryBuilder(1)
  }, [
    sort,
    sort_by,
    location_query,
    category_query,
    min_price,
    max_price,
    review,
    page,
  ])

  const queryBuilder = (status: number) => {
    if (status === 0) {
      return
    }

    let combinedQuery = ''

    if (query) {
      combinedQuery += `&search=${encodeURI(query)}`
    }

    if (sort) {
      combinedQuery += `&sort=${sort}`
    }

    if (sort_by) {
      combinedQuery += `&sort_by=${sort_by}`
    }

    if (page) {
      combinedQuery += `&page=${page}`
    }

    if (location_query) {
      for (let i = 0; i < location_query.length; i++) {
        if (i === 0) {
          combinedQuery += `&locations=${encodeURI(location_query[i].name)}`
        } else {
          combinedQuery += `,${encodeURI(location_query[i].name)}`
        }
      }
    }

    if (category_query.id) {
      combinedQuery += `&category_ids=${category_query.id}`
    }

    if (min_price > 0) {
      combinedQuery += `&min_price=${min_price}`
    }

    if (max_price > 0) {
      combinedQuery += `&max_price=${max_price}`
    }

    if (review > 0) {
      combinedQuery += `&min_rating=${review}`
    }

    setFullQuery(combinedQuery)
  }

  const handlePageClick = (pageInput: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setPage(pageInput)
  }

  const navigate = useNavigate()

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <ErrorState />
      ) : (
        <>
          <Container maxW='6xl'>
            <Flex
              width='100%'
              justifyContent='space-between'
              alignItems='center'
            >
              <SortSearchPage
                callback={(number) => {
                  queryBuilder(number)
                }}
              />
              <FilterSearhPage
                callbackReset={() => {
                  queryBuilder(1)
                }}
              />
            </Flex>
            {!data?.data ? (
              <Box mt={4}>
                <NoData />
              </Box>
            ) : (
              <>
                {' '}
                <SimpleGrid columns={[2, null, 4, 5]}>
                  {data?.data.map((val: ProductCardEntities) => (
                    <ProductCard
                      data={val}
                      key={val.id}
                      onClick={() => navigate(`/products/${val.id}`)}
                    />
                  ))}
                </SimpleGrid>
                <Pagination
                  total={data.total_pages}
                  ariaPreviousLabel={' '}
                  ariaNextLabel={' '}
                  a11yActiveLabel={' '}
                  ariaCurrentAttr={true}
                  current={page}
                  onPageChange={(page) => handlePageClick(page)}
                  className={'navigationButtons'}
                />
              </>
            )}
          </Container>
        </>
      )}
    </>
  )
}

export default SearchPage
