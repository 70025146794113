import { ArrowRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Icon,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetProductReviewQuery,
  useLazyGetProductReviewQuery,
} from '../../features/reviews/reviewsSlice'
import { ReviewProps } from '../card/entities'
import ReviewCard from '../card/reviewCard'
import ErrorState from '../errorState'
import LoadingSpinner from '../loadingSpinner'
import NoReview from '../noReview'
import './reviewPanel.css'
import Pagination from 'react-responsive-pagination'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { setPage } from '../../features/reviewPages/reviewPagesSlice'
import ImageModal from '../modal/ImageModal'
import CustomImage from '../images'
import SortFilterRating from '../sort/sortFilterRating'
import {
  resetRating,
  setSortAndSortBy,
} from '../../features/sortFilter/sortFilterSlice'

function ReviewPanel(props: {
  averageRating: number
  displayStatus: string
  displayPagination?: string[]
}) {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { sort, sort_by, rating } = useSelector(
    (state: RootState) => state.sortFilterReducer
  )

  const { page } = useSelector((state: RootState) => state.reviewPagesReducer)

  const reviewRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const handlePageClick = (page: number) => {
    reviewRef.current.scrollIntoView()
    dispatch(setPage(page))
  }

  const id: string = params.productId ?? ''
  const [fullQuery, setFullQuery] = useState(id)

  const [trigger, data] = useLazyGetProductReviewQuery()

  const {
    data: review,
    error: reviewError,
    isLoading: isReviewLoading,
  } = useGetProductReviewQuery(id)

  useEffect(() => {
    const querystring = `{"sort_by":"created_at", "sort":"desc"}`
    dispatch(setSortAndSortBy(querystring))
    dispatch(resetRating())
  }, [])

  useEffect(() => {
    trigger(id)
  }, [id])

  useEffect(() => {
    trigger(fullQuery)
  }, [fullQuery])

  useEffect(() => {
    queryBuilder()
  }, [page, sort, sort_by, rating])

  const queryBuilder = () => {
    let combinedQuery = ''
    combinedQuery += id
    combinedQuery += `?page=${page}`

    if (sort) {
      combinedQuery += `&sort=${sort}`
    }

    if (sort_by) {
      combinedQuery += `&sort_by=${sort_by}`
    }

    if (rating != '') {
      combinedQuery += `&filter=${rating}`
    }

    setFullQuery(combinedQuery)
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedImage, setSelectedImage] = useState<string>('')

  const handleImageClick = (url: string) => {
    setSelectedImage(url)
    onOpen()
  }

  const countRatingPercentage = (star: number) => {
    if (!isReviewLoading && !reviewError) {
      const countPercentage =
        (review.data.data.ratings[star] / review.data.total_items) * 100

      return countPercentage
    } else {
      return
    }
  }

  return (
    <Stack bg='white' p={4}>
      <Box w='full' bg='gray.100' p={2} ref={reviewRef}>
        <Text as='b'>Product review</Text>
      </Box>
      <ImageModal
        isOpen={isOpen}
        onClose={onClose}
        body={
          <CustomImage
            src={selectedImage}
            _hover={{ transform: 'scale(1.5)' }}
          />
        }
      />
      <Box w='full' p={2}>
        {data.isLoading || isReviewLoading ? (
          <LoadingSpinner />
        ) : data.isError || reviewError ? (
          <ErrorState />
        ) : !data.data?.data ? (
          <NoReview />
        ) : (
          <>
            <Flex
              alignItems='center'
              gap='5px'
              paddingLeft={1}
              paddingRight={2}
            >
              <Icon as={AiFillStar} fontSize='3xl' color='yellow.300' />
              <Flex w='full'>
                <Box className='container' w={['100%', '100%', '40%']}>
                  <Text fontSize='4xl'>
                    {(Math.round(props.averageRating * 100) / 100).toFixed(1)}
                  </Text>
                  <Text fontSize='md' alignSelf='flex-end'>
                    /5 from {review.data.total_items} review(s)
                  </Text>
                  <Box display={props.displayStatus}>
                    <button
                      className='buttonSeeMore'
                      onClick={() => {
                        navigate(`/reviews/${id}`)
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                      }}
                    >
                      see more &#8202;
                      <ArrowRightIcon boxSize='2.5' />
                    </button>
                  </Box>
                </Box>
                <Box w='60%' display={['none', 'none', 'block']}>
                  <SimpleGrid columns={3} gap='10px'>
                    <Flex alignItems='center' gap='5px' w='full'>
                      <Icon as={AiFillStar} fontSize='sm' color='yellow.300' />
                      <Text>1</Text>
                      <Progress
                        w='sm'
                        colorScheme='primary'
                        value={countRatingPercentage(0)}
                        mt='1'
                        size='sm'
                        rounded='lg'
                      />
                    </Flex>
                    <Flex alignItems='center' gap='5px' w='full'>
                      <Icon as={AiFillStar} fontSize='sm' color='yellow.300' />
                      <Text>2</Text>
                      <Progress
                        w='sm'
                        colorScheme='primary'
                        value={countRatingPercentage(1)}
                        mt='1'
                        size='sm'
                        rounded='lg'
                      />
                    </Flex>
                    <Flex alignItems='center' gap='5px' w='full'>
                      <Icon as={AiFillStar} fontSize='sm' color='yellow.300' />
                      <Text>3</Text>
                      <Progress
                        w='sm'
                        colorScheme='primary'
                        value={countRatingPercentage(2)}
                        mt='1'
                        size='sm'
                        rounded='lg'
                      />
                    </Flex>
                    <Flex alignItems='center' gap='5px' w='full'>
                      <Icon as={AiFillStar} fontSize='sm' color='yellow.300' />
                      <Text>4</Text>
                      <Progress
                        w='sm'
                        colorScheme='primary'
                        value={countRatingPercentage(3)}
                        mt='1'
                        size='sm'
                        rounded='lg'
                      />
                    </Flex>
                    <Flex alignItems='center' gap='5px' w='full'>
                      <Icon as={AiFillStar} fontSize='sm' color='yellow.300' />
                      <Text>5</Text>
                      <Progress
                        w='sm'
                        colorScheme='primary'
                        value={countRatingPercentage(4)}
                        mt='1'
                        size='sm'
                        rounded='lg'
                      />
                    </Flex>
                  </SimpleGrid>
                </Box>
              </Flex>
            </Flex>
            <SortFilterRating></SortFilterRating>
            <Box mt='2'>
              {!data.data.data.data.reviews ? (
                <NoReview />
              ) : (
                data.data.data.data.reviews.map((x: ReviewProps) => (
                  <Box key={x.id}>
                    <ReviewCard
                      {...x}
                      backgroundColor='primary.50'
                      mb='2'
                      cardclick={(image_url) => {
                        handleImageClick(image_url)
                      }}
                    />
                  </Box>
                ))
              )}
            </Box>
            <Box
              mt='5'
              display={props.displayPagination ?? ['none', 'none', 'block']}
            >
              <Pagination
                total={data.data.data.total_pages}
                ariaPreviousLabel={' '}
                ariaNextLabel={' '}
                a11yActiveLabel={' '}
                ariaCurrentAttr={true}
                current={page}
                onPageChange={(page) => handlePageClick(page)}
                className={'navigationButtons'}
              />
            </Box>
          </>
        )}
      </Box>
    </Stack>
  )
}

export default ReviewPanel
