import React from 'react'
import CustomModal from '.'
import SetAddressForm from '../form/SetAddressForm'

import { AddressModalProps } from './entities'

const AddressModal = (props: AddressModalProps) => {
  const { isOpen, onClose, address } = props

  return (
    <CustomModal
      size='6xl'
      title='Manage Address'
      body={<SetAddressForm defaultVal={address} onClose={onClose} />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default AddressModal
