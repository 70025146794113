import React, { useEffect, useState } from 'react'
import CustomForm from '.'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import StandardInput from '../inputs/StandardInput'
import { HStack, Heading, Switch } from '@chakra-ui/react'
import { SetAddressFormInputs } from './entities'
import { OptionsGroup } from '../select/entities'
import SearchSelect from '../select/SearchSelect'
import {
  useGetProvincesQuery,
  useLazyGetCitiesQuery,
  useLazyGetSubditrictsQuery,
} from '../../features/location/locationSlice'
import {
  useCreateUserAddressMutation,
  useDeleteUserAddressMutation,
  useSetDefaultAddressMutation,
  useSetDefaultShopAddressMutation,
  useUpdateUserAddressMutation,
} from '../../features/address/address'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  name: yup.string().min(3, 'complete address must be at least 3 characters'),
  label: yup.string().min(3),
  recipient_phone_number: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Invalid phone number'
    ),
  province_id: yup.string().required('Province is required'),
  city_id: yup.string().required('City is required'),
  subdistrict_id: yup.string().required('Subdistrict is required'),
})

const SetAddressForm = (props: { defaultVal?: any; onClose?: () => void }) => {
  const { defaultVal, onClose } = props
  const { data: provinces } = useGetProvincesQuery()

  const provinceGroups: OptionsGroup[] | undefined = provinces?.data?.map(
    (el) => {
      const option: OptionsGroup = { label: el.name, value: el.id.toString() }
      return option
    }
  )
  const [getCities, { data: cities }] = useLazyGetCitiesQuery()
  const [getSubdistrict, { data: subdistricts }] = useLazyGetSubditrictsQuery()
  const [createAddress] = useCreateUserAddressMutation()
  const [updateAddress] = useUpdateUserAddressMutation()
  const [updateDefaultAddress] = useSetDefaultAddressMutation()
  const [updateDefaultShopAddress] = useSetDefaultShopAddressMutation()
  const [deleteAddress] = useDeleteUserAddressMutation()

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    resetField,
  } = useForm<SetAddressFormInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: any) => {
    const apiCall = defaultVal
      ? updateAddress({
          ...values,
          id: defaultVal.id,
          subdistrict_id: Number(values.subdistrict_id),
          province_id: Number(values.province_id),
          city_id: Number(values.city_id),
        })
      : createAddress({
          ...values,
          subdistrict_id: Number(values.subdistrict_id),
          province_id: Number(values.province_id),
          city_id: Number(values.city_id),
        })

    toast
      .promise(apiCall.unwrap(), {
        pending: 'loading...',
        success: `Success ${defaultVal ? 'edit' : 'create new'} address`,
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
      .then((data) => {
        values.defaultAddress &&
          updateDefaultAddress({ address_id: data.data.id })
        values.defaultShopAddress &&
          updateDefaultShopAddress({ shop_location_id: data.data.id })
      })
      .then(() => window.location.replace('/profile/addresses'))
  }

  const [province, setProvince] = useState({})
  const [city, setCity] = useState({})
  const [subdistrict, setSubdistrict] = useState({})

  useEffect(() => {
    setValue('province_id', defaultVal?.province.id.toString())
    setProvince({
      label: defaultVal?.province.name,
      value: defaultVal?.province.id.toString(),
    })
  }, [])

  useEffect(() => {
    setValue('city_id', defaultVal?.city.id.toString())
    setCity({
      label: defaultVal?.city.name,
      value: defaultVal?.city.id.toString(),
    })
  }, [])

  useEffect(() => {
    setValue('subdistrict_id', defaultVal?.subdistrict.id.toString())
    setSubdistrict({
      label: defaultVal?.subdistrict.name,
      value: defaultVal?.subdistrict.id.toString(),
    })
  }, [])

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Save address'
      resetButton={(() => {
        if (defaultVal)
          return { colorScheme: 'danger', children: 'Delete', variant: 'solid' }
      })()}
      onReset={(() =>
        defaultVal
          ? () => {
              deleteAddress(defaultVal.id)
              if (onClose) onClose()
            }
          : () => null)()}
    >
      <Heading>Contact</Heading>
      <StandardInput
        autoFocus
        {...register('label')}
        defaultValue={defaultVal?.label}
        isRequired
        type='text'
        label='Label'
        isInvalid={!!errors.label}
        placeholder='Rumah, Kantor'
        errorMessage={errors.label?.message}
      />
      <StandardInput
        {...register('recipient_phone_number')}
        defaultValue={defaultVal?.recipient_phone_number}
        isRequired
        type='tel'
        label='Phone Number'
        isInvalid={!!errors.recipient_phone_number}
        placeholder='08XXXXXXXXXX'
        errorMessage={errors.recipient_phone_number?.message}
      />
      <Heading>Address</Heading>
      <StandardInput
        {...register('name')}
        defaultValue={defaultVal?.name}
        isRequired
        type='text'
        isInvalid={!!errors.name}
        label='Complete address'
        placeholder='Jl. xxxxxxxxxxxxx'
        errorMessage={errors.name?.message}
      />
      <SearchSelect
        isRequired
        required={true}
        control={control}
        name='province_id'
        value={province}
        options={provinceGroups}
        placeholder='Province'
        label='Province'
        onChange={(data) => {
          resetField('subdistrict_id', { defaultValue: '' })
          resetField('city_id', { defaultValue: '' })
          getCities(data.value)
          setValue('province_id', data.value)
          setSubdistrict({})
          setCity({})
          setProvince(data)
        }}
      />
      <SearchSelect
        isRequired
        required={true}
        control={control}
        name='city_id'
        value={city}
        options={cities?.data.map((el) => {
          const option: OptionsGroup = {
            label: el.name,
            value: el.id.toString(),
          }
          return option
        })}
        placeholder='City'
        label='City'
        onChange={(data) => {
          resetField('subdistrict_id', { defaultValue: '' })
          getSubdistrict(data.value)
          setValue('city_id', data.value)
          setSubdistrict({})
          setCity(data)
        }}
      />
      <SearchSelect
        isRequired
        required={true}
        control={control}
        name='subdistrict_id'
        value={subdistrict}
        options={subdistricts?.data.map((el) => {
          const option: OptionsGroup = {
            label: el.name,
            value: el.id.toString(),
          }
          return option
        })}
        placeholder='Subdistrict'
        label='Subdistrict'
        onChange={(data) => {
          setValue('subdistrict_id', data.value)
          setSubdistrict(data)
        }}
      />
      <HStack>
        <Switch
          {...register('defaultAddress')}
          defaultChecked={defaultVal?.defaultAddress}
        >
          Default address
        </Switch>
        <Switch
          {...register('defaultShopAddress')}
          defaultChecked={defaultVal?.defaultShopAddress}
        >
          Default shop address
        </Switch>
      </HStack>
    </CustomForm>
  )
}

export default SetAddressForm
