import { Container } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import ReviewPanel from '../../components/review/ReviewPanel'
import { useGetProductByIdQuery } from '../../features/products/productsSlice'

function ReviewPage() {
  const { productId } = useParams()

  const { data, error, isLoading } = useGetProductByIdQuery(productId)

  return (
    <Container maxW='6xl'>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorState />
      ) : (
        data && (
          <ReviewPanel
            averageRating={data.average_rating}
            displayStatus='none'
            displayPagination={['block', 'block', 'block']}
          />
        )
      )}
    </Container>
  )
}

export default ReviewPage
