import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormErrorMessage,
  forwardRef,
  InputGroup,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { CustomInputProps } from './entities'
import CustomInput from './index'
import { FormLabel } from '@chakra-ui/react'

const PasswordInput = forwardRef<CustomInputProps, 'input'>((props, ref) => {
  const { label, placeholder, isRequired, isInvalid, errorMessage, ...rest } =
    props
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid} my={4}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <CustomInput
          {...rest}
          ref={ref}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          onPaste={(e) => {
            e.preventDefault()
            return false
          }}
          onCopy={(e) => {
            e.preventDefault()
            return false
          }}
        />
        <Button onClick={handleClick} h='44px'>
          {show ? <ViewIcon /> : <ViewOffIcon />}
        </Button>
      </InputGroup>
      {/* Error message will be displayed if isInvalid is true */}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

export default PasswordInput
