import React from 'react'
import { Card, CardBody, Heading, Text } from '@chakra-ui/react'
import { DashboardCardProps } from './entities'

const DashboardCard = (props: DashboardCardProps) => {
  return (
    <Card
      boxShadow='sm'
      borderColor='primary.200'
      backgroundColor='primary.50'
      borderWidth={1}
      onClick={() => {
        props.evtOnClick && props.evtOnClick()
      }}
      cursor={props.evtOnClick ? 'pointer' : 'default'}
    >
      <CardBody>
        <Heading size='md' mb='8'>
          {props.title}
        </Heading>

        <Text>{props.value}</Text>
      </CardBody>
    </Card>
  )
}

export default DashboardCard
