import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { BiFilterAlt } from 'react-icons/bi'
import FilterDrawerSearchPage from '../filterDrawer/filterDrawerSearchPage'

function FilterSearhPage(props: { callbackReset: () => void }) {
  const { callbackReset } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <FilterDrawerSearchPage
        isOpen={isOpen}
        onClose={onClose}
        callbackReset={() => {
          callbackReset()
          onClose()
        }}
      />
      <Flex>
        <Button
          gap='5px'
          color='primary.600'
          onClick={isOpen ? onClose : onOpen}
        >
          <Icon as={BiFilterAlt} fontSize='xl' />
          Filter
        </Button>
      </Flex>
    </>
  )
}

export default FilterSearhPage
