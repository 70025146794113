import { Text, VStack } from '@chakra-ui/react'
import React from 'react'
import CustomImage from '../images/index'

const NoData = () => {
  return (
    <VStack>
      <CustomImage
        width='100px'
        height='100px'
        src='/assets/svgs/no-data.svg'
      />
      <Text>No Data</Text>
    </VStack>
  )
}

export default NoData
