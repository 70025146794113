import './filterAccordion.css'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { RootState } from '../../app/store'
import { useGetCategoriesQuery } from '../../features/categories/categoriesSlice'
import { useGetCitiesQuery } from '../../features/location/locationSlice'
import {
  setPriceQuery,
  setReviewQuery,
  toggleCategoryQuery,
  toggleLocationQuery,
} from '../../features/sortFilter/sortFilterSlice'
import PrimaryButton from '../buttons/PrimaryButton'
import ErrorState from '../errorState'
import LoadingSpinner from '../loadingSpinner'
import { Category } from '../../features/categories/entities'
import StandardInput from '../inputs/StandardInput'
import { City } from '../../features/address/entities'

function FilterAccordion() {
  const dispatch = useDispatch()

  const [minPrice, setMinPrice] = useState(0)

  const [maxPrice, setMaxPrice] = useState(0)

  const { location_query, category_query } = useSelector(
    (state: RootState) => state.sortFilterReducer
  )

  const {
    data: location,
    error: locationError,
    isLoading: isLocationLoading,
  } = useGetCitiesQuery()

  const {
    data: category,
    error: categoryError,
    isLoading: isCategoryLoading,
  } = useGetCategoriesQuery('getCategories')

  const [categories, setCategories] = useState<Array<Category>>()
  const [parentCategories, setParentCategories] = useState<Array<Category>>()
  const [filteredCities, setFilteredCities] = useState<Array<City>>()

  const handleCategoryClick = (categoryClicked: Category) => {
    if (categoryClicked?.sub_categories?.length) {
      setParentCategories([...(parentCategories || []), categoryClicked])
      setCategories(categoryClicked.sub_categories)
    }
    dispatch(
      toggleCategoryQuery({
        id: categoryClicked.id,
        name: categoryClicked.name,
      })
    )
  }

  const handleParentCategoryClick = (categoryClicked: Category) => {
    if (
      categoryClicked.id !== parentCategories?.[parentCategories.length - 1]?.id
    ) {
      setParentCategories([...[], categoryClicked])
      setCategories(categoryClicked.sub_categories)
      dispatch(
        toggleCategoryQuery({
          id: categoryClicked.id,
          name: categoryClicked.name,
        })
      )
    } else if (categoryClicked.id === parentCategories?.[0].id) {
      setParentCategories([])
      setCategories(category)
      dispatch(
        toggleCategoryQuery({
          id: '',
          name: '',
        })
      )
    } else {
      dispatch(
        toggleCategoryQuery({
          id: categoryClicked.id,
          name: categoryClicked.name,
        })
      )
    }
  }

  useEffect(() => {
    setCategories(category)
    if (category_query && category) {
      const index = category.findIndex(
        (x: Category) => x.id === category_query.id
      )
      if (category[index]?.sub_categories?.length) {
        setParentCategories([...(parentCategories || []), category[index]])
        setCategories(category[index].sub_categories)
      }
    }
  }, [category])

  const handleSearchCity = (query: string) => {
    let tempCities: City[] = []
    if (query.length >= 3) {
      tempCities =
        location?.data.filter((x: City) =>
          x.name.toLowerCase().includes(query.toLowerCase())
        ) || []
    }
    setFilteredCities(tempCities)
  }

  const priceFilterHandler = () => {
    if (minPrice > maxPrice && maxPrice > 0) {
      toast.error('Minimum price cannot be larger than maximum price', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      dispatch(setPriceQuery({ min_price: minPrice, max_price: maxPrice }))
    }
  }

  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            Location
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {isLocationLoading ? (
            <LoadingSpinner />
          ) : locationError ? (
            <ErrorState />
          ) : (
            <>
              <SimpleGrid
                maxW={'full'}
                columns={[2, 3, 4, 4]}
                className='container'
              >
                {location?.data?.map((x: City) =>
                  location_query.find(({ id }) => id === x.id) ? (
                    <div key={x.id}>
                      <label>
                        <Input
                          type='checkbox'
                          checked
                          onChange={() => {
                            dispatch(
                              toggleLocationQuery({ id: x.id, name: x.name })
                            )
                          }}
                        />
                        <span>{x.name}</span>
                      </label>
                    </div>
                  ) : null
                )}
              </SimpleGrid>
              <StandardInput
                onChange={(e) => {
                  handleSearchCity(e.target.value)
                }}
                placeholder='Search city...'
              />
              <SimpleGrid
                maxW={'full'}
                columns={[2, 3, 4, 4]}
                className='container'
              >
                {filteredCities?.map((x: City) =>
                  location_query.find(({ id }) => id === x.id) ? null : (
                    <div key={x.id}>
                      <label>
                        <Input
                          type='checkbox'
                          value={x.name}
                          onChange={() => {
                            dispatch(
                              toggleLocationQuery({ id: x.id, name: x.name })
                            )
                          }}
                        />
                        <span>{x.name}</span>
                      </label>
                    </div>
                  )
                )}
              </SimpleGrid>
            </>
          )}
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            Price
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <Flex flexDirection='column' gap='5px'>
            <Text>Minimum price</Text>
            <InputGroup>
              <InputLeftAddon>IDR</InputLeftAddon>
              <Input
                placeholder='Minimum price'
                value={minPrice.toString()}
                pattern='^([1-9])(\d){0}$'
                type='number'
                onChange={(event) => {
                  if (Number.isNaN(event.target.valueAsNumber)) {
                    setMinPrice(0)
                  }
                  const num = Number(event.target.value)
                  if (num <= 0) setMinPrice(0)
                  else setMinPrice(+num)
                }}
              />
            </InputGroup>
            <Text>Maximum price</Text>
            <InputGroup>
              <InputLeftAddon>IDR</InputLeftAddon>
              <Input
                type='number'
                placeholder='Maximum price'
                value={maxPrice.toString()}
                pattern='^([1-9])(\d){0}$'
                onChange={(event) => {
                  if (Number.isNaN(event.target.valueAsNumber)) {
                    setMaxPrice(0)
                  }
                  const num = Number(event.target.value)
                  if (num <= 0) setMaxPrice(0)
                  else setMaxPrice(+num)
                }}
              />
            </InputGroup>
          </Flex>
          <PrimaryButton
            mt='3'
            width='full'
            onClick={() => priceFilterHandler()}
          >
            Apply
          </PrimaryButton>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            Category
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {isCategoryLoading ? (
            <LoadingSpinner />
          ) : categoryError ? (
            <ErrorState />
          ) : (
            <>
              <SimpleGrid columns={[2, 3, 4, 4]} className='container' mb={6}>
                {parentCategories?.map((x: Category, i: number) => {
                  if (i + 1 !== (parentCategories.length || 0)) {
                    return (
                      <HStack key={x.id} alignContent='center'>
                        <div>
                          <label>
                            <input
                              type='checkbox'
                              checked
                              onChange={() => {
                                handleParentCategoryClick(x)
                              }}
                            />
                            <span>{x.name}</span>
                          </label>
                        </div>
                        <Text as='b' mr={3}>
                          &gt;
                        </Text>
                      </HStack>
                    )
                  }
                  return (
                    <HStack key={x.id}>
                      <div>
                        <label>
                          <input
                            type='checkbox'
                            checked
                            onChange={() => {
                              handleParentCategoryClick(x)
                            }}
                          />
                          <span>{x.name}</span>
                        </label>
                      </div>
                    </HStack>
                  )
                })}
              </SimpleGrid>
              <SimpleGrid columns={[2, 3, 4, 4]} className='container'>
                {categories?.map((x: Category) => (
                  <div key={x.id}>
                    <label>
                      <input
                        type='checkbox'
                        checked={category_query.id === x.id}
                        onChange={() => {
                          handleCategoryClick(x)
                        }}
                      />
                      <span>{x.name}</span>
                    </label>
                  </div>
                ))}
              </SimpleGrid>
            </>
          )}
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            Rating
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <SimpleGrid columns={[1, 2, 3, 3]} className='containerRadio'>
            <div>
              <label>
                <input
                  type='radio'
                  name='radios'
                  value={5}
                  onChange={(event) => {
                    dispatch(setReviewQuery(Number(event.target.value)))
                  }}
                />
                <span>
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                </span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type='radio'
                  name='radios'
                  value={4}
                  onChange={(event) => {
                    dispatch(setReviewQuery(Number(event.target.value)))
                  }}
                />
                <span>
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Text fontSize='lg'>&nbsp; and up</Text>
                </span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type='radio'
                  name='radios'
                  value={3}
                  onChange={(event) => {
                    dispatch(setReviewQuery(Number(event.target.value)))
                  }}
                />
                <span>
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Text fontSize='lg'>&nbsp; and up</Text>
                </span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type='radio'
                  name='radios'
                  value={2}
                  onChange={(event) => {
                    dispatch(setReviewQuery(Number(event.target.value)))
                  }}
                />
                <span>
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Text fontSize='lg'>&nbsp; and up</Text>
                </span>
              </label>
            </div>
            <div>
              <label>
                <input
                  type='radio'
                  name='radios'
                  value={1}
                  onChange={(event) => {
                    dispatch(setReviewQuery(Number(event.target.value)))
                  }}
                />
                <span>
                  <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Icon as={AiOutlineStar} fontSize='xl' color='yellow.300' />
                  <Text fontSize='lg'>&nbsp; and up</Text>
                </span>
              </label>
            </div>
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default FilterAccordion
