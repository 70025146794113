import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

const SealabspayCardDetail = ({
  title,
  value,
  colorTitle,
  colorValue,
}: {
  title: string
  value: string
  colorTitle: string
  colorValue: string
}) => {
  return (
    <>
      <Flex alignItems='baseline' flexDirection={{ base: 'column', md: 'row' }}>
        <Box
          flex={2}
          color={colorTitle}
          fontWeight='semibold'
          letterSpacing='wide'
          fontSize='xs'
          textTransform='uppercase'
          ml='2'
          mr='2'
        >
          <Text>{title}</Text>
        </Box>
        <Box
          flex={7}
          mt='1'
          ml='5'
          fontWeight='semibold'
          as='h4'
          lineHeight='tight'
          noOfLines={1}
          color={colorValue}
        >
          <Text>{value}</Text>
        </Box>
      </Flex>
    </>
  )
}

export default SealabspayCardDetail
