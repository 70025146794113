import { Container } from '@chakra-ui/react'
import React from 'react'
import EditProductForm from '../../../components/form/EditProductForm'

const CreateProductPage = () => {
  return (
    <Container maxW='6xl' overflow='auto'>
      <EditProductForm />
    </Container>
  )
}

export default CreateProductPage
