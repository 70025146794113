import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
} from '@chakra-ui/react'
import React from 'react'
import { CustomInputProps } from './entities'
import CustomInput from './index'

const StandardInput = forwardRef<CustomInputProps, 'input'>((props, ref) => {
  const {
    label,
    placeholder,
    isRequired,
    isInvalid,
    errorMessage,
    isLabelTop = true,
    ...rest
  } = props
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={isInvalid}
      my={4}
      display={isLabelTop ? 'block' : 'flex'}
    >
      {label && <FormLabel flex={1}>{label}</FormLabel>}
      <CustomInput
        flex={5}
        ref={ref}
        type='text'
        placeholder={placeholder}
        {...rest}
      />

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

export default StandardInput
