import React from 'react'
import CustomForm from '.'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ResetPasswordFormInputs } from './entities'
import PasswordInput from '../inputs/PasswordInput'
import { useNavigate, useParams } from 'react-router-dom'
import { useResetPasswordMutation } from '../../features/auth/authSlice'
import { toast } from 'react-toastify'

const ResetPasswordForm = () => {
  const param = useParams()
  const navigate = useNavigate()
  const [resetPassword] = useResetPasswordMutation()

  // TODO: get username from user
  const username = 'acong'
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8)
      .required('Must be not empty')
      .test(
        'passwords-contain-username',
        'Password cannot contain username',
        function (value) {
          return !value?.includes(username) ?? true
        }
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordFormInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: ResetPasswordFormInputs) => {
    const promiseResetPassword = resetPassword({
      token: param['token'],
      password: values.password,
    })
      .unwrap()
      .then(() => navigate('/'))

    toast.promise(promiseResetPassword, {
      pending: 'loading...',
      success: 'Reset password success',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Change password'
    >
      <PasswordInput
        {...register('password')}
        isRequired
        isInvalid={!!errors.password}
        placeholder='Password'
        errorMessage={errors.password?.message}
      />
      <PasswordInput
        {...register('confirmPassword')}
        isRequired
        isInvalid={!!errors.confirmPassword}
        placeholder='Confirm Password'
        errorMessage={errors.confirmPassword?.message}
      />
    </CustomForm>
  )
}

export default ResetPasswordForm
