import React from 'react'
import CustomForm from './index'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { LoginFormInputs } from './entities'
import { yupResolver } from '@hookform/resolvers/yup'
import StandardInput from '../inputs/StandardInput'
import PasswordInput from '../inputs/PasswordInput'
import { useLoginMutation } from '../../features/auth/authSlice'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { clearAll } from '../../features/slices/transactionSlice/transactionSlice'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Must be not empty'),
  password: yup.string().required('Must be not empty'),
})

const LoginForm = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [loginUser] = useLoginMutation()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: LoginFormInputs) => {
    loginUser({
      username: values.email,
      password: values.password,
    })
      .unwrap()
      .then(() => {
        dispatch(clearAll())
        if (location.state?.prevURI) {
          window.location.replace(location.state.prevURI)
        } else {
          window.location.replace('/')
        }
      })
      .catch((err: any) => toast.error(err))
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Login'
    >
      <StandardInput
        autoFocus
        {...register('email')}
        isRequired
        type='email'
        isInvalid={!!errors.email}
        label='Email'
        placeholder='john.doe@gmail.com'
        errorMessage={errors.email?.message}
      />
      <PasswordInput
        {...register('password')}
        isRequired
        isInvalid={!!errors.password}
        label='Password'
        placeholder='password'
        errorMessage={errors.password?.message}
      />
    </CustomForm>
  )
}

export default LoginForm
