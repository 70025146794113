import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransactionCalculation } from './entities'

const initialState: TransactionCalculation = {
  data: {},
  isLoading: false,
  isError: false,
  errors: [],
}

const transactionCalculationSlice = createSlice({
  name: 'transactionCalculation',
  initialState: initialState,
  reducers: {
    resetAll: () => initialState,
    modifyShopSubtotal: (
      state,
      action: PayloadAction<{
        shopId: string
        amount: number
      }>
    ) => {
      const { shopId, amount } = action.payload
      if (!state.data[shopId]) {
        state.data[shopId] = {
          itemSubtotal: 0,
          discountTotal: 0,
          shipmentCostTotal: 0,
        }
      }
      state.data[shopId].itemSubtotal = amount
    },
    modifyShipmentCostTotal: (
      state,
      action: PayloadAction<{
        shopId: string
        amount: number
      }>
    ) => {
      const { shopId, amount } = action.payload
      if (!state.data[shopId]) {
        state.data[shopId] = {
          itemSubtotal: 0,
          discountTotal: 0,
          shipmentCostTotal: 0,
        }
      }
      state.data[shopId].shipmentCostTotal = amount
    },
    modifyDiscountTotal: (
      state,
      action: PayloadAction<{
        shopId: string
        amount: number
      }>
    ) => {
      const { shopId, amount } = action.payload
      if (!state.data[shopId]) {
        state.data[shopId] = {
          itemSubtotal: 0,
          discountTotal: 0,
          shipmentCostTotal: 0,
        }
      }
      state.data[shopId].discountTotal = amount
    },
  },
})

export const {
  modifyDiscountTotal,
  modifyShipmentCostTotal,
  modifyShopSubtotal,
  resetAll,
} = transactionCalculationSlice.actions
export default transactionCalculationSlice.reducer
