import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthReduxProp } from './entities'

const initialState: AuthReduxProp = {
  email: '',
  picture: '',
  name: '',
}

const authReduxSlice = createSlice({
  name: 'authRedux',
  initialState: initialState,
  reducers: {
    setEmailAndPicture: (state, action: PayloadAction<AuthReduxProp>) => {
      state.email = action.payload.email
      state.picture = action.payload.picture
      state.name = action.payload.name
    },
  },
})

export const { setEmailAndPicture } = authReduxSlice.actions
export default authReduxSlice.reducer
