/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Badge,
  Box,
  ButtonGroup,
  Container,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import {
  useDeleteShopVoucherMutation,
  useEditShopVoucherMutation,
  useGetShopVoucherQuery,
} from '../../features/shopVouchers/shopVouchersSlice'
import moment from 'moment'
import convertToRupiah from '../../helpers/currencyFormat'
import ErrorState from '../../components/errorState/index'
import LoadingSpinner from '../../components/loadingSpinner'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { TokenMeta } from '../../features/auth/entities'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import DangerButton from '../../components/buttons/DangerButton'
import ShopVoucherForm from '../../components/form/ShopVoucherForm'
import { ShopVoucherStatusesEnum, ShopVoucherTypeEnum } from '../../const'
import { toast } from 'react-toastify'
import { OKResponse } from '../../features/entities'
import { ShopVoucher } from '../../features/shopVouchers/entities'
import { useDisclosure } from '@chakra-ui/react'
import DuplicateShopVoucherModal from '../../components/modal/DuplicateShopVoucherModal'

const ShopVoucherDetailPage = () => {
  const navigate = useNavigate()
  const token = Cookies.get('tokenaccess') ?? ''
  if (!token) {
    navigate('/login')
  }
  const tokenData: TokenMeta = jwtDecode<TokenMeta>(token)
  const { shopVoucherID } = useParams()
  const { data, isError } = useGetShopVoucherQuery(shopVoucherID!)

  const [isEditing, setIsEditing] = useState(false)
  const [editShopVoucher] = useEditShopVoucherMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [deleteShopVoucher] = useDeleteShopVoucherMutation()

  const showEditToast = async (promise: Promise<OKResponse<ShopVoucher>>) =>
    toast
      .promise(promise, {
        pending: 'Editing shop voucher...',
        success: 'Success edit shop voucher',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
      .then(() => setIsEditing(!isEditing))
      .catch(() => {
        return
      })

  const showDeleteToast = async (promise: Promise<OKResponse<ShopVoucher>>) =>
    toast
      .promise(promise, {
        pending: 'Deleting shop voucher...',
        success: 'Success deleting shop voucher',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
      .then(() => navigate(`/seller/vouchers`))
      .catch(() => {
        return
      })
  return isError ? (
    <ErrorState />
  ) : !data ? (
    <LoadingSpinner />
  ) : (
    <>
      <DuplicateShopVoucherModal
        isOpen={isOpen}
        onClose={onClose}
        shopVoucher={data.data}
        userName={tokenData.data.username}
      />
      <Container maxW='6xl' mt={4}>
        <Box
          mt={4}
          borderWidth={1}
          bgColor='primary.50'
          borderColor='primary.200'
          padding={2}
          borderRadius={10}
        >
          {!isEditing ? (
            <Grid templateColumns='3fr 7fr' gap={3} width='full'>
              <GridItem>Code</GridItem>
              <GridItem>{data.data.code}</GridItem>
              <GridItem>Status</GridItem>
              <GridItem>
                <Badge
                  colorScheme={
                    data.data.status === 'ONGOING'
                      ? 'green'
                      : data.data.status === 'INCOMING'
                      ? 'teal'
                      : 'orange'
                  }
                >
                  {data.data.status}
                </Badge>
              </GridItem>
              <GridItem>Discount</GridItem>
              <GridItem>
                {data.data.type === 'NOMINAL'
                  ? 'Rp' + convertToRupiah(data.data.amount)
                  : data.data.amount + '%'}
              </GridItem>
              <GridItem>Start Date</GridItem>
              <GridItem>
                {moment(data.data.start_date).format('dddd DD-MM-YYYY')}
              </GridItem>
              <GridItem>End Date</GridItem>
              <GridItem>
                {moment(data.data.end_date).format('dddd DD-MM-YYYY')}
              </GridItem>
              <GridItem>Minimum payment</GridItem>
              <GridItem>
                {'Rp' + convertToRupiah(data.data.minimum_payment)}
              </GridItem>
              <GridItem>Quota</GridItem>
              <GridItem>{data.data.quota}</GridItem>
            </Grid>
          ) : (
            <ShopVoucherForm
              onReset={() => setIsEditing(!isEditing)}
              userName={tokenData.data.username}
              inputs={{
                amount: data.data.amount,
                code: data.data.code,
                minimumPayment: data.data.minimum_payment,
                quota: data.data.quota,
                type: ShopVoucherTypeEnum[data.data.type],
                status: ShopVoucherStatusesEnum[data.data.status],
                endDate: new Date(data.data.end_date),
                startDate: new Date(data.data.start_date),
              }}
              onSubmit={(values) =>
                showEditToast(
                  editShopVoucher({ args: values, id: shopVoucherID! }).unwrap()
                )
              }
            />
          )}

          {!isEditing && (
            <ButtonGroup w='full'>
              <PrimaryButton w='full' onClick={() => setIsEditing(!isEditing)}>
                Edit
              </PrimaryButton>
              <PrimaryButton w='full' onClick={onOpen}>
                Duplicate
              </PrimaryButton>
              <DangerButton
                w='full'
                onClick={() => {
                  showDeleteToast(deleteShopVoucher(shopVoucherID!).unwrap())
                }}
              >
                Delete
              </DangerButton>
            </ButtonGroup>
          )}
        </Box>
      </Container>
    </>
  )
}

export default ShopVoucherDetailPage
