import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import CartShop from '../../components/cart/CartShop'
import IndeterminateCheckbox from '../../components/inputs/IndeterminateCheckbox'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import convertToRupiah from '../../helpers/currencyFormat'
import { useLazyGetCartItemsShopsQuery } from '../../features/carts/cartsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { TransactionSliceStateProp } from '../../features/slices/transactionSlice/entities'
import {
  addAll,
  clearAll,
  updateItem,
} from '../../features/slices/transactionSlice/transactionSlice'
import { OrderProp } from '../../features/slices/transactionSlice/entities'
import CustomImage from '../../components/images/index'
import { useNavigate } from 'react-router-dom'
import { calculateDiscountedPrice } from '../../helpers/priceCalculation'

const calculateTotalPrice = (data: { [shopId: string]: OrderProp }): number => {
  let totalPrice = 0

  const storeKeys = Object.keys(data)
  storeKeys.forEach((storeKey) => {
    const productKeys = Object.keys(data[storeKey].products)
    productKeys.forEach((productKey) => {
      totalPrice +=
        data[storeKey].products[productKey].price *
        data[storeKey].products[productKey].quantity
    })
  })
  return totalPrice
}

const CartPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: transactionData } = useSelector<
    RootState,
    TransactionSliceStateProp
  >((state) => state.transactionSlice)

  const [shopCartItemsTrigger, shopCartItemsResult] =
    useLazyGetCartItemsShopsQuery()
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)
  const [isSelectedPartial, setIsSelectedPartial] = useState<boolean>(false)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  useEffect(() => {
    shopCartItemsTrigger(null)
  }, [])

  useEffect(() => {
    let selectedPartial = true
    let selectedAll = true

    if (!shopCartItemsResult.isLoading) {
      if (Object.keys(transactionData.shops).length === 0) {
        selectedAll = false
        selectedPartial = false
      } else {
        shopCartItemsResult.data?.items.forEach((val) => {
          const shop = transactionData.shops[val.shop.id]
          if (!shop) {
            selectedAll = false
          } else {
            const keysCount = Object.keys(shop.products).length
            if (keysCount !== val.cart_items.length) {
              selectedAll = false
            }
          }

          val.cart_items.forEach((cartVal) => {
            if (
              transactionData.shops[val.shop.id].products[cartVal.inventory.id]
            ) {
              if (
                calculateDiscountedPrice(
                  cartVal.inventory.price,
                  cartVal.discount_type,
                  cartVal.discount_amount
                ) !==
                transactionData.shops[val.shop.id].products[
                  cartVal.inventory.id
                ].price
              ) {
                dispatch(
                  updateItem({
                    shopId: val.shop.id,
                    productId: cartVal.inventory.id,
                    orderDetail: {
                      id: cartVal.inventory.id,
                      name: cartVal.inventory.product?.title || '',
                      quantity: cartVal.quantity,
                      weight: cartVal.inventory.product?.weight || 1,
                      variantName:
                        (cartVal.inventory.first_variant_type ||
                          cartVal.inventory.second_variant_type) &&
                        `${cartVal.inventory.first_variant_type.name}${
                          cartVal.inventory.second_variant_type
                            ? `, ${cartVal.inventory.second_variant_type.name}`
                            : ''
                        }`,
                      price: calculateDiscountedPrice(
                        cartVal.inventory.price,
                        cartVal.discount_type,
                        cartVal.discount_amount
                      ),
                      actualPrice: cartVal.inventory.price,
                      stock: cartVal.inventory.stock,
                    },
                  })
                )
              }
            }
          })
        })
      }

      setIsSelectedAll(selectedAll)
      setIsSelectedPartial(selectedPartial)
    }
  }, [shopCartItemsResult])

  useEffect(() => {
    if (shopCartItemsResult.isUninitialized) return

    let selectedPartial = true
    let selectedAll = true

    if (!shopCartItemsResult.isLoading) {
      if (Object.keys(transactionData.shops).length === 0) {
        selectedAll = false
        selectedPartial = false
      } else {
        shopCartItemsResult.data?.items.forEach((val) => {
          const shop = transactionData.shops[val.shop.id]
          if (!shop) {
            selectedAll = false
          } else {
            const keysCount = Object.keys(shop.products).length
            if (keysCount !== val.cart_items.length) {
              selectedAll = false
            }
          }
        })
      }

      setIsSelectedAll(selectedAll)
      setIsSelectedPartial(selectedPartial)
    }
  }, [transactionData])

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(transactionData.shops))
  }, [transactionData])

  return (
    <Flex
      mx='auto'
      maxW='1500px'
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      gap={8}
      mt={10}
    >
      <Box flex={4} px={3}>
        <Text fontSize='1.5rem'>My Carts</Text>
        {shopCartItemsResult.data?.items_count ? (
          <>
            <Flex
              borderBottom='1px solid'
              borderBottomColor='gray.300'
              py={3}
              alignItems='center'
            >
              <Flex alignItems='center' gap={3}>
                <IndeterminateCheckbox
                  isIndeterminate={isSelectedPartial && !isSelectedAll}
                  checked={isSelectedAll}
                  isChecked={isSelectedAll}
                  size='lg'
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        addAll(
                          shopCartItemsResult.data
                            ? shopCartItemsResult.data.items.map((orderVal) => {
                                const result: OrderProp = {
                                  id: orderVal.shop.id,
                                  name: orderVal.shop.name,
                                  products: {},
                                }

                                orderVal.cart_items.forEach((cartVal) => {
                                  let price = cartVal.inventory.price
                                  if (
                                    cartVal.inventory.product?.discount_type
                                  ) {
                                    switch (
                                      cartVal.inventory.product.discount_type
                                    ) {
                                      case 'nominal':
                                        price -=
                                          cartVal.inventory.product
                                            .discount_amount
                                        break
                                      case 'percentage':
                                        price -=
                                          (cartVal.inventory.product
                                            .discount_amount /
                                            100) *
                                          price
                                        break
                                    }
                                  }

                                  result.products[cartVal.inventory.id] = {
                                    id: cartVal.inventory.id,
                                    name:
                                      cartVal.inventory.product?.title || '',
                                    quantity: cartVal.quantity,
                                    weight:
                                      cartVal.inventory.product?.weight || 1,
                                    variantName:
                                      (cartVal.inventory.first_variant_type ||
                                        cartVal.inventory
                                          .second_variant_type) &&
                                      `${
                                        cartVal.inventory.first_variant_type
                                          .name
                                      }${
                                        cartVal.inventory.second_variant_type
                                          ? `, ${cartVal.inventory.second_variant_type.name}`
                                          : ''
                                      }`,
                                    price,
                                    actualPrice: cartVal.inventory.price,
                                    stock: cartVal.inventory.stock,
                                  }
                                })
                                return result
                              })
                            : []
                        )
                      )
                    } else {
                      dispatch(clearAll())
                    }
                  }}
                />
                <Text>Select All</Text>
              </Flex>
              {/* Disabled since there was no endpoint for this in backend */}
              {/* <SecondaryButton ml='auto'>Clear Selected</SecondaryButton> */}
            </Flex>
            {shopCartItemsResult.data?.items.map((val) => (
              <CartShop
                key={val.shop.id}
                shop={val.shop}
                cart_items={val.cart_items}
              />
            ))}
          </>
        ) : (
          <>
            <CustomImage w='50%' src='assets/svgs/empty-cart.svg' />
            <Text>Cart is empty</Text>
          </>
        )}
      </Box>

      <Box flex={1}>
        <Box border='1px solid' borderColor='gray.200' p={5} rounded='md'>
          <Text fontSize='1.25rem' fontWeight='bold' mb={5}>
            Purchase Summary
          </Text>
          <Box mb={5}>
            <Text>Total Price</Text>
            <Text textAlign='right' fontWeight='bold' fontSize='1.5rem'>
              Rp.&nbsp;
              {convertToRupiah(totalPrice)}
            </Text>
          </Box>
          <PrimaryButton
            type='button'
            width='full'
            onClick={(e) => {
              e.preventDefault()
              navigate('/checkout')
            }}
            disabled={Object.keys(transactionData.shops).length === 0}
          >
            Checkout
          </PrimaryButton>
        </Box>
      </Box>
    </Flex>
  )
}

export default CartPage
