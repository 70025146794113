import React from 'react'
import { Heading, Container } from '@chakra-ui/react'
import SetAddressForm from '../../components/form/SetAddressForm'

const CreateAddressPage = () => {
  return (
    <Container>
      <Heading as={'h1'}>Create Address</Heading>
      <SetAddressForm />
    </Container>
  )
}

export default CreateAddressPage
