import { Image, ImageProps, Skeleton } from '@chakra-ui/react'
import React from 'react'

const CustomImage = (props: ImageProps) => {
  const { src, ...rest } = props

  return src ? (
    <Image src={src} {...rest} />
  ) : (
    <Skeleton>
      <Image {...rest} />
    </Skeleton>
  )
}

export default CustomImage
