import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import CustomForm from '.'
import StandardInput from '../inputs/StandardInput'
import { ChangePasswordFormInputs } from './entities'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Flex, Link, Spinner, Text } from '@chakra-ui/react'
import {
  useChangePasswordMutation,
  useSendChangePasswordCodeMutation,
} from '../../features/users/usersSlice'
import Cookies from 'js-cookie'
import PasswordInput from '../inputs/PasswordInput'
import { RootState } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { ChangePasswordToken } from '../../features/slices/changePasswordTokenTimerSlice/entities'
import moment from 'moment'
import {
  modifyChangeToken,
  resetTimer,
} from '../../features/slices/changePasswordTokenTimerSlice/changePasswordTokenTimerSlice'

const schema = yup
  .object()
  .shape<Record<keyof ChangePasswordFormInputs, yup.AnySchema>>({
    password: yup.string().required('Must be not empty'),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    verificationCode: yup.string().required('Must be not empty'),
  })

const ChangePasswordForm = () => {
  const dispatch = useDispatch()
  const { data: tokenTimerData } = useSelector<RootState, ChangePasswordToken>(
    (state) => state.changePasswordTokenTimer
  )

  const [sendVerificationCode] = useSendChangePasswordCodeMutation()
  const [changePassword] = useChangePasswordMutation()

  const [verificationCodeLoading, setVerificatioNCodeLoading] = useState(false)
  const [verificationCodeCooldown, setVerificationCodeCooldown] = useState(0)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordFormInputs>({
    resolver: yupResolver(schema),
  })

  const evtOnSubmit = async (values: ChangePasswordFormInputs) => {
    const changePasswordPromise = changePassword({
      password: values.password,
      token: values.verificationCode,
    })
      .unwrap()
      .then(() => {
        Cookies.remove('tokenaccess')
        Cookies.remove('tokenrefresh')
        toast.warning('Login is required after change password')
        window.location.replace('/login?status=401')
      })

    await toast.promise(changePasswordPromise, {
      pending: 'loading...',
      success: 'Success change password',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  useEffect(() => {
    if (!tokenTimerData.issuedDate) return

    const diffTime = moment().diff(moment(tokenTimerData.issuedDate), 'seconds')
    const remainingTime = 60 - diffTime
    if (remainingTime > 0) {
      setVerificationCodeCooldown(remainingTime)
    } else {
      dispatch(resetTimer())
    }
  }, [])

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (verificationCodeCooldown > 0) {
        setVerificationCodeCooldown(verificationCodeCooldown - 1)
      }
      if (verificationCodeCooldown === 0) {
        dispatch(resetTimer())
        clearInterval(myInterval)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [verificationCodeCooldown])

  return (
    <CustomForm
      submitLabel='Submit'
      onSubmit={handleSubmit(evtOnSubmit)}
      isLoading={isSubmitting}
    >
      <PasswordInput
        {...register('password')}
        autoFocus
        isRequired
        isInvalid={!!errors.password}
        errorMessage={errors.password?.message}
        placeholder='Password'
        label='Password'
      />
      <PasswordInput
        {...register('passwordConfirm')}
        isRequired
        isInvalid={!!errors.passwordConfirm}
        errorMessage={errors.passwordConfirm?.message}
        placeholder='Password Confirmation'
        label='Password Confirmation'
      />
      <Flex alignItems='center' gap={3} w='full'>
        <Box flex={4}>
          <StandardInput
            {...register('verificationCode')}
            isRequired
            placeholder='Verification Code'
          />
        </Box>
        <Box flex={2}>
          {verificationCodeLoading ? (
            <Spinner />
          ) : (
            <Text
              as={verificationCodeCooldown === 0 ? Link : Text}
              onClick={(e) => {
                e.preventDefault()
                if (verificationCodeCooldown > 0 || verificationCodeLoading)
                  return

                setVerificatioNCodeLoading(true)
                const verificationCodePromise = sendVerificationCode()
                  .unwrap()
                  .then(() => {
                    setVerificationCodeCooldown(60)
                    dispatch(
                      modifyChangeToken(moment().format('YYYY-MM-DD HH:mm:ss'))
                    )
                    setVerificatioNCodeLoading(false)
                  })
                toast.promise(verificationCodePromise, {
                  pending: 'loading...',
                  success: 'Verification code sent, please check your email',
                  error: {
                    render({ data }) {
                      return `${data}`
                    },
                  },
                })
              }}
            >
              {verificationCodeCooldown
                ? `Please wait ${verificationCodeCooldown} seconds again to resend`
                : 'Send verification code'}
            </Text>
          )}
        </Box>
      </Flex>
    </CustomForm>
  )
}

export default ChangePasswordForm
