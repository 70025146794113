import { Heading, HStack, Icon, Text, Card, CardBody } from '@chakra-ui/react'
import React from 'react'
import { FaPhone, FaPencilAlt } from 'react-icons/fa'
import { AddressCardProps } from './entities'

const AddressCard = (props: AddressCardProps) => {
  const {
    address: {
      city,
      name,
      province,
      subdistrict,
      label,
      recipient_phone_number,
    },
    badges,
    ...rest
  } = props

  return (
    <Card
      boxShadow='md'
      border='1px'
      borderColor='primary.400'
      cursor='pointer'
      maxW='350px'
      bgColor='primary.50'
      {...rest}
    >
      <CardBody>
        <HStack justifyContent='space-between' w='full'>
          <Heading size='md'>{label}</Heading>
          <Icon>
            <FaPencilAlt />
          </Icon>
        </HStack>
        <HStack>
          <Icon>
            <FaPhone />
          </Icon>
          <Text>{recipient_phone_number}</Text>
        </HStack>
        <Text>{name}</Text>
        <Text fontWeight='bold'>
          {subdistrict.name}, {city.name},{province.name}, {city.postal_code}
        </Text>
        <HStack>{badges?.map((el) => el)}</HStack>
      </CardBody>
    </Card>
  )
}

export default AddressCard
