import React from 'react'
import CustomModal from '..'
import { DetailTransactionModalProps } from '../entities'
import DetailOrderHistory from './detailOrderHistory'

const DetailOrderModal = (props: DetailTransactionModalProps) => {
  const { isOpen, onClose, id } = props

  return (
    <CustomModal
      size={{ base: 'sm', md: '3xl' }}
      title='Detail Order'
      body={<DetailOrderHistory id={id} />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default DetailOrderModal
