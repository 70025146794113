import React, { useState } from 'react'
import { Box, Flex, Portal, Text, useDisclosure } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import CustomForm from '.'
import StandardInput from '../inputs/StandardInput'
import StandardTextarea from '../inputs/StandardTextArea'
import { ShopCreationInputs } from './entities'
import AddressSelectionModal from '../modal/checkoutModals/AddressSelectionModal'
import { Address } from '../../features/address/entities'
import SecondaryButton from '../buttons/SecondaryButton'

const schema = yup
  .object()
  .shape<Record<keyof ShopCreationInputs, yup.AnySchema>>({
    name: yup.string().min(4).max(30).required('Must be not empty'),
    description: yup.string(),
    address_id: yup
      .string()
      .uuid('Invalid address')
      .required('Must be not empty'),
  })

const ShopCreationForm = (props: {
  addresses: Address[]
  evtOnSubmit: (inputs: ShopCreationInputs) => void
}) => {
  const {
    isOpen: addressIsOpen,
    onOpen: addressOnOpen,
    onClose: addressOnClose,
  } = useDisclosure()
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>()

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<ShopCreationInputs>({
    resolver: yupResolver(schema),
  })

  return (
    <>
      <Portal>
        <AddressSelectionModal
          addresses={props.addresses || []}
          selectedAddress={selectedAddress}
          isOpen={addressIsOpen}
          onClose={addressOnClose}
          evtOnClick={(address: Address) => {
            setValue('address_id', address.id)
            clearErrors('address_id')
            setSelectedAddress(address)
          }}
        />
      </Portal>

      <CustomForm
        onSubmit={handleSubmit(props.evtOnSubmit)}
        isLoading={isSubmitting}
        submitLabel='Create Shop'
      >
        <StandardInput
          {...register('name')}
          autoFocus
          isRequired
          isInvalid={!!errors.name}
          label='Shop Name'
          errorMessage={errors.name?.message}
        />
        <StandardTextarea
          {...register('description')}
          resize='none'
          rows={3}
          isInvalid={!!errors.description}
          errorMessage={errors.description?.message}
          label='Description'
          placeholder='Introduction about your shop...'
        />

        <Flex gap={3} px={3} py={5} flexDir='column'>
          <Box flex={1}>
            {selectedAddress ? (
              <>
                <Text fontWeight='bold'>
                  {selectedAddress.label}{' '}
                  {selectedAddress.recipient_phone_number}
                </Text>
                <Text>{selectedAddress.name}</Text>
                <Text>
                  {selectedAddress.province.name},{' '}
                  {selectedAddress.subdistrict.name},{' '}
                  {selectedAddress.city.name},{' '}
                  {selectedAddress.city.postal_code}
                </Text>
              </>
            ) : (
              <Flex alignItems='center'>
                <Text>No address selected</Text>
              </Flex>
            )}
            {errors.address_id?.message && (
              <Text color='red.500' marginTop={2} fontSize='sm'>
                {errors.address_id.message}
              </Text>
            )}
          </Box>
          <Flex flexDirection='column' gap={2}>
            <SecondaryButton
              type='button'
              onClick={(e) => {
                e.preventDefault()
                addressOnOpen()
              }}
            >
              Change Address
            </SecondaryButton>
          </Flex>
        </Flex>
      </CustomForm>
    </>
  )
}

export default ShopCreationForm
