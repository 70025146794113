import { Center, Container, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import TransactionHistoryCard from '../../components/card/TransactionHistoryCard'
import LoadingSpinner from '../../components/loadingSpinner'
import { useLazyGetUserTransactionsQuery } from '../../features/transactions/transactionsSlice'
import Pagination from 'react-responsive-pagination'
import './index.css'
import CustomImage from '../../components/images'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'

const TransactionHistoryPage = () => {
  const [query, setQuery] = React.useState('')
  const [page, setPage] = React.useState(1)

  const [trigger, { data: transactions, isLoading }] =
    useLazyGetUserTransactionsQuery()

  React.useEffect(() => {
    trigger(query)
  }, [query])

  React.useEffect(() => {
    setQuery(`&page=${page}`)
  }, [page])

  const handlePageClick = (pageInput: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setPage(pageInput)
  }

  const navigate = useNavigate()

  return (
    <Container
      maxW={'6xl'}
      border={'1px solid black'}
      borderColor={'primary.300'}
      rounded={'md'}
      p={'5'}
      mt={'6'}
      overflow='auto'
    >
      <Center mb={'7'}>
        <Heading color={'primary.600'}>Transaction Histories</Heading>
      </Center>
      {isLoading ? (
        <LoadingSpinner />
      ) : transactions?.data ? (
        transactions?.data.map((x: any) => (
          <TransactionHistoryCard
            key={x.id}
            id={x.id}
            created_at={x.created_at}
            is_reviewed={x.is_reviewed}
            is_delivered={x.is_delivered}
            shop_name={x.orders[0].shop.name}
            status={x.status}
            order_detail_id={x.orders[0].order_detail.id}
            order_title={x.orders[0].order_detail.title}
            order_thumbnail_url={x.orders[0].order_detail.thumbnail_url}
            order_qty={x.orders[0].order_detail.quantity}
            order_price={x.orders[0].order_detail.price}
            total_price={x.total_price}
            remaining_order_detail={x.orders[0].remaining_order_detail}
          />
        ))
      ) : (
        <>
          {transactions?.data === null && (
            <Container minW={'xs'} maxW={'2xl'} rounded={'md'} p={8}>
              <Flex direction={'column'}>
                <Flex justifyContent={'center'}>
                  <CustomImage
                    width={'300px'}
                    height={'auto'}
                    src='/assets/svgs/empty_favorite.svg'
                  />
                </Flex>
                <Flex direction={'column'} mt={3} gap={3}>
                  <Center>
                    <Heading>Oops..!</Heading>
                  </Center>
                  <Center>
                    <Text color={'text.light'}>
                      There is no transaction yet
                    </Text>
                  </Center>
                  <Center>
                    <PrimaryButton
                      onClick={() => navigate('/')}
                      type='button'
                      fontWeight='medium'
                    >
                      Go Shopping
                    </PrimaryButton>
                  </Center>
                </Flex>
              </Flex>
            </Container>
          )}
        </>
      )}
      {transactions?.data && !isLoading && (
        <Pagination
          total={transactions.total_pages}
          ariaPreviousLabel={' '}
          ariaNextLabel={' '}
          a11yActiveLabel={' '}
          ariaCurrentAttr={true}
          current={page}
          onPageChange={(page) => handlePageClick(page)}
          className={'navigationButtons'}
        />
      )}
    </Container>
  )
}
export default TransactionHistoryPage
