import React from 'react'
import {
  Avatar,
  Container,
  Divider,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { MdLocationOn, MdStar } from 'react-icons/md'
import { useGetShopInformationQuery } from '../../features/shops/shopsSlice'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../loadingSpinner'
import ErrorState from '../errorState'
import CustomImage from '../images'

const ShopDetail = () => {
  const params = useParams()
  const id = params.shopID

  const {
    data: profile,
    error: isProfileError,
    isLoading: isProfileLoading,
  } = useGetShopInformationQuery(id)

  return (
    <>
      {isProfileLoading ? (
        <LoadingSpinner />
      ) : isProfileError ? (
        <ErrorState />
      ) : (
        profile && (
          <Container maxWidth='full'>
            <VStack>
              <Flex
                flexDirection={['column', 'column', 'column', 'row', 'row']}
                width='full'
                justifyContent='space-between'
                gap='10px'
              >
                <Flex
                  width={['100%', '100%', '100%', '50%', '50%']}
                  alignItems='center'
                  gap='10px'
                >
                  <Avatar size='xl' src={profile.data.user.profile_photo} />
                  <Flex
                    flexDirection='column'
                    justifyContent='center'
                    maxWidth='100%'
                  >
                    <Text fontSize='xl' noOfLines={1}>
                      {profile.data.name}
                    </Text>
                    <Flex gap='2px'>
                      <Icon as={MdLocationOn} fontSize='xl' />
                      <Text fontSize='md'>{profile.data.location}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <TableContainer width={['100%', '100%', '100%', '50%', '50%']}>
                  <Table>
                    <Tbody>
                      <Tr bg='primary.200'>
                        <Td>Rating</Td>
                        <Td>
                          <Flex gap='5px'>
                            <Icon as={MdStar} fontSize='lg' color='yellow' />
                            <Text>
                              {(
                                Math.round(profile.data.rating * 100) / 100
                              ).toFixed(2)}
                            </Text>
                          </Flex>
                        </Td>
                      </Tr>
                      <Tr bg='primary.300'>
                        <Td>Product</Td>
                        <Td>
                          <Text>{profile.data.product_count} item(s)</Text>
                        </Td>
                      </Tr>
                      <Tr bg='primary.200'>
                        <Td>Joined since</Td>
                        <Td>
                          <Text>
                            {new Date(
                              profile.data.created_at
                            ).toLocaleDateString()}
                          </Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
              <Flex justifyContent='flex-start' w='100%'>
                <Text fontSize='md'>{profile.data.description}</Text>
              </Flex>
              <Divider />
              <Text alignSelf={'start'} fontSize='lg'>
                Courier services:
              </Text>
              {profile.data.couriers.map((val) => (
                <Flex w='100%' alignItems='center' gap='2rem' key={val.name}>
                  <CustomImage
                    boxSize='100px'
                    src={`/assets/pngs/${val.id}.png`}
                    objectFit='scale-down'
                  />
                  <Flex flexDirection='column'>
                    <Text fontSize='md' textOverflow={'ellipsis'}>
                      {val.name}
                    </Text>
                    <Text fontSize='sm' color='gray'>
                      {val.service}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </VStack>
          </Container>
        )
      )}
    </>
  )
}

export default ShopDetail
