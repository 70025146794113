import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Container,
  HStack,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { DataTable } from '../../components/datatable'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import ConfirmDeleteModal from '../../components/modal/ConfirmDeleteModal'
import {
  useDeleteShopProductCategoryMutation,
  useLazyGetShopProductCategoriesQuery,
} from '../../features/shopProductCategories/shopProductCategoriesSlice'
import { useGetProfileQuery } from '../../features/users/usersSlice'

const ShopCategoryPage = () => {
  // Get profile
  const { data: profileData } = useGetProfileQuery()

  // Get product category
  const [trigger, result] = useLazyGetShopProductCategoriesQuery()

  useEffect(() => {
    if (!profileData) return

    trigger({ userID: profileData.data.id })
  }, [profileData])

  // Generate columns
  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('categoryName', {
      cell: (info) => renderCategoryNameCell(info.getValue()),
      header: 'Category Name',
    }),
    columnHelper.accessor('actions', {
      cell: (info) => renderActionsCell(info.getValue()),
      header: 'Actions',
    }),
  ]

  // Generate data from rtk
  const [categories, setCategories] = useState([])
  useEffect(() => {
    const data: any = result.data

    if (!data || data.length === 0) {
      setCategories([])
      return
    }

    const categories = data.map((val: any) => ({
      categoryName: {
        name: val.name,
      },
      actions: {
        id: val.id,
      },
    }))
    setCategories(categories)
  }, [result])

  const renderCategoryNameCell = ({ name }: any) => (
    <Box py={'8px'}>
      <Text>{name}</Text>
    </Box>
  )

  const [deleteId, setDeleteId] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [deleteCategory] = useDeleteShopProductCategoryMutation()

  const handleDelete = () => {
    deleteCategory({ id: deleteId })
      .then(() => {
        toast.success('Success deleting category', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
      .catch((err: any) => {
        toast.error(err.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
    onClose()
  }
  const handleModalOpen = (id: string) => {
    setDeleteId(id)
    onOpen()
  }

  const renderActionsCell = ({ id }: any) => (
    <HStack gap={'8px'} py={'8px'}>
      <IconButton
        onClick={() => handleModalOpen(id)}
        colorScheme={'red'}
        icon={<DeleteIcon />}
        aria-label='delete'
      />
    </HStack>
  )

  return (
    <Container maxW='6xl'>
      {result.isLoading && <LoadingSpinner />}
      {!result.isLoading && result.isError && <ErrorState />}
      {!result.isLoading && !result.isError && result.data && (
        <Box>
          <ConfirmDeleteModal
            isOpen={isOpen}
            onClose={onClose}
            deleteAction={() => handleDelete()}
            title='Delete Category'
            body='Are you sure you want to delete this category?'
            buttonText='Delete'
          />
          <DataTable columns={columns} data={categories} />
        </Box>
      )}
    </Container>
  )
}

export default ShopCategoryPage
