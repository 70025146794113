import { Box, Container, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import NoData from '../../components/noData'
import ProductCard from '../../components/productCard'
import { useLazyGetShopProductsQuery } from '../../features/products/productsSlice'
import Pagination from 'react-responsive-pagination'

function MostPurchasedPage() {
  const { shopID: id } = useParams()
  const [page, setPage] = useState(1)
  const [pageQuery, setPageQuery] = useState('')

  const mostRecommendedQuery = 'sort_by=units_sold&sort=desc'

  const [
    trigger,
    {
      data: recommendedProduct,
      error: isRecommendedProductError,
      isLoading: isRecommendedProductLoading,
    },
  ] = useLazyGetShopProductsQuery({ id, query: mostRecommendedQuery })

  const navigate = useNavigate()

  useEffect(() => {
    trigger({ id, query: mostRecommendedQuery })
  }, [])

  useEffect(() => {
    trigger({ id, query: pageQuery })
  }, [pageQuery])

  useEffect(() => {
    let newPage = mostRecommendedQuery
    newPage += `&page=${page}`
    setPageQuery(newPage)
  }, [page])

  const handlePageClick = (pageInput: number) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setPage(pageInput)
  }

  return (
    <Container maxW='6xl'>
      {isRecommendedProductLoading ? (
        <LoadingSpinner />
      ) : isRecommendedProductError ? (
        <ErrorState />
      ) : !recommendedProduct?.data ? (
        <NoData />
      ) : (
        <>
          <Box
            mt={4}
            borderColor={'gray.100'}
            boxShadow='md'
            borderWidth={1}
            padding={2}
            borderRadius={10}
          >
            <Text fontSize='xl'>Most purchased product</Text>
            <SimpleGrid columns={[2, null, 4, 5]}>
              {recommendedProduct.data.map((x: any) => (
                <ProductCard
                  key={x.id}
                  data={x}
                  onClick={() => navigate(`/products/${x.id}`)}
                />
              ))}
            </SimpleGrid>
          </Box>
          <Pagination
            total={recommendedProduct.total_pages}
            ariaPreviousLabel={' '}
            ariaNextLabel={' '}
            a11yActiveLabel={' '}
            ariaCurrentAttr={true}
            current={page}
            onPageChange={(page) => handlePageClick(page)}
            className={'navigationButtons'}
          />
        </>
      )}
    </Container>
  )
}

export default MostPurchasedPage
