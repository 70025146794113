import React from 'react'
import { Card, CardHeader, Text } from '@chakra-ui/react'
import { ShopCourierCardProps } from './entities'
import convertToRupiah from '../../helpers/currencyFormat'
import moment from 'moment'

const ShopCourierCard = (props: ShopCourierCardProps) => {
  const { shopCourier, onClick, ...rest } = props

  return (
    <Card
      boxShadow='xl'
      borderColor='primary.200'
      backgroundColor='primary.50'
      borderWidth={1}
      onClick={onClick}
      cursor='pointer'
      {...rest}
    >
      <CardHeader>
        <Text fontWeight='bold'>
          {shopCourier.name} - {shopCourier.service}
        </Text>
        <Text fontWeight='thin'>
          Rp.&nbsp;{convertToRupiah(shopCourier.price)}
        </Text>
        <Text fontWeight='thin'>
          Est.&nbsp;
          {moment().add(shopCourier.etd, 'days').format('DD MMM YYYY')}
        </Text>
      </CardHeader>
    </Card>
  )
}

export default ShopCourierCard
