import React from 'react'
import CustomModal from '.'
import RatingForm from '../form/rating/RatingForm'
import { RatingModalProps } from './entities'

const RatingModal = (props: RatingModalProps) => {
  const {
    isOpen,
    onClose,
    orderDetailID,
    name,
    variant,
    thumbnail_url,
    productID,
  } = props
  return (
    <CustomModal
      size={['sm', 'md', '6xl']}
      title='Give rating'
      body={
        <RatingForm
          orderDetailID={orderDetailID}
          name={name}
          variant={variant}
          thumbnail_url={thumbnail_url}
          product_id={productID}
          onClose={onClose}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default RatingModal
