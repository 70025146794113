import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  forwardRef,
  Input,
} from '@chakra-ui/react'
import { CustomInputProps } from './entities'

const DateTimeInput = forwardRef<CustomInputProps, 'input'>((props, ref) => {
  const { isRequired, isInvalid, errorMessage, ...rest } = props
  return (
    <FormControl
      variant='floating'
      isRequired={isRequired}
      isInvalid={isInvalid}
    >
      <Input
        type='datetime-local'
        minH='44px'
        focusBorderColor='primary.400'
        errorBorderColor='danger.400'
        ref={ref}
        {...rest}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

export default DateTimeInput
