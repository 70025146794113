import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
} from '@chakra-ui/react'
import React from 'react'
import { CustomInputProps } from './entities'
import CustomInput from './index'

const NumberInput = forwardRef<CustomInputProps, 'input'>((props, ref) => {
  const {
    label,
    placeholder,
    isRequired,
    isInvalid,
    errorMessage,
    isLabelTop = true,
    ...rest
  } = props
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={isInvalid}
      my={4}
      display={isLabelTop ? 'block' : 'flex'}
    >
      {/* Empty placeholder is required for behavior of the label */}
      {label && <FormLabel flex={1}>{label}</FormLabel>}
      <CustomInput
        flex={5}
        ref={ref}
        type='number'
        placeholder={placeholder}
        {...rest}
      />

      {/* Error message will be displayed if isInvalid is true */}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

export default NumberInput
