import React, { useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { ShopCartItem } from '../../features/carts/entities'
import {
  addAllShopItems,
  clearAllShopItems,
} from '../../features/slices/transactionSlice/transactionSlice'
import { TransactionSliceStateProp } from '../../features/slices/transactionSlice/entities'
import IndeterminateCheckbox from '../inputs/IndeterminateCheckbox'
import CartShopItem from './CartShopItem'
import { RootState } from '../../app/store'

const CartShop = ({ shop, cart_items }: ShopCartItem) => {
  const dispatch = useDispatch()
  const { data: transactionData } = useSelector<
    RootState,
    TransactionSliceStateProp
  >((state) => state.transactionSlice)

  const [isProductSelectedAll, setIsProductSelectedAll] =
    useState<boolean>(false)
  const [isProductPartialSelected, setIsProductPartialSelected] =
    useState<boolean>(false)

  useEffect(() => {
    const orderDetailCount = transactionData.shops[shop.id]
      ? Object.keys(transactionData.shops[shop.id].products).length
      : 0

    if (orderDetailCount === 0) {
      setIsProductPartialSelected(false)
    } else {
      setIsProductPartialSelected(true)
    }

    if (orderDetailCount === cart_items.length) {
      setIsProductSelectedAll(true)
    } else {
      setIsProductSelectedAll(false)
    }
  }, [transactionData])

  return (
    <Flex
      flexDirection='column'
      py={3}
      _notLast={{
        borderBottom: '5px solid',
        borderColor: 'gray.200',
      }}
    >
      <Flex
        alignItems='center'
        py={3}
        gap={3}
        borderBottom='1px solid'
        borderBottomColor='gray.300'
      >
        <IndeterminateCheckbox
          isIndeterminate={isProductPartialSelected && !isProductSelectedAll}
          checked={isProductSelectedAll}
          isChecked={isProductSelectedAll}
          size='lg'
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(
                addAllShopItems({
                  shopId: shop.id,
                  shopName: shop.name,
                  orderDetails: cart_items.map((val) => {
                    let price = val.inventory.price
                    if (val.inventory.product?.discount_type) {
                      switch (val.inventory.product.discount_type) {
                        case 'nominal':
                          price -= val.inventory.product.discount_amount
                          break
                        case 'percentage':
                          price -=
                            (val.inventory.product.discount_amount / 100) *
                            price
                          break
                      }
                    }

                    return {
                      id: val.inventory.id,
                      quantity: val.quantity,
                      name: val.inventory.product?.title || '',
                      weight: val.inventory.product?.weight || 1,
                      variantName:
                        (val.inventory.first_variant_type ||
                          val.inventory.second_variant_type) &&
                        `${val.inventory.first_variant_type.name}${
                          val.inventory.second_variant_type
                            ? `, ${val.inventory.second_variant_type.name}`
                            : ''
                        }`,
                      price,
                      actualPrice: val.inventory.price,
                      stock: val.inventory.stock,
                    }
                  }),
                })
              )
            } else {
              dispatch(clearAllShopItems(shop.id))
            }
          }}
        />
        <Text fontWeight='bold'>{shop.name}</Text>
      </Flex>
      <Flex flexDirection='column' justifyContent='center' py={5}>
        {cart_items.map((val) => (
          <CartShopItem
            key={val.id}
            shopId={shop.id}
            shopName={shop.name}
            discountAmount={val.discount_amount}
            discountType={val.discount_type}
            {...val}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default CartShop
