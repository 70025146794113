import React from 'react'
import { Avatar, Box, Flex, HStack, SimpleGrid, Text } from '@chakra-ui/react'
import SecondaryButton from '../buttons/SecondaryButton'
import { ProductShopProps } from './entities'
import { NavLink } from 'react-router-dom'

const ProductSeller = (props: ProductShopProps) => {
  const { data } = props
  return (
    <Flex
      bg='white'
      p={4}
      w='full'
      justifyContent='space-between'
      direction={['column', 'row']}
    >
      <HStack
        gap={4}
        w={['100%', '40%']}
        justifyContent={['space-between', 'start']}
        p={4}
        borderRight={['none', '1px solid gray']}
      >
        <Box>
          <Avatar
            size='lg'
            src={
              data.user.profile_photo === ''
                ? '/assets/pngs/defaultShop.png'
                : data.user.profile_photo
            }
          />
        </Box>
        <Box>
          <Text fontWeight='bold'>{data.name}</Text>
          <SecondaryButton>
            <NavLink to={`/shops/${data.id}`}>Visit Shop</NavLink>
          </SecondaryButton>
        </Box>
      </HStack>
      <SimpleGrid
        columns={[2, 3]}
        gap={4}
        alignItems='center'
        w={['100%', '70%']}
        p={4}
      >
        <HStack>
          <Text color='gray' fontSize={['sm', 'base']}>
            Location
          </Text>
          <Text color='primary.400' fontSize={['sm', 'base']}>
            {data.location}
          </Text>
        </HStack>
        <HStack>
          <Text color='gray' fontSize={['sm', 'base']}>
            Ratings
          </Text>
          <Text color='primary.400' fontSize={['sm', 'base']}>
            {(Math.round(data.rating * 100) / 100).toFixed(2)}
          </Text>
        </HStack>
        <HStack>
          <Text color='gray' fontSize={['sm', 'base']}>
            Products
          </Text>
          <Text color='primary.400' fontSize={['sm', 'base']}>
            {data.product_count}
          </Text>
        </HStack>
      </SimpleGrid>
    </Flex>
  )
}

export default ProductSeller
