import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Center } from '@chakra-ui/react'
import Layout from '../layout/index'
import UserProfilePage from '../pages/userProfilePage'
import ProductPage from '../pages/productPage'
import LoginPage from '../pages/loginPage'
import ForgotPasswordPage from '../pages/forgotPasswordPage/index'
import ResetPasswordPage from '../pages/resetPasswordPage'
import HomePage from '../pages/homePage'
import ShopCategoryPage from '../pages/shopCategoryPage'
import CreateProductPage from '../pages/sellerPages/productPage'
import ProductsInCategoryPage from '../pages/productsInCategoryPage'
import AddressesPage from '../pages/addressesPage'
import SellerLayout from '../layout/SellerLayout'
import SearchPage from '../pages/searchPage'
import ShopProfilePage from '../pages/shopProfilePage'
import ShopVouchersPage from '../pages/shopVouchersPage/index'
import CreateShopVoucherPage from '../pages/shopVouchersPage/CreateShopVoucherPage'
import CreatePromotionPage from '../pages/sellerPages/promotionsPage/CreatePromotionPage'
import UpdatePromotionPage from '../pages/sellerPages/promotionsPage/UpdatePromotionPage'
import PromotionDetailPage from '../pages/sellerPages/promotionsPage/PromotionDetailPage'
import ProfileLayout from '../layout/ProfileLayout'
import SealabspayPage from '../pages/sealabspayPage'
import MostPurchasedPage from '../pages/mostPurchasedPage/index'
import ShopPage from '../pages/shopPage/index'
import ShopVoucherDetailPage from '../pages/shopVouchersPage/ShopVoucherDetailPage'
import CheckoutPage from '../pages/checkoutPage'
import PromotionPage from '../pages/sellerPages/promotionsPage/PromotionPage'
import CartPage from '../pages/cartPage'
import PaymentPage from '../pages/paymentPage'
import PaymentResultPage from '../pages/paymentResultPage'
import UpdateProductPage from '../pages/sellerPages/productPage/UpdateProductPage'
import ReviewPage from '../pages/reviewPage'
import WalletPage from '../pages/walletPage'
import GuestRoute from './GuestRoute'
import UserProtectedRoute from './UserProtectedRoute'
import SellerProtectedRoute from './SellerProtectedRoute'
import DisplayProductPage from '../pages/sellerPages/productPage/DisplayProductPage'
import ShopCreationPage from '../pages/shopCreationPage'
import CreateAddressPage from '../pages/addressesPage/CreateAddressPage'
import RegisterPage from '../pages/registerPage'
import WalletPaymentPage from '../pages/walletPaymentPage'
import TransactionHistoryPage from '../pages/transactionHistoryPage'
import CreateShopCategoryPage from '../pages/shopCategoryPage/CreateShopCategoryPage'
import ChangePasswordPage from '../pages/userPasswordPage/ChangePasswordPage'
import FavoritePage from '../pages/favoritePage'
import SellerDashboardPage from '../pages/sellerPages/dashboardPage'
import DisplayOrdersPage from '../pages/sellerPages/ordersPage'
import DetailProductHistoryPage from '../pages/detailProductHistoryPage'
import DeliveryPage from '../pages/sellerPages/deliveryPage'

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='shop-category' element={<ShopCategoryPage />} />
          <Route path='shops/:shopID'>
            <Route index element={<ShopPage />} />
            <Route path='profile' element={<ShopProfilePage />} />
            <Route path='most-purchased' element={<MostPurchasedPage />} />
          </Route>
          <Route
            path='categories/:categoryId'
            element={<ProductsInCategoryPage />}
          />
          <Route path='products/:productId' element={<ProductPage />} />
          <Route path='payments/:id' element={<PaymentPage />} />
          <Route path='wallet-payments/:id' element={<WalletPaymentPage />} />
          <Route path='payment-result/:type' element={<PaymentResultPage />} />
          <Route path='search' element={<SearchPage />} />

          <Route element={<GuestRoute />}>
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<RegisterPage />} />
            <Route path='forgot' element={<ForgotPasswordPage />} />
            <Route
              path='reset-password/:token'
              element={<ResetPasswordPage />}
            />
          </Route>

          <Route element={<UserProtectedRoute />}>
            <Route path='cart' element={<CartPage />} />
            <Route path='checkout' element={<CheckoutPage />} />
            <Route path='transaction' element={<TransactionHistoryPage />} />
            <Route
              path='detail-product-history/:id'
              element={<DetailProductHistoryPage />}
            />
            <Route path='favorites' element={<FavoritePage />} />
            <Route path='profile' element={<ProfileLayout />}>
              <Route path='user' element={<UserProfilePage />} />
              <Route path='sealabspay' element={<SealabspayPage />} />
              <Route path='wallet' element={<WalletPage />} />
              <Route path='addresses' element={<AddressesPage />} />
              <Route path='password' element={<ChangePasswordPage />} />
            </Route>
            <Route path='create-shop' element={<ShopCreationPage />} />
            <Route element={<SellerProtectedRoute />}>
              <Route path='seller/' element={<SellerLayout />}>
                <Route index element={<SellerDashboardPage />} />
                <Route path='categories'>
                  <Route index element={<ShopCategoryPage />} />
                  <Route path='create' element={<CreateShopCategoryPage />} />
                </Route>
                <Route path='products'>
                  <Route index element={<DisplayProductPage />} />
                  <Route path='create' element={<CreateProductPage />} />
                  <Route path='update/:id' element={<UpdateProductPage />} />
                </Route>
                <Route path='orders'>
                  <Route index element={<DisplayOrdersPage />} />
                </Route>
                <Route path='deliveries'>
                  <Route index element={<DeliveryPage />} />
                </Route>
                <Route path='promotion'>
                  <Route index element={<PromotionPage />} />
                  <Route path='create' element={<CreatePromotionPage />} />
                  <Route
                    path=':promotionId'
                    element={<PromotionDetailPage />}
                  />
                  <Route
                    path='update/:promotionId'
                    element={<UpdatePromotionPage />}
                  />
                </Route>
                <Route path='vouchers'>
                  <Route index element={<ShopVouchersPage />} />
                  <Route path='create' element={<CreateShopVoucherPage />} />
                  <Route
                    path=':shopVoucherID'
                    element={<ShopVoucherDetailPage />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='reset-password/:token' element={<ResetPasswordPage />} />
          <Route path='profile' element={<ProfileLayout />}>
            <Route path='user' element={<UserProfilePage />} />
            <Route path='sealabspay' element={<SealabspayPage />} />
            <Route path='wallet' element={<WalletPage />} />
            <Route path='addresses'>
              <Route index element={<AddressesPage />} />
              <Route path='create' element={<CreateAddressPage />} />
            </Route>
          </Route>

          <Route path='reviews/:productId' element={<ReviewPage />} />
          <Route path='*' element={<Center>Page not found</Center>} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
