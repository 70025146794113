import {
  Button,
  forwardRef,
  HStack,
  Input,
  useNumberInput,
} from '@chakra-ui/react'
import React from 'react'
import { StepInputProps } from './entities'

const StepInput = forwardRef<StepInputProps, 'input'>((props, ref) => {
  const {
    minValue,
    maxValue,
    defaultValue,
    handleChange,
    totalStock,
    ...rest
  } = props
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue,
      min: minValue,
      max: maxValue,
      precision: 0,
      onChange: handleChange,
      focusInputOnChange: false,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <HStack h='44px' display={props.totalStock! >= 1 ? 'block' : 'none'}>
      <Button {...dec}>-</Button>
      <Input
        ref={ref}
        w='100px'
        focusBorderColor='primary.400'
        errorBorderColor='danger.400'
        {...input}
        {...rest}
      />
      <Button {...inc}>+</Button>
    </HStack>
  )
})

export default StepInput
