import { ErrorResponse } from '@remix-run/router'
import Cookies from 'js-cookie'
import { apiSlice } from '../api/apiSlice'
import { OKResponse } from '../entities'
import { CartItem, CartItemsResponse, ShopCartItemsResponse } from './entities'

export const cartsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCartItems: builder.query({
      query: () => ({
        url: '/carts',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<CartItemsResponse>) => {
        const cartItems = response.data

        if (cartItems.items === null) {
          cartItems.items = []
        }

        return cartItems
      },
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['carts'],
    }),
    getCartItemsShops: builder.query({
      query: () => ({
        url: '/carts/shops',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<ShopCartItemsResponse>) => {
        const cartItems = response.data
        if (cartItems.items === null) {
          cartItems.items = []
        }

        return cartItems
      },
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['carts'],
    }),
    addItemToCart: builder.mutation({
      query: ({ inventoryId, quantity }) => ({
        url: '/carts',
        method: 'POST',
        body: JSON.stringify({
          inventory_id: inventoryId,
          quantity,
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<CartItemsResponse>) =>
        response.data,
      transformErrorResponse: (response: ErrorResponse) => {
        return response.data.message
      },
      invalidatesTags: ['carts'],
    }),
    updateItemInCart: builder.mutation({
      query: ({ productId, quantity }) => ({
        url: `/carts/${productId}`,
        method: 'PUT',
        body: JSON.stringify({
          quantity,
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<CartItem>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['carts'],
    }),
    deleteItemInCart: builder.mutation({
      query: ({ productId }) => ({
        url: `/carts/${productId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<CartItemsResponse>) =>
        response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['carts'],
    }),
  }),
})

export const {
  useGetCartItemsQuery,
  useLazyGetCartItemsShopsQuery,
  useAddItemToCartMutation,
  useUpdateItemInCartMutation,
  useDeleteItemInCartMutation,
} = cartsApiSlice
