import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import CustomImage from '../images'

const Rating = (props: {
  name: string
  variant: string
  thumbnailUrl: string
}) => {
  return (
    <Box>
      <Box>
        <Flex gap='10px' alignItems='center'>
          <AspectRatio minW={'100px'} ratio={1}>
            <CustomImage
              src={props.thumbnailUrl}
              boxSize='120px'
              __css={{
                backgroundColor: 'gray.200',
                borderRadius: '20px',
              }}
              alt='order-detail.png'
            />
          </AspectRatio>
          <Box>
            <Text fontSize='xl' noOfLines={1}>
              {props.name}
            </Text>
            <Text fontSize='md' color='gray' noOfLines={1}>
              Variant: {props.variant}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default Rating
