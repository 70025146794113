import {
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  IconButton,
  Text,
  Circle,
  HStack,
} from '@chakra-ui/react'
import React from 'react'
import { FaCartPlus } from 'react-icons/fa'

import { Center } from '@chakra-ui/react'
import { useGetCartItemsQuery } from '../../features/carts/cartsSlice'
import LoadingSpinner from '../loadingSpinner'
import CartItem from '../cartItem'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomImage from '../images/index'

const CartPopover = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data: userData } = useGetProfileQuery()
  const { data: cartData, isLoading: cartIsLoading } =
    useGetCartItemsQuery(null)

  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <HStack position='relative'>
          <IconButton
            pr={3}
            variant='ghost'
            minH='44px'
            minW='44px'
            aria-label='cart'
            fontSize='44px'
            colorScheme='primary'
            onClick={() =>
              userData
                ? navigate('/cart')
                : navigate('/login', {
                    state: {
                      prevURI: location.pathname,
                    },
                  })
            }
            icon={<FaCartPlus />}
          />
          {cartData && cartData.items_count > 0 && (
            <Circle
              size='22px'
              bg='tomato'
              color='white'
              position='absolute'
              right='0'
              top='0'
            >
              {cartData.items_count > 9 ? '9+' : cartData.items_count}
            </Circle>
          )}
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>My Cart</PopoverHeader>
          <PopoverBody>
            {cartIsLoading && <LoadingSpinner />}
            {!cartIsLoading && (!cartData || cartData?.items_count === 0) && (
              <Center flexDir='column'>
                <CustomImage
                  height='100px'
                  width='100px'
                  src='/assets/svgs/empty-cart.svg'
                />
                <Text>Cart is empty</Text>
              </Center>
            )}
            {!cartIsLoading &&
              cartData &&
              cartData.items_count > 0 &&
              cartData.items.map((val) => (
                <CartItem
                  key={val.id}
                  data={{
                    id: val.id,
                    imageUrl:
                      'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
                    name: val.inventory.product
                      ? `${val.inventory.product.title}`
                      : 'unknown',
                    quantity: val.quantity,
                    totalPrice: val.inventory.price * val.quantity,
                  }}
                />
              ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default CartPopover
