import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useGetCategoriesQuery } from '../../features/categories/categoriesSlice'
import CategoryCard from '../categoryCard'
import { Category } from '../categoryCard/entities'
import ErrorState from '../errorState'
import LoadingSpinner from '../loadingSpinner'
import NoData from '../noData'

const CategoryPanel = () => {
  const { data, error, isLoading } = useGetCategoriesQuery('getCategories')

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorState />
      ) : (
        <>
          {data?.length === 0 ? (
            <NoData />
          ) : (
            <Flex gap={4} overflow='auto'>
              {data?.map((x: Category) => (
                <CategoryCard key={x.id} {...x} />
              ))}
            </Flex>
          )}
        </>
      )}
    </>
  )
}

export default CategoryPanel
