import {
  Badge,
  Box,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { FaShoppingBag } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Sealabspay } from '../../features/sealabspay/entities'
import { useGetOwnSealabspayQuery } from '../../features/sealabspay/sealabspaySlice'
import { useUpdatePaymentMethodTransactionMutation } from '../../features/transactions/transactionsSlice'
import { Wallet } from '../../features/wallets/entities'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import CustomImage from '../images'
import PaymentSelectionModal from '../modal/checkoutModals/PaymentSelectionModal'
import DetailTransactionModal from '../modal/transactionModals/DetailTransactionModal'
import { TransactionHistoryProps } from './entities'

const TransactionHistoryCard = (props: TransactionHistoryProps) => {
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure()
  const {
    isOpen: paymentModalIsOpen,
    onOpen: paymentModalOnOpen,
    onClose: paymentModalOnClose,
  } = useDisclosure()
  const { data: sealabsPaysData } = useGetOwnSealabspayQuery(null)
  const [updatePaymentMethod] = useUpdatePaymentMethodTransactionMutation()
  const navigate = useNavigate()

  return (
    <>
      <DetailTransactionModal isOpen={isOpen} onClose={onClose} id={props.id} />
      <PaymentSelectionModal
        sealabsPays={sealabsPaysData || []}
        isOpen={paymentModalIsOpen}
        onClose={paymentModalOnClose}
        evtOnClick={(data: {
          paymentType: 'sealabspay' | 'wallet'
          sealabspay?: Sealabspay
          wallet?: Wallet
        }) => {
          if (data.paymentType === 'sealabspay') {
            updatePaymentMethod({
              transactionID: props.id,
              paymentType: data.paymentType,
              id: data.sealabspay?.id,
            })
              .unwrap()
              .then(() => navigate('/payments/' + props.id))
          } else {
            updatePaymentMethod({
              transactionID: props.id,
              paymentType: data.paymentType,
              id: data.wallet?.id,
            })
              .unwrap()
              .then(() => navigate('/payments/' + props.id))
          }
        }}
      />
      <Box shadow={'md'} rounded={'sm'} p={'5'} mb={'4'}>
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <Flex justifyContent={'center'} alignItems={'center'} mr={'2'}>
              <FaShoppingBag fontSize={'20px'} />
            </Flex>
            <Box mr={'2'}>
              <Text as={'b'}>Shopping</Text>
            </Box>
            <Box mr={'2'}>
              <Text noOfLines={1}>
                {moment(props.created_at).format('DD MMM YYYY')}
              </Text>
            </Box>
            <Box mr={'2'}>
              {props.status === 'PAID' ? (
                <Badge colorScheme='green'>Paid</Badge>
              ) : (
                <Badge colorScheme='red'>
                  {props.status === 'WAITFORPAYMENT'
                    ? 'Wait for Payment'
                    : props.status === 'CANCELED'
                    ? 'Canceled'
                    : ''}
                </Badge>
              )}
            </Box>
          </Flex>
          <Divider py={'1'} mb={'2'} />
          <Flex direction={'row'} mb={'2'}>
            <Text as={'b'}>{props.shop_name}</Text>
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent={'space-between'}
            mb={'6'}
          >
            <Flex direction={'row'} mb={{ base: '5', md: '0' }}>
              <CustomImage
                w={'70px'}
                h={'60px'}
                mr={'3'}
                src={props.order_thumbnail_url}
              />
              <Flex direction={'column'}>
                <Text
                  as={'b'}
                  cursor={'pointer'}
                  onClick={() =>
                    navigate('/detail-product-history/' + props.order_detail_id)
                  }
                >
                  {props.order_title}
                </Text>
                <Text color={'text.light'} mb={2}>
                  {props.order_qty} item(s) | Rp{' '}
                  {props.order_price.toLocaleString('id-ID')}
                </Text>
                <Text
                  color={'text.light'}
                  cursor={'pointer'}
                  onClick={() => onOpen()}
                >
                  {props.remaining_order_detail !== 0 &&
                    '+' + props.remaining_order_detail + ' other item'}
                </Text>
              </Flex>
            </Flex>
            <Flex
              borderLeft={{ base: '', md: '1px solid gray' }}
              direction={'column'}
              px={'10'}
              ml={{ base: '0', md: '3' }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Text color={'text.light'}>Total Price</Text>
              <Text as={'b'}>
                Rp {props.total_price.toLocaleString('id-ID')}
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent={'right'}
          >
            <SecondaryButton
              mb={{ base: '3', md: '0' }}
              onClick={() => {
                onOpen()
              }}
            >
              Detail Transaction
            </SecondaryButton>
            {props.status === 'PAID' ? (
              <>
                {!props.is_reviewed && (
                  <PrimaryButton
                    ml={{ base: '0', md: '3' }}
                    onClick={() => onOpen()}
                  >
                    Give Review
                  </PrimaryButton>
                )}
                {props.is_delivered && (
                  <PrimaryButton
                    ml={{ base: '0', md: '3' }}
                    onClick={() => onOpen()}
                  >
                    Complete Order
                  </PrimaryButton>
                )}
              </>
            ) : (
              <PrimaryButton
                ml={{ base: '0', md: '3' }}
                onClick={() => paymentModalOnOpen()}
              >
                Change Payment Method
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default TransactionHistoryCard
