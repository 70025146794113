import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig } from 'redux-persist'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import { ChangePasswordToken } from './entities'

const persistConfig: PersistConfig<ChangePasswordToken> = {
  key: 'changePasswordTokenTimer',
  storage,
}

const initialState: ChangePasswordToken = {
  data: {},
  isLoading: false,
  isError: false,
  errors: [],
}

const changePasswordTokenTimerSlice = createSlice({
  name: 'changePasswordTokenTimer',
  initialState: initialState,
  reducers: {
    resetTimer: () => initialState,
    modifyChangeToken: (state, action: PayloadAction<string>) => {
      state.data.issuedDate = action.payload
    },
  },
})

export const { modifyChangeToken, resetTimer } =
  changePasswordTokenTimerSlice.actions
export default persistReducer(
  persistConfig,
  changePasswordTokenTimerSlice.reducer
)
