import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import convertToRupiah from '../../helpers/currencyFormat'
import { CheckoutPriceDetailProps } from './entities'

const CheckoutPriceDetail = ({ title, amount }: CheckoutPriceDetailProps) => {
  return (
    <Box mb={2}>
      <Text>{title}</Text>
      <Text textAlign='right'>Rp.&nbsp;{convertToRupiah(amount)}</Text>
    </Box>
  )
}

export default CheckoutPriceDetail
