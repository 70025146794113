import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex, Portal, Text, useDisclosure } from '@chakra-ui/react'
import { toast } from 'react-toastify'
import moment from 'moment'
import convertToRupiah from '../../helpers/currencyFormat'
import SecondaryButton from '../buttons/SecondaryButton'
import CheckoutStoreProduct from './CheckoutStoreProduct'
import {
  OrderProp,
  TransactionSliceStateProp,
} from '../../features/slices/transactionSlice/entities'
import { ShopVoucher } from '../../features/shopVouchers/entities'
import { useLazyGetShopVouchersQuery } from '../../features/shopVouchers/shopVouchersSlice'
import ShopVoucherSelectionModal from '../modal/checkoutModals/ShopVoucherSelectionModal'
import ShopCourierSelectionModal from '../modal/checkoutModals/ShopCourierSelectionModal'
import { modifyShop } from '../../features/slices/transactionSlice/transactionSlice'
import { useLazyGetShopCouriersShipmentCostQuery } from '../../features/shopCouriers/shopCouriersSlice'
import { RootState } from '../../app/store'
import { ShopCourierShipmentCost } from '../../features/shopCouriers/entities'
import {
  modifyDiscountTotal,
  modifyShipmentCostTotal,
  modifyShopSubtotal,
} from '../../features/slices/transactionCalculationSlice/transactionCalculationSlice'

const CheckoutOrderDetail = ({
  id: shopId,
  name: shopName,
  products,
}: OrderProp) => {
  const dispatch = useDispatch()
  const { data: transactionData } = useSelector<
    RootState,
    TransactionSliceStateProp
  >((state) => state.transactionSlice)

  const {
    isOpen: voucherModalIsOpen,
    onOpen: voucherModalOnOpen,
    onClose: voucherModalOnClose,
  } = useDisclosure()
  const {
    isOpen: courierModalIsOpen,
    onOpen: courierModalOnOpen,
    onClose: courierModalOnClose,
  } = useDisclosure()

  const [shopVoucherTrigger, shopVoucherResult] = useLazyGetShopVouchersQuery()
  const [shopCouriersCostTrigger, shopCouriersCostResult] =
    useLazyGetShopCouriersShipmentCostQuery()

  const [totalItemPrice, setTotalItemPrice] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [courierSelected, setCourierSelected] =
    useState<ShopCourierShipmentCost | null>(null)
  const [voucherSelected, setVoucherSelected] = useState<ShopVoucher | null>(
    null
  )

  useEffect(() => {
    shopVoucherTrigger({
      shopId,
      status: 'ONGOING',
    })
  }, [])

  useEffect(() => {
    const products = transactionData.shops[shopId].products
    const productKeys = Object.keys(products)
    let tempSubtotal = 0

    productKeys.forEach((val) => {
      tempSubtotal += products[val].quantity * products[val].price
    })
    dispatch(
      modifyShopSubtotal({
        shopId,
        amount: tempSubtotal,
      })
    )
    setTotalItemPrice(tempSubtotal)

    let discountAmount = 0
    if (voucherSelected) {
      if (voucherSelected.type === 'NOMINAL') {
        discountAmount = voucherSelected.amount
      } else {
        discountAmount = Math.floor(
          (voucherSelected.amount / 100) * tempSubtotal
        )
      }
    }
    setTotalDiscount(discountAmount)
    dispatch(
      modifyDiscountTotal({
        shopId,
        amount: discountAmount,
      })
    )
    tempSubtotal -= discountAmount

    if (courierSelected) {
      tempSubtotal += courierSelected.price
      dispatch(
        modifyShipmentCostTotal({
          shopId,
          amount: courierSelected.price,
        })
      )
    }

    setSubtotal(tempSubtotal)
  }, [
    transactionData.shops[shopId]?.products,
    voucherSelected,
    courierSelected,
  ])

  useEffect(() => {
    if (!transactionData.shipmentAddressId) return

    let totalWeight = 0
    const shop = transactionData.shops[shopId]
    Object.keys(shop.products).forEach((val) => {
      totalWeight += shop.products[val].weight
    })

    shopCouriersCostTrigger({
      addressId: transactionData.shipmentAddressId,
      shopId: shopId,
      weight: totalWeight,
    })
  }, [transactionData.shipmentAddressId])

  useEffect(() => {
    if (!shopVoucherResult.data) return
    if (!transactionData.shops[shopId]) return

    setVoucherSelected(
      shopVoucherResult.data.data.filter(
        (val) => val.id === transactionData.shops[shopId].voucherId
      )[0]
    )
  }, [shopVoucherResult])

  useEffect(() => {
    if (!shopCouriersCostResult.data) return
    if (!transactionData.shops[shopId]) return

    setCourierSelected(
      shopCouriersCostResult.data.filter(
        (val) => val.id === transactionData.shops[shopId].courierId
      )[0]
    )
  }, [shopCouriersCostResult])

  useEffect(() => {
    if (!voucherSelected) return

    if (totalItemPrice <= voucherSelected.minimum_payment) {
      setVoucherSelected(null)
      toast.warning(
        `Voucher in ${shopName} is not fulfill it's minimum requirement`
      )
      dispatch(
        modifyShop({
          shopId,
          shopVoucherId: null,
        })
      )
    }
  }, [totalItemPrice])

  return (
    <>
      <Portal>
        <ShopVoucherSelectionModal
          isOpen={voucherModalIsOpen}
          onClose={voucherModalOnClose}
          shopVouchers={shopVoucherResult.data?.data || []}
          selectedShopVoucher={voucherSelected || undefined}
          totalItemPrice={totalItemPrice}
          evtOnClick={(voucher) => {
            if (voucher.id === voucherSelected?.id) {
              setVoucherSelected(null)
              dispatch(
                modifyShop({
                  shopId,
                  shopVoucherId: null,
                })
              )
            } else {
              setVoucherSelected(voucher)
              dispatch(
                modifyShop({
                  shopId,
                  shopVoucherId: voucher.id,
                })
              )
            }
            voucherModalOnClose()
          }}
        />
        <ShopCourierSelectionModal
          isOpen={courierModalIsOpen}
          onClose={courierModalOnClose}
          selectedCourier={courierSelected || undefined}
          shopCouriers={shopCouriersCostResult.data || []}
          evtOnClick={(courier) => {
            setCourierSelected(courier)
            dispatch(
              modifyShop({
                shopId,
                shopCourierId: courier.id,
              })
            )
            courierModalOnClose()
          }}
        />
      </Portal>

      <Flex
        pt={2}
        pb={5}
        gap={5}
        borderBottom='3px solid'
        borderColor='gray.200'
        flexDirection='column'
      >
        <Box>
          <Text fontWeight='bold' fontSize='1.2rem'>
            {shopName}
          </Text>
        </Box>
        <Flex flexDirection='column' px={5} gap={5}>
          {Object.keys(products).map((val) => (
            <CheckoutStoreProduct
              key={val}
              shopId={shopId}
              {...products[val]}
            />
          ))}
        </Flex>
        <Flex
          flexDirection='column'
          bgColor='primary.50'
          gap={3}
          px={5}
          pt={5}
          justifyItems='center'
        >
          <Flex
            borderTop='1px dashed'
            borderBottom='1px dashed'
            borderColor='gray.300'
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            gap={3}
            py={2}
          >
            <SecondaryButton
              type='button'
              onClick={(e) => {
                e.preventDefault()
                voucherModalOnOpen()
              }}
            >
              {voucherSelected ? voucherSelected.code : 'Voucher Selection'}
            </SecondaryButton>
            <Box textAlign='right' ml='auto'>
              <Text fontWeight='bold' fontSize='1.3rem'>
                {voucherSelected
                  ? `Rp.\xa0${convertToRupiah(totalDiscount * -1)}`
                  : '-'}
              </Text>
            </Box>
          </Flex>
          <Flex
            alignItems={{ base: 'flex-start', md: 'center' }}
            flexDirection={{ base: 'column', md: 'row' }}
            gap={3}
            borderBottom='1px solid'
            borderColor='primary.100'
            pb={5}
          >
            <SecondaryButton
              type='button'
              display='block'
              whiteSpace='normal'
              h='min-content'
              py={3}
              px={3}
              onClick={(e) => {
                e.preventDefault()
                courierModalOnOpen()
              }}
            >
              {courierSelected ? (
                <Box w='full' textAlign='left'>
                  <Box noOfLines={1}>
                    <Text fontWeight='bold' fontSize='1.1rem'>
                      {courierSelected.name} - {courierSelected.service}
                    </Text>
                  </Box>
                  <Text fontWeight='thin'>
                    Rp.&nbsp;{convertToRupiah(courierSelected.price)}
                  </Text>
                  <Text fontWeight='thin'>
                    Est.&nbsp;
                    {moment()
                      .add(courierSelected.etd, 'days')
                      .format('DD MMM YYYY')}
                  </Text>
                </Box>
              ) : (
                'Courier Selection'
              )}
            </SecondaryButton>
            <Box textAlign='right' ml='auto'>
              <Text fontWeight='bold' fontSize='1.3rem'>
                {courierSelected?.price
                  ? `Rp.\xa0${convertToRupiah(courierSelected.price)}`
                  : '-'}
              </Text>
            </Box>
          </Flex>
          <Box textAlign='right' ml='auto' pb={3}>
            <Text fontWeight='bold' fontSize='1.5rem'>
              {courierSelected ? `Rp.\xa0${convertToRupiah(subtotal)}` : '-'}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default CheckoutOrderDetail
