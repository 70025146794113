import React from 'react'
import { Box, Center, Container, Heading } from '@chakra-ui/react'
import RecommendedPanel from '../../components/recommendedPanel/index'
import PromotionBanner from '../../components/promotionBanner'
import CategoryPanel from '../../components/categoryPanel/index'

const HomePage = () => {
  return (
    <Container maxW={'6xl'} p={5}>
      <PromotionBanner />
      <Box
        rounded={'md'}
        border={'1px solid black'}
        borderColor={'primary.500'}
        p={'5'}
        mt={6}
      >
        <Center>
          <Heading as='h1' mb={5} color='primary.500' fontWeight='bold' py={2}>
            CATEGORIES
          </Heading>
        </Center>
        <CategoryPanel />
      </Box>
      <Box
        mt={8}
        rounded={'md'}
        border={'1px solid black'}
        borderColor={'primary.500'}
        p={'5'}
      >
        <Center>
          {' '}
          <Heading as='h1' color='primary.500' fontWeight='bold' py={2}>
            FOR YOU
          </Heading>
        </Center>
        <RecommendedPanel />
      </Box>
    </Container>
  )
}

export default HomePage
