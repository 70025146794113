import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaStar } from 'react-icons/fa'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import CustomForm from '..'
import { useCreateReviewMutation } from '../../../features/reviews/reviewsSlice'
import FileInput from '../../inputs/FileInput'
import Rating from '../../ratingItem'
import { CreateRating } from '../entities'
import './ratingForm.css'

const RatingForm = (props: {
  orderDetailID: string
  name: string
  variant: string
  thumbnail_url: string
  product_id: string
  onClose: () => void
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [images, setImages] = useState<File[]>([])

  const schema = yup.object().shape({
    rating: yup.number().min(1).max(5).required(),
  })

  const handleDeleteImage = (index: number) => {
    const urls = imageUrls.slice()
    const imgs = images.slice()

    urls.splice(index, 1)
    imgs.splice(index, 1)

    setImageUrls(urls)
    setImages(imgs)
  }

  const imageInput = useRef<HTMLInputElement>(null)

  const handleAddImage = async (e: any) => {
    setImageUrls([...imageUrls, URL.createObjectURL(e.target.files[0])])
    setImages([...images, e.target.files[0]])

    e.target.files = null
  }

  const stars = Array(5).fill(0)
  const [starValue, setStarValue] = useState(0)
  const [hoverValue, setHoverValue] = useState(undefined)

  const handleClick = (value: any) => {
    setStarValue(value)
    setValue('rating', value)
  }
  const handleMouseOver = (newHoverValue: any) => {
    setHoverValue(newHoverValue)
  }
  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

  const colors = {
    orange: '#FFBA5A',
    grey: '#a9a9a9',
  }

  const [createReview] = useCreateReviewMutation()

  const onSubmit = (values: CreateRating) => {
    const formData = new FormData()

    if (images.length !== 0) {
      images.forEach((image: File) => formData.append('image', image))
    }

    if (values.comments) {
      formData.append('message', values.comments)
    }

    formData.append('rating', values.rating.toString())
    formData.append('order_detail_history_id', props.orderDetailID)
    formData.append('product_id', props.product_id)

    const promiseCreate = createReview({ data: formData })
      .unwrap()
      .then(() => props.onClose())

    toast.promise(promiseCreate, {
      pending: 'loading...',
      success: 'Success Give Review',
      error: {
        render({ data }: any) {
          return `${data}`
        },
      },
    })
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateRating>({
    resolver: yupResolver(schema),
  })

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Add review'
    >
      <Box>
        <Rating
          name={props.name}
          variant={props.variant}
          thumbnailUrl={props.thumbnail_url}
        />
        <Box className='reviewContainer'>
          <FormControl
            className='starContainer'
            {...register('rating')}
            isRequired
            isInvalid={!!errors.rating}
          >
            <Text fontSize='xl'>Give rating</Text>
            <VStack>
              <Box className='star' fontSize='3xl'>
                {stars.map((_, index) => {
                  return (
                    <Icon
                      as={FaStar}
                      key={index}
                      size={24}
                      onClick={() => handleClick(index + 1)}
                      onMouseOver={() => handleMouseOver(index + 1)}
                      onMouseLeave={handleMouseLeave}
                      color={
                        (hoverValue || starValue) > index
                          ? colors.orange
                          : colors.grey
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  )
                })}
              </Box>
              <FormErrorMessage>{errors.rating?.message}</FormErrorMessage>
            </VStack>
          </FormControl>
          <FormControl>
            <Box>
              <Textarea
                {...register('comments')}
                placeholder='Share your experience with the product!'
              ></Textarea>
            </Box>
          </FormControl>
        </Box>
        <FormControl>
          <FormLabel fontSize='xl'>Upload product image</FormLabel>
          <HStack overflow={'scroll'}>
            {imageUrls.map((element: any, index: number) => (
              <Box key={index} minW='100px' minH='100px' position='relative'>
                <IconButton
                  position='absolute'
                  zIndex={1}
                  bottom={0}
                  isDisabled={images.length === 1}
                  aria-label='Delete image'
                  onClick={() => handleDeleteImage(index)}
                  size='sm'
                  icon={<CloseIcon />}
                />
                <Image
                  boxSize='100px'
                  objectFit='cover'
                  src={element}
                  alt='product image'
                />
              </Box>
            ))}
            {imageUrls.length < 5 && (
              <IconButton
                minW={'100px'}
                minH={'100px'}
                onClick={() => imageInput?.current?.click()}
                aria-label='Add Image'
                colorScheme='primary'
                icon={<AddIcon />}
              />
            )}
          </HStack>
          <FileInput
            display='none'
            ref={imageInput}
            accept='.png,.jpg,.webp'
            onChange={handleAddImage}
          />
        </FormControl>
      </Box>
    </CustomForm>
  )
}

export default RatingForm
