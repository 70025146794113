import { ButtonGroup, ButtonProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import PrimaryButton from '../buttons/PrimaryButton'
import CustomButton from '../buttons'

const CustomForm = (props: {
  onSubmit: () => void
  onReset?: () => void
  withButton?: boolean
  submitLabel?: string
  resetButton?: ButtonProps
  isLoading: boolean
  children: ReactNode
}) => {
  const {
    onSubmit,
    onReset,
    isLoading,
    submitLabel,
    resetButton,
    withButton = true,
    children,
  } = props

  return (
    <form onSubmit={onSubmit} onReset={onReset}>
      {children}
      <ButtonGroup w='full' justifyContent='end' pt={3}>
        {withButton && (
          <PrimaryButton w='full' isLoading={isLoading} type='submit'>
            {submitLabel ?? 'Submit'}
          </PrimaryButton>
        )}
        {onReset && (
          <CustomButton
            variant='outline'
            w='full'
            isLoading={isLoading}
            type='reset'
            {...resetButton}
          >
            {resetButton?.children ?? 'Reset'}
          </CustomButton>
        )}
      </ButtonGroup>
    </form>
  )
}

export default CustomForm
