import { Box, Select, Tab, TabList, Tabs } from '@chakra-ui/react'
import React from 'react'
import { TabsProps } from '../entities'

const OrderStatusTabs = (props: TabsProps<string[]>) => {
  const { data, selectedIndex, onClick } = props

  return (
    <Box overflowY='auto'>
      <Tabs index={selectedIndex}>
        <TabList>
          {data.slice().map((tab: string, index: number) => (
            <Tab key={index} onClick={() => onClick(index)}>
              {tab}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  )
}

export default OrderStatusTabs
