import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import { CustomInputProps } from './entities'
import CustomInput from './index'

const FileInput = forwardRef<CustomInputProps, 'input'>((props, ref) => {
  const { placeholder, isRequired, isInvalid, errorMessage, ...rest } = props
  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid} my={4}>
      <FormLabel color={useColorModeValue('gray.600', 'gray.200')}>
        {placeholder}
      </FormLabel>
      {/* Empty placeholder is required for behavior of the label */}
      <CustomInput
        {...rest}
        ref={ref}
        type='file'
        sx={{
          '::file-selector-button': {
            border: 'none',
            outline: 'none',
            height: '100%',
          },
        }}
        pl={0}
        placeholder=' '
      />
      {/* Error message will be displayed if isInvalid is true */}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

export default FileInput
