import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

const LoadingSpinner = () => {
  return (
    <Center p={2}>
      <Spinner
        color='primary.400'
        thickness='4px'
        emptyColor='gray.200'
        speed='0.65s'
        size='lg'
      />
    </Center>
  )
}

export default LoadingSpinner
