import { Text, VStack } from '@chakra-ui/react'
import React from 'react'
import CustomImage from '../images'

const ErrorState = () => {
  return (
    <VStack>
      <CustomImage width='100px' height='100px' src='/assets/svgs/error.svg' />
      <Text>Something went wrong</Text>
    </VStack>
  )
}

export default ErrorState
