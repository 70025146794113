import React from 'react'
import { ButtonProps } from '@chakra-ui/react'
import CustomButton from './index'

const PrimaryButton = (props: ButtonProps) => {
  const { ...rest } = props
  return <CustomButton {...rest} />
}

export default PrimaryButton
