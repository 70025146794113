import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Icon,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import CustomImage from '../images'
import StepInput from '../inputs/StepInput'
import IndeterminateCheckbox from '../inputs/IndeterminateCheckbox'
import convertToRupiah from '../../helpers/currencyFormat'
import { CartItem } from '../../features/carts/entities'
import { TransactionSliceStateProp } from '../../features/slices/transactionSlice/entities'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import {
  addItem,
  modifyItem,
  removeItem,
} from '../../features/slices/transactionSlice/transactionSlice'
import {
  useDeleteItemInCartMutation,
  useUpdateItemInCartMutation,
} from '../../features/carts/cartsSlice'
import { toast } from 'react-toastify'
import { FaRegTrashAlt } from 'react-icons/fa'
import { calculateDiscountedPrice } from '../../helpers/priceCalculation'

let searchFilterTimeout: NodeJS.Timeout

const CartShopItem = ({
  shopId,
  shopName,
  inventory,
  quantity,
  discountType,
  discountAmount,
}: CartItem & {
  shopId: string
  shopName: string
  discountType: string
  discountAmount: number
}) => {
  const dispatch = useDispatch()
  const [updateItemInCart] = useUpdateItemInCartMutation()
  const [deleteItemInCart] = useDeleteItemInCartMutation()
  const { data: transactionData } = useSelector<
    RootState,
    TransactionSliceStateProp
  >((state) => state.transactionSlice)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [amount, setAmount] = useState<number>(quantity)

  useEffect(() => {
    const item = transactionData.shops[shopId]?.products[inventory.id]
    setIsChecked(!!item)
  }, [transactionData])

  return (
    <>
      <Flex
        flexDirection='column'
        flex={1}
        py={3}
        _notLast={{
          borderBottom: '1px solid',
          borderColor: 'gray.100',
        }}
      >
        <Flex gap={5}>
          <IndeterminateCheckbox
            size='lg'
            isChecked={isChecked}
            checked={isChecked}
            onChange={(e) => {
              if (e.target.checked) {
                const price = calculateDiscountedPrice(
                  inventory.price,
                  discountType,
                  discountAmount
                )

                dispatch(
                  addItem({
                    shopId: shopId,
                    shopName: shopName,
                    orderDetail: {
                      id: inventory.id,
                      name: inventory.product?.title || '',
                      quantity,
                      weight: inventory.product?.weight || 1,
                      variantName:
                        (inventory.first_variant_type ||
                          inventory.second_variant_type) &&
                        `${inventory.first_variant_type.name}${
                          inventory.second_variant_type
                            ? `, ${inventory.second_variant_type.name}`
                            : ''
                        }`,
                      price,
                      actualPrice: inventory.price,
                      stock: inventory.stock,
                    },
                  })
                )
              } else {
                dispatch(
                  removeItem({
                    shopId,
                    productId: inventory.id,
                  })
                )
              }
            }}
          />
          <Flex
            flex={1}
            gap={4}
            alignItems='flex-start'
            mb={{
              base: 3,
              md: 0,
            }}
          >
            <ChakraLink as={Link} to={`/products/${inventory.product?.id}`}>
              <CustomImage
                src='https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000'
                w='75px'
                h='75px'
                rounded='sm'
                objectFit='cover'
              />
            </ChakraLink>
            <Flex flexDir={{ base: 'column', md: 'row' }} flex='1'>
              <Box flex={1}>
                <ChakraLink
                  as={Link}
                  to={`/products/${inventory.product?.id}`}
                  fontWeight='bold'
                  textDecoration='none !important'
                >
                  {inventory.product ? inventory.product.title : 'unknown'}
                </ChakraLink>
                {(inventory.first_variant_type ||
                  inventory.second_variant_type) && (
                  <Text color='text.light'>{`${
                    inventory.first_variant_type.name
                  }${
                    inventory.second_variant_type
                      ? `, ${inventory.second_variant_type.name}`
                      : ''
                  }`}</Text>
                )}
              </Box>
              <Flex flexDirection='column' ml='auto' alignItems='flex-end'>
                {discountType !== '' ? (
                  <>
                    <Text as='s' color='text.light' fontSize='0.9rem'>
                      Rp.&nbsp;
                      {convertToRupiah(inventory.price)}
                    </Text>
                    <Text color='text.light' fontSize='0.9rem'>
                      {amount} item(s) | Rp.&nbsp;
                      {convertToRupiah(
                        calculateDiscountedPrice(
                          inventory.price,
                          discountType,
                          discountAmount
                        )
                      )}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text color='text.light' fontSize='0.9rem'>
                      {amount} item(s) | Rp.&nbsp;
                      {convertToRupiah(inventory.price)}
                    </Text>
                  </>
                )}

                <Text fontWeight='bold' fontSize='1.3rem'>
                  Rp.&nbsp;
                  {convertToRupiah(
                    calculateDiscountedPrice(
                      inventory.price,
                      discountType,
                      discountAmount
                    ) * amount
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent='flex-end' alignItems='center' gap={10}>
          <Button
            type='button'
            backgroundColor='transparent'
            onClick={(e) => {
              e.preventDefault()
              deleteItemInCart({ productId: inventory.id })
                .unwrap()
                .then(() => {
                  dispatch(
                    removeItem({
                      shopId,
                      productId: inventory.id,
                    })
                  )
                })
                .catch((err) => toast.error(err))
            }}
          >
            <Icon as={FaRegTrashAlt} fontSize={22} />
          </Button>
          <StepInput
            minValue={1}
            maxValue={inventory.stock}
            defaultValue={quantity}
            totalStock={inventory.stock}
            handleChange={(e) => {
              const value = parseInt(e, 10)
              if (value < 1 && value > inventory.stock) return

              setAmount(value)
              clearTimeout(searchFilterTimeout)

              searchFilterTimeout = setTimeout(() => {
                updateItemInCart({
                  productId: inventory.id,
                  quantity: value,
                })
                  .unwrap()
                  .then((val) => {
                    dispatch(
                      modifyItem({
                        shopId,
                        productId: inventory.id,
                        quantity: val.quantity,
                      })
                    )
                  })
                  .catch((err) => toast.error(err))
              }, 600)
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default CartShopItem
