import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Text,
  Textarea,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import ErrorState from '../../components/errorState'
import LoadingSpinner from '../../components/loadingSpinner'
import { Stack } from '@chakra-ui/react'
import {
  useEditShopDescriptionMutation,
  useGetSelfShopInformationQuery,
} from '../../features/shops/shopsSlice'
import { toast } from 'react-toastify'

const ShopProfilePage = () => {
  const [isDisabled, setDisabled] = useState(true)
  const [isDisplay, setDisplay] = useState('none')
  const { data, error } = useGetSelfShopInformationQuery()
  const [edit] = useEditShopDescriptionMutation()
  const [descriptionValue, setDescription] = useState('')

  const onSaveChanges = () => {
    const promiseEdit = edit({
      description: descriptionValue,
    }).unwrap()

    toast.promise(promiseEdit, {
      pending: 'loading...',
      success: 'Edit shop profile success',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  return (
    <>
      {!data ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorState />
      ) : (
        <Stack gap='3'>
          <Box maxWidth='full' px={['1rem', '3rem', '7rem']} boxShadow='xl'>
            <Box>
              <Text textStyle='h1'>Seller Profile</Text>
              <Text textStyle='h3' color={'grey'}>
                Seller profile to edit description
              </Text>
            </Box>
            <Flex
              paddingTop={['.5rem', '1rem', '1rem']}
              flexDirection='column'
              gap={['.2rem', '.5rem', '.5rem']}
            >
              <Flex flexDirection='row'>
                <Box flex='1'>
                  <Text fontSize={['sm', 'md', 'xl']}>Shop name</Text>
                </Box>
                <Center flex='1'>
                  <Text fontSize={['sm', 'md', 'xl']}>{data.data.name}</Text>
                </Center>
              </Flex>
              <Flex flexDirection={['column', 'column', 'row']}>
                <Box flex='1'>
                  <Flex justifyContent='space-between' alignItems='center'>
                    <Text fontSize={['sm', 'md', 'xl']}>Shop description</Text>
                    <IconButton
                      onClick={() => {
                        setDisabled(false)
                      }}
                      size={['sm', 'md', 'lg']}
                      aria-label='edit-button'
                      icon={<FaPencilAlt />}
                      display={['block', 'none', 'none']}
                      bg='none'
                    ></IconButton>
                  </Flex>
                </Box>
                <Box flex='1'>
                  <Flex
                    flexDirection='column'
                    gap={['.2rem', '.8rem', '.8rem']}
                  >
                    <Flex flexDirection='row'>
                      <Textarea
                        isDisabled={isDisabled}
                        width={'100%'}
                        value={descriptionValue}
                        onChange={(event) => {
                          setDescription(event.target.value)
                        }}
                      ></Textarea>
                      <IconButton
                        width={'8%'}
                        onClick={() => {
                          setDisabled(false)
                          setDisplay('block')
                        }}
                        size={['sm', 'md', 'lg']}
                        aria-label='edit-button'
                        icon={<FaPencilAlt />}
                        display={['none', 'block', 'block']}
                        bg='none'
                      ></IconButton>
                    </Flex>
                    <Center>
                      <Button
                        display={isDisplay}
                        width={'50%'}
                        fontSize={['sm', 'md', 'lg']}
                        onClick={() => {
                          setDisplay('none')
                          setDisabled(true)
                          onSaveChanges()
                        }}
                      >
                        Save changes
                      </Button>
                    </Center>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Stack>
      )}
    </>
  )
}

export default ShopProfilePage
