import Cookies from 'js-cookie'
import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'
import { Address } from './entities'
import { SetAddressFormInputs } from '../../components/form/entities'
import { Profile } from '../users/entities'

export const addressApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserAddresses: builder.query<OKResponse<Array<Address>>, void>({
      query: () => ({
        url: `/addresses/self`,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      providesTags: ['address'],
    }),
    setDefaultAddress: builder.mutation<
      OKResponse<Profile>,
      { address_id: string }
    >({
      query: (data) => ({
        url: `/profile-address`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidatesTags: ['address'],
    }),
    setDefaultShopAddress: builder.mutation<
      OKResponse<any>,
      { shop_location_id: string }
    >({
      query: (data) => ({
        url: `/profile-shop`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidatesTags: ['address', 'users'],
    }),
    createUserAddress: builder.mutation<
      OKResponse<Address>,
      SetAddressFormInputs
    >({
      query: (data: SetAddressFormInputs) => ({
        url: `/addresses`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidatesTags: ['address'],
    }),
    updateUserAddress: builder.mutation<
      OKResponse<Address & { id: string }>,
      SetAddressFormInputs
    >({
      query: (data: SetAddressFormInputs) => ({
        url: '/addresses',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidatesTags: ['address'],
    }),
    deleteUserAddress: builder.mutation<OKResponse<Address>, number>({
      query: (id) => ({
        url: '/addresses',
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        body: JSON.stringify({
          id: id,
        }),
      }),
      invalidatesTags: ['address'],
    }),
    getAddressById: builder.query({
      query: ({ addressId }) => ({
        url: `/addresses?id=${addressId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: ({ data }: OKResponse<Address | null>) => data,
      transformErrorResponse: (error: ErrorResponse) => error.data.message,
      providesTags: ['address'],
    }),
  }),
})

export const {
  useGetUserAddressesQuery,
  useSetDefaultAddressMutation,
  useSetDefaultShopAddressMutation,
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
  useDeleteUserAddressMutation,
  useGetAddressByIdQuery,
  useLazyGetUserAddressesQuery,
} = addressApiSlice
