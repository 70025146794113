import Cookies from 'js-cookie'
import { apiSlice } from '../api/apiSlice'

export const reviewsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductReview: builder.query({
      query: (id: string) => ({
        url: `/reviews/${id}`,
        method: 'GET',
      }),
      providesTags: ['reviews'],
    }),
    createReview: builder.mutation({
      query: ({ data }: any) => ({
        url: '/reviews',
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      invalidatesTags: ['reviews', 'detailTransaction', 'transactions'],
    }),
  }),
})

export const {
  useGetProductReviewQuery,
  useLazyGetProductReviewQuery,
  useCreateReviewMutation,
} = reviewsApiSlice
