import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import AddressCard from '../card/AddressCard'
import { Address } from '../../features/address/entities'

const AddressSelection = ({
  addresses,
  selectedAddress,
  evtOnClick,
}: {
  addresses: Address[]
  selectedAddress?: Address
  evtOnClick: (address: Address) => void
}) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
      }}
      gap={5}
    >
      {addresses.map((val) => (
        <GridItem key={val.id}>
          <AddressCard
            address={val}
            backgroundColor={
              selectedAddress?.id === val.id ? 'primary.300' : 'primary.50'
            }
            onClick={() => {
              evtOnClick({
                ...val,
              })
            }}
            badges={[]}
          />
        </GridItem>
      ))}
    </Grid>
  )
}

export default AddressSelection
