import React from 'react'
import { Box, Button, forwardRef } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import { SwitchButtonInputProps } from './entities'

const SwitchButtonInput = forwardRef<SwitchButtonInputProps, 'button'>(
  (props, ref) => {
    const { isSelected, placeholder, ...rest } = props
    return (
      <Button
        _hover={{
          color: 'primary.400',
          borderColor: 'primary.400',
        }}
        _active={{
          backgroundColor: 'transparent',
        }}
        backgroundColor='white'
        border='1px solid'
        borderColor='gray.300'
        position='relative'
        overflow='hidden'
        fontWeight='normal'
        ref={ref}
        {...rest}
      >
        {isSelected && (
          <>
            <Box
              color='primary.400'
              width='30px'
              height='30px'
              backgroundColor='primary.400'
              position='absolute'
              bottom='-15px'
              right='-15px'
              transform='rotate(45deg)'
            ></Box>
            <Box bottom={0} right={0} position='absolute' fontSize='12px'>
              <FaCheck color='white' />
            </Box>
          </>
        )}
        {placeholder}
      </Button>
    )
  }
)

export default SwitchButtonInput
