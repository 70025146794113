import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import CustomForm from '.'
import { SealabspayFormInputs } from './entities'
import { Box, Text } from '@chakra-ui/react'
import StandardInput from '../inputs/StandardInput'
import { useCreateSealabspayMutation } from '../../features/sealabspay/sealabspaySlice'
import { toast } from 'react-toastify'
import moment from 'moment'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

const SealabspayForm = (props: { onClose: () => void }) => {
  const schema = yup.object().shape({
    name: yup.string().required('Must be not empty').label('Name'),
    card_number: yup
      .string()
      .required('Must be not empty')
      .length(16)
      .label('Card Number'),
    active_date: yup
      .string()
      .required('Must be not empty')
      .label('Active Date'),
  })
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SealabspayFormInputs>({ resolver: yupResolver(schema) })
  const [createSealabspay] = useCreateSealabspayMutation()

  const onSubmit = (values: SealabspayFormInputs) => {
    const promiseCreateSealabspay = createSealabspay({
      name: values.name,
      cardNumber: values.card_number,
      activeDate: moment(values.active_date).format('YYYY-MMM-DD'),
    })
      .unwrap()
      .then(() => {
        props.onClose()
      })

    toast.promise(promiseCreateSealabspay, {
      pending: 'loading...',
      success: 'Success Create Sealabspay',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }
  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isSubmitting}
      submitLabel='Create'
    >
      <StandardInput
        label='Name'
        autoFocus
        {...register('name')}
        isRequired
        type='text'
        isInvalid={!!errors.name}
        errorMessage={errors.name?.message}
      />
      <StandardInput
        label='Card Number'
        {...register('card_number')}
        isRequired
        type='text'
        isInvalid={!!errors.card_number}
        errorMessage={errors.card_number?.message}
      />
      <Text>Active Date</Text>
      <Box mt={3} mb={5}>
        <Controller
          name='active_date'
          control={control}
          defaultValue={new Date()}
          render={({ field: { onChange, value } }) => {
            return (
              <SingleDatepicker
                date={value}
                minDate={moment(new Date()).subtract(1, 'months').toDate()}
                onDateChange={onChange}
                configs={{
                  dateFormat: 'dd-MMM-yyyy',
                }}
              />
            )
          }}
        />
      </Box>
    </CustomForm>
  )
}

export default SealabspayForm
