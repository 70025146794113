import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import convertToRupiah from '../../helpers/currencyFormat'
import CustomImage from '../images'
import StandardInput from '../inputs/StandardInput'
import { CheckoutProductProp } from '../../features/slices/transactionSlice/entities'
import { useDispatch } from 'react-redux'
import { modifyItem } from '../../features/slices/transactionSlice/transactionSlice'

const CheckoutStoreProduct = (
  prop: CheckoutProductProp & { shopId: string }
) => {
  const dispatch = useDispatch()

  const [amount, setAmount] = useState<number>(1)
  const [note, setNote] = useState<string | undefined>()

  useEffect(() => {
    setAmount(prop.quantity)
    if (prop.note) {
      setNote(prop.note)
    }
  }, [])

  return (
    <Flex flexDirection='column'>
      <Flex
        gap={3}
        alignItems='flex-start'
        mb={{
          base: 3,
          md: 0,
        }}
      >
        <CustomImage
          src='https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000'
          w='75px'
          h='75px'
          rounded='sm'
          objectFit='cover'
        />
        <Box flex={1}>
          <Text fontWeight='bold'>{prop.name}</Text>
          {prop.variantName && (
            <Text color='text.light'>{prop.variantName}</Text>
          )}
        </Box>
        <Flex justifyContent='center' textAlign='right' flexDirection='column'>
          <Text color='text.light' fontSize='0.9rem'>
            {amount || 0} item(s) x Rp.&nbsp;{convertToRupiah(prop.price)}
          </Text>
          <Text fontWeight='bold' fontSize='1.3rem'>
            Rp.&nbsp;{convertToRupiah(prop.quantity * prop.price)}
          </Text>
        </Flex>
      </Flex>
      <Flex
        alignItems={{
          base: 'flex-start',
          md: 'center',
        }}
        gap={{
          base: 0,
          md: 5,
        }}
        flexDirection={{
          base: 'column-reverse',
          md: 'row',
        }}
      >
        <StandardInput
          value={note}
          width='250px'
          placeholder='Note'
          onChange={(e) => {
            setNote(e.target.value)
            dispatch(
              modifyItem({
                shopId: prop.shopId,
                productId: prop.id,
                note: e.target.value,
              })
            )
          }}
        />
      </Flex>
    </Flex>
  )
}

export default CheckoutStoreProduct
