import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import React from 'react'
import { useController } from 'react-hook-form'
import { SearchSelectProps } from './entities'

const SearchSelect = ({
  control,
  name,
  id,
  label,
  value,
  isRequired = false,
  ...props
}: SearchSelectProps) => {
  const {
    field: { ref, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <FormControl isRequired={isRequired} py={4} isInvalid={!!error} id={id}>
      <FormLabel>{label}</FormLabel>
      <Select {...rest} {...props} ref={ref} value={value} name={name} />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}
export default SearchSelect
