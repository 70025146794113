import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { resetQuery } from '../../features/sortFilter/sortFilterSlice'
import FilterAccordion from '../accordion/filterAccordion'
import DangerButton from '../buttons/DangerButton'

const FilterDrawerSearchPage = (props: {
  isOpen: boolean
  onClose: () => void
  callbackReset: () => void
}) => {
  const { isOpen, onClose, callbackReset } = props
  const dispatch = useDispatch()

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      size={['xs', 'md', 'lg']}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filter</DrawerHeader>
          <DrawerBody>
            <FilterAccordion />
          </DrawerBody>
          <DrawerFooter gap='1rem'>
            <DangerButton
              w='full'
              onClick={() => {
                dispatch(resetQuery())
                callbackReset()
              }}
            >
              Reset
            </DangerButton>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default FilterDrawerSearchPage
