import { Center, Container, Flex, Heading, Text } from '@chakra-ui/react'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'
import React from 'react'
import CustomImage from '../../components/images'
import PrimaryButton from '../../components/buttons/PrimaryButton'

const PaymentResultPage = () => {
  const params = useParams()
  const type = params.type
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')
  const navigate = useNavigate()

  return (
    <Container
      minW={'xs'}
      maxW={'2xl'}
      rounded={'md'}
      border={'1px solid black'}
      borderColor={'primary.300'}
      p={8}
    >
      <Flex direction={'column'}>
        <Flex justifyContent={'center'}>
          <CustomImage
            width={'300px'}
            height={'auto'}
            src={
              status === 'TXN_PAID'
                ? '/assets/svgs/payment-success.svg'
                : '/assets/svgs/payment-failed.svg'
            }
          />
        </Flex>
        <Flex direction={'column'} mt={3} gap={3}>
          <Center>
            <Heading>
              {status === 'TXN_PAID' ? 'Hoorrayy!!' : 'Sorry...'}
            </Heading>
          </Center>
          <Center>
            <Text color={'text.light'}>
              {status === 'TXN_PAID' && type === 'payment'
                ? 'Your payment has been successfully processed'
                : status === 'TXN_PAID' && type === 'topup'
                ? 'Your topup has been successfully processed'
                : status === 'TXN_BLOCKED' && type === 'payment'
                ? 'Your wallet is temporarily blocked, please try again later'
                : 'Your payment has been canceled or declined'}
            </Text>
          </Center>
          <Center>
            <PrimaryButton
              onClick={() => {
                {
                  type === 'topup'
                    ? navigate('/profile/wallet')
                    : navigate('/transaction')
                }
              }}
              type='button'
              fontWeight='medium'
            >
              {type === 'topup'
                ? 'Go To My Wallet'
                : 'Go To My Purchase History'}
            </PrimaryButton>
          </Center>
        </Flex>
      </Flex>
    </Container>
  )
}

export default PaymentResultPage
