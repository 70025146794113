import Cookies from 'js-cookie'
import { ProductCardData } from '../../components/productCard/entities'
import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'
import { Product, ProductsWithMeta } from './entities'

export const productsApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductById: builder.query({
      query: (id: any) => ({
        url: `/products/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: OKResponse<Product>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['products'],
    }),
    getProductsByCategory: builder.query({
      query: ({ categoryId }: any) => ({
        url: `/products/categories/${categoryId}`,
        method: 'GET',
      }),
      transformResponse: (response: OKResponse<Product[] | null>) => {
        if (typeof response.data === null) return []

        const data = response.data ? response.data : []
        if (data.length === 0) return []

        const productCardDatas: ProductCardData[] = data.map((el) => ({
          ...el,
          name: el.title,
          percent_sold: el.units_sold / el.total_stock,
          location: '',
          is_discount: false,
          price: 0,
          discount: 0,
          discount_type: '',
          final_price: 0,
          thumbnail_url:
            'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
          average_rating: 0,
          category_id: '',
          condition: '',
          description: '',
          favorite_count: 0,
          height: 0,
          inventories: [],
          is_hazardous: false,
          length: 0,
          preorder_days: 0,
          shop_id: '',
          shop_product_category_id: '',
          sku: '',
          title: '',
          total_stock: 0,
          units_sold: 0,
          variant_groups: [],
          view_count: 0,
          weight: 0,
          width: 0,
          discount_amount: 0,
          min_price: 0,
          min_discount_price: 0,
          max_price: 0,
          max_discount_price: 0,
          product_media: [],
        }))

        return productCardDatas
      },
      providesTags: ['products'],
    }),
    getProductsByShopCategory: builder.query({
      query: ({ categoryId, query }: any) => ({
        url: `/products/shop-categories/${categoryId}?${query ?? ''}`,
        method: 'GET',
      }),
      transformResponse: (response: OKResponse<ProductsWithMeta>) => {
        if (typeof response.data === null) return []

        const datas = response.data?.data ? response.data?.data : []
        if (datas.length === 0) return []

        const productCardDatas: Array<Product> = datas.map((data) => ({
          ...data,
          name: data.title,
          percent_sold: data.units_sold / data.total_stock,
          location: data?.shop?.location,
          is_discount: data.discount_type !== '',
          discount_type: data.discount_type,
          discount: data.discount_amount,
          thumbnail_url:
            data.product_media?.at(0)?.media_url ||
            'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
          // template response
          // please update later, after the actual implementation provided by BE
          price: 0,
          final_price: 0,
        }))

        const products: ProductsWithMeta = {
          total_items: response.data?.total_items,
          total_pages: response.data?.total_pages,
          data: productCardDatas,
          limit: response.data?.limit,
          page: response.data?.page,
          search: response.data?.search,
          sort: response.data?.sort,
          sort_by: response.data?.sort_by,
        }

        return products
      },
      providesTags: ['products'],
    }),
    createProduct: builder.mutation({
      query: ({ data }: any) => ({
        url: '/shops/products',
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        transformErrorResponse: (response: ErrorResponse) =>
          response.data.message,
      }),
      invalidatesTags: ['products'],
    }),
    updateProduct: builder.mutation({
      query: ({ id, data }: any) => ({
        url: `/shops/products/${id}`,
        method: 'PUT',
        body: data,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        transformErrorResponse: (response: ErrorResponse) =>
          response.data.message,
      }),
      invalidatesTags: ['products'],
    }),
    deleteProduct: builder.mutation({
      query: ({ id }: any) => ({
        url: `/shops/products/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        transformErrorResponse: (response: ErrorResponse) =>
          response.data.message,
      }),
      invalidatesTags: ['products'],
    }),
    getSearchProduct: builder.query({
      query: (query: any) => ({
        url: `/products?limit=30${query}`,
        method: 'GET',
      }),
      transformResponse: (response: OKResponse<ProductsWithMeta>) => {
        if (typeof response.data === null) return []

        const datas = response.data?.data ? response.data?.data : []
        if (datas.length === 0) return []

        const productCardDatas: Array<Product> = datas.map((data) => ({
          ...data,
          name: data.title,
          percent_sold: data.units_sold / data.total_stock,
          location: data?.shop?.location,
          is_discount: data.discount_type !== '',
          discount_type: data.discount_type,
          discount: data.discount_amount,
          thumbnail_url:
            data.product_media?.at(0)?.media_url ||
            'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
          // template response
          // please update later, after the actual implementation provided by BE
          price: 0,
          final_price: 0,
        }))

        const products: ProductsWithMeta = {
          total_items: response.data?.total_items,
          total_pages: response.data?.total_pages,
          data: productCardDatas,
          limit: response.data?.limit,
          page: response.data?.page,
          search: response.data?.search,
          sort: response.data?.sort,
          sort_by: response.data?.sort_by,
        }

        return products
      },

      providesTags: ['products'],
    }),
    getShopProducts: builder.query({
      query: ({ id, query }: any) => ({
        url: `/shops/${id}/products?${query ?? ''}`,
        method: 'GET',
      }),
      providesTags: ['products'],
      transformResponse: (response: OKResponse<ProductsWithMeta>) => {
        if (typeof response.data === null) return []

        const datas = response.data?.data ? response.data?.data : []
        if (datas.length === 0) return []

        const productCardDatas: Array<Product> = datas.map((data) => ({
          ...data,
          id: data.id,
          name: data.title,
          percent_sold: data.units_sold / data.total_stock,
          location: data?.shop?.location,
          is_discount: data.discount_type !== '',
          discount_type: data.discount_type,
          discount: data.discount_amount,
          thumbnail_url:
            data.product_media?.at(0)?.media_url ||
            'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
          // template response
          // please update later, after the actual implementation provided by BE
          price: 0,
          final_price: 0,
        }))

        const products: ProductsWithMeta = {
          total_items: response.data?.total_items,
          total_pages: response.data?.total_pages,
          data: productCardDatas,
          limit: response.data?.limit,
          page: response.data?.page,
          search: response.data?.search,
          sort: response.data?.sort,
          sort_by: response.data?.sort_by,
        }

        return products
      },
    }),
    getRecommendedProducts: builder.query({
      query: () => ({
        url: '/products/recommended',
      }),
      transformResponse: (
        response: OKResponse<Array<Product>>
      ): Array<Product> => {
        if (typeof response.data === null) return []

        const datas = response.data ? response.data : []
        if (datas.length === 0) return []

        const productCardDatas: Array<Product> = datas.map((data) => ({
          ...data,
          id: data.id,
          name: data.title,
          percent_sold: data.units_sold / data.total_stock,
          location: data?.shop?.location,
          is_discount: data.discount_type !== '',
          discount_type: data.discount_type,
          discount: data.discount_amount,
          thumbnail_url:
            data.product_media?.at(0)?.media_url ||
            'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
          // template response
          // please update later, after the actual implementation provided by BE
          price: 0,
          final_price: 0,
        }))

        return productCardDatas
      },
    }),

    getProductIsFavorite: builder.query({
      query: (id: string) => ({
        url: `/products/${id}/favorites`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: ({ data }: OKResponse<boolean>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['isFavorite'],
    }),
    favoriteProduct: builder.mutation({
      query: (id: string) => ({
        url: `/products/${id}/favorites`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        transformErrorResponse: (response: ErrorResponse) =>
          response.data.message,
        invalidatesTags: ['isFavorite'],
      }),
    }),
    getFavoritesProduct: builder.query({
      query: () => ({
        url: `/favorites`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<Product[] | null>) => {
        if (typeof response.data === null) return []

        const datas = response.data ? response.data : []
        if (datas.length === 0) return []

        const productCardDatas: Array<Product> = datas.map((data) => ({
          ...data,
          id: data.id,
          name: data.title,
          percent_sold: data.units_sold / data.total_stock,
          location: data?.shop?.location,
          is_discount: data.discount_type !== '',
          discount_type: data.discount_type,
          discount: data.discount_amount,
          thumbnail_url:
            data.product_media?.at(0)?.media_url ||
            'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
          // template response
          // please update later, after the actual implementation provided by BE
          price: 0,
          final_price: 0,
        }))

        // const products: ProductsWithMeta = {
        //   total_items: response.data?.total_items,
        //   total_pages: response.data?.total_pages,
        //   data: productCardDatas,
        //   limit: response.data?.limit,
        //   page: response.data?.page,
        //   search: response.data?.search,
        //   sort: response.data?.sort,
        //   sort_by: response.data?.sort_by,
        // }

        return productCardDatas
      },

      providesTags: ['products'],
    }),
  }),
})

export const {
  useGetProductsByCategoryQuery,
  useLazyGetProductsByCategoryQuery,
  useLazyGetProductsByShopCategoryQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetSearchProductQuery,
  useGetShopProductsQuery,
  useGetRecommendedProductsQuery,
  useLazyGetRecommendedProductsQuery,
  useLazyGetShopProductsQuery,
  useLazyGetSearchProductQuery,
  useLazyGetProductIsFavoriteQuery,
  useFavoriteProductMutation,
  useLazyGetFavoritesProductQuery,
} = productsApiSlice
