import { apiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import { OKResponse } from '../entities'
import { ShopInformation } from './entities'
import { ShopCreationInputs } from '../../components/form/entities'

export const shopsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSelfShopInformation: builder.query<OKResponse<ShopInformation>, void>({
      query: () => ({
        url: '/shops/self',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      providesTags: ['shops'],
    }),
    createShop: builder.mutation<null, ShopCreationInputs>({
      query: (model) => ({
        url: '/shops/register',
        method: 'POST',
        body: model,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      invalidatesTags: ['shops'],
    }),
    editShopDescription: builder.mutation({
      query: (model) => ({
        url: '/shops/description',
        method: 'PATCH',
        body: model,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      invalidatesTags: ['shops'],
    }),
    getShopInformation: builder.query<
      OKResponse<ShopInformation>,
      string | undefined
    >({
      query: (id) => ({
        url: `/shops/${id}`,
        method: 'GET',
      }),
      providesTags: ['shops'],
    }),
  }),
})

export const {
  useCreateShopMutation,
  useEditShopDescriptionMutation,
  useGetShopInformationQuery,
  useLazyGetShopInformationQuery,
  useGetSelfShopInformationQuery,
} = shopsApiSlice
