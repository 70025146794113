import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Voucher } from '../../features/vouchers/entities'
import CheckoutMarketplaceVoucherCard from '../card/CheckoutMarketplaceVoucherCard'

const MarketplaceVoucherSelection = ({
  vouchers,
  selectedVoucher,
  totalItemPrice,
  evtOnClick,
}: {
  vouchers: Voucher[]
  totalItemPrice: number
  selectedVoucher?: Voucher
  evtOnClick: (voucher: Voucher) => void
}) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
      }}
      maxH='4xl'
      overflow='auto'
      gap={5}
    >
      {vouchers
        .slice()
        .sort((a, b) => b.amount - a.amount)
        .map(
          (val) =>
            totalItemPrice >= val.minimum_payment && (
              <GridItem key={val.id}>
                <CheckoutMarketplaceVoucherCard
                  backgroundColor={
                    totalItemPrice < val.minimum_payment
                      ? 'gray.400'
                      : selectedVoucher?.id === val.id
                      ? 'primary.300'
                      : 'primary.50'
                  }
                  voucher={val}
                  onClick={() => {
                    if (totalItemPrice < val.minimum_payment) return
                    evtOnClick(val)
                  }}
                />
              </GridItem>
            )
        )}
      {vouchers.map(
        (val) =>
          totalItemPrice < val.minimum_payment && (
            <GridItem key={val.id}>
              <CheckoutMarketplaceVoucherCard
                backgroundColor={'gray.400'}
                voucher={val}
                onClick={() => {
                  return
                }}
              />
            </GridItem>
          )
      )}
    </Grid>
  )
}

export default MarketplaceVoucherSelection
