import { Flex, Select, Text } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setSortAndSortBy } from '../../features/sortFilter/sortFilterSlice'

function SortSellerPage() {
  const dispatch = useDispatch()

  return (
    <Flex alignItems='center' justifyContent='flex-end'>
      <Text minWidth='60px'>Sort by:</Text>
      <Select
        variant='filled'
        width={['55%', '40%', '30%']}
        onChange={(event) => {
          dispatch(setSortAndSortBy(event.target.value))
        }}
      >
        <option value='{"sort_by":"created_at", "sort":"asc"}'>
          Newest arrivals
        </option>
        <option value='{"sort_by":"price", "sort":"asc"}'>
          Price: low to high
        </option>
        <option value='{"sort_by":"price", "sort":"desc"}'>
          Price: high to low
        </option>
      </Select>
    </Flex>
  )
}

export default SortSellerPage
