import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import CustomForm from '.'
import { useCreateShopProductCategoryMutation } from '../../features/shopProductCategories/shopProductCategoriesSlice'
import StandardInput from '../inputs/StandardInput'
import { ShopProductCategoryInputs } from './entities'

const EditShopProductCategoryForm = () => {
  const navigate = useNavigate()

  const [createShopCategory] = useCreateShopProductCategoryMutation()

  const schema = yup.object().shape({
    name: yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ShopProductCategoryInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: ShopProductCategoryInputs) => {
    createShopCategory({ data: values })
      .then(() => {
        toast.success('Success creating category', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        navigate('/seller/categories')
      })
      .catch((err: any) => {
        toast.error(err.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
  }

  return (
    <CustomForm
      onSubmit={handleSubmit(onSubmit)}
      submitLabel='Create Category'
      isLoading={isSubmitting}
    >
      <StandardInput
        {...register('name')}
        isInvalid={!!errors.name}
        errorMessage={errors.name?.message}
        isRequired
        label='Category Name'
      />
    </CustomForm>
  )
}

export default EditShopProductCategoryForm
