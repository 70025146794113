import { apiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import { ErrorResponse, OKResponse } from '../entities'
import { Profile, UserShop } from './entities'

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<OKResponse<Profile>, void>({
      query: () => ({
        url: '/profile',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['users'],
    }),
    updateProfileData: builder.mutation<
      OKResponse<Profile>,
      { fullname: string; gender: 'male' | 'female'; birthdate: string }
    >({
      query: (data: {
        fullname: string
        gender: 'male' | 'female'
        birthdate: string
      }) => ({
        url: '/profile/data',
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidatesTags: ['users'],
    }),
    updateProfilePicture: builder.mutation<OKResponse<Profile>, any>({
      query: ({ data }) => ({
        url: '/profile/picture',
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: data,
      }),
      invalidatesTags: ['users'],
    }),
    updateProfilePictureWithUrl: builder.mutation({
      query: ({ data }) => {
        return {
          url: '/profile/picture-url',
          method: 'PATCH',
          headers: {
            Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
          },
          body: JSON.stringify({
            profile_photo: data,
          }),
        }
      },
    }),
    getUsers: builder.query({
      query: () => ({
        url: '/users',
      }),
      providesTags: ['users'],
    }),
    getOwnShop: builder.query({
      query: () => ({
        url: '/shops/self',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: ({ data }: OKResponse<any>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['users'],
    }),
    stepupPassword: builder.mutation({
      query: ({ password }) => ({
        url: '/stepup-password',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: JSON.stringify({ password }),
      }),
      transformResponse: ({ data }: OKResponse<string>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
    sendChangePasswordCode: builder.mutation<OKResponse<null>, void>({
      query: () => ({
        url: '/profile/password',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
    changePassword: builder.mutation<
      OKResponse<null>,
      { token: string; password: string }
    >({
      query: (input: { token: string; password: string }) => ({
        url: '/profile/password',
        method: 'PUT',
        body: { ...input },
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetProfileQuery,
  useGetOwnShopQuery,
  useStepupPasswordMutation,
  useSendChangePasswordCodeMutation,
  useChangePasswordMutation,
  useUpdateProfileDataMutation,
  useUpdateProfilePictureMutation,
  useUpdateProfilePictureWithUrlMutation,
} = usersApiSlice
