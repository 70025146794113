import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { ShopVoucher } from '../../features/shopVouchers/entities'
import CheckoutShopVoucherCard from '../card/CheckoutShopVoucherCard'

const ShopVoucherSelection = ({
  shopVouchers,
  selectedShopVoucher,
  totalItemPrice,
  evtOnClick,
}: {
  shopVouchers: ShopVoucher[]
  totalItemPrice: number
  selectedShopVoucher?: ShopVoucher
  evtOnClick: (voucher: ShopVoucher) => void
}) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
      }}
      maxH='4xl'
      overflow='auto'
      gap={5}
    >
      {shopVouchers
        .slice()
        .sort((a, b) => b.amount - a.amount)
        .map(
          (val) =>
            totalItemPrice >= val.minimum_payment && (
              <GridItem key={val.id}>
                <CheckoutShopVoucherCard
                  backgroundColor={
                    totalItemPrice < val.minimum_payment
                      ? 'gray.400'
                      : selectedShopVoucher?.id === val.id
                      ? 'primary.300'
                      : 'primary.50'
                  }
                  shopVoucher={val}
                  onClick={() => {
                    if (totalItemPrice < val.minimum_payment) return
                    evtOnClick(val)
                  }}
                />
              </GridItem>
            )
        )}
      {shopVouchers.map(
        (val) =>
          totalItemPrice < val.minimum_payment && (
            <GridItem key={val.id}>
              <CheckoutShopVoucherCard
                backgroundColor={'gray.400'}
                shopVoucher={val}
                onClick={() => {
                  return
                }}
              />
            </GridItem>
          )
      )}
    </Grid>
  )
}

export default ShopVoucherSelection
