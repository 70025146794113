const getStringEnv = (name: string) => {
  const val = process.env[name]
  return val ? val : ''
}

const getNumberEnv = (name: string) => {
  const val = process.env[name]
  return val ? parseInt(val, 10) : 0
}

export { getStringEnv, getNumberEnv }
