import {
  Box,
  Divider,
  Flex,
  Text,
  Grid,
  GridItem,
  Badge,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  useCreateDeliveryStatusMutation,
  useGetOrderDetailQuery,
} from '../../../features/orders/ordersSlice'
import CustomImage from '../../images'

import { Step, Steps } from 'chakra-ui-steps'
import { FiInfo } from 'react-icons/fi'
import moment from 'moment'
import StandardInput from '../../inputs/StandardInput'
import PrimaryButton from '../../buttons/PrimaryButton'

const DetailOrderHistory = ({ id }: { id: string }) => {
  const { data: order, isLoading } = useGetOrderDetailQuery({
    orderId: id,
  })
  const [createDeliveryStatus] = useCreateDeliveryStatusMutation()
  const [deliveryStatus, setDeliveryStatus] = useState('')

  return (
    <>
      {order !== null && !isLoading && (
        <Box overflowY={'scroll'} maxH={'750px'}>
          {/* Transaction Detail */}
          <Box shadow={'md'} rounded={'md'} mt={3} p={'4'} mr={3}>
            <Text as={'b'}>{order?.receiver_name}</Text>
            <Text color={'text.light'}>{order?.receiver_city}</Text>
            {order?.status === 'WAITSELLERCONFIRMATION' ? (
              <Badge colorScheme={'gray'}>Waiting for Confirmation</Badge>
            ) : order?.status === 'PROCESSED' ? (
              <Badge colorScheme={'yellow'}>Processed</Badge>
            ) : order?.status === 'ONDELIVERY' ? (
              <Badge colorScheme={'yellow'}>On Delivery</Badge>
            ) : order?.status === 'DELIVERED' ? (
              <Badge colorScheme={'green'}>Delivered</Badge>
            ) : order?.status === 'Completed' ? (
              <Badge colorScheme={'green'}>Completed</Badge>
            ) : order?.status === 'CANCELED' ? (
              <Badge colorScheme={'red'}>Cancelled</Badge>
            ) : (
              ''
            )}
          </Box>

          {/* Delivery Information */}
          <Box
            border={'1px solid black'}
            borderColor={'primary.300'}
            rounded={'md'}
            mt={3}
            p={'4'}
          >
            <Text as={'b'}>Delivery Information</Text>
            <Divider mb={2} mt={2} />
            <Grid templateColumns='repeat(8, 1fr)' gap={4} mb={2}>
              <GridItem colSpan={1}>
                <Text color={'text.light'}>Status</Text>
              </GridItem>
              <GridItem colSpan={7}>
                <Text color={'text.normal'} ml={3} noOfLines={1}>
                  :&nbsp;&nbsp;
                  {order?.status === 'WAITSELLERCONFIRMATION'
                    ? 'Wait for Seller Confirmation'
                    : order?.status === 'PROCESSED'
                    ? 'Processed'
                    : order?.status === 'ONDELIVERY'
                    ? 'On Delivery'
                    : order?.status === 'DELIVERED'
                    ? 'Delivered'
                    : order?.status === 'COMPLETED'
                    ? 'Completed'
                    : order?.status === 'WAITFORPAYMENT'
                    ? 'Wait for Payment'
                    : ''}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(8, 1fr)' gap={4} mb={2}>
              <GridItem colSpan={1}>
                <Text color={'text.light'}>Courier</Text>
              </GridItem>
              <GridItem colSpan={7}>
                <Text color={'text.normal'} ml={3} noOfLines={1}>
                  :&nbsp;&nbsp;{order?.courier.name}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns='repeat(8, 1fr)' gap={4}>
              <GridItem colSpan={1}>
                <Text color={'text.light'}>Address</Text>
              </GridItem>
              <GridItem colSpan={7}>
                <Text color={'text.normal'} ml={3} noOfLines={1}>
                  :&nbsp;&nbsp;{order?.receiver_city}
                </Text>
              </GridItem>
            </Grid>
            <Flex align='end' gap={4}>
              <StandardInput
                label='Update Delivery Status'
                placeholder='Delivery Status'
                value={deliveryStatus}
                onChange={(e) => setDeliveryStatus(e.target.value)}
              />
              <PrimaryButton
                onClick={() =>
                  createDeliveryStatus({
                    data: {
                      order_id: order?.id,
                      status: deliveryStatus,
                    },
                  })
                }
              >
                Update
              </PrimaryButton>
            </Flex>
            {order?.delivery_histories && (
              <Steps mt={8} activeStep={0} orientation='vertical'>
                {order?.delivery_histories
                  .slice()
                  .reverse()
                  .map(({ created_at, status }: any, index: number) => (
                    <Step
                      label={moment(created_at).toLocaleString()}
                      description={status}
                      key={index}
                      icon={FiInfo}
                    />
                  ))}
              </Steps>
            )}
          </Box>

          {/* Orders Detail */}
          {order?.order_details.map((x?: any) => (
            <Box key={x?.id} shadow={'md'} rounded={'md'} mt={3} p={'4'} mr={3}>
              <Box
                border={'1px solid black'}
                borderColor={'primary.300'}
                rounded={'md'}
                mt={3}
                p={'4'}
              >
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent={'space-between'}
                >
                  <Flex direction={'row'} mb={{ base: '5', md: '0' }}>
                    <CustomImage
                      w={'70px'}
                      h={'auto'}
                      mr={'3'}
                      src={x.thumbnail_url}
                    />
                    <Flex direction={'column'}>
                      <Text as={'b'}>{x.title}</Text>
                      <Text color={'text.light'}>
                        {x.quantity} item(s) | Rp{' '}
                        {x.price.toLocaleString('id-ID')}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    direction={'column'}
                    gap={1}
                    px={7}
                    borderLeft={{ base: '', md: '1px solid gray' }}
                    justifyContent={'center'}
                    align={'center'}
                  >
                    <Text>Total Price</Text>
                    <Text as={'b'}>Rp {x.price.toLocaleString('id-ID')}</Text>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

export default DetailOrderHistory
