import {
  Center,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import ErrorState from '../../components/errorState'
import CustomImage from '../../components/images'
import LoadingSpinner from '../../components/loadingSpinner'
import ProductCard from '../../components/productCard'
import { ProductCardData } from '../../components/productCard/entities'
import { useLazyGetFavoritesProductQuery } from '../../features/products/productsSlice'

const FavoritePage = () => {
  const [trigger, { data, isLoading, error }] =
    useLazyGetFavoritesProductQuery()
  const navigate = useNavigate()

  useEffect(() => {
    trigger()
  }, [])
  return (
    <Container
      maxW={'6xl'}
      border={'1px solid black'}
      borderColor={'primary.300'}
      rounded={'md'}
      p={'5'}
      mt={'6'}
    >
      <Center mb={'7'}>
        <Heading color={'primary.600'}>My Favorite Product</Heading>
      </Center>
      {isLoading && <LoadingSpinner />}
      {error && <ErrorState />}
      {!isLoading && !error && (
        <>
          {!data ||
            (data.length === 0 && (
              <Container minW={'xs'} maxW={'2xl'} rounded={'md'} p={8}>
                <Flex direction={'column'}>
                  <Flex justifyContent={'center'}>
                    <CustomImage
                      width={'300px'}
                      height={'auto'}
                      src='/assets/svgs/empty_favorite.svg'
                    />
                  </Flex>
                  <Flex direction={'column'} mt={3} gap={3}>
                    <Center>
                      <Heading>Oops..!</Heading>
                    </Center>
                    <Center>
                      <Text color={'text.light'}>
                        There is no favorite product yet
                      </Text>
                    </Center>
                    <Center>
                      <PrimaryButton
                        onClick={() => navigate('/')}
                        type='button'
                        fontWeight='medium'
                      >
                        Go Browse Product
                      </PrimaryButton>
                    </Center>
                  </Flex>
                </Flex>
              </Container>
            ))}
          {data && data.length > 0 && (
            <SimpleGrid columns={[2, null, 5]}>
              {data?.map((val: ProductCardData) => (
                <ProductCard key={val.id} data={val} />
              ))}
            </SimpleGrid>
          )}
        </>
      )}
    </Container>
  )
}

export default FavoritePage
