import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../components/loadingSpinner'
import { useGetProfileQuery } from '../../features/users/usersSlice'

const GuestRoute = () => {
  const navigate = useNavigate()
  const {
    data: userData,
    isLoading: userIsLoading,
    isUninitialized: userIsUnitialized,
  } = useGetProfileQuery()

  useEffect(() => {
    if (userIsUnitialized || userIsLoading) return
    if (userData) {
      navigate('/')
      return
    }
  }, [userIsLoading])

  return !userData ? <Outlet /> : <LoadingSpinner />
}

export default GuestRoute
