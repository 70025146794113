import React, { useEffect } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import PromotionUpdateForm from '../../../components/form/PromotionUpdateForm'
import {
  useLazyGetShopPromotionByIdQuery,
  useUpdateShopPromotionMutation,
} from '../../../features/shopPromotions/shopPromotionsSlice'
import { toast } from 'react-toastify'

const UpdatePromotionPage = () => {
  const [updateShopPromotion] = useUpdateShopPromotionMutation()
  const navigate = useNavigate()
  const params = useParams()
  const promotionId = params['promotionId']

  const [shopPromotionTrigger, shopPromotionResult] =
    useLazyGetShopPromotionByIdQuery()

  useEffect(() => {
    if (!promotionId) return
    shopPromotionTrigger({ promotionId }, false)
  }, [])

  useEffect(() => {
    if (moment().diff(moment(shopPromotionResult.data?.start_date)) > 0) {
      navigate(`/seller/promotion/${promotionId}`)
    }
  }, [shopPromotionResult])

  return (
    <Box px={{ base: 2, md: '50px' }} py='1rem' flex='1' w='full'>
      <Flex
        flexDirection={{
          md: 'row',
          base: 'column',
        }}
        mb={10}
      >
        <Box flex={1}>
          <Text as='h1' fontWeight='bold' fontSize='2rem'>
            Update Promotion
          </Text>
          <Text>This is page for update your shop promotions</Text>
        </Box>
      </Flex>

      {shopPromotionResult.error && (
        <Text fontWeight='bold' fontSize='2rem' textAlign='center'>
          Promotion not found
        </Text>
      )}

      {shopPromotionResult.isLoading && (
        <Text fontWeight='bold' fontSize='2rem' textAlign='center'>
          Loading promotion
        </Text>
      )}

      {!shopPromotionResult.isUninitialized &&
        !shopPromotionResult.isLoading &&
        !shopPromotionResult.error && (
          <PromotionUpdateForm
            submitButtonTitle='Update Promotion'
            data={
              shopPromotionResult.data && {
                amount: shopPromotionResult.data.amount,
                name: shopPromotionResult.data.name,
                endDate: moment(shopPromotionResult.data.end_date).format(
                  'YYYY-MM-DDTHH:mm'
                ),
                maxOrder: shopPromotionResult.data.max_order,
                minimumPayment: shopPromotionResult.data.minimum_payment,
                quota: shopPromotionResult.data.quota,
                startDate: moment(shopPromotionResult.data.start_date).format(
                  'YYYY-MM-DDTHH:mm'
                ),
                type: shopPromotionResult.data.type,
                promotionProductsIds: shopPromotionResult.data
                  .promotion_products
                  ? shopPromotionResult.data.promotion_products.map(
                      (val) => val.product.id
                    )
                  : [],
              }
            }
            evtFormOnSubmit={async ({ startDate, endDate, ...rest }) => {
              const promiseUpdatePromotion = updateShopPromotion({
                promotionId,
                startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
                endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
                ...rest,
              })
                .unwrap()
                .then(() => {
                  // Actually wanted to use navigate('param')
                  // But since there's will be bug on promotion update, will use this for a while
                  // Bug trigger:
                  // Update promotion page -> Save -> Open the same update promotion page again to see the bugz
                  window.location.replace(`/seller/promotion`)
                })

              toast.promise(promiseUpdatePromotion, {
                pending: 'loading...',
                success: 'Promotion updated',
                error: {
                  render({ data }) {
                    return `${data}`
                  },
                },
              })
            }}
          />
        )}
    </Box>
  )
}

export default UpdatePromotionPage
