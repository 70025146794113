import { Text, VStack } from '@chakra-ui/react'
import React from 'react'
import CustomImage from '../images/index'

const NoReview = () => {
  return (
    <VStack>
      <CustomImage
        width='100px'
        height='100px'
        src='/assets/svgs/no-review.svg'
      />
      <Text>No Review</Text>
    </VStack>
  )
}

export default NoReview
