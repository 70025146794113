import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react'
import React from 'react'
import { CustomInputProps } from './entities'
import CustomInput from './index'

const RightAddonInput = forwardRef<CustomInputProps, 'input'>((props, ref) => {
  const {
    placeholder,
    isRequired,
    isInvalid,
    errorMessage,
    rightAddon,
    label,
    ...rest
  } = props
  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid} my={4}>
      {/* Empty placeholder is required for behavior of the label */}
      <FormLabel ml='1rem'>{label}</FormLabel>
      <InputGroup>
        <CustomInput
          borderRightRadius='0'
          ref={ref}
          {...rest}
          placeholder={placeholder}
        />
        <InputRightAddon minH='44px'>{rightAddon}</InputRightAddon>

        {/* Error message will be displayed if isInvalid is true */}
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

export default RightAddonInput
