import React from 'react'
import CustomModal from '.'
import SealabspayForm from '../form/SealabspayForm'
import { SealabspayCreateModalProps } from './entities'

const SealabspayModal = (props: SealabspayCreateModalProps) => {
  const { isOpen, onClose } = props
  return (
    <CustomModal
      size={{ base: 'sm', md: 'md' }}
      title='Create Sealabspay'
      body={<SealabspayForm onClose={onClose} />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default SealabspayModal
