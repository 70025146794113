import { Flex, Select, Text } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setPage } from '../../features/reviewPages/reviewPagesSlice'
import { setSortAndSortBy } from '../../features/sortFilter/sortFilterSlice'
import ReviewFilter from '../review/reviewFilter'

function SortFilterRating() {
  const dispatch = useDispatch()

  return (
    <Flex
      w='full'
      paddingLeft={1}
      paddingRight={2}
      alignItems='center'
      mt={3}
      flexDirection={['column', 'column', 'row', 'row']}
      justifyContent='space-between'
      gap='20px'
    >
      <Flex
        alignItems='center'
        justifyContent='flex-start'
        minWidth={['50%', '33%', '35%', '28%']}
      >
        <Text minWidth='60px'>Sort by:</Text>
        <Select
          variant='filled'
          onChange={(event) => {
            dispatch(setSortAndSortBy(event.target.value))
            dispatch(setPage(1))
          }}
        >
          <option value='{"sort_by":"created_at", "sort":"desc"}'>
            Newest comments
          </option>
          <option value='{"sort_by":"created_at", "sort":"asc"}'>
            Oldest comments
          </option>
        </Select>
      </Flex>
      <ReviewFilter />
    </Flex>
  )
}

export default SortFilterRating
