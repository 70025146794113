import React from 'react'
import CustomModal from '..'
import { TopupModalProps } from '../entities'
import SealabspaySelection from './SealabspaySelection'

const TopupModal = (props: TopupModalProps) => {
  const { isOpen, onClose } = props

  return (
    <CustomModal
      title='Select Sealabspay'
      size='lg'
      body={<SealabspaySelection sealabsPays={props.sealabsPays} />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default TopupModal
