import { ErrorResponse } from '@remix-run/router'
import Cookies from 'js-cookie'
import { apiSlice } from '../api/apiSlice'
import { OKResponse } from '../entities'
import { ShopPromotion } from './entities'

export const shopPromotionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShopPromotions: builder.query({
      query: ({ status }) => ({
        url: `shops/promotions?status=${status}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<ShopPromotion[]>) =>
        response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
    getShopPromotionById: builder.query({
      query: ({ promotionId }) => ({
        url: `shops/promotions/${promotionId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<ShopPromotion>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
    createShopPromotion: builder.mutation({
      query: ({
        name,
        quota,
        maxOrder: max_order,
        startDate: start_date,
        endDate: end_date,
        type,
        amount,
        minimumPayment: minimum_payment,
        promotionProductsIds: promotion_products_ids,
      }) => {
        return {
          url: 'shops/promotions',
          method: 'POST',
          body: {
            name,
            quota,
            max_order,
            start_date,
            end_date,
            type,
            amount,
            minimum_payment,
            promotion_products_ids,
          },
          headers: {
            Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
          },
        }
      },
      transformResponse: (response: OKResponse<ShopPromotion>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
    updateShopPromotion: builder.mutation({
      query: ({
        promotionId,
        name,
        quota,
        maxOrder: max_order,
        startDate: start_date,
        endDate: end_date,
        type,
        amount,
        minimumPayment: minimum_payment,
        newAddPromotionProductIds: new_add_promotion_product_ids,
        willDeletePromotionProductIds: will_delete_promotion_product_ids,
      }) => ({
        url: `shops/promotions/${promotionId}`,
        method: 'PUT',
        body: {
          name,
          quota,
          max_order,
          start_date,
          end_date,
          type,
          amount,
          minimum_payment,
          new_add_promotion_product_ids,
          will_delete_promotion_product_ids,
        },
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<ShopPromotion>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
  }),
})

export const {
  useLazyGetShopPromotionsQuery,
  useLazyGetShopPromotionByIdQuery,
  useCreateShopPromotionMutation,
  useUpdateShopPromotionMutation,
} = shopPromotionsApiSlice
