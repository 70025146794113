import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import PrimaryButton from '../buttons/PrimaryButton'
import SealabspayConfirmationModal from '../modal/SealabspayConfirmationModal'
import { SealabspayCardProps } from './entities'
import SealabspayCardDetail from './SealabspayCardDetail'

const SealabspayCard = (props: SealabspayCardProps) => {
  const { id, name, number, validDate } = props
  const {
    isOpen: isOpenDefault,
    onOpen: onOpenDefault,
    onClose: onCloseDefault,
  } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  return (
    <>
      <SealabspayConfirmationModal
        isOpen={isOpenDefault}
        onClose={onCloseDefault}
        id={id || ''}
        action={'update'}
        body={'Are you sure want to set this card as default?'}
        buttonPrimaryColor={''}
      />

      <SealabspayConfirmationModal
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        id={id || ''}
        action={'delete'}
        body={'Are you sure want to delete this card?'}
        buttonPrimaryColor={'danger.300'}
      />

      <Box
        bgColor={'primary.50'}
        p={5}
        rounded='md'
        border='1px solid black'
        borderColor={'primary.200'}
        h='full'
      >
        <Flex flexDirection='column' h='100%'>
          <SealabspayCardDetail
            title='Name'
            value={name}
            colorTitle='primary.500'
            colorValue='text.normal'
          />
          <SealabspayCardDetail
            title='Card Number'
            value={number}
            colorTitle='primary.500'
            colorValue='text.normal'
          />
          <SealabspayCardDetail
            title='Valid Date'
            value={validDate}
            colorTitle='primary.500'
            colorValue='text.normal'
          />

          <Flex justifyContent='space-between'>
            <Box
              mt='3'
              ml='3'
              onClick={() => onOpenDelete()}
              style={{ cursor: 'pointer' }}
            >
              <FaTrashAlt fontSize='25px' />
            </Box>
            <PrimaryButton fontSize='0.9rem' onClick={() => onOpenDefault()}>
              Set as Default
            </PrimaryButton>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default SealabspayCard
