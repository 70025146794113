import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import { DataTable } from '../../components/datatable'
import CustomImage from '../../components/images'
import ChangeWalletPinModal from '../../components/modal/ChangeWalletPinModal'
import TopupModal from '../../components/modal/topupModals/TopupModal'
import WalletModal from '../../components/modal/WalletModal'
import { useGetOwnSealabspayQuery } from '../../features/sealabspay/sealabspaySlice'
import { WalletHistories } from '../../features/wallets/entities'
import {
  useGetOwnWalletQuery,
  useLazyGetWalletTransactionQuery,
} from '../../features/wallets/walletsSlice'
import Pagination from 'react-responsive-pagination'
import './index.css'

const WalletPage = () => {
  const { data: wallet } = useGetOwnWalletQuery(null)
  const [trigger, result] = useLazyGetWalletTransactionQuery()
  const columnHelper = createColumnHelper<WalletHistories>()
  const [walletHistories, setWalletHistories] = useState<
    WalletHistories[] | any
  >()
  const { data: sealabsPaysData } = useGetOwnSealabspayQuery(null)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')

  const handlePageClick = (pageInput: number) => {
    walletTransactionRef.current.scrollIntoView()
    setPage(pageInput)
  }

  const columns = [
    columnHelper.accessor('created_at', {
      cell: (info) => moment(info.getValue()).format('DD MMM YYYY'),
      header: 'Date',
      sortingFn: (a, b) =>
        a.original.created_at > b.original.created_at ? 1 : -1,
    }),
    columnHelper.accessor('fromto', {
      cell: (info) => info.getValue().toLocaleUpperCase(),
      header: 'From / To',
      sortingFn: (a, b) => (a.original.amount > b.original.amount ? 1 : -1),
    }),
    columnHelper.accessor('amount', {
      cell: (info) => 'Rp ' + info.getValue().toLocaleString('id-ID'),
      header: 'Amount',
      sortingFn: (a, b) => (a.original.amount > b.original.amount ? 1 : -1),
    }),
    columnHelper.accessor('description', {
      cell: (info) => info.getValue().toLocaleUpperCase(),
      header: 'Description',
      sortingFn: (a, b) =>
        a.original.description > b.original.description ? 1 : -1,
    }),
  ]

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure()

  const {
    isOpen: isOpenChangePin,
    onOpen: onOpenChangePin,
    onClose: onCloseChangePin,
  } = useDisclosure()

  const {
    isOpen: isOpenTopup,
    onOpen: onOpenTopup,
    onClose: onCloseTopup,
  } = useDisclosure()

  useEffect(() => {
    trigger(query)
      .unwrap()
      .then((data) => {
        if (data.data !== null) {
          setWalletHistories(data)
        }
      })
  }, [query])

  useEffect(() => {
    setQuery(`&page=${page}`)
  }, [page])

  const walletTransactionRef =
    useRef() as React.MutableRefObject<HTMLInputElement>

  return (
    <>
      <WalletModal isOpen={isOpenCreate} onClose={onCloseCreate} />
      <ChangeWalletPinModal
        isOpen={isOpenChangePin}
        onClose={onCloseChangePin}
      />
      <TopupModal
        sealabsPays={sealabsPaysData || []}
        isOpen={isOpenTopup}
        onClose={onCloseTopup}
      />
      <Box px={{ base: '5px', md: '50px' }} py='1rem' flex='1'>
        <Flex
          flexDirection={{
            md: 'row',
            base: 'column',
          }}
          mb={10}
        >
          <Box flex={1}>
            <Heading as='h1' fontWeight='bold' fontSize='2rem'>
              Wallet Page
            </Heading>
            <Text>This is page to manage wallet</Text>
          </Box>
        </Flex>

        {wallet === undefined && (
          <Container
            minW={'xs'}
            maxW={'2xl'}
            rounded={'md'}
            border={'1px solid black'}
            borderColor={'primary.300'}
            p={8}
          >
            <Flex direction={'column'}>
              <Flex justifyContent={'center'}>
                <CustomImage
                  width={'300px'}
                  height={'auto'}
                  src='/assets/svgs/wallet.svg'
                />
              </Flex>
              <Flex direction={'column'} mt={3} gap={3}>
                <Center>
                  <Heading>Oops..!</Heading>
                </Center>
                <Center>
                  <Text color={'text.light'}>
                    Please create your wallet first!
                  </Text>
                </Center>
                <Center>
                  <PrimaryButton
                    onClick={() => onOpenCreate()}
                    type='button'
                    fontWeight='medium'
                  >
                    Create Wallet
                  </PrimaryButton>
                </Center>
              </Flex>
            </Flex>
          </Container>
        )}

        {wallet !== undefined && (
          <Container
            maxW='8xl'
            rounded={'md'}
            border={'1px solid black'}
            borderColor={'primary.300'}
            p={8}
          >
            <Flex direction={{ base: 'column', lg: 'row' }}>
              <Center>
                <Flex
                  direction={'column'}
                  mb={{ base: '5', lg: '0' }}
                  w={{ base: '275px', sm: '350px' }}
                  mr={{ base: '0', md: '5' }}
                >
                  <Box rounded={'md'} shadow={'lg'} h={'150px'} p={5}>
                    <Flex
                      direction={'row'}
                      justifyContent={'space-between'}
                      p={3}
                    >
                      <Flex mr={5}>
                        <Center>
                          <FaMoneyBillAlt
                            fontSize={'30px'}
                            style={{ marginRight: '1rem' }}
                          />
                        </Center>
                        <Center>
                          <Flex direction={'column'}>
                            <Text fontSize={'xs'} color={'text.light'}>
                              Balance
                            </Text>
                            <Text as={'b'} color={'text.normal'}>
                              Rp {wallet?.balance.toLocaleString('id-ID')}
                            </Text>
                          </Flex>
                        </Center>
                      </Flex>
                      <Box>
                        <PrimaryButton onClick={() => onOpenTopup()}>
                          Topup
                        </PrimaryButton>
                      </Box>
                    </Flex>
                    <Divider />
                    <Flex justifyContent={'center'} p={3}>
                      <Text as={'b'}>{wallet?.status.toLocaleUpperCase()}</Text>
                    </Flex>
                  </Box>
                  <Box p={5} mt={5}>
                    <Center>
                      <SecondaryButton onClick={() => onOpenChangePin()}>
                        Change Pin
                      </SecondaryButton>
                    </Center>
                  </Box>
                </Flex>
              </Center>
              <Flex
                flex={1}
                rounded={'md'}
                shadow={'lg'}
                p={5}
                direction={'column'}
              >
                <Flex
                  justifyContent={'center'}
                  mb={4}
                  ref={walletTransactionRef}
                >
                  <Text fontSize={'3xl'} color={'text-normal'}>
                    Wallet Transaction
                  </Text>
                </Flex>
                <Box
                  p={2}
                  rounded='md'
                  border={'1px solid black'}
                  borderColor={'primary.300'}
                  overflowX={'scroll'}
                  maxW={['2xs', 'sm', 'md', '6xl']}
                >
                  <Tabs>
                    <TabList>
                      <Tab>All Transaction</Tab>
                    </TabList>
                  </Tabs>
                  {result.data?.total_items === 0 && (
                    <Center mt={4}>Data not Found</Center>
                  )}

                  {walletHistories && (
                    <DataTable columns={columns} data={walletHistories.data} />
                  )}
                </Box>
                {walletHistories && (
                  <Pagination
                    total={walletHistories.total_pages}
                    ariaPreviousLabel={' '}
                    ariaNextLabel={' '}
                    a11yActiveLabel={' '}
                    ariaCurrentAttr={true}
                    current={page}
                    onPageChange={(page) => handlePageClick(page)}
                    className={'navigationButtons'}
                  />
                )}
              </Flex>
            </Flex>
          </Container>
        )}
      </Box>
    </>
  )
}

export default WalletPage
