import { apiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import { OKResponse } from '../entities'
import { ShopCourierProp, ShopCourierShipmentCost } from './entities'
import { ErrorResponse } from '@remix-run/router'

export const shopCouriersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOwnShopCouriers: builder.query({
      query: () => ({
        url: '/couriers',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<ShopCourierProp[] | null>) =>
        response.data || [],
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['shopCouriers'],
    }),
    getShopCouriersShipmentCost: builder.query<
      ShopCourierShipmentCost[],
      {
        shopId: string
        addressId: string
        weight: number
      }
    >({
      query: ({ shopId, addressId, weight }) => ({
        url: `/shops/${shopId}/couriers?address_id=${addressId}&weight=${weight}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (
        response: OKResponse<ShopCourierShipmentCost[] | null>
      ) => response.data || [],
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['shopCouriers'],
    }),
    updateOwnShopCouriers: builder.mutation({
      query: ({ ids }) => ({
        url: '/shops/couriers',
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        body: JSON.stringify({
          courier_ids: ids,
        }),
      }),
      invalidatesTags: ['shopCouriers'],
    }),
  }),
})

export const {
  useLazyGetOwnShopCouriersQuery,
  useLazyGetShopCouriersShipmentCostQuery,
  useUpdateOwnShopCouriersMutation,
} = shopCouriersApiSlice
