import React from 'react'
import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SealabspayCard from '../../components/card/SealabspayCard'
import SealabspayCardDefault from '../../components/card/SealabspayCardDefault'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { useGetOwnSealabspayQuery } from '../../features/sealabspay/sealabspaySlice'
import moment from 'moment'
import LoadingSpinner from '../../components/loadingSpinner'
import SealabspayModal from '../../components/modal/SealabspayModal'
import CustomImage from '../../components/images'

const SealabspayPage = () => {
  const { data: userData, isLoading: isLoadingUser } = useGetProfileQuery()
  const { data: sealabspays, isLoading } =
    useGetOwnSealabspayQuery('getOwnSealabspay')
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure()

  return (
    <>
      <SealabspayModal isOpen={isOpen} onClose={onClose} />

      <Box px='50px' py='1rem' flex='1'>
        <Flex
          flexDirection={{
            md: 'row',
            base: 'column',
          }}
          mb={10}
        >
          <Box flex={1}>
            <Text as='h1' fontWeight='bold' fontSize='2rem'>
              Sealabspay Page
            </Text>
            <Text>This is page to manage sealabspay accounts</Text>
          </Box>
          <Flex alignItems='center'>
            {sealabspays !== null && (
              <PrimaryButton
                type='button'
                fontWeight='medium'
                onClick={() => onOpen()}
              >
                Create Sealabspay
              </PrimaryButton>
            )}
          </Flex>
        </Flex>

        {sealabspays === null && (
          <Container
            minW={'xs'}
            maxW={'2xl'}
            rounded={'md'}
            border={'1px solid black'}
            borderColor={'primary.300'}
            p={8}
          >
            <Flex direction={'column'}>
              <Flex justifyContent={'center'}>
                <CustomImage
                  width={'300px'}
                  height={'auto'}
                  src='/assets/svgs/wallet.svg'
                />
              </Flex>
              <Flex direction={'column'} mt={3} gap={3}>
                <Center>
                  <Heading>Oops..!</Heading>
                </Center>
                <Center>
                  <Text color={'text.light'}>
                    Please create your sealabspay first!
                  </Text>
                </Center>
                <Center>
                  <PrimaryButton
                    type='button'
                    fontWeight='medium'
                    onClick={() => onOpen()}
                  >
                    Create Sealabspay
                  </PrimaryButton>
                </Center>
              </Flex>
            </Flex>
          </Container>
        )}

        {isLoading && (
          <Flex h='100' w='full' alignItems='center' justifyContent='center'>
            {' '}
            <LoadingSpinner />
          </Flex>
        )}

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
            xl: 'repeat(3, 1fr)',
          }}
          gap={6}
        >
          <>
            {!isLoading &&
              !isLoadingUser &&
              sealabspays &&
              sealabspays.map((val) => (
                <>
                  {userData &&
                    val.id == userData.data.default_sealabspay_account_id && (
                      <GridItem w='100%' h='100%' key={val.id}>
                        <SealabspayCardDefault
                          name={val.name}
                          number={val.card_number}
                          validDate={moment(val.active_date).format('MM / YY')}
                        />
                      </GridItem>
                    )}
                </>
              ))}
            {!isLoading &&
              !isLoadingUser &&
              sealabspays &&
              sealabspays.map((val) => (
                <>
                  {userData &&
                    val.id != userData.data.default_sealabspay_account_id && (
                      <GridItem w='100%' h='100%' key={val.id}>
                        <SealabspayCard
                          id={val.id}
                          name={val.name}
                          number={val.card_number}
                          validDate={moment(val.active_date).format('MM / YY')}
                        />
                      </GridItem>
                    )}
                </>
              ))}
          </>
        </Grid>
      </Box>
    </>
  )
}

export default SealabspayPage
