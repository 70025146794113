import { forwardRef, Input, InputProps } from '@chakra-ui/react'
import React from 'react'

const CustomInput = forwardRef<InputProps, 'input'>((props, ref) => {
  const { ...rest } = props
  return (
    <Input
      minH='44px'
      focusBorderColor='primary.400'
      errorBorderColor='danger.400'
      ref={ref}
      {...rest}
    />
  )
})

export default CustomInput
