import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Switch,
  Text,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { DataTable } from '../../../components/datatable'
import ErrorState from '../../../components/errorState'
import CustomImage from '../../../components/images'
import LoadingSpinner from '../../../components/loadingSpinner'
import { useGetUserAddressesQuery } from '../../../features/address/address'
import { useGetCouriersQuery } from '../../../features/couriers/couriersSlice'
import {
  useLazyGetShopCouriersShipmentCostQuery,
  useUpdateOwnShopCouriersMutation,
} from '../../../features/shopCouriers/shopCouriersSlice'
import { useGetSelfShopInformationQuery } from '../../../features/shops/shopsSlice'

const DeliveryPage = () => {
  const {
    data: couriersData,
    isLoading: couriersIsLoading,
    isError: couriersIsError,
  } = useGetCouriersQuery({})
  const {
    data: shopData,
    isLoading: shopIsLoading,
    isError: shopIsError,
  } = useGetSelfShopInformationQuery()
  const [updateOwnShopCouriers, updateOwnShopCouriersResult] =
    useUpdateOwnShopCouriersMutation()
  const {
    data: addressesData,
    isLoading: addressesIsLoading,
    isError: addressesIsError,
  } = useGetUserAddressesQuery()
  const [getShopCouriers, getShopCouriersResult] =
    useLazyGetShopCouriersShipmentCostQuery()

  const [couriers, setCouriers] = useState([])
  const [courierIds, setCourierIds] = useState<Array<any>>([])

  const isLoading =
    couriersIsLoading ||
    getShopCouriersResult.isLoading ||
    updateOwnShopCouriersResult.isLoading ||
    shopIsLoading ||
    addressesIsLoading
  const isError =
    couriersIsError ||
    getShopCouriersResult.isError ||
    updateOwnShopCouriersResult.isError ||
    shopIsError ||
    addressesIsError

  useEffect(() => {
    if (!couriersData) return
    if (!shopData?.data) return
    if (!addressesData?.data) return

    getShopCouriers({
      shopId: shopData?.data.id,
      addressId: addressesData?.data[0].id,
      weight: 1,
    })
      .unwrap()
      .then((res) => setDisplayedCouriers(res))
  }, [couriersData, shopData, addressesData])

  const setDisplayedCouriers = (res: any) => {
    if (!couriersData) return
    const couriers: any = couriersData?.map((val) => ({
      name: {
        name: val.name,
      },
      isActive: {
        id: val.id,
        isActive:
          res.filter((shopCourier: any) => shopCourier.id === val.id).length >
          0,
      },
    }))

    setCouriers(couriers)

    const courierIds: any = res.map((val: any) => val.id)
    setCourierIds(courierIds)
  }
  useEffect(() => {
    if (!getShopCouriersResult.data) return
    setDisplayedCouriers(getShopCouriersResult.data)
  }, [getShopCouriersResult])

  const renderNameCell = ({ name }: any) => {
    return (
      <Box>
        <Text>{name}</Text>
      </Box>
    )
  }

  const renderIsActiveCell = ({ id, isActive }: any) => {
    return (
      <Box>
        <Switch
          defaultChecked={isActive}
          onChange={() => {
            const ids = courierIds.slice()
            isActive ? ids.splice(courierIds.indexOf(id), 1) : ids.push(id)
            updateOwnShopCouriers({ ids })
          }}
        />
      </Box>
    )
  }

  // Generate columns
  const columnHelper = createColumnHelper<any>()
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => renderNameCell(info.getValue()),
      header: 'Name',
    }),
    columnHelper.accessor('isActive', {
      cell: (info) => renderIsActiveCell(info.getValue()),
      header: 'Is Active',
    }),
  ]

  const navigate = useNavigate()

  return (
    <Container maxW='6xl'>
      {isLoading && <LoadingSpinner />}
      {(!isLoading && isError && <ErrorState />) ||
        (!addressesData?.data ? (
          <Container
            minW='xs'
            maxW='2xl'
            rounded='md'
            border='1px solid black'
            borderColor='primary.300'
            p={8}
          >
            <Flex direction='column'>
              <Flex justifyContent='center'>
                <CustomImage
                  width='300px'
                  height='auto'
                  src='/assets/svgs/user-no-address.svg'
                />
              </Flex>
              <Flex direction='column' mt={3} gap={3}>
                <Center>
                  <Heading>Oops..!</Heading>
                </Center>
                <Center>
                  <Text color='text.light'>
                    Address is required before you can manage delivery services.
                  </Text>
                </Center>
                <Center>
                  <Text color='text.light' fontSize='2xs'>
                    Else, the courier will search over all continent to find you
                  </Text>
                </Center>
                <Center>
                  <PrimaryButton
                    onClick={() => navigate('/profile/addresses')}
                    type='button'
                  >
                    Go to Address Page
                  </PrimaryButton>
                </Center>
              </Flex>
            </Flex>
          </Container>
        ) : (
          <DataTable columns={columns} data={couriers} />
        ))}
    </Container>
  )
}

export default DeliveryPage
