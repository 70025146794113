import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { apiSlice } from '../features/api/apiSlice'
import sortFilterReducer from '../features/sortFilter/sortFilterSlice'
import reviewPagesReducer from '../features/reviewPages/reviewPagesSlice'
import transactionSlice from '../features/slices/transactionSlice/transactionSlice'
import transactionCalculation from '../features/slices/transactionCalculationSlice/transactionCalculationSlice'
import authReduxReducer from '../features/auth/authReduxSlice'
import changePasswordTokenTimer from '../features/slices/changePasswordTokenTimerSlice/changePasswordTokenTimerSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    sortFilterReducer,
    reviewPagesReducer,
    transactionSlice,
    transactionCalculation,
    authReduxReducer,
    changePasswordTokenTimer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
