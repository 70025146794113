import { Box, Icon, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { setRatingFilter } from '../../features/sortFilter/sortFilterSlice'
import './reviewFilter.css'

function ReviewFilter() {
  const dispatch = useDispatch()

  return (
    <Box w={['100%', '100%', 'auto']} overflowX='auto'>
      <SimpleGrid
        minW={'max-content'}
        columns={7}
        className='containerRadioReview'
      >
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={1}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>
              <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
              &nbsp;1
            </span>
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={2}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>
              <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
              &nbsp;2
            </span>
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={3}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>
              <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
              &nbsp;3
            </span>
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={4}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>
              <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
              &nbsp;4
            </span>
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={5}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>
              <Icon as={AiFillStar} fontSize='xl' color='yellow.300' />
              &nbsp;5
            </span>
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={'images'}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>with image</span>
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              name='radioReview'
              value={'comments'}
              onChange={(event) => {
                dispatch(setRatingFilter(event.target.value))
              }}
            />
            <span>with comment</span>
          </label>
        </div>
      </SimpleGrid>
    </Box>
  )
}

export default ReviewFilter
