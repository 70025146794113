import React from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import ShopCourierCard from '../card/ShopCourierCard'
import { ShopCourierShipmentCost } from '../../features/shopCouriers/entities'

const ShopCourierSelection = ({
  shopCouriers,
  selectedCourier,
  evtOnClick,
}: {
  shopCouriers: ShopCourierShipmentCost[]
  selectedCourier?: ShopCourierShipmentCost
  evtOnClick: (courier: ShopCourierShipmentCost) => void
}) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
      }}
      maxH='4xl'
      overflow='auto'
      gap={5}
    >
      {shopCouriers.map((val) => (
        <GridItem key={val.id}>
          <ShopCourierCard
            backgroundColor={
              selectedCourier?.id === val.id ? 'primary.300' : 'primary.50'
            }
            shopCourier={val}
            onClick={() => evtOnClick(val)}
          />
        </GridItem>
      ))}
    </Grid>
  )
}

export default ShopCourierSelection
