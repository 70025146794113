import React, { useEffect } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import PromotionCreateForm from '../../../components/form/PromotionCreateForm'
import {
  useCreateShopPromotionMutation,
  useLazyGetShopPromotionByIdQuery,
} from '../../../features/shopPromotions/shopPromotionsSlice'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const CreatePromotionPage = () => {
  const [createShopPromotion] = useCreateShopPromotionMutation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [shopPromotionTrigger, shopPromotionResult] =
    useLazyGetShopPromotionByIdQuery()
  const promotionId = searchParams.get('copy')

  useEffect(() => {
    if (!promotionId) return
    shopPromotionTrigger({ promotionId }, false)
  }, [])

  return (
    <Box px={{ base: 2, md: '50px' }} py='1rem' flex='1' w='full'>
      <Flex
        flexDirection={{
          md: 'row',
          base: 'column',
        }}
        mb={10}
      >
        <Box flex={1}>
          <Text as='h1' fontWeight='bold' fontSize='2rem'>
            Create Promotion
          </Text>
          <Text>This is page for create your shop promotions</Text>
        </Box>
      </Flex>

      {!shopPromotionResult.isLoading && (
        <PromotionCreateForm
          submitButtonTitle='Create Promotion'
          data={
            shopPromotionResult.data && {
              amount: shopPromotionResult.data.amount,
              name: '',
              endDate: '',
              maxOrder: shopPromotionResult.data.max_order,
              minimumPayment: shopPromotionResult.data.minimum_payment,
              quota: shopPromotionResult.data.quota,
              startDate: '',
              type: shopPromotionResult.data.type,
              promotionProductsIds: shopPromotionResult.data.promotion_products
                ? shopPromotionResult.data.promotion_products.map(
                    (val) => val.product.id
                  )
                : [],
            }
          }
          evtFormOnSubmit={async ({ startDate, endDate, ...rest }) => {
            const promiseShopPromotion = createShopPromotion({
              startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
              endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
              ...rest,
            })
              .unwrap()
              .then(() => navigate('/seller/promotion'))

            toast.promise(promiseShopPromotion, {
              pending: 'loading...',
              success: 'Promotion created',
              error: {
                render({ data }) {
                  return `${data}`
                },
              },
            })
          }}
        />
      )}
    </Box>
  )
}

export default CreatePromotionPage
