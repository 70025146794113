import { SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import {
  useGetRecommendedProductsQuery,
  useGetSearchProductQuery,
} from '../../features/products/productsSlice'
import ErrorState from '../errorState'
import LoadingSpinner from '../loadingSpinner'
import NoData from '../noData'
import ProductCard from '../productCard'
import { ProductCardData } from '../productCard/entities'

const RecommendedPanel = () => {
  const { data, error, isLoading } = useGetSearchProductQuery(
    '&sort_by=units_sold&sort=desc'
  )

  return error ? (
    <ErrorState />
  ) : isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {!data.data?.length ? (
        <NoData />
      ) : (
        <SimpleGrid columns={[2, null, 5]}>
          {data?.data.map((val: ProductCardData) => (
            <ProductCard key={val.id} data={val} />
          ))}
        </SimpleGrid>
      )}
    </>
  )
}

export default RecommendedPanel
