import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'
import {
  CheckoutProductProp,
  OrderProp,
  TransactionSliceStateProp,
} from './entities'

const persistConfig: PersistConfig<TransactionSliceStateProp> = {
  key: 'transaction',
  storage,
}

const initialState: TransactionSliceStateProp = {
  data: {
    shops: {},
  },
  isLoading: false,
  isError: false,
  errors: [],
}

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: initialState,
  reducers: {
    addItem: (
      state,
      action: PayloadAction<{
        shopId: string
        shopName: string
        orderDetail: CheckoutProductProp
      }>
    ) => {
      const { shopId, shopName, orderDetail } = action.payload

      if (!state.data.shops[shopId]) {
        state.data.shops[shopId] = {
          id: shopId,
          name: shopName,
          products: {},
        }
      }
      state.data.shops[shopId].products[orderDetail.id] = orderDetail
    },
    removeItem: (
      state,
      action: PayloadAction<{
        shopId: string
        productId: string
      }>
    ) => {
      const { shopId, productId } = action.payload

      if (!state.data.shops[shopId]) return
      delete state.data.shops[shopId].products[productId]

      if (Object.keys(state.data.shops[shopId].products).length === 0) {
        delete state.data.shops[shopId]
      }
    },
    modifyItem: (
      state,
      action: PayloadAction<{
        shopId: string
        productId: string
        quantity?: number
        note?: string
        orderDetail?: CheckoutProductProp
      }>
    ) => {
      const { shopId, productId, quantity, note } = action.payload

      if (!state.data.shops[shopId]?.products[productId]) return

      if (quantity) {
        state.data.shops[shopId].products[productId].quantity = quantity
      }
      if (note) {
        state.data.shops[shopId].products[productId].note = note
      }
    },
    updateItem: (
      state,
      action: PayloadAction<{
        shopId: string
        productId: string
        orderDetail: CheckoutProductProp
      }>
    ) => {
      const { shopId, productId, orderDetail } = action.payload

      if (!state.data.shops[shopId]?.products[productId]) return
      state.data.shops[shopId].products[productId] = orderDetail
    },

    addAllShopItems: (
      state,
      action: PayloadAction<{
        shopId: string
        shopName: string
        orderDetails: CheckoutProductProp[]
      }>
    ) => {
      const { shopId, shopName, orderDetails } = action.payload

      if (!state.data.shops[shopId]) {
        state.data.shops[shopId] = {
          id: shopId,
          name: shopName,
          products: {},
        }
      } else {
        state.data.shops[shopId].products = {}
      }

      orderDetails.forEach((val) => {
        state.data.shops[shopId].products[val.id] = val
      })
    },
    clearAllShopItems: (state, action: PayloadAction<string>) => {
      const shopId = action.payload
      delete state.data.shops[shopId]
    },
    modifyShop: (
      state,
      action: PayloadAction<{
        shopId: string
        shopVoucherId?: string | null
        shopCourierId?: string
      }>
    ) => {
      const { shopId, shopCourierId, shopVoucherId } = action.payload
      const shop = state.data.shops[shopId]

      if (!shop) return

      if (shopVoucherId) {
        shop.voucherId = shopVoucherId
      } else if (shopVoucherId === null) {
        delete shop.voucherId
      }
      if (shopCourierId) {
        shop.courierId = shopCourierId
      }
    },

    addAll: (state, action: PayloadAction<OrderProp[]>) => {
      const orders = action.payload
      const newData: { [shopId: string]: OrderProp } = {}

      orders.forEach((orderVal) => {
        if (!newData[orderVal.id]) {
          newData[orderVal.id] = {
            id: orderVal.id,
            name: orderVal.name,
            products: {},
          }
        } else {
          newData[orderVal.id].products = {}
        }

        const productKeys = Object.keys(orderVal.products)
        productKeys.forEach((productVal) => {
          newData[orderVal.id].products[productVal] =
            orderVal.products[productVal]
        })
      })
      state.data.shops = newData
    },
    clearAll: (state) => {
      state.data = initialState.data
    },
    modifyTransaction: (
      state,
      action: PayloadAction<{
        addressId?: string
        marketplaceVoucherId?: string | null
      }>
    ) => {
      const { addressId, marketplaceVoucherId } = action.payload

      if (addressId) {
        state.data.shipmentAddressId = addressId
      }
      if (marketplaceVoucherId) {
        state.data.voucherId = marketplaceVoucherId
      } else if (marketplaceVoucherId === null) {
        delete state.data.voucherId
      }
    },
  },
})

export const {
  addItem,
  removeItem,
  updateItem,
  modifyItem,
  addAllShopItems,
  clearAllShopItems,
  modifyShop,
  addAll,
  clearAll,
  modifyTransaction,
} = transactionSlice.actions
export default persistReducer(persistConfig, transactionSlice.reducer)
