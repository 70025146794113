import {
  Card,
  CardHeader,
  CardBody,
  Text,
  Flex,
  Avatar,
  Icon,
  Box,
  AspectRatio,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import CustomImage from '../images'
import { ReviewProps } from './entities'

const ReviewCard = (
  props: ReviewProps & {
    cardclick: (media_url: string) => void
  }
) => {
  const { cardclick, ...rest } = props

  return (
    <Card {...rest}>
      <CardHeader>
        <Flex alignItems='center' gap='10px'>
          <Avatar size={['sm', 'md', 'lg']} />
          <Flex flexDirection='column'>
            <Text noOfLines={1}>{props.user.username}</Text>
            <Flex>
              {(() => {
                const arr = []
                for (let i = 0; i < 5; i++) {
                  if (i < props.rating) {
                    arr.push(
                      <Icon
                        as={AiFillStar}
                        fontSize='xl'
                        color='yellow.300'
                        key={i}
                      />
                    )
                  } else {
                    arr.push(
                      <Icon
                        as={AiOutlineStar}
                        fontSize='xl'
                        color='yellow.300'
                        key={i}
                      />
                    )
                  }
                }
                return arr
              })()}
            </Flex>
            <Box mt='1'>
              <Text fontSize='sm' color='gray'>
                {moment(props.created_at).format('DD-MM-YYYY HH:mm')}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </CardHeader>
      {!props.review_media && !props.message ? (
        <Box />
      ) : (
        <CardBody pt='0'>
          {props.review_media && (
            <Flex overflowX='auto' gap='10px'>
              {props.review_media.map((x, index) => (
                <AspectRatio key={index} minW={'100px'} ratio={1}>
                  <CustomImage
                    src={x.media_url}
                    boxSize='120px'
                    __css={{
                      backgroundColor: 'gray.200',
                      borderRadius: '20px',
                    }}
                    alt={`review ${index + 1}`}
                    onClick={() => cardclick(x.media_url)}
                  />
                </AspectRatio>
              ))}
            </Flex>
          )}
          <Box mt='1'>
            {props.message && (
              <Text className='reviewMessage'>{props.message}</Text>
            )}
          </Box>
        </CardBody>
      )}
    </Card>
  )
}

export default ReviewCard
