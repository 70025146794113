import { useGetCategoriesQuery } from '../../features/categories/categoriesSlice'
import { ClickedCategory } from './entities'
import React, { useState } from 'react'
import CustomModal from '.'
import { Category } from '../../features/categories/entities'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import CategoryItem from '../category/CategoryItem'
import PrimaryButton from '../buttons/PrimaryButton'
import LoadingSpinner from '../loadingSpinner'
import ErrorState from '../errorState'

const CategoryModal = (props: {
  isOpen: boolean
  onClose: () => void
  setCategory: (id: string) => void
  setPathString: (path: string) => void
}) => {
  const { isOpen, onClose, setCategory, setPathString } = props

  // Selected category
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  )

  // Categories modal
  const [item, setItem] = useState<ClickedCategory>({})
  const [item2, setItem2] = useState<ClickedCategory>({})
  const {
    data: categories,
    error: categoriesError,
    isLoading: categoriesIsLoading,
  } = useGetCategoriesQuery(null)

  const [selectedFirstCategory, setSelectedFirstCategory] = useState<Category>(
    {} as Category
  )
  const [selectedSecondCategory, setSelectedSecondCategory] =
    useState<Category>({} as Category)
  const [selectedThirdCategory, setSelectedThirdCategory] = useState<Category>(
    {} as Category
  )

  const handleCategoryClick = (
    category: Category,
    id: string,
    categories?: Array<Category>
  ) => {
    setItem({
      parent_id: id,
      data: categories,
    })

    setItem2({
      parent_id: undefined,
      data: undefined,
    })
    setSelectedThirdCategory({} as Category)
    setSelectedSecondCategory({} as Category)
    setSelectedFirstCategory(category)
    setSelectedCategory(category)
  }

  const handleCategory2Click = (
    category: Category,
    id?: string,
    categories?: Array<Category>
  ) => {
    setItem2({
      parent_id: id,
      data: categories,
    })
    setSelectedThirdCategory({} as Category)
    setSelectedSecondCategory(category)
    setSelectedCategory(category)
  }

  const handleCategory3Click = (category: Category) => {
    setSelectedThirdCategory(category)
    setSelectedCategory(category)
  }

  const getCategoryPath = `${
    Object.keys(selectedFirstCategory).length !== 0
      ? selectedFirstCategory.name
      : ''
  } ${
    Object.keys(selectedSecondCategory).length !== 0
      ? ` > ${selectedSecondCategory.name}`
      : ''
  } ${
    Object.keys(selectedThirdCategory).length !== 0
      ? ` > ${selectedThirdCategory.name}`
      : ''
  }`

  return (
    <>
      {categoriesIsLoading && <LoadingSpinner />}
      {categoriesError && <ErrorState />}
      {!categoriesIsLoading && !categoriesError && (
        <CustomModal
          size={'6xl'}
          title='Product Category'
          isOpen={isOpen}
          onClose={onClose}
          body={
            <Box overflow='auto'>
              <Stack
                direction='row'
                w={['80vh', '80vh', '80vh', 'full']}
                maxH='500px'
                overflowY='auto'
                spacing='unset'
              >
                <Box w='33%' minH='350px' bg='gray.300' p={1}>
                  {categories.map((x: Category) => {
                    return (
                      <CategoryItem
                        key={x.id}
                        data={x}
                        onCategoryClick={() => {
                          handleCategoryClick(x, x.id ?? '', x.sub_categories)
                        }}
                        bg={selectedFirstCategory.id === x.id ? 'gray.100' : ''}
                      />
                    )
                  })}
                </Box>
                <Box
                  w='33%'
                  minH='350px'
                  bg='gray.300'
                  p={1}
                  borderX='1px dashed'
                >
                  {item !== undefined &&
                    item.data?.map((x) => {
                      return (
                        <CategoryItem
                          key={x.id}
                          data={x}
                          onCategoryClick={() => {
                            handleCategory2Click(x, x.id, x.sub_categories)
                          }}
                          bg={
                            selectedSecondCategory.id === x.id ? 'gray.100' : ''
                          }
                        />
                      )
                    })}
                </Box>
                <Box w='33%' minH='350px' bg='gray.300' p={1}>
                  {item2 !== undefined &&
                    item2.data?.map((x) => {
                      return (
                        <CategoryItem
                          key={x.id}
                          data={x}
                          onCategoryClick={() => {
                            handleCategory3Click(x)
                          }}
                          bg={
                            selectedThirdCategory.id === x.id ? 'gray.100' : ''
                          }
                        />
                      )
                    })}
                </Box>
              </Stack>
              <Text mt={'8px'} fontSize='md'>
                Selected: {getCategoryPath}
              </Text>
              <Flex mt={'8px'}>
                <PrimaryButton
                  disabled={selectedCategory?.sub_categories?.length !== 0}
                  onClick={() => {
                    setCategory(selectedCategory?.id as string)
                    setPathString(getCategoryPath)
                  }}
                >
                  Confirm
                </PrimaryButton>
              </Flex>
            </Box>
          }
        />
      )}
    </>
  )
}

export default CategoryModal
