import {
  Box,
  Divider,
  Flex,
  Text,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetDetailTransactionsQuery } from '../../../features/transactions/transactionsSlice'
import PrimaryButton from '../../buttons/PrimaryButton'
import SecondaryButton from '../../buttons/SecondaryButton'
import CustomImage from '../../images'
import RatingModal from '../RatingModal'
import UpdateStatusModal from './UpdateStatusModal'

const DetailOrder = ({ id }: { id: string }) => {
  const { data: transaction, isLoading } = useGetDetailTransactionsQuery({
    transactionID: id,
  })

  const calculateTotalPrice = () => {
    let total = 0
    transaction?.orders.forEach((x: any) => (total += x.total_price))

    return total
  }

  const calculateTotalShippingPrice = () => {
    let total = 0
    transaction?.orders.forEach((x: any) => (total += x.shipping_price))

    return total
  }

  const {
    isOpen: isOpenReview,
    onOpen: onOpenReview,
    onClose: onCloseReview,
  } = useDisclosure()

  const {
    isOpen: isOpenComplete,
    onOpen: onOpenComplete,
    onClose: onCloseComplete,
  } = useDisclosure()

  const navigate = useNavigate()

  return (
    <>
      {transaction !== null && !isLoading && (
        <Box overflowY={'scroll'} maxH={'750px'}>
          {/* Transaction Detail */}
          <Box shadow={'md'} rounded={'md'} mt={3} p={'4'} mr={3}>
            <Text as={'b'}>
              {transaction?.status === 'PAID'
                ? 'Paid'
                : transaction?.status === 'WAITFORPAYMENT'
                ? 'Wait for Payment'
                : transaction?.status === 'CANCELED'
                ? 'Canceled'
                : ''}
            </Text>
            <Divider mb={2} mt={2} />
            <Flex justifyContent={'space-between'}>
              <Text color={'text.light'}>Purchased Date</Text>
              <Text color={'text.normal'}>
                {moment(transaction?.created_at).format('DD MMMM YYYY, hh:mm') +
                  ' WIB'}
              </Text>
            </Flex>
          </Box>

          {/* Orders Detail */}
          {transaction?.orders.map((x: any) => (
            <Box key={x.id} shadow={'md'} rounded={'md'} mt={3} p={'4'} mr={3}>
              <Flex justifyContent={'space-between'}>
                <Text as={'b'}>Product Detail</Text>
                <Flex direction={'row'} mb={'2'}>
                  <Text as={'b'}>{x.shop.name}</Text>
                </Flex>
              </Flex>
              <Divider mb={2} />
              {x.order_details.map((y: any) => (
                <>
                  <UpdateStatusModal
                    isOpen={isOpenComplete}
                    onClose={onCloseComplete}
                    id={x.id}
                    status={'COMPLETED'}
                  />
                  <RatingModal
                    isOpen={isOpenReview}
                    onClose={onCloseReview}
                    orderDetailID={y.id}
                    name={y.title}
                    variant={y.inventory.code}
                    thumbnail_url={y.thumbnail_url}
                    productID={y.product_id}
                  />
                  <Box
                    key={y.id}
                    border={'1px solid black'}
                    borderColor={'primary.300'}
                    rounded={'md'}
                    mt={3}
                    p={'4'}
                  >
                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      justifyContent={'space-between'}
                    >
                      <Flex direction={'row'} mb={{ base: '5', md: '0' }}>
                        <CustomImage
                          w={'70px'}
                          h={'60px'}
                          mr={'3'}
                          src={y.thumbnail_url}
                        />
                        <Flex direction={'column'}>
                          <Text
                            as={'b'}
                            cursor={'pointer'}
                            onClick={() =>
                              navigate('/detail-product-history/' + y.id)
                            }
                          >
                            {y.title}
                          </Text>
                          <Text color={'text.light'}>
                            {y.quantity} item(s) | Rp{' '}
                            {y.price.toLocaleString('id-ID')}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        direction={'column'}
                        gap={1}
                        px={7}
                        borderLeft={{ base: '', md: '1px solid gray' }}
                        justifyContent={'center'}
                        align={'center'}
                      >
                        <Text>Total Price</Text>
                        <Text as={'b'}>
                          Rp {y.price.toLocaleString('id-ID')}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      justifyContent={{ base: 'center', md: 'right' }}
                      mt={3}
                    >
                      {!y.is_reviewed && y.shipping_status === 'COMPLETED' && (
                        <SecondaryButton onClick={() => onOpenReview()}>
                          Give Review
                        </SecondaryButton>
                      )}
                    </Flex>
                  </Box>
                </>
              ))}

              {/* Delivery Information */}
              <Box
                border={'1px solid black'}
                borderColor={'primary.300'}
                rounded={'md'}
                mt={3}
                p={'4'}
              >
                <Text as={'b'}>Delivery Information</Text>
                <Divider mb={2} mt={2} />
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  justifyContent={'space-between'}
                >
                  <Box mb={{ base: '2', md: '0' }}>
                    <Grid
                      templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(6, 1fr)',
                      }}
                      gap={4}
                      mb={2}
                    >
                      <GridItem colSpan={1}>
                        <Text color={'text.light'}>Status</Text>
                      </GridItem>
                      <GridItem colSpan={{ base: 3, md: 5 }}>
                        <Text color={'text.normal'} ml={3} noOfLines={1}>
                          :&nbsp;&nbsp;
                          {x.order_status === 'WAITSELLERCONFIRMATION'
                            ? 'Wait for Seller Confirmation'
                            : x.order_status === 'PROCESSED'
                            ? 'Processed'
                            : x.order_status === 'ONDELIVERY'
                            ? 'On Delivery'
                            : x.order_status === 'DELIVERED'
                            ? 'Delivered'
                            : x.order_status === 'COMPLETED'
                            ? 'Completed'
                            : x.order_status === 'WAITFORPAYMENT'
                            ? 'Wait for Payment'
                            : ''}
                        </Text>
                      </GridItem>
                    </Grid>
                    <Grid
                      templateColumns={{
                        base: 'repeat(5, 1fr)',
                        md: 'repeat(6, 1fr)',
                      }}
                      gap={4}
                      mb={2}
                    >
                      <GridItem colSpan={1}>
                        <Text color={'text.light'}>Courier</Text>
                      </GridItem>
                      <GridItem colSpan={{ base: 4, md: 5 }}>
                        <Text color={'text.normal'} ml={3} noOfLines={1}>
                          :&nbsp;&nbsp;{x.courier.name}
                        </Text>
                      </GridItem>
                    </Grid>
                    <Grid
                      templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(6, 1fr)',
                      }}
                      gap={4}
                    >
                      <GridItem colSpan={1}>
                        <Text color={'text.light'}>Address</Text>
                      </GridItem>
                      <GridItem colSpan={{ base: 3, md: 5 }}>
                        <Text color={'text.normal'} ml={3} noOfLines={1}>
                          :&nbsp;&nbsp;{transaction?.address.name}
                        </Text>
                      </GridItem>
                    </Grid>
                  </Box>
                  {x.order_status === 'DELIVERED' && (
                    <Flex justifyContent={'center'} alignItems={'center'}>
                      <PrimaryButton onClick={() => onOpenComplete()}>
                        Complete Order
                      </PrimaryButton>
                    </Flex>
                  )}
                </Flex>
              </Box>
            </Box>
          ))}

          {/* Detail Payment */}
          <Box shadow={'md'} rounded={'md'} mt={3} p={'4'} mr={3} mb={3}>
            <Text as={'b'}>Detail Payment</Text>
            <Divider mb={2} mt={2} />
            <Flex direction={'column'} gap={2}>
              <Flex justifyContent={'space-between'}>
                <Text color={'text.light'}>Payment Method</Text>
                <Text as={'b'}>
                  {transaction?.payment_type.toLocaleUpperCase()}
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Text color={'text.light'}>Total Price</Text>
                <Text color={'text.normal'}>
                  Rp {Number(calculateTotalPrice()).toLocaleString('id-ID')}
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'}>
                <Text color={'text.light'}>Total Shipping Price</Text>
                <Text color={'text.normal'}>
                  Rp{' '}
                  {Number(calculateTotalShippingPrice()).toLocaleString(
                    'id-ID'
                  )}
                </Text>
              </Flex>
              <Divider />
              <Flex justifyContent={'space-between'}>
                <Text as={'b'}>Total Purchase</Text>
                <Text as={'b'}>
                  Rp {transaction?.total_price.toLocaleString('id-ID')}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  )
}

export default DetailOrder
