import React from 'react'
import { Center, Box, Heading } from '@chakra-ui/react'
import ForgotPasswordForm from '../../components/form/ForgotPasswordForm'
import CustomImage from '../../components/images/index'

const ForgotPasswordPage = () => {
  return (
    <Center flexDir='column'>
      <Heading>Forgot Password</Heading>
      <Box my={5}>
        <CustomImage height='200px' src='/assets/svgs/forgot-password.svg' />
      </Box>
      <ForgotPasswordForm />
    </Center>
  )
}

export default ForgotPasswordPage
