import { ButtonGroup, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { toast } from 'react-toastify'
import CustomModal from '.'
import {
  useDeleteSealabspayMutation,
  useUpdateDefaultMutation,
} from '../../features/sealabspay/sealabspaySlice'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import { SealabspayModalProps } from './entities'

const SealabspayConfirmationModal = (props: SealabspayModalProps) => {
  const { isOpen, onClose, id, action, body, buttonPrimaryColor } = props
  const [updateDefault] = useUpdateDefaultMutation()
  const [deleteSealabspay] = useDeleteSealabspayMutation()

  const handleAction = () => {
    if (action === 'update') {
      const promiseUpdate = updateDefault({ sealabspayAccountID: id }).unwrap()

      toast.promise(promiseUpdate, {
        pending: 'loading...',
        success: 'Success Update Default Sealabspay',
        error: {
          render({ data }: any) {
            return `${data}`
          },
        },
      })
    } else if (action === 'delete') {
      const promiseDelete = deleteSealabspay({
        sealabspayAccountID: id,
      }).unwrap()

      toast.promise(promiseDelete, {
        pending: 'loading...',
        success: 'Success Delete Sealabspay',
        error: {
          render({ data }: any) {
            return `${data}`
          },
        },
      })
    }
  }

  return (
    <CustomModal
      size={{ base: 'sm', md: 'md' }}
      title='Sealabspay'
      body={<Text mt='3'>{body}</Text>}
      footer={
        <ButtonGroup>
          <Flex gap={3}>
            <PrimaryButton
              onClick={handleAction}
              flex='1'
              bgColor={buttonPrimaryColor}
            >
              Yes
            </PrimaryButton>
            <SecondaryButton onClick={onClose} flex='1'>
              Cancel
            </SecondaryButton>
          </Flex>
        </ButtonGroup>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default SealabspayConfirmationModal
