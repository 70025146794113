import Cookies from 'js-cookie'
import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'
import { Wallet, WalletTransactionHistories } from './entities'

export const walletApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOwnWallet: builder.query({
      query: () => ({
        url: '/wallet',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: ({ data }: OKResponse<Wallet>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['wallet'],
    }),
    getWalletTransaction: builder.query({
      query: (query: any) => ({
        url: `/wallets?limit=5${query}`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: ({ data }: OKResponse<WalletTransactionHistories>) =>
        data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
    createWallet: builder.mutation({
      query: ({ pin }) => ({
        url: '/wallet',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: JSON.stringify({ pin }),
      }),
      transformResponse: ({ data }: OKResponse<Wallet>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['wallet'],
    }),
    changeWalletPin: builder.mutation({
      query: ({ pin }) => ({
        url: '/wallet',
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: JSON.stringify({ pin }),
      }),
      transformResponse: ({ data }: OKResponse<Wallet>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['wallet'],
    }),
    paymentWallet: builder.mutation({
      query: ({ pin, transactionID }) => ({
        url: '/wallet-payment',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: JSON.stringify({ pin, transaction_id: transactionID }),
      }),
      transformResponse: ({ data }: OKResponse<string>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['transactions'],
    }),
  }),
})

export const {
  useGetOwnWalletQuery,
  useLazyGetWalletTransactionQuery,
  useCreateWalletMutation,
  useChangeWalletPinMutation,
  usePaymentWalletMutation,
} = walletApiSlice
