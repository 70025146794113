import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import CustomImage from '../../components/images'
import { useGetTransactionQuery } from '../../features/transactions/transactionsSlice'
import { usePaymentWalletMutation } from '../../features/wallets/walletsSlice'

const WalletPaymentPage = () => {
  const params = useParams()
  const transactionID = params.id
  const { data } = useGetTransactionQuery(transactionID)
  const [pin, setPin] = useState('')
  const [triggerPaymentWallet] = usePaymentWalletMutation()
  const navigate = useNavigate()

  const handlePaymentWallet = () => {
    triggerPaymentWallet({ pin, transactionID })
      .unwrap()
      .then(() => navigate('/payment-result/payment?status=TXN_PAID'))
      .catch((err) => {
        if (err === 'invalid wallet pin') {
          toast.error(err)
        } else if (err === 'wallet is temporarily blocked') {
          navigate('/payment-result/payment?status=TXN_BLOCKED')
        } else {
          navigate('/payment-result/payment?status=TXN_FAILED')
        }
      })

    setPin('')
  }

  return (
    <>
      <Flex direction={'column'} mb={4}>
        <Center>
          <FaMoneyBillAlt fontSize={'100px'} color={'#58A7A5'} />
        </Center>
        <Center>
          <Text fontSize={'3xl'} color={'text.normal'}>
            Wallet Pay
          </Text>
        </Center>
      </Flex>
      <Container
        minW={'xs'}
        maxW={'xl'}
        rounded={'md'}
        border={'1px solid black'}
        borderColor={'primary.300'}
        p={8}
      >
        <Flex direction={'column'}>
          <Flex justifyContent={'center'} mb={10}>
            <CustomImage
              width={'200px'}
              height={'auto'}
              src={'/assets/svgs/wallet.svg'}
            />
          </Flex>
          <Flex justifyContent={'center'}>
            <Box
              bgColor={'primary.200'}
              borderRadius='full'
              p='3'
              w={'200px'}
              mb='5'
            >
              <Center>
                <Text as={'b'} fontSize={'xl'}>
                  Rp. {data?.total_price.toLocaleString('id-ID')}
                </Text>
              </Center>
            </Box>
          </Flex>
          <Flex justifyContent={'center'} mb={5}>
            <Flex direction={'column'} gap={3}>
              <Center>
                <Text>Enter wallet pin:</Text>
              </Center>
              <HStack>
                <PinInput
                  size='lg'
                  value={pin}
                  onChange={(value) => setPin(value)}
                  mask
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>
          </Flex>
          <Flex justifyContent={'center'}>
            <Box p='3' w={'400px'} mb='5'>
              {pin.length === 6 ? (
                <PrimaryButton w={'full'} onClick={handlePaymentWallet}>
                  Submit
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  w={'full'}
                  onClick={handlePaymentWallet}
                  disabled
                >
                  Submit
                </PrimaryButton>
              )}
            </Box>
          </Flex>
          <Flex justifyContent={'center'}>
            <Text
              color={'red'}
              cursor={'pointer'}
              onClick={() =>
                navigate('/payment-result/wallet?status=TXN_FAILED')
              }
            >
              Cancel Payment
            </Text>
          </Flex>
        </Flex>
      </Container>
    </>
  )
}

export default WalletPaymentPage
