import React from 'react'
import CustomModal from '..'
import { DetailTransactionModalProps } from '../entities'
import DetailOrder from './DetailOrder'

const DetailTransactionModal = (props: DetailTransactionModalProps) => {
  const { isOpen, onClose, id } = props

  return (
    <CustomModal
      size={{ base: 'sm', md: '3xl' }}
      title='Detail Transaction'
      body={<DetailOrder id={id} />}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default DetailTransactionModal
