import React from 'react'
import CustomModal from '..'
import AddressSelection from '../../checkout/AddressSelection'
import { AddressSelectionModalProps } from '../entities'

const AddressSelectionModal = (props: AddressSelectionModalProps) => {
  const { isOpen, onClose } = props

  return (
    <CustomModal
      size='6xl'
      title='Select Address'
      body={
        <AddressSelection
          addresses={props.addresses}
          selectedAddress={props.selectedAddress}
          evtOnClick={props.evtOnClick}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default AddressSelectionModal
