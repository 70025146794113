import { apiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import { OKResponse } from '../entities'
import { Courier } from './entities'

export const couriersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCouriers: builder.query({
      query: () => ({
        url: '/couriers',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      providesTags: ['couriers'],
      transformResponse: (response: OKResponse<Courier[]>) => {
        const couriers = response.data
        return couriers
      },
    }),
  }),
})

export const { useGetCouriersQuery } = couriersApiSlice
