import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Routing from './routing'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { StepsStyleConfig as Steps } from 'chakra-ui-steps'

export const App = () => {
  const textStyles = {
    h1: {
      fontSize: ['20px', '30px', '30px'],
    },
    h2: {
      fontSize: ['15px', '22px', '22px'],
    },
    h3: {
      fontSize: ['13px', '18px', '18px'],
    },
  }

  const colors = {
    primary: {
      '50': '#EEF6F6',
      '100': '#D0E6E6',
      '200': '#B2D6D6',
      '300': '#94C7C5',
      '400': '#76B7B5',
      '500': '#58A7A5',
      '600': '#468684',
      '700': '#356463',
      '800': '#234342',
      '900': '#122121',
    },
    danger: {
      '50': '#F8ECED',
      '100': '#EBCBCD',
      '200': '#DFAAAD',
      '300': '#D2898D',
      '400': '#C6676D',
      '500': '#B9464D',
      '600': '#94383E',
      '700': '#6F2A2E',
      '800': '#4A1C1F',
      '900': '#250E0F',
    },
    text: {
      light: '#737A79',
      normal: '#2B3333',
    },
  }

  const activeLabelStyles = {
    mx: 3,
    transform: 'scale(0.95) translateY(-24px)',
  }

  const components = {
    Steps,
    Form: {
      variants: {
        floating: ({ colorMode }: { colorMode: string }) => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .chakra-input__group input:not(:placeholder-shown) ~ label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: colorMode === 'dark' ? '#1A202C' : 'white',
              pointerEvents: 'none',
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        }),
      },
    },
  }

  const theme = extendTheme({ colors, components, textStyles })

  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <Routing />
    </ChakraProvider>
  )
}
