import { DeleteIcon, EditIcon, StarIcon, ViewIcon } from '@chakra-ui/icons'
import {
  AspectRatio,
  Badge,
  Box,
  Container,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../app/store'
import { DataTable } from '../../../components/datatable'
import ErrorState from '../../../components/errorState'
import CustomImage from '../../../components/images'
import LoadingSpinner from '../../../components/loadingSpinner'
import { Inventory } from '../../../components/product/entities'

import Pagination from 'react-responsive-pagination'
import {
  useDeleteProductMutation,
  useLazyGetShopProductsQuery,
} from '../../../features/products/productsSlice'
import { setSortAndSortBy } from '../../../features/sortFilter/sortFilterSlice'
import SortSellerPage from '../../../components/sort/sortSellerPage'
import ConfirmDeleteModal from '../../../components/modal/ConfirmDeleteModal'
import { toast } from 'react-toastify'
import { useGetSelfShopInformationQuery } from '../../../features/shops/shopsSlice'
import NoData from '../../../components/noData'

const DisplayProductPage = () => {
  const renderProductNameCell = ({ imageUrl, title, status, views }: any) => (
    <Box py='8px'>
      <HStack gap={'8px'}>
        <AspectRatio w='100px' ratio={1}>
          <CustomImage
            boxSize='100px'
            objectFit='cover'
            __css={{ backgroundColor: 'gray.200', borderRadius: '20px' }}
            alt={`product`}
            src={imageUrl}
          ></CustomImage>
        </AspectRatio>
        <VStack align={'start'}>
          <Badge colorScheme={status === 'active' ? 'green' : 'gray'}>
            {status}
          </Badge>
          <Text>{title}</Text>
          <HStack>
            <ViewIcon />
            <Text>{views}</Text>
            <StarIcon />
            <Text>{views}</Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  )

  const renderVariantCodesCell = ({ codes }: any) => (
    <VStack align='stretch'>
      {codes.map((code: string, index: number) => (
        <Box py={'8px'} key={index}>
          {code}
        </Box>
      ))}
    </VStack>
  )

  const renderVariantPricesCell = ({ prices }: any) => (
    <VStack align='stretch'>
      {prices.map((price: string, index: number) => (
        <Box py={'8px'} key={index}>
          {price}
        </Box>
      ))}
    </VStack>
  )

  const renderVariantStocksCell = ({ stocks }: any) => (
    <VStack align='stretch'>
      {stocks.map((stock: string, index: number) => (
        <Box py={'8px'} key={index}>
          {stock}
        </Box>
      ))}
    </VStack>
  )

  const renderActionsCell = ({ id }: any) => (
    <HStack gap={'8px'} py={'8px'}>
      <IconButton
        onClick={() => handleEdit(id)}
        colorScheme={'gray'}
        icon={<EditIcon />}
        aria-label='edit'
      />
      <IconButton
        onClick={() => handleModalOpen(id)}
        colorScheme={'red'}
        icon={<DeleteIcon />}
        aria-label='delete'
      />
    </HStack>
  )

  const navigate = useNavigate()

  const [deleteId, setDeleteId] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleModalOpen = (id: string) => {
    setDeleteId(id)
    onOpen()
  }

  const handleEdit = (id: string) => {
    navigate(`/seller/products/update/${id}`)
  }

  const handleDelete = () => {
    deleteProduct({ id: deleteId })
      .then(() => {
        toast.success('Success deleting product', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
      .catch((err: any) => {
        toast.error(err.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      })
    onClose()
  }

  // Get shop id
  const { data: shopData } = useGetSelfShopInformationQuery()
  const shopId = shopData?.data.id

  const [trigger, result] = useLazyGetShopProductsQuery()
  const [deleteProduct] = useDeleteProductMutation({})

  const columnHelper = createColumnHelper<any>()
  const [products, setProducts] = useState([])

  const columns = [
    columnHelper.accessor('productName', {
      cell: (info) => renderProductNameCell(info.getValue()),
      header: 'Product Name',
    }),
    columnHelper.accessor('variantCodes', {
      cell: (info) => renderVariantCodesCell(info.getValue()),
      header: 'Variant Codes',
    }),
    columnHelper.accessor('variantPrices', {
      cell: (info) => renderVariantPricesCell(info.getValue()),
      header: 'Variant Prices',
    }),
    columnHelper.accessor('variantStocks', {
      cell: (info) => renderVariantStocksCell(info.getValue()),
      header: 'Variant Stocks',
    }),
    columnHelper.accessor('actions', {
      cell: (info) => renderActionsCell(info.getValue()),
      header: 'Actions',
    }),
  ]

  const [querySort, setQuerySort] = useState(
    'limit=10&sort_by=created_at&sort=asc'
  )
  const { sort, sort_by } = useSelector(
    (state: RootState) => state.sortFilterReducer
  )

  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const productListRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const handlePageClick = (page: number) => {
    productListRef.current.scrollIntoView()
    setPage(page)
  }

  useEffect(() => {
    const querystring = `{"sort_by":"created_at", "sort":"asc"}`
    dispatch(setSortAndSortBy(querystring))
    trigger({ id: shopId, query: '' }, false)
  }, [])

  useEffect(() => {
    const data: any = result.data

    if (!data || data.length === 0) {
      setProducts([])
      return
    }

    const products = data.data.map((val: any) => ({
      productName: {
        imageUrl: val.thumbnail_url,
        title: val.title,
        status: val.status,
        sku: val.sku,
        views: val.view_count,
      },
      variantCodes: {
        codes: val.inventories.map((element: Inventory) => element.code),
      },
      variantPrices: {
        prices: val.inventories.map((element: Inventory) => element.price),
      },
      variantStocks: {
        stocks: val.inventories.map((element: Inventory) => element.stock),
      },
      actions: {
        id: val.id,
      },
    }))

    setProducts(products)
  }, [result])

  useEffect(() => {
    trigger({ id: shopId, query: querySort }, false)
  }, [querySort])

  useEffect(() => {
    queryBuilder()
  }, [sort, sort_by, page])

  const queryBuilder = () => {
    let combinedQuery = 'limit=10'

    if (sort) {
      combinedQuery += `&sort=${sort}`
    }

    if (sort_by) {
      combinedQuery += `&sort_by=${sort_by}`
    }

    if (page) {
      combinedQuery += `&page=${page}`
    }

    setQuerySort(combinedQuery)
  }

  return (
    <Container maxW='6xl' overflow='auto'>
      {result.isLoading && <LoadingSpinner />}
      {!result.isLoading && result.isError && <ErrorState />}
      {!result.isLoading && !result.isError && result.data && (
        <Box padding={2} borderRadius={10} mt={4} ref={productListRef}>
          <ConfirmDeleteModal
            isOpen={isOpen}
            onClose={onClose}
            deleteAction={() => handleDelete()}
            title='Delete Product'
            body='Are you sure you want to delete this product?'
            buttonText='Delete'
          />
          {products.length > 0 ? (
            <>
              <SortSellerPage />
              <DataTable columns={columns} data={products} />
              <Box mt={12}>
                <Pagination
                  total={result.data.total_pages ?? 0}
                  ariaPreviousLabel={' '}
                  ariaNextLabel={' '}
                  a11yActiveLabel={' '}
                  ariaCurrentAttr={true}
                  current={page}
                  onPageChange={(page) => handlePageClick(page)}
                  className={'navigationButtons'}
                />
              </Box>
            </>
          ) : (
            <NoData />
          )}
        </Box>
      )}
    </Container>
  )
}

export default DisplayProductPage
