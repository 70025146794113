import { apiSlice } from '../api/apiSlice'
import Cookies from 'js-cookie'
import { OKResponse } from '../entities'
import {
  CreateTransaction,
  Transaction,
  TransactionDetailResponse,
  TransactionHistoryResponse,
} from './entities'
import { ErrorResponse } from '@remix-run/router'

export const transactionsApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createTransaction: builder.mutation<
      OKResponse<Transaction>,
      CreateTransaction
    >({
      query: (transactionData: CreateTransaction) => ({
        url: '/transactions',
        method: 'POST',
        body: transactionData,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      invalidatesTags: ['carts'],
    }),
    getTransaction: builder.query({
      query: (transactionID) => ({
        url: '/payments/' + transactionID,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: ({ data }: OKResponse<Transaction>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['transactions'],
    }),
    getUserTransactions: builder.query({
      query: (query: string) => ({
        url: `/transactions?limit=5${query}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: ({ data }: OKResponse<TransactionHistoryResponse>) =>
        data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['transactions'],
    }),
    getDetailTransactions: builder.query({
      query: ({ transactionID }) => ({
        url: '/transactions/' + transactionID + '/details',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: ({ data }: OKResponse<TransactionDetailResponse>) =>
        data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['detailTransaction'],
    }),
    updatePaymentMethodTransaction: builder.mutation({
      query: ({ transactionID, paymentType, id }) => ({
        url: '/transactions/' + transactionID,
        method: 'PATCH',
        body: JSON.stringify({
          payment_type: paymentType,
          sealabspay_account_id: id,
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: ({ data }: OKResponse<string>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['transactions', 'detailTransaction'],
    }),
  }),
})

export const {
  useCreateTransactionMutation,
  useGetTransactionQuery,
  useGetUserTransactionsQuery,
  useLazyGetUserTransactionsQuery,
  useGetDetailTransactionsQuery,
  useUpdatePaymentMethodTransactionMutation,
} = transactionsApiSlice
