import React from 'react'
import {
  Avatar,
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MdLocationOn, MdStar } from 'react-icons/md'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import ShopDetailModal from '../modal/ShopDetailModal'
import { useParams } from 'react-router-dom'
import { useGetShopInformationQuery } from '../../features/shops/shopsSlice'
import LoadingSpinner from '../loadingSpinner'
import ErrorState from '../errorState'

const ShopProfile = () => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()

  const params = useParams()
  const id = params.shopID

  const {
    data: profile,
    error: isProfileError,
    isLoading: isProfileLoading,
  } = useGetShopInformationQuery(id)

  return (
    <>
      {isProfileLoading ? (
        <LoadingSpinner />
      ) : isProfileError ? (
        <ErrorState />
      ) : (
        profile && (
          <>
            <ShopDetailModal
              isOpen={isModalOpen}
              onClose={onModalClose}
            ></ShopDetailModal>
            <Container maxWidth='full'>
              <Flex
                maxWidth='full'
                justifyContent='space-between'
                flexDirection={['column', 'column', 'column', 'row', 'row']}
                gap={['1rem', '2rem', '2rem', '2rem', '2rem']}
              >
                <Grid templateColumns='repeat(4, 1fr)' gap={4}>
                  <GridItem colSpan={1}>
                    <Avatar size='xl' src={profile.data.user.profile_photo} />
                  </GridItem>
                  <GridItem colSpan={3}>
                    <Flex
                      flexDirection='column'
                      h='100%'
                      justifyContent='center'
                      width='full'
                    >
                      <Flex
                        width='full'
                        justifyContent='space-between'
                        maxWidth='100%'
                      >
                        <Text fontSize='xl' noOfLines={1}>
                          {profile.data.name}
                        </Text>
                        <Box>
                          <IconButton
                            onClick={onModalOpen}
                            size={['sm', 'md', 'lg']}
                            aria-label='detail-button'
                            icon={<BsFillInfoCircleFill />}
                            bg='none'
                          />
                        </Box>
                      </Flex>
                      <Flex gap='2px'>
                        <Icon as={MdLocationOn} fontSize='xl' />
                        <Text fontSize='md'>{profile.data.location}</Text>
                      </Flex>
                    </Flex>
                  </GridItem>
                </Grid>
                <Flex
                  gap={['1rem', '1rem', '1rem', '3rem', '7rem']}
                  alignItems='center'
                  flexDirection={['column', 'column', 'column', 'row', 'row']}
                  width={['100%', '100%', '100%', '50%', '50%']}
                >
                  <Flex
                    flexDirection={['row', 'row', 'row', 'column', 'column']}
                    justifyContent='space-between'
                    width='full'
                  >
                    <Text fontSize='lg'>Rating</Text>
                    <Flex gap='5px' fontSize='lg'>
                      <Icon as={MdStar} fontSize='xl' color='yellow' />
                      <Text>
                        {(Math.round(profile.data.rating * 100) / 100).toFixed(
                          2
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection={['row', 'row', 'row', 'column', 'column']}
                    justifyContent='space-between'
                    width='full'
                  >
                    <Text fontSize='lg'>Product</Text>
                    <Text fontSize='lg'>
                      {profile.data.product_count} item(s)
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection={['row', 'row', 'row', 'column', 'column']}
                    justifyContent='space-between'
                    width='full'
                  >
                    <Text fontSize='lg'>Joined since</Text>
                    <Text fontSize='lg'>
                      {new Date(profile.data.created_at).toLocaleDateString()}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Container>
          </>
        )
      )}
    </>
  )
}

export default ShopProfile
