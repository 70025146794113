import { Badge, Box, Flex, Portal, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import CheckoutOrderDetail from '../../components/checkout/CheckoutOrderDetail'
import CheckoutPriceDetail from '../../components/checkout/CheckoutPriceDetail'
import AddressSelectionModal from '../../components/modal/checkoutModals/AddressSelectionModal'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { useLazyGetUserAddressesQuery } from '../../features/address/address'
import { TransactionSliceStateProp } from '../../features/slices/transactionSlice/entities'
import MarketplaceVoucherSelectionModal from '../../components/modal/checkoutModals/MarketplaceVoucherSelectionModal'
import PaymentSelectionModal from '../../components/modal/checkoutModals/PaymentSelectionModal'
import { useGetOwnSealabspayQuery } from '../../features/sealabspay/sealabspaySlice'
import { Sealabspay } from '../../features/sealabspay/entities'
import { useCreateTransactionMutation } from '../../features/transactions/transactionsSlice'
import {
  clearAll as transactionClearAll,
  modifyTransaction,
  updateItem,
} from '../../features/slices/transactionSlice/transactionSlice'
import { toast } from 'react-toastify'
import convertToRupiah from '../../helpers/currencyFormat'
import { TransactionCalculation } from '../../features/slices/transactionCalculationSlice/entities'
import { resetAll as transactionCalculationResetAll } from '../../features/slices/transactionCalculationSlice/transactionCalculationSlice'
import { Wallet } from '../../features/wallets/entities'
import { Address } from '../../features/address/entities'
import { useNavigate } from 'react-router-dom'
import { Voucher } from '../../features/vouchers/entities'
import { useLazyGetVouchersQuery } from '../../features/vouchers/vouchersSlice'
import { useLazyGetCartItemsShopsQuery } from '../../features/carts/cartsSlice'
import { calculateDiscountedPrice } from '../../helpers/priceCalculation'

const CheckoutPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data: transactionCalculationData } = useSelector<
    RootState,
    TransactionCalculation
  >((state) => state.transactionCalculation)

  const {
    isOpen: addressIsOpen,
    onClose: addressOnClose,
    onOpen: addressOnOpen,
  } = useDisclosure()
  const {
    isOpen: marketplaceVoucherIsOpen,
    onClose: marketplaceVoucherOnClose,
    onOpen: marketplaceVoucherOnOpen,
  } = useDisclosure()
  const {
    isOpen: paymentModalIsOpen,
    onClose: paymentModalOnClose,
    onOpen: paymentModalOnOpen,
  } = useDisclosure()
  const { data: transactionData } = useSelector<
    RootState,
    TransactionSliceStateProp
  >((state) => state.transactionSlice)

  const { data: userData } = useGetProfileQuery()
  const [shopCartItemsTrigger, shopCartItemsResult] =
    useLazyGetCartItemsShopsQuery()
  const [addressTrigger, addressResult] = useLazyGetUserAddressesQuery()
  const [marketplaceVoucherTrigger, marketplaceVoucherResult] =
    useLazyGetVouchersQuery()
  const { data: sealabsPaysData } = useGetOwnSealabspayQuery(null)
  const [createTransaction] = useCreateTransactionMutation()

  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null)
  const [selectedMarketplaceVoucher, setSelectedMarketplaceVoucher] =
    useState<Voucher | null>(null)
  const [grandSubTotal, setGrandSubTotal] = useState(0)
  const [grandShipmentCost, setGrandShipmentCost] = useState(0)
  const [grandDiscount, setGrandDiscount] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)

  const evtCreateTransaction = (
    paymentType: 'sealabspay' | 'wallet',
    sealabspayID: string | undefined,
    walletID: string | undefined
  ) => {
    createTransaction({
      address_id: transactionData.shipmentAddressId || '',
      voucher_id: transactionData.voucherId,
      payment_type: paymentType,
      sealabspay_account_id: sealabspayID,
      wallet_id: walletID,
      orders: Object.keys(transactionData.shops).map((val) => {
        const shop = transactionData.shops[val]
        return {
          shop_voucher_id: shop.voucherId,
          courier_id: shop.courierId || '',
          shop_id: val,
          order_details: Object.keys(shop.products).map((val) => {
            const product = shop.products[val]
            return {
              id: product.id,
              quantity: product.quantity,
              note: product.note,
            }
          }),
        }
      }),
    })
      .unwrap()
      .then((data: any) => {
        dispatch(transactionCalculationResetAll())
        dispatch(transactionClearAll())
        navigate('/payments/' + data.data.id)
      })
      .catch((err: any) => toast.error(err.data.message))
  }

  useEffect(() => {
    if (Object.keys(transactionData.shops).length === 0) {
      toast.error('Please select the item(s) first')
      navigate('/cart')
      return
    }

    shopCartItemsTrigger(null)
    addressTrigger()
    marketplaceVoucherTrigger({ status: 'ONGOING' })
    dispatch(transactionCalculationResetAll())

    if (!transactionData.shipmentAddressId) {
      dispatch(
        modifyTransaction({
          addressId: userData?.data.default_address_id,
        })
      )
    }
  }, [])

  useEffect(() => {
    if (shopCartItemsResult.isLoading) return
    if (Object.keys(transactionData.shops).length === 0) return

    shopCartItemsResult.data?.items.forEach((val) => {
      val.cart_items.forEach((cartVal) => {
        if (transactionData.shops[val.shop.id].products[cartVal.inventory.id]) {
          if (
            calculateDiscountedPrice(
              cartVal.inventory.price,
              cartVal.discount_type,
              cartVal.discount_amount
            ) !==
            transactionData.shops[val.shop.id].products[cartVal.inventory.id]
              .price
          ) {
            dispatch(
              updateItem({
                shopId: val.shop.id,
                productId: cartVal.inventory.id,
                orderDetail: {
                  id: cartVal.inventory.id,
                  name: cartVal.inventory.product?.title || '',
                  quantity: cartVal.quantity,
                  weight: cartVal.inventory.product?.weight || 1,
                  variantName:
                    (cartVal.inventory.first_variant_type ||
                      cartVal.inventory.second_variant_type) &&
                    `${cartVal.inventory.first_variant_type.name}${
                      cartVal.inventory.second_variant_type
                        ? `, ${cartVal.inventory.second_variant_type.name}`
                        : ''
                    }`,
                  price: calculateDiscountedPrice(
                    cartVal.inventory.price,
                    cartVal.discount_type,
                    cartVal.discount_amount
                  ),
                  actualPrice: cartVal.inventory.price,
                  stock: cartVal.inventory.stock,
                },
              })
            )
          }
        }
      })
    })
  }, [shopCartItemsResult])

  useEffect(() => {
    addressResult?.data?.data?.forEach((val) => {
      if (transactionData.shipmentAddressId === val.id) {
        setSelectedAddress({ ...val })
      }
    })
  }, [addressResult.data])

  useEffect(() => {
    marketplaceVoucherResult.data?.forEach((val) => {
      if (transactionData.voucherId === val.id) {
        setSelectedMarketplaceVoucher({ ...val })
      }
    })
  }, [marketplaceVoucherResult.data])

  useEffect(() => {
    const tempGrandSubTotal = Object.keys(transactionCalculationData).reduce(
      (accumulator, val) =>
        accumulator + transactionCalculationData[val].itemSubtotal,
      0
    )

    const tempGrandShipmentCost = Object.keys(
      transactionCalculationData
    ).reduce(
      (accumulator, val) =>
        accumulator + transactionCalculationData[val].shipmentCostTotal,
      0
    )

    let discountAmount = 0
    if (selectedMarketplaceVoucher) {
      if (selectedMarketplaceVoucher.type === 'NOMINAL') {
        discountAmount = selectedMarketplaceVoucher.amount
      } else {
        discountAmount = Math.floor(
          (selectedMarketplaceVoucher.amount / 100) * tempGrandSubTotal
        )
      }
    }
    const tempGrandDiscount = Math.min(
      Object.keys(transactionCalculationData).reduce(
        (accumulator, val) =>
          accumulator + transactionCalculationData[val].discountTotal,
        0
      ) + discountAmount,
      tempGrandSubTotal
    )

    const tempGrandTotal =
      tempGrandSubTotal + tempGrandShipmentCost - tempGrandDiscount

    setGrandSubTotal(tempGrandSubTotal)
    setGrandShipmentCost(tempGrandShipmentCost)
    setGrandDiscount(tempGrandDiscount)
    setGrandTotal(tempGrandTotal >= 0 ? tempGrandTotal : 0)
  }, [transactionCalculationData, selectedMarketplaceVoucher])

  return (
    <>
      <Portal>
        <AddressSelectionModal
          addresses={addressResult.data?.data || []}
          selectedAddress={selectedAddress || undefined}
          isOpen={addressIsOpen}
          onClose={addressOnClose}
          evtOnClick={(address: Address) => {
            setSelectedAddress(address)
            dispatch(
              modifyTransaction({
                addressId: address.id,
              })
            )
            addressOnClose()
          }}
        />
        <MarketplaceVoucherSelectionModal
          isOpen={marketplaceVoucherIsOpen}
          onClose={marketplaceVoucherOnClose}
          vouchers={marketplaceVoucherResult.data || []}
          selectedVoucher={selectedMarketplaceVoucher || undefined}
          totalItemPrice={Object.keys(transactionData.shops).reduce(
            (accumulator, val) => {
              const shopTotal = Object.keys(
                transactionData.shops[val].products
              ).reduce(
                (productAccumulator, productVal) =>
                  productAccumulator +
                  transactionData.shops[val].products[productVal].price *
                    transactionData.shops[val].products[productVal].quantity,
                0
              )
              return accumulator + shopTotal
            },
            0
          )}
          evtOnClick={(voucher) => {
            if (voucher.id === selectedMarketplaceVoucher?.id) {
              setSelectedMarketplaceVoucher(null)
              dispatch(
                modifyTransaction({
                  marketplaceVoucherId: null,
                })
              )
            } else {
              setSelectedMarketplaceVoucher(voucher)
              dispatch(
                modifyTransaction({
                  marketplaceVoucherId: voucher.id,
                })
              )
            }
            marketplaceVoucherOnClose()
          }}
        />
        <PaymentSelectionModal
          sealabsPays={sealabsPaysData || []}
          isOpen={paymentModalIsOpen}
          onClose={paymentModalOnClose}
          evtOnClick={(data: {
            paymentType: 'sealabspay' | 'wallet'
            sealabspay?: Sealabspay
            wallet?: Wallet
          }) => {
            evtCreateTransaction(
              data.paymentType,
              data.sealabspay?.id,
              data.wallet?.id
            )
          }}
        />
      </Portal>

      <Flex
        mx='auto'
        maxW='1500px'
        flexDirection={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Flex flex={4} px={5} gap={5} flexDirection='column'>
          <Box borderBottom='5px solid' borderColor='gray.200'>
            <Text
              fontWeight='bold'
              fontSize='1.5rem'
              pb={2}
              mb={2}
              borderBottom='1px solid'
              borderColor='gray.300'
            >
              Shipping Address
            </Text>
            <Flex gap={3} px={3} py={5} flexDir={{ base: 'column', md: 'row' }}>
              <Box flex={1}>
                {selectedAddress ? (
                  <>
                    <Text fontWeight='bold'>
                      {selectedAddress.label}{' '}
                      {selectedAddress.recipient_phone_number}
                      &nbsp;&nbsp;&nbsp;
                      {selectedAddress.id ===
                        userData?.data.default_address_id && (
                        <Badge colorScheme='blue' mx='auto'>
                          Default
                        </Badge>
                      )}
                    </Text>
                    <Text>{selectedAddress.name}</Text>
                    <Text>
                      {selectedAddress.province.name},{' '}
                      {selectedAddress.subdistrict.name},{' '}
                      {selectedAddress.city.name},{' '}
                      {selectedAddress.city.postal_code}
                    </Text>
                  </>
                ) : (
                  <Flex alignItems='center'>
                    <Text>No address selected</Text>
                  </Flex>
                )}
              </Box>
              <Flex flexDirection='column' gap={2}>
                <SecondaryButton
                  type='button'
                  onClick={(e) => {
                    e.preventDefault()
                    addressOnOpen()
                  }}
                >
                  Change Address
                </SecondaryButton>
              </Flex>
            </Flex>
          </Box>

          <Flex gap={5} flexDirection='column'>
            {Object.keys(transactionData.shops).map((val) => (
              <CheckoutOrderDetail key={val} {...transactionData.shops[val]} />
            ))}
          </Flex>
        </Flex>

        <Box
          flex={1}
          border='1px solid'
          borderColor='gray.200'
          p={5}
          borderRadius='md'
        >
          <Box mb={5} pb={3} borderBottom='3px solid' borderColor='gray.300'>
            <SecondaryButton
              w='full'
              onClick={() => {
                marketplaceVoucherOnOpen()
              }}
            >
              <Box flex={1}>
                {selectedMarketplaceVoucher
                  ? selectedMarketplaceVoucher.name
                  : 'Voucher'}
              </Box>
              <FaChevronRight />
            </SecondaryButton>
          </Box>

          <Box mb={5} borderBottom='3px solid' borderColor='gray.300'>
            <Box mb={5} borderBottom='1px solid' borderColor='gray.300'>
              <CheckoutPriceDetail title='Subtotal' amount={grandSubTotal} />
              <CheckoutPriceDetail
                title='Shipment Cost'
                amount={grandShipmentCost}
              />
              <CheckoutPriceDetail title='Discount' amount={grandDiscount} />
            </Box>

            <Box mb={5}>
              <Text>Total</Text>
              <Text fontWeight='bold' fontSize='1.5rem' textAlign='right'>
                Rp. {convertToRupiah(grandTotal)}
              </Text>
            </Box>
          </Box>

          <Flex gap={3} flexDirection='column'>
            <PrimaryButton
              w='100%'
              onClick={(e) => {
                e.preventDefault()
                if (!selectedAddress) {
                  return toast.error('Shipping address required')
                }

                const shopKeys = Object.keys(transactionData.shops)
                const shopWithoutCourier = shopKeys.filter(
                  (val) => !transactionData.shops[val].courierId
                )
                if (shopWithoutCourier.length > 0) {
                  shopWithoutCourier.forEach((val) => {
                    toast.error(
                      `Please assign courier for shop '${transactionData.shops[val].name}'`
                    )
                  })
                  return
                }
                paymentModalOnOpen()
              }}
              disabled={Object.keys(transactionData.shops).length === 0}
            >
              <Box flex={1}>Payment</Box>
              <FaChevronRight />
            </PrimaryButton>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default CheckoutPage
