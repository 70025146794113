import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'
import { Voucher } from './entities'

export const vouchersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVouchers: builder.query({
      query: ({ status }) => ({
        url: `/vouchers?status=${status}`,
        method: 'GET',
      }),
      transformResponse: (response: OKResponse<Voucher[] | null>) =>
        response.data || [],
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['vouchers'],
    }),
  }),
})

export const { useGetVouchersQuery, useLazyGetVouchersQuery } = vouchersApiSlice
