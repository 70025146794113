import {
  ButtonGroup,
  Grid,
  GridItem,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomModal from '.'
import { ShopVoucherStatusesEnum, ShopVoucherTypeEnum } from '../../const'
import { ShopVoucher } from '../../features/shopVouchers/entities'
import { useCreateShopVoucherMutation } from '../../features/shopVouchers/shopVouchersSlice'
import PrimaryButton from '../buttons/PrimaryButton'
import { ShopVoucherFormInputs } from '../form/entities'
import StandardInput from '../inputs/StandardInput'
import { DuplicateShopVoucherModalProps as DuplicateShopVoucherModalProps } from './entities'
import { toast } from 'react-toastify'
import { OKResponse } from '../../features/entities'
import { useNavigate } from 'react-router-dom'
import SecondaryButton from '../buttons/SecondaryButton'

const DuplicateShopVoucherModal = (props: DuplicateShopVoucherModalProps) => {
  const { isOpen, onClose, shopVoucher, userName } = props
  const [newCode, setNewCode] = useState(
    shopVoucher.code.replace(new RegExp(userName, 'gi'), '')
  )
  const [duplicateShopVoucher] = useCreateShopVoucherMutation()
  const nav = useNavigate()
  const showToast = async (promise: Promise<OKResponse<ShopVoucher>>) =>
    toast
      .promise(promise, {
        pending: 'Duplicating shop voucher...',
        success: 'Success duplicate shop voucher',
        error: {
          render({ data }) {
            return `${data}`
          },
        },
      })
      .then((data) => {
        onClose()
        nav(`/seller/vouchers/` + data.data.id)
      })
      .catch()
  return (
    <CustomModal
      size='6xl'
      title='Duplicate Voucher'
      body={
        <VStack>
          <Text>Input new duplicated voucher code</Text>
          <Grid templateColumns='3fr 7fr' gap={3} width='full'>
            <GridItem>Code</GridItem>
            <GridItem>
              <InputGroup>
                <InputLeftAddon>{userName.toUpperCase()}</InputLeftAddon>
                <StandardInput
                  value={newCode}
                  onChange={(val) => setNewCode(val.target.value)}
                />
              </InputGroup>
            </GridItem>
          </Grid>
        </VStack>
      }
      footer={
        <ButtonGroup>
          <PrimaryButton
            onClick={() => {
              const newData: ShopVoucherFormInputs = {
                amount: shopVoucher.amount,
                code: userName + newCode,
                status: ShopVoucherStatusesEnum[shopVoucher.status],
                type: ShopVoucherTypeEnum[shopVoucher.type],
                quota: shopVoucher.quota,
                minimumPayment: shopVoucher.minimum_payment,
                startDate: shopVoucher.start_date,
                endDate: shopVoucher.end_date,
              }

              showToast(duplicateShopVoucher(newData).unwrap())
            }}
          >
            Save
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </ButtonGroup>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default DuplicateShopVoucherModal
