import Cookies from 'js-cookie'
import { ProductCardData } from '../../components/productCard/entities'
import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'
import { TransactionDetailResponse } from '../transactions/entities'
import { Order, OrderDetailDTO } from './entities'

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ filter }) => ({
        url: `/shops/orders?filter=${filter}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<Order[] | null>) => {
        if (typeof response.data === null) return []

        const data = response.data ? response.data : []
        if (data.length === 0) return []

        return data
      },
      providesTags: ['orders'],
    }),
    getOrderDetail: builder.query({
      query: ({ orderId }) => ({
        url: `/shops/orders/${orderId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
      }),
      transformResponse: (response: OKResponse<any>) => {
        if (typeof response.data === null) return []

        const data = response.data

        return data
      },
      transformErrorResponse: ({ data }: ErrorResponse) => {
        return data.message
      },
      providesTags: ['detailOrder'],
    }),
    confirmOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body: JSON.stringify({
          status: 'PROCESSED',
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
          'content-type': 'application/json',
        },
      }),
      transformResponse: ({ data }: OKResponse<string>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['orders', 'detailOrder'],
    }),
    cancelOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body: JSON.stringify({
          status: 'CANCELED',
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
          'content-type': 'application/json',
        },
      }),
      transformResponse: ({ data }: OKResponse<string>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['orders', 'detailOrder'],
    }),
    sendOrder: builder.mutation({
      query: ({ id }) => ({
        url: `/orders/${id}`,
        method: 'PATCH',
        body: JSON.stringify({
          status: 'ONDELIVERY',
        }),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
          'content-type': 'application/json',
        },
      }),
      transformResponse: ({ data }: OKResponse<string>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['orders', 'detailOrder'],
    }),
    updateOrderStatus: builder.mutation({
      query: ({ orderID, status }) => ({
        url: '/orders/' + orderID,
        method: 'PATCH',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
        body: JSON.stringify({ status: status }),
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['orders', 'transactions', 'detailTransaction'],
    }),
    createDeliveryStatus: builder.mutation({
      query: ({ data }) => ({
        url: '/order-deliveries',
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
          'content-type': 'application/json',
        },
      }),
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['detailOrder', 'detailTransaction'],
    }),
    getDetailProduct: builder.query({
      query: ({ orderID }) => ({
        url: '/order-details/' + orderID,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: ({ data }: OKResponse<OrderDetailDTO>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
    }),
  }),
})

export const {
  useLazyGetOrdersQuery,
  useGetOrderDetailQuery,
  useConfirmOrderMutation,
  useCancelOrderMutation,
  useSendOrderMutation,
  useUpdateOrderStatusMutation,
  useCreateDeliveryStatusMutation,
  useGetDetailProductQuery,
} = ordersApiSlice
