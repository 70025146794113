import React from 'react'
import {
  Box,
  Container,
  Grid,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import CustomImage from '../images/index'

function Footer() {
  return (
    <Box
      bg={useColorModeValue('white', 'dark')}
      color={useColorModeValue('gray.700', 'gray.200')}
      borderTopWidth={1}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.400', 'gray.700')}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={'flex-start'}>
            <Text fontWeight={'800'} fontSize={'lg'} mb={2}>
              Company
            </Text>
            <Link href={'#'}>About Us</Link>
            <Link href={'#'}>Blog</Link>
            <Link href={'#'}>Careers</Link>
            <Link href={'#'}>Contact Us</Link>
          </Stack>

          <Stack align={'flex-start'}>
            <Text fontWeight={'800'} fontSize={'lg'} mb={2}>
              Support
            </Text>
            <Link href={'#'}>Help Center</Link>
            <Link href={'#'}>Safety Center</Link>
            <Link href={'#'}>Community Guidelines</Link>
          </Stack>

          <Stack align={'flex-start'}>
            <Text fontWeight={'800'} fontSize={'lg'} mb={2}>
              Policy
            </Text>
            <Link href={'#'}>Cookies Policy</Link>
            <Link href={'#'}>Privacy Policy</Link>
            <Link href={'#'}>Terms of Service</Link>
            <Link href={'#'}>Law Enforcement</Link>
          </Stack>

          <Stack align={'flex-start'}>
            <Text fontWeight={'800'} fontSize={'lg'} mb={2}>
              Install App
            </Text>
            <Grid gap={2}>
              <RouterLink to=''>
                <CustomImage
                  src='/assets/svgs/download-app-store.svg'
                  w='150px'
                  h='50px'
                  alt='Apple Store Download'
                />
              </RouterLink>
              <RouterLink to=''>
                <CustomImage
                  src='/assets/svgs/download-google-play.svg'
                  w='150px'
                  h='50px'
                  alt='Google Play Download'
                />
              </RouterLink>
            </Grid>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          gap={4}
          justify={{ md: 'space-between' }}
          align={{ md: 'center' }}
        >
          <Text>© 2022 SeaMart. All rights reserved</Text>
          <Stack direction='row' spacing={6}>
            <Link href='https://www.facebook.com' isExternal>
              <CustomImage
                src='/assets/svgs/facebook.svg'
                alt='Share facebook'
                h='30px'
                w='30px'
              />
            </Link>
            <Link href='https://www.twitter.com' isExternal>
              <CustomImage
                src='/assets/svgs/twitter.svg'
                alt='Share twitter'
                h='30px'
                w='30px'
              />
            </Link>
            <Link href='https://www.instagram.com' isExternal>
              <CustomImage
                src='/assets/svgs/instagram.svg'
                alt='Share instagram'
                h='30px'
                w='30px'
              />
            </Link>
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
