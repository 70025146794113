import React from 'react'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { ImageModalProps } from './entities'

const ImageModal = (props: ImageModalProps) => {
  const { body, ...rest } = props
  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {body}
      </ModalContent>
    </Modal>
  )
}

export default ImageModal
