import {
  AspectRatio,
  Badge,
  Box,
  Container,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DangerButton from '../../../components/buttons/DangerButton'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import SecondaryButton from '../../../components/buttons/SecondaryButton'
import { DataTable } from '../../../components/datatable'
import ErrorState from '../../../components/errorState'
import CustomImage from '../../../components/images'
import LoadingSpinner from '../../../components/loadingSpinner'
import DetailOrderModal from '../../../components/modal/orderModals/detailOrderModal'
import NoData from '../../../components/noData'
import OrderStatusTabs from '../../../components/tabs/orderStatusTabs'
import {
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useLazyGetOrdersQuery,
  useSendOrderMutation,
} from '../../../features/orders/ordersSlice'

const DisplayOrdersPage = () => {
  const [getOrders, getOrdersResult] = useLazyGetOrdersQuery()
  const [orders, setOrders] = useState([])
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure()
  const { search } = useLocation()

  const query = new URLSearchParams(search)
  const paramFilter = query.get('filter')

  const renderOrderCell = ({
    imageUrl,
    title,
    recipientName,
    recipientCity,
    remainingProducts,
  }: any) => (
    <Box py={'8px'}>
      <VStack align={'start'} gap={'8px'}>
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <Text as={'b'}>{recipientName}</Text>
          </Flex>
          <Flex direction={'row'} mb={'8px'}>
            <Text color={'text.light'}>{recipientCity}</Text>
          </Flex>
        </Flex>

        <HStack gap={'8px'}>
          <AspectRatio w='100px' ratio={1}>
            <CustomImage
              boxSize='100px'
              objectFit='cover'
              __css={{ backgroundColor: 'gray.200', borderRadius: '20px' }}
              alt={`product`}
              src={imageUrl}
            ></CustomImage>
          </AspectRatio>
          <VStack align={'start'} gap={'0'}>
            <Text as={'b'}>{title}</Text>
            {remainingProducts && (
              <Text color={'text.light'}>
                +{remainingProducts} other items.
              </Text>
            )}
          </VStack>
        </HStack>
      </VStack>
    </Box>
  )

  const renderCourierCell = ({ courier, service }: any) => {
    return (
      <Box>
        <Text as={'b'}>{courier}</Text>
        <Text color={'text.light'}>{service}</Text>
      </Box>
    )
  }

  const renderStatusCell = ({ status }: any) => {
    return status === 'WAITSELLERCONFIRMATION' ? (
      <Badge colorScheme={'gray'}>Waiting for Confirmation</Badge>
    ) : status === 'PROCESSED' ? (
      <Badge colorScheme={'yellow'}>Processed</Badge>
    ) : status === 'ONDELIVERY' ? (
      <Badge colorScheme={'yellow'}>On Delivery</Badge>
    ) : status === 'DELIVERED' ? (
      <Badge colorScheme={'green'}>Delivered</Badge>
    ) : status === 'Completed' ? (
      <Badge colorScheme={'green'}>Completed</Badge>
    ) : status === 'CANCELED' ? (
      <Badge colorScheme={'red'}>Cancelled</Badge>
    ) : (
      ''
    )
  }

  const renderActionCell = ({ id, status }: any) => {
    return (
      <HStack>
        {status === 'WAITSELLERCONFIRMATION' && (
          <>
            <PrimaryButton onClick={() => confirmOrder({ id })}>
              Confirm
            </PrimaryButton>
            <DangerButton onClick={() => cancelOrder({ id })}>
              Cancel
            </DangerButton>
          </>
        )}
        {status === 'PROCESSED' && (
          <PrimaryButton onClick={() => sendOrder({ id })}>Send</PrimaryButton>
        )}

        <SecondaryButton
          onClick={() => {
            setSelectedId(id)
            onOpen()
          }}
        >
          Detail
        </SecondaryButton>
      </HStack>
    )
  }

  // Generate columns
  const columnHelper = createColumnHelper<any>()
  const columns = [
    columnHelper.accessor('order', {
      cell: (info) => renderOrderCell(info.getValue()),
      header: 'Order',
    }),
    columnHelper.accessor('status', {
      cell: (info) => renderStatusCell(info.getValue()),
      header: 'Status',
    }),
    columnHelper.accessor('courier', {
      cell: (info) => renderCourierCell(info.getValue()),
      header: 'Courier',
    }),
    columnHelper.accessor('action', {
      cell: (info) => renderActionCell(info.getValue()),
      header: 'Action',
    }),
  ]

  useEffect(() => {
    if (!getOrdersResult.data) {
      setOrders([])
      return
    }

    const orders: any = getOrdersResult.data.map((val: any) => ({
      order: {
        imageUrl: val.order_detail.thumbnail_url,
        title: val.order_detail.title,
        status: val.status,
        recipientName: val.receiver_name,
        recipientCity: val.receiver_city,
        remainingProducts: val.remaining_order_detail,
      },
      status: {
        status: val.status,
      },
      courier: {
        courier: val.courier.name,
        service: val.courier.service,
      },
      action: {
        id: val.id,
        status: val.status,
      },
    }))
    setOrders(orders)
  }, [getOrdersResult.data])

  const [selectedId, setSelectedId] = useState('')

  const [confirmOrder] = useConfirmOrderMutation()
  const [cancelOrder] = useCancelOrderMutation()
  const [sendOrder] = useSendOrderMutation()

  const [selectedTab, setSelectedTab] = useState(0)
  const [filter, setFilter] = useState('all')

  const statuses = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Waiting for Payment',
      value: 'WAITFORPAYMENT',
    },
    {
      label: 'Waiting for Confirmation',
      value: 'WAITSELLERCONFIRMATION',
    },
    {
      label: 'Processed',
      value: 'PROCESSED',
    },
    {
      label: 'On Delivery',
      value: 'ONDELIVERY',
    },
    {
      label: 'Delivered',
      value: 'DELIVERED',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ]

  useEffect(() => {
    if (!selectedTab) return
    setFilter(statuses[selectedTab].value)
  }, [selectedTab])

  useEffect(() => {
    if (!filter) return

    getOrders({ filter })
  }, [filter])

  useEffect(() => {
    if (!paramFilter) return
    setFilter(paramFilter)
    setSelectedTab(
      statuses
        .map((status) => status.value.toUpperCase())
        .indexOf(paramFilter.toUpperCase())
    )
  }, [paramFilter])

  return (
    <Container maxW='6xl'>
      <OrderStatusTabs
        data={statuses.map((status) => status.label)}
        selectedIndex={selectedTab}
        onClick={(index: number) => setSelectedTab(index)}
      />
      {getOrdersResult.isLoading && <LoadingSpinner />}
      {!getOrdersResult.isLoading && getOrdersResult.isError && <ErrorState />}
      {!getOrdersResult.isLoading &&
        !getOrdersResult.isError &&
        orders &&
        (orders?.length > 0 ? (
          <Box>
            <DetailOrderModal
              isOpen={isOpen}
              onClose={onClose}
              id={selectedId}
            />
            <DataTable columns={columns} data={orders} />
          </Box>
        ) : (
          <Box mt={'8px'}>
            <NoData />
          </Box>
        ))}
    </Container>
  )
}

export default DisplayOrdersPage
