import { ErrorResponse } from '@remix-run/router'
import Cookies from 'js-cookie'
import { apiSlice } from '../api/apiSlice'
import { OKResponse } from '../entities'
import { Profile } from '../users/entities'
import { Sealabspay } from './entities'

export const sealabspayApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOwnSealabspay: builder.query({
      query: () => ({
        url: '/sealabspay-accounts/self',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: ({ data }: OKResponse<Sealabspay[]>) => data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['sealabspayAccounts'],
    }),
    updateDefault: builder.mutation({
      query: ({ sealabspayAccountID }) => ({
        url: '/profile-sealabspay',
        method: 'PUT',
        body: JSON.stringify({
          sealabspay_account_id: sealabspayAccountID,
        }),
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: (response: OKResponse<Profile>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['users'],
    }),
    deleteSealabspay: builder.mutation({
      query: ({ sealabspayAccountID }) => ({
        url: '/sealabspay-accounts',
        method: 'DELETE',
        body: JSON.stringify({
          sealabspay_account_id: sealabspayAccountID,
        }),
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: (response: OKResponse<Sealabspay[]>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['sealabspayAccounts'],
    }),
    createSealabspay: builder.mutation({
      query: ({ name, cardNumber, activeDate }) => ({
        url: '/sealabspay-accounts',
        method: 'POST',
        body: JSON.stringify({
          name,
          card_number: cardNumber,
          active_date: activeDate,
        }),
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: (response: OKResponse<Sealabspay[]>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['sealabspayAccounts', 'users'],
    }),
    paymentSealabspay: builder.mutation({
      query: ({ cardNumber, amount, redirectURL, id, paymentType }) => ({
        url: '/sealabspay-accounts-payment',
        method: 'POST',
        body: JSON.stringify({
          card_number: cardNumber,
          amount,
          redirect_url: redirectURL,
          transaction_id: id,
          payment_type: paymentType,
        }),
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: (response: OKResponse<string>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['transactions'],
    }),
    topupSealabspay: builder.mutation({
      query: ({ cardNumber, amount, redirectURL, id, paymentType }) => ({
        url: '/sealabspay-accounts-payment',
        method: 'POST',
        body: JSON.stringify({
          card_number: cardNumber,
          amount,
          redirect_url: redirectURL,
          sealabspay_account_id: id,
          payment_type: paymentType,
        }),
        headers: {
          Authorization: 'Bearer ' + Cookies.get('tokenaccess'),
        },
      }),
      transformResponse: (response: OKResponse<string>) => response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      invalidatesTags: ['wallet'],
    }),
  }),
})

export const {
  useGetOwnSealabspayQuery,
  useUpdateDefaultMutation,
  useDeleteSealabspayMutation,
  useCreateSealabspayMutation,
  usePaymentSealabspayMutation,
  useTopupSealabspayMutation,
} = sealabspayApiSlice
