import { Container } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import ErrorState from '../../../components/errorState'
import EditProductForm from '../../../components/form/EditProductForm'
import LoadingSpinner from '../../../components/loadingSpinner'
import { useGetProductByIdQuery } from '../../../features/products/productsSlice'

const UpdateProductPage = () => {
  const params = useParams()
  const id = params['id']
  const { data, isLoading, isError } = useGetProductByIdQuery(id)

  return (
    <Container maxW='6xl' overflow='auto'>
      {isLoading && <LoadingSpinner />}
      {!isLoading && isError && <ErrorState />}
      {!isLoading && !isError && data && <EditProductForm data={data} />}
    </Container>
  )
}

export default UpdateProductPage
