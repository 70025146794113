import React from 'react'
import { Badge, Box, Flex } from '@chakra-ui/react'
import { SealabspayCardProps } from './entities'
import SealabspayCardDetail from './SealabspayCardDetail'

const SealabspayCardDefault = (props: SealabspayCardProps) => {
  const { name, number, validDate } = props
  return (
    <Box bgColor={'primary.400'} p={5} rounded='md' shadow='md' h='full'>
      <Flex flexDirection='column' h='100%'>
        <Flex alignItems='baseline'>
          <Badge borderRadius='full' px='2' colorScheme='teal' mb='5'>
            DEFAULT
          </Badge>
        </Flex>
        <SealabspayCardDetail
          title='Name'
          value={name}
          colorTitle='primary.50'
          colorValue='primary.800'
        />
        <SealabspayCardDetail
          title='Card Number'
          value={number}
          colorTitle='primary.50'
          colorValue='primary.800'
        />
        <SealabspayCardDetail
          title='Valid Date'
          value={validDate}
          colorTitle='primary.50'
          colorValue='primary.800'
        />
      </Flex>
    </Box>
  )
}

export default SealabspayCardDefault
