import { HStack, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import CustomImage from '../images/index'

const ProductShare = () => {
  const handleShareclick = (type: string) => {
    let url = ''
    if (type === 'fb') {
      url =
        'https://www.facebook.com/sharer/sharer.php?u=' +
        encodeURIComponent(document.URL) +
        '&t=' +
        encodeURIComponent(document.URL)
    } else if (type === 'tw') {
      url =
        'https://twitter.com/intent/tweet?text=Check%20this%20out!%20' +
        encodeURIComponent(document.title) +
        '%20' +
        encodeURIComponent(document.URL) +
        '%20'
    }
    window.open(
      url,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    )
  }

  return (
    <HStack>
      <Text>Share:</Text>
      <IconButton
        aria-label='facebook button'
        rounded='full'
        bg='transparent'
        icon={<CustomImage w={'35px'} src='/assets/svgs/facebook.svg' />}
        onClick={() => {
          handleShareclick('fb')
        }}
      />
      <IconButton
        aria-label='twitter button'
        rounded='full'
        bg='transparent'
        icon={<CustomImage w={'35px'} src='/assets/svgs/twitter.svg' />}
        onClick={() => {
          handleShareclick('tw')
        }}
      />
    </HStack>
  )
}

export default ProductShare
