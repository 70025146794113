import Cookies from 'js-cookie'
import { minuteToDay } from '../../helpers/durationFormat'
import { getNumberEnv } from '../../helpers/env'
import { apiSlice } from '../api/apiSlice'
import { ErrorResponse } from '../entities'
import { usersApiSlice } from '../users/usersSlice'
import {
  GoogleLoginResponseNotExist,
  LoginResponse,
  RegisterResponse,
} from './entities'
import { OKResponse } from '../entities'

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: ({ email, password, username }) => ({
        url: '/register',
        method: 'POST',
        body: JSON.stringify({ email, password, username }),
      }),
      transformResponse: ({ data }: OKResponse<RegisterResponse>) => {
        const tokenAccessLifetime = getNumberEnv(
          'REACT_APP_TOKEN_ACCESS_EXPIRED'
        )
        const tokenRefreshLifetime = getNumberEnv(
          'REACT_APP_TOKEN_REFRESH_EXPIRED'
        )

        Cookies.set('tokenaccess', data.token_access, {
          expires: minuteToDay(tokenAccessLifetime),
        })
        Cookies.set('tokenrefresh', data.token_refresh, {
          expires: minuteToDay(tokenRefreshLifetime),
        })
        return data
      },
      transformErrorResponse: ({ data }: ErrorResponse) => {
        return data.message
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          await dispatch(usersApiSlice.endpoints.getProfile.initiate())

          const reduxState: any = getState()
          const imageUrl = reduxState.authReduxReducer.picture
          const name = reduxState.authReduxReducer.name

          await dispatch(
            usersApiSlice.endpoints.updateProfileData.initiate({
              fullname: name,
              gender: 'male',
              birthdate: '01-01-1990',
            })
          ).unwrap()

          await dispatch(
            usersApiSlice.endpoints.updateProfilePictureWithUrl.initiate({
              data: imageUrl,
            })
          ).unwrap()
        } catch (err) {
          return
        }
      },
    }),
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: '/login',
        method: 'POST',
        body: JSON.stringify({
          username,
          password,
        }),
      }),
      transformResponse: ({ data }: LoginResponse) => {
        const tokenAccessLifetime = getNumberEnv(
          'REACT_APP_TOKEN_ACCESS_EXPIRED'
        )
        const tokenRefreshLifetime = getNumberEnv(
          'REACT_APP_TOKEN_REFRESH_EXPIRED'
        )

        Cookies.set('tokenaccess', data.token_access, {
          expires: minuteToDay(tokenAccessLifetime),
        })
        Cookies.set('tokenrefresh', data.token_refresh, {
          expires: minuteToDay(tokenRefreshLifetime),
        })
        return data
      },
      transformErrorResponse: ({ data }: ErrorResponse) => {
        return data.message
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          await dispatch(usersApiSlice.endpoints.getProfile.initiate())
        } catch (err) {
          return
        }
      },
      invalidatesTags: ['auth'],
    }),
    sendResetPasswordCode: builder.mutation({
      query: ({ email }) => ({
        url: '/forgot',
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      }),
      transformResponse: ({ data }: OKResponse<null>) => data,
      transformErrorResponse: ({ data }: ErrorResponse) => data.message,
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: '/forgot/reset',
        method: 'PUT',
        body: JSON.stringify({
          token,
          password,
        }),
      }),
      transformResponse: ({ data }: OKResponse<null>) => data,
      transformErrorResponse: ({ data }: ErrorResponse) => data.message,
    }),
    loginGoogle: builder.mutation({
      query: ({ email }) => ({
        url: '/google-login',
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      }),
      transformResponse: ({
        data,
      }: LoginResponse | GoogleLoginResponseNotExist) => {
        if (typeof data !== 'boolean') {
          const tokenAccessLifetime = getNumberEnv(
            'REACT_APP_TOKEN_ACCESS_EXPIRED'
          )
          const tokenRefreshLifetime = getNumberEnv(
            'REACT_APP_TOKEN_REFRESH_EXPIRED'
          )

          Cookies.set('tokenaccess', data.token_access, {
            expires: minuteToDay(tokenAccessLifetime),
          })
          Cookies.set('tokenrefresh', data.token_refresh, {
            expires: minuteToDay(tokenRefreshLifetime),
          })
        }

        return data
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled

          await dispatch(usersApiSlice.endpoints.getProfile.initiate()).unwrap()
        } catch (err) {
          return
        }
      },
      invalidatesTags: ['auth'],
    }),
  }),
})

export const {
  useRegisterMutation,
  useLoginMutation,
  useSendResetPasswordCodeMutation,
  useResetPasswordMutation,
  useLoginGoogleMutation,
} = authApiSlice
