import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import CustomForm from '.'
import StandardInput from '../inputs/StandardInput'
import { ForgotPasswordFormInputs } from './entities'
import { useSendResetPasswordCodeMutation } from '../../features/auth/authSlice'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Must be not empty'),
})

const ForgotPasswordForm = () => {
  const [sendResetPassword] = useSendResetPasswordCodeMutation()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ForgotPasswordFormInputs>({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values: ForgotPasswordFormInputs) => {
    const promiseResetPassword = sendResetPassword({
      email: values.email,
    })
      .unwrap()
      .then(() => {
        reset()
      })

    await toast.promise(promiseResetPassword, {
      pending: 'loading...',
      success: 'Verification code sent',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  return (
    <CustomForm onSubmit={handleSubmit(onSubmit)} isLoading={isSubmitting}>
      <StandardInput
        autoFocus
        {...register('email')}
        isRequired
        type='email'
        isInvalid={!!errors.email}
        label='Email'
        placeholder='john.doe@gmail.com'
        errorMessage={errors.email?.message}
      />
    </CustomForm>
  )
}

export default ForgotPasswordForm
