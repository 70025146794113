import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  InputGroup,
} from '@chakra-ui/react'
import React from 'react'
import { CustomInputProps } from './entities'
import CustomInput from './index'

const AdditionalElementsInput = forwardRef<CustomInputProps, 'input'>(
  (props, ref) => {
    const {
      placeholder,
      isRequired,
      isInvalid,
      errorMessage,
      leftElement,
      rightAddon,
      label,
      isLabelTop,
      ...rest
    } = props
    return (
      <FormControl
        isRequired={isRequired}
        isInvalid={isInvalid}
        my={4}
        display={isLabelTop ? 'block' : 'flex'}
      >
        {label && <FormLabel flex={1}>{label}</FormLabel>}
        <InputGroup>
          {leftElement && (
            <Box p={0} backgroundColor='gray.100'>
              {leftElement}
            </Box>
          )}
          <CustomInput ref={ref} {...rest} placeholder={placeholder ?? ''} />
          {rightAddon && (
            <Box p={0} backgroundColor='gray.100'>
              {rightAddon}
            </Box>
          )}

          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </InputGroup>
      </FormControl>
    )
  }
)

export default AdditionalElementsInput
