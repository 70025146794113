import Cookies from 'js-cookie'
import { ShopProductCategory } from '../../components/shopProductCategoryCard/entities'
import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'

export const shopProductCategoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShopProductCategories: builder.query({
      query: ({ userID }) => ({
        url: `/shops/users/${userID}/categories`,
        method: 'GET',
      }),
      providesTags: ['shopProductCategories'],
      transformResponse: (response: any) => response.data,
    }),
    getShopProductCategoryById: builder.query({
      query: ({ id, userID }) => ({
        url: `/shops/users/${userID}/categories/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: OKResponse<ShopProductCategory>) =>
        response.data,
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['shopProductCategories'],
    }),
    getShopProductCategoriesByShopID: builder.query({
      query: ({ shopID }) => ({
        url: `/shops/${shopID}/categories`,
        method: 'GET',
      }),
      providesTags: ['shopProductCategories'],
      transformResponse: (response: any) => response.data,
    }),
    createShopProductCategory: builder.mutation({
      query: ({ data }: any) => ({
        url: '/shops/categories',
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        transformErrorResponse: (response: ErrorResponse) =>
          response.data.message,
        invalidatesTags: ['shopProductCategories'],
      }),
    }),
    deleteShopProductCategory: builder.mutation({
      query: ({ id }: any) => ({
        url: `/shops/categories/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${Cookies.get('tokenaccess')}`,
        },
        transformErrorResponse: (response: ErrorResponse) =>
          response.data.message,
      }),
      invalidatesTags: ['shopProductCategories'],
    }),
  }),
})

export const {
  useGetShopProductCategoriesQuery,
  useLazyGetShopProductCategoriesQuery,
  useLazyGetShopProductCategoryByIdQuery,
  useLazyGetShopProductCategoriesByShopIDQuery,
  useCreateShopProductCategoryMutation,
  useDeleteShopProductCategoryMutation,
} = shopProductCategoriesApiSlice
