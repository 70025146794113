import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../../components/loadingSpinner'
import { useGetSelfShopInformationQuery } from '../../features/shops/shopsSlice'

const SellerProtectedRoute = () => {
  const navigate = useNavigate()
  const {
    data: shopData,
    isLoading: shopIsLoading,
    isUninitialized: shopIsUninitialized,
  } = useGetSelfShopInformationQuery()

  useEffect(() => {
    if (shopIsUninitialized || shopIsLoading) return
    if (!shopData) {
      navigate('/create-shop')
      return
    }
  }, [shopIsLoading])

  return shopData ? <Outlet /> : <LoadingSpinner />
}

export default SellerProtectedRoute
