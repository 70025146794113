import { Badge, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { ProductPriceProps } from './entities'

const ProductPrice = ({ discountedPrice, price, discountAmount }: ProductPriceProps) => {
  return (
    <Flex
      w='100%'
      bg='gray.100'
      alignItems={['start', 'start', 'center']}
      gap={[2, 4]}
      p={4}
      my={2}
      direction={['column-reverse', 'column-reverse', 'row']}
    >
      {/* real price */}
      {discountedPrice && (
        <Text as='s' color='gray' fontSize='base'>
          {price}
        </Text>
      )}
      {/* discounted price */}
      <Text fontWeight='extrabold' color='primary.400' fontSize='2xl'>
        {discountedPrice || price}
      </Text>
      {discountedPrice && (
        <Badge bg='primary.400' color='gray.100'>
          <Text fontSize='base'>{discountAmount} OFF</Text>
        </Badge>
      )}
    </Flex>
  )
}

export default ProductPrice
