import { City, Province, Subdistrict } from '../address/entities'
import { apiSlice } from '../api/apiSlice'
import { OKResponse } from '../entities'

export const locationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProvinces: builder.query<OKResponse<Array<Province>>, void>({
      query: () => ({
        url: '/provinces',
        method: 'GET',
      }),
    }),
    getCities: builder.query<OKResponse<Array<City>>, number | string | void>({
      query: (province_id?: number) => ({
        url: '/cities',
        method: 'GET',
        params: { province_id },
      }),
      providesTags: ['locations'],
    }),
    getSubditricts: builder.query<
      OKResponse<Array<Subdistrict>>,
      number | string | void
    >({
      query: (city_id: number) => ({
        url: '/subdistricts',
        method: 'GET',
        params: { city_id },
      }),
      providesTags: ['locations'],
    }),
  }),
})

export const {
  useGetProvincesQuery,
  useLazyGetCitiesQuery,
  useGetCitiesQuery,
  useLazyGetSubditrictsQuery,
} = locationApiSlice
