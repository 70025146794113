import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  locationProp,
  priceProp,
  sortFilterProp,
  categoryProp,
} from './entities'

const initialState: sortFilterProp = {
  sort: '',
  sort_by: '',
  location_query: [],
  category_query: {},
  min_price: 0,
  max_price: 0,
  review: 0,
  rating: '',
}

const sortFilterSlice = createSlice({
  name: 'sortFilter',
  initialState: initialState,
  reducers: {
    setSortAndSortBy: (state, action: PayloadAction<string>) => {
      state.sort_by = JSON.parse(action.payload).sort_by
      state.sort = JSON.parse(action.payload).sort
    },
    toggleLocationQuery: (state, action: PayloadAction<locationProp>) => {
      const index = state.location_query.findIndex(
        (x) => x.id === action.payload.id
      )
      if (index === -1) {
        state.location_query.push(action.payload)
      } else {
        state.location_query.splice(index, 1)
      }
    },
    toggleCategoryQuery: (state, action: PayloadAction<categoryProp>) => {
      state.category_query.id = action.payload.id
      state.category_query.name = action.payload.name
    },
    setPriceQuery: (state, action: PayloadAction<priceProp>) => {
      state.min_price = action.payload.min_price
      state.max_price = action.payload.max_price
    },
    setReviewQuery: (state, action: PayloadAction<number>) => {
      state.review = action.payload
    },
    resetQuery: (state) => {
      state.location_query = initialState.location_query
      state.category_query = initialState.category_query
      state.min_price = initialState.min_price
      state.max_price = initialState.max_price
      state.review = initialState.review
    },
    setRatingFilter: (state, action: PayloadAction<number | string>) => {
      state.rating = action.payload
    },
    resetRating: (state) => {
      state.rating = initialState.rating
    },
  },
})

export const {
  setSortAndSortBy,
  toggleLocationQuery,
  toggleCategoryQuery,
  setPriceQuery,
  setReviewQuery,
  resetQuery,
  setRatingFilter,
  resetRating,
} = sortFilterSlice.actions
export default sortFilterSlice.reducer
