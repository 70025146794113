import React, { useEffect } from 'react'
import { Box, Center, Container, Flex, Heading, Text } from '@chakra-ui/react'
import { useGetOwnWalletQuery } from '../../features/wallets/walletsSlice'
import CustomImage from '../../components/images'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import ShopCreationForm from '../../components/form/ShopCreationForm'
import { useNavigate } from 'react-router-dom'
import {
  useCreateShopMutation,
  useGetSelfShopInformationQuery,
} from '../../features/shops/shopsSlice'
import { useGetUserAddressesQuery } from '../../features/address/address'
import { ShopCreationInputs } from '../../components/form/entities'
import { toast } from 'react-toastify'

const ShopCreationPage = () => {
  const navigate = useNavigate()

  const { data: walletData, isLoading: walletIsLoading } =
    useGetOwnWalletQuery(null)
  const {
    data: shopData,
    isLoading: shopIsLoading,
    isUninitialized: shopIsUninitialized,
  } = useGetSelfShopInformationQuery()
  const { data: addressData, isLoading: addressIsLoading } =
    useGetUserAddressesQuery()
  const [createShop] = useCreateShopMutation()

  const evtOnSubmit = (inputs: ShopCreationInputs) => {
    const promiseCreateShop = createShop(inputs)
      .unwrap()
      .then(() => navigate('/seller/products'))

    toast.promise(promiseCreateShop, {
      pending: 'loading...',
      success: 'Create shop success',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  useEffect(() => {
    if (shopIsUninitialized || shopIsLoading) return
    if (shopData) navigate('/seller/products')
  }, [shopData])

  return (
    <>
      {(!walletIsLoading && !walletData && (
        <Container
          minW='xs'
          maxW='2xl'
          rounded='md'
          border='1px solid black'
          borderColor='primary.300'
          p={8}
        >
          <Flex direction='column'>
            <Flex justifyContent='center'>
              <CustomImage
                width='300px'
                height='auto'
                src='/assets/svgs/wallet.svg'
              />
            </Flex>
            <Flex direction='column' mt={3} gap={3}>
              <Center>
                <Heading>Oops..!</Heading>
              </Center>
              <Center>
                <Text color='text.light'>
                  Wallet is required before you can create your shop.
                </Text>
              </Center>
              <Center>
                <PrimaryButton
                  onClick={() => navigate('/profile/wallet')}
                  type='button'
                >
                  Go to Wallet Page
                </PrimaryButton>
              </Center>
            </Flex>
          </Flex>
        </Container>
      )) ||
        (!addressIsLoading && !addressData?.data && (
          <Container
            minW='xs'
            maxW='2xl'
            rounded='md'
            border='1px solid black'
            borderColor='primary.300'
            p={8}
          >
            <Flex direction='column'>
              <Flex justifyContent='center'>
                <CustomImage
                  width='300px'
                  height='auto'
                  src='/assets/svgs/user-no-address.svg'
                />
              </Flex>
              <Flex direction='column' mt={3} gap={3}>
                <Center>
                  <Heading>Oops..!</Heading>
                </Center>
                <Center>
                  <Text color='text.light'>
                    Address is required before you can create your shop.
                  </Text>
                </Center>
                <Center>
                  <Text color='text.light' fontSize='2xs'>
                    Else, the courier will search over all continent to find you
                  </Text>
                </Center>
                <Center>
                  <PrimaryButton
                    onClick={() => navigate('/profile/addresses')}
                    type='button'
                  >
                    Go to Address Page
                  </PrimaryButton>
                </Center>
              </Flex>
            </Flex>
          </Container>
        )) ||
        (!walletIsLoading && walletData && (
          <Box my='12' px='3'>
            <Flex
              mx='auto'
              gap={10}
              maxW='6xl'
              alignItems='center'
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <Box flex={2} display={{ base: 'none', md: 'block' }}>
                <CustomImage h='auto' src='/assets/svgs/create-shop.svg' />
              </Box>
              <Box
                position='relative'
                flex={3}
                w='full'
                px={{
                  base: 0,
                  md: 5,
                }}
                py='8'
                border={{ base: 'none', md: '1px solid' }}
                borderColor={{ base: 'transparent', md: 'primary.200' }}
                borderRadius='md'
              >
                <Box
                  position={{ base: 'static', md: 'absolute' }}
                  top={{ base: 0, md: -6 }}
                  bgColor='white'
                  px={{ base: 0, md: 2 }}
                  mb={{ base: 5, md: 0 }}
                >
                  <Heading>Create Shop</Heading>
                </Box>
                <ShopCreationForm
                  addresses={addressData?.data || []}
                  evtOnSubmit={evtOnSubmit}
                />
              </Box>
            </Flex>
          </Box>
        ))}
    </>
  )
}

export default ShopCreationPage
