import React, { useState } from 'react'
import {
  HStack,
  Portal,
  SimpleGrid,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { FaPlus } from 'react-icons/fa'
import AddressModal from '../../components/modal/AddressModal'
import { useGetUserAddressesQuery } from '../../features/address/address'
import LoadingSpinner from '../../components/loadingSpinner/index'
import { Address } from '../../features/address/entities'
import { Heading, Badge } from '@chakra-ui/react'
import AddressCard from '../../components/card/AddressCard'
import { useGetProfileQuery } from '../../features/users/usersSlice'
import { ReactElement } from 'react'
import NoData from '../../components/noData'
import { useNavigate } from 'react-router-dom'

const AddressesPage = () => {
  const { data: profile } = useGetProfileQuery()
  const { data } = useGetUserAddressesQuery()
  const [address, setAddress] = useState<Address>()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const defaultAddress = profile?.data.default_address_id
  const defaultShopAddress = profile?.data.default_shop_location_id
  const navigate = useNavigate()

  return (
    <>
      <VStack w='full' gap={3} p={5}>
        <HStack w='full' justifyContent='space-between'>
          <Heading>Addresses</Heading>
          <PrimaryButton
            leftIcon={<FaPlus />}
            alignSelf='start'
            w={{ base: 'full', sm: 'fit-content' }}
            onClick={() => navigate('create')}
          >
            Add new address
          </PrimaryButton>
        </HStack>
        {data ? (
          <SimpleGrid gap={3} w='full'>
            {data.data ? (
              data.data.map((el) => {
                const badges: Array<ReactElement> = []

                if (el.id === defaultAddress)
                  badges.push(
                    <Badge key={'default'} variant={'outline'}>
                      DEFAULT
                    </Badge>
                  )

                if (el.id === defaultShopAddress)
                  badges.push(
                    <Badge key={'defaultShop'} variant={'outline'}>
                      SHOP
                    </Badge>
                  )

                return (
                  <AddressCard
                    badges={badges}
                    address={el}
                    onClick={() => {
                      setAddress(el)
                      onOpen()
                    }}
                    key={el.id}
                  />
                )
              })
            ) : (
              <NoData />
            )}
          </SimpleGrid>
        ) : (
          <LoadingSpinner />
        )}
      </VStack>
      <Portal>
        {address && (
          <AddressModal
            isOpen={isOpen}
            onClose={onClose}
            address={{
              ...address,
              city_id: address.city.id,
              province_id: address.province.id,
              subdistrict_id: address.subdistrict.id,
              defaultAddress: defaultAddress === address.id,
              defaultShopAddress: defaultShopAddress === address.id,
            }}
          />
        )}
      </Portal>
    </>
  )
}

export default AddressesPage
