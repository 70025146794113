import React, { useRef } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Link as ChakraLink,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  useDisclosure,
  Icon,
  DrawerHeader,
  CloseButton,
  Flex,
  Portal,
} from '@chakra-ui/react'

import { Link } from 'react-router-dom'
import { MdMenu } from 'react-icons/md'
import { Heading } from '@chakra-ui/react'
import { SidebarProps } from './entities'
import PrimaryButton from '../buttons/PrimaryButton'

const Sidebar = (props: SidebarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<any>()

  return (
    <>
      <Portal>
        <Box
          display={{ base: isOpen ? 'none' : 'block', md: 'none' }}
          position='fixed'
          bottom='8'
          right='8'
          zIndex='modal'
        >
          <PrimaryButton
            borderRadius='full'
            onClick={(e) => {
              e.preventDefault()
              onOpen()
            }}
            w={'12'}
            h={'12'}
            boxShadow='sm'
          >
            <Icon as={MdMenu} fontSize='1.5rem' />
          </PrimaryButton>
        </Box>
      </Portal>

      <Box
        display={{ base: 'none', md: 'block' }}
        textAlign='left'
        py='1rem'
        overflowY='auto'
        minH='100%'
        w='250px'
        borderRight={'1px solid #d2cfcf'}
      >
        <SidebarContent {...props} />
      </Box>

      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='full'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            as={Flex}
            justifyContent='space-between'
            alignItems='center'
          >
            <Text fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
              Seller Menu
            </Text>
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onClose}
            />
          </DrawerHeader>
          <DrawerBody>
            <SidebarContent {...props} evtOnClose={() => onClose()} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const SidebarContent = (props: SidebarProps & { evtOnClose?: () => void }) => (
  <Accordion py={0} m={0} allowMultiple>
    {props.items.map((val) => (
      <AccordionItem border={0} my={1} key={val.title}>
        <Heading>
          <AccordionButton>
            <Box flex='1' textAlign='left' py={2}>
              <Text color='text.normal' fontWeight='bold'>
                {val.icon}&nbsp;{val.title}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          {val.items.map((valChild) => (
            <Box key={`${val.title}-${valChild.title}`}>
              <ChakraLink
                as={Link}
                display='block'
                h='2.5rem'
                ml={7}
                color='text.normal'
                to={valChild.ref}
                onClick={() => {
                  if (!props.evtOnClose) return
                  props.evtOnClose()
                }}
              >
                {valChild.title}
              </ChakraLink>
            </Box>
          ))}
        </AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
)

export default Sidebar
