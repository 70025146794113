import { apiSlice } from '../api/apiSlice'
import { ErrorResponse, OKResponse } from '../entities'
import { Category } from './entities'

export const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: '/categories',
        method: 'GET',
      }),
      // TODO: change any
      transformResponse: (response: any) => {
        const categories = response.data
        if (categories.data === null) {
          categories.data = []
        }

        return categories
      },
      transformErrorResponse: (response: ErrorResponse) =>
        response.data.message,
      providesTags: ['categories'],
    }),
    getCategoryById: builder.query({
      query: ({ categoryId }) => ({
        url: `/category/${categoryId}`,
        method: 'GET',
      }),
      transformResponse: ({ data }: OKResponse<Category>) => data,
    }),
  }),
})

export const {
  useGetCategoriesQuery,
  useLazyGetCategoriesQuery,
  useGetCategoryByIdQuery,
  useLazyGetCategoryByIdQuery,
} = categoriesApiSlice
