import { Avatar, AvatarBadge } from '@chakra-ui/avatar'
import React, { useRef } from 'react'
import { FaCamera } from 'react-icons/fa'
import {
  useGetProfileQuery,
  useUpdateProfilePictureMutation,
} from '../../features/users/usersSlice'
import FileInput from '../inputs/FileInput'
import { toast } from 'react-toastify'

const ProfilePicture = () => {
  const imageInput = useRef<any>(null)
  const { data } = useGetProfileQuery()
  const [updateProfilePhoto] = useUpdateProfilePictureMutation()

  const onSubmit = async (data?: File) => {
    const formData = new FormData()
    if (data) formData.append('profile_picture', data)

    toast.promise(updateProfilePhoto({ data: formData }).unwrap(), {
      pending: 'loading...',
      success: 'Success update profile photo',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  return (
    <>
      <Avatar
        m={3}
        name={data?.data.fullname}
        src={data?.data.profile_picture}
        size='2xl'
        cursor='pointer'
        onClick={() => imageInput?.current?.click()}
      >
        <AvatarBadge bg='primary.400' borderColor='transparent'>
          <FaCamera />
        </AvatarBadge>
      </Avatar>
      <FileInput
        accept='.png,.jpg,.webp'
        display='none'
        ref={imageInput}
        onChange={(e) => onSubmit(e.target.files?.[0])}
      />
    </>
  )
}

export default ProfilePicture
