import React from 'react'
import { Checkbox, CheckboxProps, forwardRef } from '@chakra-ui/react'

const IndeterminateCheckbox = forwardRef<CheckboxProps, 'input'>(
  (props, ref) => {
    return (
      <Checkbox
        focusBorderColor='primary.400'
        errorBorderColor='danger.400'
        ref={ref}
        {...props}
      />
    )
  }
)

export default IndeterminateCheckbox
