import { Box, VStack } from '@chakra-ui/react'
import React from 'react'
import ShopProductCategoryCard from '../shopProductCategoryCard'
import { ShopProductCategory } from '../shopProductCategoryCard/entities'
import { ShopProductCategoryPanelProps } from './entities'

const ShopProductCategoryPanel = (props: ShopProductCategoryPanelProps) => {
  const { data, selectedIndex, onClick } = props
  return (
    <Box overflowY='auto'>
      <VStack>
        {data.slice().map((x: ShopProductCategory, index: number) => (
          <ShopProductCategoryCard
            key={x.id}
            data={x}
            isActive={index === selectedIndex}
            onClick={() => onClick(index)}
          />
        ))}
      </VStack>
    </Box>
  )
}

export default ShopProductCategoryPanel
