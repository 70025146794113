import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import ChangePasswordForm from '../../components/form/ChangePasswordForm'

const ChangePasswordPage = () => {
  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      w='full'
      maxH={{ base: '700px', md: '500px' }}
    >
      <Box
        w={{ base: 'full', md: 'xl' }}
        h='fit-content'
        border={{ base: 'none', md: '1px solid black' }}
        borderColor={{ base: 'transparent', md: 'primary.300' }}
        p={{ base: 0, md: 10 }}
        borderRadius='sm'
      >
        <ChangePasswordForm />
      </Box>
    </Flex>
  )
}

export default ChangePasswordPage
