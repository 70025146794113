import React, { useEffect, useState } from 'react'
import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import { useLazyGetShopPromotionByIdQuery } from '../../../features/shopPromotions/shopPromotionsSlice'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import convertToRupiah from '../../../helpers/currencyFormat'
import { DataTable } from '../../../components/datatable'
import { createColumnHelper } from '@tanstack/react-table'
import {
  PromotionProductDataTable,
  PromotionProductInformationField,
  PromotionProductPriceField,
  PromotionProductStockField,
} from './entities'
import CustomImage from '../../../components/images'

const renderInformationCell = ({
  imageUrl,
  name,
  sku,
}: PromotionProductInformationField) => (
  <Flex gap={2.5}>
    <CustomImage
      w='50px'
      h='50px'
      borderRadius='2px'
      objectFit='cover'
      src={imageUrl}
    />
    <Box>
      <Text fontWeight='bold' color='text.normal'>
        {name}
      </Text>
      <Text fontSize='14px' color='text.light'>
        SKU: {sku}
      </Text>
    </Box>
  </Flex>
)

const renderPriceCell = ({ maxPrice, minPrice }: PromotionProductPriceField) =>
  minPrice === maxPrice
    ? `Rp.\xa0${convertToRupiah(minPrice)}`
    : `Rp.\xa0${convertToRupiah(minPrice)} - Rp.\xa0${convertToRupiah(
        maxPrice
      )}`

const renderStockCell = ({ stock }: PromotionProductStockField) => stock

const PromotionDetailPage = () => {
  const columnHelper = createColumnHelper<PromotionProductDataTable>()
  const params = useParams()
  const [promotionProducts, setPromotionProducts] = useState<
    PromotionProductDataTable[]
  >([])
  const promotionId = params['promotionId']
  const [shopPromotionTrigger, shopPromotionResult] =
    useLazyGetShopPromotionByIdQuery()

  const columns = [
    columnHelper.accessor('information', {
      cell: (info) => renderInformationCell(info.getValue()),
      header: 'Information',
      sortingFn: (a, b) =>
        a.original.information.name > b.original.information.name ? 1 : -1,
    }),
    columnHelper.accessor('price', {
      cell: (info) => renderPriceCell(info.getValue()),
      header: 'Price',
    }),
    columnHelper.accessor('stock', {
      cell: (info) => renderStockCell(info.getValue()),
      header: 'Stock',
    }),
  ]

  useEffect(() => {
    shopPromotionTrigger({ promotionId }, false)
      .unwrap()
      .then(({ promotion_products: promotionproducts }) => {
        setPromotionProducts(
          promotionproducts
            ? promotionproducts.map(({ product }) => {
                const {
                  id,
                  title: name,
                  sku,
                  max_price: maxPrice,
                  min_price: minPrice,
                  total_stock: stock,
                } = product

                // Temporary return
                // Image URL from backend
                return {
                  information: {
                    id,
                    name,
                    imageUrl:
                      'https://img.freepik.com/free-photo/flat-lay-batch-cooking-composition_23-2148765597.jpg?w=2000',
                    sku,
                  },
                  price: {
                    maxPrice,
                    minPrice,
                  },
                  stock: {
                    stock,
                  },
                }
              })
            : []
        )
      })
  }, [])

  return (
    <Box px={{ base: 2, md: '50px' }} py='1rem' flex='1' w='full'>
      <Box mb='1rem'>
        <Text as='h2' fontWeight='bold' fontSize='1.5rem' mb='1rem'>
          Promotion Details&nbsp;
          <Badge
            colorScheme={
              shopPromotionResult.data?.status === 'will come'
                ? 'blue'
                : shopPromotionResult.data?.status === 'on going'
                ? 'green'
                : 'whiteAlpha'
            }
            rounded='sm'
          >
            {shopPromotionResult.data?.status}
          </Badge>
        </Text>
        <Box p={2} rounded='md' shadow='md' mb={10} px={7}>
          <Flex
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
            alignItems={{
              base: 'flex-start',
              md: 'center',
            }}
          >
            <Box flex={1} mb='1rem'>
              <Text>Promotion Name</Text>
              <Text fontWeight='bold'>{shopPromotionResult.data?.name}</Text>
            </Box>
            <Box
              flex={1}
              mb='1rem'
              textAlign={{
                base: 'left',
                right: 'right',
              }}
            >
              <Text>Promotion Period</Text>
              <Text fontWeight='bold'>
                {moment(shopPromotionResult.data?.start_date).format(
                  'DD MMM YYYY HH:mm'
                )}{' '}
                -{' '}
                {moment(shopPromotionResult.data?.end_date).format(
                  'DD MMM YYYY HH:mm'
                )}
              </Text>
            </Box>
          </Flex>

          <Flex
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
            alignItems={{
              base: 'flex-start',
              md: 'center',
            }}
          >
            <Box flex={1} mb='1rem'>
              <Text>Minimum Payment</Text>
              <Text fontWeight='bold'>
                {shopPromotionResult.data?.type === 'nominal' &&
                  `Rp.\xa0${
                    shopPromotionResult.data
                      ? convertToRupiah(shopPromotionResult.data.amount)
                      : 0
                  }`}
                {shopPromotionResult.data?.type === 'percentage' &&
                  `${shopPromotionResult.data.amount}%`}
              </Text>
            </Box>
            <Box flex={1} mb='1rem'>
              <Text>Type</Text>
              <Text fontWeight='bold'>{shopPromotionResult.data?.type}</Text>
            </Box>
          </Flex>

          <Flex
            flexDirection={{
              base: 'column',
              md: 'row',
            }}
            alignItems={{
              base: 'flex-start',
              md: 'center',
            }}
          >
            <Box flex={1} mb='1rem'>
              <Text>Quota</Text>
              <Text fontWeight='bold'>{shopPromotionResult.data?.quota}</Text>
            </Box>
            <Box flex={1} mb='1rem'>
              <Text>Maximum Order</Text>
              <Text fontWeight='bold'>
                {shopPromotionResult.data?.max_order}
              </Text>
            </Box>
            <Box flex={1} mb='1rem'>
              <Text>Minimum Paymemt</Text>
              <Text fontWeight='bold'>
                Rp.&nbsp;
                {shopPromotionResult.data
                  ? convertToRupiah(shopPromotionResult.data.minimum_payment)
                  : 0}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box mb='1rem'>
        <Text as='h2' fontWeight='bold' fontSize='1.5rem' mb='1rem'>
          Promotion Products&nbsp;
        </Text>
        <Box p={2} rounded='md' shadow='md' mb={10} px={7} overflow='auto'>
          <DataTable columns={columns} data={promotionProducts} />
        </Box>
      </Box>
    </Box>
  )
}

export default PromotionDetailPage
