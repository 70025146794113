import React from 'react'
import CustomModal from '..'
import PaymentSelection from '../../checkout/PaymentSelection'
import { PaymentSelectionModalProps } from '../entities'

const PaymentSelectionModal = (props: PaymentSelectionModalProps) => {
  const { isOpen, onClose } = props

  return (
    <CustomModal
      title='Select Payment'
      size='lg'
      body={
        <PaymentSelection
          sealabsPays={props.sealabsPays}
          onClose={props.onClose}
          evtOnClick={props.evtOnClick}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default PaymentSelectionModal
