import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import CustomModal from '.'
import PrimaryButton from '../buttons/PrimaryButton'
import SecondaryButton from '../buttons/SecondaryButton'
import CustomForm from '../form'
import { StepUpPasswordFormInputs } from '../form/entities'
import { WalletCreateModalProps } from './entities'
import { useStepupPasswordMutation } from '../../features/users/usersSlice'
import { toast } from 'react-toastify'
import { useChangeWalletPinMutation } from '../../features/wallets/walletsSlice'
import PasswordInput from '../inputs/PasswordInput'

const ChangeWalletPinModal = (props: WalletCreateModalProps) => {
  const { isOpen, onClose } = props
  const [pin, setPin] = useState('')
  const [triggerInputPin, setTriggerInputPin] = useState(false)
  const [inputPin, setInputPin] = useState(false)
  const [triggerStepUpPassword, resultStepup] = useStepupPasswordMutation()
  const [triggerChangePin] = useChangeWalletPinMutation()
  const handleChangePin = (value: string) => {
    setPin(value)
    if (value.length === 6) {
      setInputPin(true)
    } else {
      setInputPin(false)
    }
  }
  const onSubmit = (values: StepUpPasswordFormInputs) => {
    const promiseStepup = triggerStepUpPassword({ password: values.password })
      .unwrap()
      .then(() => {
        setTriggerInputPin(true)
      })

    toast.promise(promiseStepup, {
      pending: 'loading...',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8)
      .required('Must be not empty')
      .label('Password'),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<StepUpPasswordFormInputs>({ resolver: yupResolver(schema) })

  const handleSubmitChangePin = () => {
    const promiseChangePin = triggerChangePin({ pin: pin })
      .unwrap()
      .then(() => {
        setPin('')
        reset()
        setTriggerInputPin(false)
        onClose()
      })

    toast.promise(promiseChangePin, {
      pending: 'loading...',
      success: 'Success Change Wallet Pin',
      error: {
        render({ data }) {
          return `${data}`
        },
      },
    })
  }

  return (
    <CustomModal
      size={{ base: 'sm', md: 'md' }}
      title='Change Wallet Pin'
      body={
        <>
          <Box p={5}>
            {!triggerInputPin && (
              <>
                <Center mb={5}>
                  <Text as={'b'} fontSize={'lg'}>
                    INPUT PASSWORD
                  </Text>
                </Center>
                <Box mb={4}>
                  <CustomForm
                    onSubmit={handleSubmit(onSubmit)}
                    isLoading={isSubmitting || resultStepup.isLoading}
                    submitLabel='Submit'
                  >
                    <PasswordInput
                      autoFocus
                      {...register('password')}
                      isRequired
                      type='password'
                      label='Password'
                      isInvalid={!!errors.password}
                      errorMessage={errors.password?.message}
                    />
                  </CustomForm>
                </Box>
              </>
            )}
            {triggerInputPin && (
              <>
                <Center mb={5}>
                  <Text as={'b'} fontSize={'lg'}>
                    INPUT NEW PIN
                  </Text>
                </Center>
                <Flex justifyContent={'center'}>
                  <HStack>
                    <PinInput size={'lg'} onChange={handleChangePin} mask>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </Flex>
              </>
            )}
          </Box>
        </>
      }
      footer={
        <>
          {triggerInputPin && (
            <ButtonGroup>
              <Flex gap={3}>
                {!inputPin ? (
                  <PrimaryButton
                    onClick={handleSubmitChangePin}
                    flex='1'
                    disabled
                  >
                    Create
                  </PrimaryButton>
                ) : (
                  <PrimaryButton onClick={handleSubmitChangePin} flex='1'>
                    Create
                  </PrimaryButton>
                )}
                <SecondaryButton onClick={onClose} flex='1'>
                  Cancel
                </SecondaryButton>
              </Flex>
            </ButtonGroup>
          )}
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}

export default ChangeWalletPinModal
